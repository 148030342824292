import React, { FunctionComponent } from "react"
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  OutlinedInput,
  FormControlProps,
  SelectChangeEvent,
} from "@mui/material"
import { SelectPair } from "./select-pair"

interface IOutlinedSelectProps extends FormControlProps {
  onValueChange: (value: string) => void
  value: string
  options: SelectPair[]
  name?: string
}

export const OutlinedSelect: FunctionComponent<IOutlinedSelectProps> = (props) => {
  const { onValueChange, options, name, value, ...rest } = props

  const inputLabel = React.useRef<HTMLLabelElement>(null)

  const handleChangeEvent = (event: SelectChangeEvent<string>) => {
    onValueChange(event.target.value)
  }

  return (
    <FormControl variant="outlined" {...rest} fullWidth size="small">
      {name && (
        <InputLabel ref={inputLabel} htmlFor="outlined-select">
          {name}
        </InputLabel>
      )}
      <Select
        value={value}
        onChange={handleChangeEvent}
        inputProps={{
          name,
          id: "outlined-select",
        }}
        label={name}
        input={<OutlinedInput label={name || undefined} name="outlined-input" id="outlined-input" />}
      >
        {options.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.title}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
