import React, { useCallback, useContext } from "react"
import {
  Paper,
  Skeleton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { HouseholdOverviewTableContext } from "../context/household-overview-table-context"
import { HouseholdOverviewFilterContext } from "../context/household-overview-filter-context"
import { HouseholdOverviewTableRow } from "./household-overview-table-row"
import { HouseholdContainerSortingAttribute, OrderBy } from "../../../../api/graphql/graphql-global-types"
import { CustomSnackBarContent } from "../../../partials/wrapper/CustomSnackBarContent"

interface IHouseholdOverviewTableProps {}
export const HouseholdOverviewTable: React.FC<IHouseholdOverviewTableProps> = (props) => {
  const { householdContainers, totalResults, error, loading } = useContext(HouseholdOverviewTableContext)
  const { filter, setPagination } = useContext(HouseholdOverviewFilterContext)
  const theme = useTheme()
  const { t } = useTranslation()

  const handleSort = useCallback(
    (attribute: HouseholdContainerSortingAttribute) => {
      if (attribute === filter.pagination.orderBy) {
        setPagination({
          ...filter.pagination,
          sortingType: filter.pagination.sortingType === OrderBy.ASC ? OrderBy.DESC : OrderBy.ASC,
          page: 0,
        })
      } else {
        setPagination({
          ...filter.pagination,
          sortingType: OrderBy.ASC,
          orderBy: attribute,
          page: 0,
        })
      }
    },
    [filter, setPagination],
  )

  if (error || (!loading && !totalResults)) {
    return <CustomSnackBarContent variant="info" message={t(error ? "errors.generic" : "households.table.no_data")} />
  }

  return (
    <TableContainer component={Paper}>
      <Table aria-label="households table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <TableSortLabel
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    color:
                      filter.pagination.orderBy !== HouseholdContainerSortingAttribute.ADDRESS
                        ? "rgba(0, 0, 0, 0.1)"
                        : undefined,
                  },
                }}
                direction={
                  filter.pagination.sortingType === OrderBy.ASC ||
                  filter.pagination.orderBy !== HouseholdContainerSortingAttribute.ADDRESS
                    ? "asc"
                    : "desc"
                }
                onClick={() => handleSort(HouseholdContainerSortingAttribute.ADDRESS)}
              >
                {t("households.table.address")}
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">{t("households.table.material")}</TableCell>
            <TableCell align="center">{t("households.table.container_type")}</TableCell>
            <TableCell align="center">
              <TableSortLabel
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    color:
                      filter.pagination.orderBy !== HouseholdContainerSortingAttribute.COMMENT
                        ? "rgba(0, 0, 0, 0.1)"
                        : undefined,
                  },
                }}
                direction={
                  filter.pagination.sortingType === OrderBy.ASC ||
                  filter.pagination.orderBy !== HouseholdContainerSortingAttribute.COMMENT
                    ? "asc"
                    : "desc"
                }
                onClick={() => handleSort(HouseholdContainerSortingAttribute.COMMENT)}
              >
                {t("households.table.comment")}
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">{t("households.table.rfid")}</TableCell>
            <TableCell align="center"></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {!loading &&
            householdContainers.map((container) => (
              <HouseholdOverviewTableRow key={container.id} container={container} />
            ))}
          {loading &&
            Array.from(Array(10).keys()).map((num) => (
              <TableRow key={num}>
                <TableCell align="center">
                  <Skeleton height={40} />
                </TableCell>
                <TableCell align="center">
                  <Skeleton height={40} />
                </TableCell>
                <TableCell align="center">
                  <Skeleton height={40} />
                </TableCell>
                <TableCell align="center">
                  <Skeleton height={40} />
                </TableCell>
                <TableCell align="center">
                  <Skeleton height={40} />
                </TableCell>
                <TableCell align="center" sx={{ width: theme.spacing(6) }}>
                  <Skeleton height={40} />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={totalResults}
        rowsPerPage={filter.pagination.pagesize}
        page={filter.pagination.page}
        onPageChange={(_, page) => setPagination({ ...filter.pagination, page })}
        onRowsPerPageChange={(event) =>
          setPagination({ ...filter.pagination, pagesize: parseInt(event.target.value, 10) })
        }
      />
    </TableContainer>
  )
}
