import React, { FunctionComponent, Fragment } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Backdrop,
  CircularProgress,
} from "@mui/material"
import { useTranslation } from "react-i18next"

interface ICloneDialogProps {
  loading: boolean
  open: boolean
  onClose: () => void
  onAccept: () => void
}

export const CloneDialog: FunctionComponent<ICloneDialogProps> = (props) => {
  const { t } = useTranslation()
  const { loading } = props
  return (
    <Fragment>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("clone.header")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary" disabled={loading}>
            {t("no")}
          </Button>
          <Button onClick={props.onAccept} color="primary" autoFocus disabled={loading}>
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
