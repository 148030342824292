import React, { FunctionComponent, Fragment, useContext, useState, useEffect } from "react"
import { Grid, TextField, IconButton, CircularProgress } from "@mui/material"
import { useTranslation } from "react-i18next"
import { UserManagementContext } from "../context/user-management-context"
import { Edit, Done, Clear } from "@mui/icons-material"
import {
  UPDATE_PASSWORD_MUTATION,
  UpdatePasswordResult,
  UpdatePasswordVariables,
} from "../../../../api/graphql/mutations/update-password"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"

interface IPasswordChangerProps {
  email: string
  value: string
  setValue: (value: string) => void
}

export const PasswordChanger: FunctionComponent<IPasswordChangerProps> = (props) => {
  const { value, setValue, email } = props
  const { t } = useTranslation()
  const [isEditing, setIsEditing] = useState<boolean>(false)
  const { selectedUser, isInCreateMode } = useContext(UserManagementContext)

  useEffect(() => {
    setIsEditing(false)
  }, [selectedUser, setIsEditing])

  const onMutationComplete = () => {
    toast.info(t("user_management.data.password_changed"))
    onCancel()
  }

  const [updatePasswordMutation, { loading }] = useMutation<UpdatePasswordResult, UpdatePasswordVariables>(
    UPDATE_PASSWORD_MUTATION,
    { onCompleted: onMutationComplete },
  )

  const onCancel = () => {
    setIsEditing(false)
    setValue("")
  }

  const onSave = () => {
    updatePasswordMutation({
      variables: {
        email,
        password: value,
      },
    })
  }

  return (
    <Fragment>
      <Grid container alignItems="center" alignContent="center" justifyContent="center" spacing={1}>
        <Grid item xs>
          <TextField
            size="small"
            id="outlined-search"
            fullWidth
            disabled={selectedUser && !isEditing}
            label={t("user_management.data.password")}
            variant="outlined"
            value={value}
            type="password"
            onChange={(ev) => {
              setValue(ev.target.value)
            }}
          />
        </Grid>
        {!isInCreateMode && !isEditing && (
          <Fragment>
            <Grid item>
              <IconButton size="small" color="primary" onClick={() => setIsEditing(true)}>
                <Edit />
              </IconButton>
            </Grid>
          </Fragment>
        )}
        {!isInCreateMode && isEditing && (
          <Fragment>
            <Grid item>
              {!loading && (
                <IconButton size="small" color="primary" onClick={onSave}>
                  <Done />
                </IconButton>
              )}
              {loading && <CircularProgress color="primary" size={24} sx={{ p: 0.5 }} />}
            </Grid>
            <Grid item>
              <IconButton size="small" color="primary" onClick={onCancel} disabled={loading}>
                <Clear />
              </IconButton>
            </Grid>
          </Fragment>
        )}
      </Grid>
    </Fragment>
  )
}
