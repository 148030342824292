import React, { FunctionComponent, useEffect } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Backdrop,
  CircularProgress,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLazyQuery } from "@apollo/client"
import {
  GetDbCloningStatusResult,
  GET_DB_CLONING_STATUS_QUERY,
} from "../../../api/graphql/queries/get-db-cloning-status"
import { DbCloningStatus } from "../../../api/graphql/graphql-global-types"
import moment from "moment"

interface ICloneDbStatusDialogProps {
  open: boolean
  onClose: () => void
}

export const CloneDbStatusDialog: FunctionComponent<ICloneDbStatusDialogProps> = (props) => {
  const { t } = useTranslation()
  const [getDbCloningStatus, { loading, data }] = useLazyQuery<GetDbCloningStatusResult>(GET_DB_CLONING_STATUS_QUERY, {
    fetchPolicy: "no-cache",
  })

  useEffect(() => {
    if (props.open) {
      getDbCloningStatus()
    }
  }, [props.open, getDbCloningStatus])

  const getContent = (result: GetDbCloningStatusResult) => {
    switch (result.getDbCloningStatus?.status) {
      case DbCloningStatus.Success:
        return t("clone_status.success", {
          date: moment(result.getDbCloningStatus.startedAt).format(t("date_time_format")),
        })
      case DbCloningStatus.Running:
        return t("clone_status.pending", {
          date: moment(result.getDbCloningStatus.startedAt).format(t("date_time_format")),
        })
      case DbCloningStatus.DeleteTourgenerationsFailedWarning:
        return t("clone_status.delete_tourgenerations_failed_warning")
      case DbCloningStatus.ScheduledToursLeftWarning:
        return t("clone_status.scheduled_tours_left_warning")
      case null:
      case undefined:
        return t("clone_status.null")
      default:
        return t("clone_status.error")
    }
  }

  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <>{!loading && data && getContent(data)}</>
          </DialogContentText>
          <DialogContentText>
            {!loading && data && (
              <>
                {t("clone_status.code")}: <b>{data.getDbCloningStatus?.status ?? "-"}</b>
              </>
            )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary">
            {t("ok")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
