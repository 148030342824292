import React, { FunctionComponent, PropsWithChildren, createContext, useEffect } from "react"
import { useLazyQuery } from "@apollo/client"
import { GET_ROUTE_WITH_ID, Route, RouteResult } from "../../../../api/graphql/queries/get-route"
import { useRefetch } from "../../../../context/refetch-context"

interface IRouteContextProviderProps extends PropsWithChildren<Record<never, never>> {
  routeId: number
}

interface IRouteContext {
  route: Route | undefined
  routeLoading: boolean
}

export const RouteContext = createContext<IRouteContext>({ route: undefined, routeLoading: false })

export const RouteContextProvider: FunctionComponent<IRouteContextProviderProps> = (props) => {
  const { routeId, children } = props
  const { needToRefetch, setNeedToRefetch } = useRefetch()
  const [routeQuery, { data: routeData, loading: routeLoading }] = useLazyQuery<RouteResult>(GET_ROUTE_WITH_ID)

  //initial Load
  useEffect(() => {
    routeQuery({ variables: { id: routeId } })
  }, [routeQuery, routeId])

  //refetch
  useEffect(() => {
    if (needToRefetch) {
      routeQuery({ variables: { id: routeId } })
      setNeedToRefetch(false)
    }
  }, [needToRefetch, setNeedToRefetch, routeQuery, routeId])

  const route = routeData?.getRouteWithId
  return <RouteContext.Provider value={{ route, routeLoading }}>{children}</RouteContext.Provider>
}
