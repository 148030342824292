import React, { FunctionComponent, useContext } from "react"
import { Card, Grid, Tooltip, useTheme } from "@mui/material"
import LocalShippingOutlinedIcon from "@mui/icons-material/LocalShippingOutlined"
import { Text } from "../../../partials/wrapper/text"
import { MinimalTour } from "../../../../api/graphql/queries/get-minimal-tours-with-region-id"
import moment from "moment"
import { useSelectedTour } from "../../../../context/selected-tour-context"
import { useTranslation } from "react-i18next"
import { Badge } from "../../user-management/partials/badge"
import { UserContext } from "../../../../context/user-context"
import { UserRole } from "../../../../api/graphql/graphql-global-types"
import { hexToRgb } from "../../../../utils/color"
import { TourGenerationIcon } from "../../../partials/icons/tour-generation-icon"

interface ITourItemProps {
  num: number
  item: MinimalTour
}

export const TourItem: FunctionComponent<ITourItemProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { item } = props

  const { selectedTour, setSelectedTour } = useSelectedTour()
  const { user } = useContext(UserContext)

  const onClick = () => {
    setSelectedTour(props.item)
  }

  const isSelected = () => {
    if (!selectedTour) return false
    return selectedTour.id === props.item.id
  }

  return (
    <Card
      sx={{
        m: 1,
        p: 1,
        "&:hover": {
          cursor: "pointer",
        },
        boxShadow: isSelected() ? `0 0 4px 0 rgba(${[...hexToRgb(theme.palette.primary.main)]}, 0.5)` : undefined,
      }}
      onClick={onClick}
    >
      <Grid container direction="column">
        <Grid item container alignItems="center" spacing={1} justifyContent="flex-start">
          <Grid item>
            <LocalShippingOutlinedIcon />
          </Grid>
          <Grid item xs>
            <Grid container justifyContent="space-between">
              <Text fontSize={15} bold>
                {item.vehicle.licence_plate}
              </Text>
              {item.manuallyCreated && (
                <Tooltip title={t("tour_overview.manual_tour") as string}>
                  <Grid
                    item
                    sx={{
                      border: "2px solid",
                      paddingRight: 0.3,
                      paddingLeft: 0.3,
                      borderRadius: "50%",
                    }}
                  >
                    M
                  </Grid>
                </Tooltip>
              )}
            </Grid>
          </Grid>
        </Grid>
        <Grid item container direction="row" justifyContent="space-between" spacing={1}>
          <Grid item>
            <Text fontSize={15}>{moment(props.item.date).format(t("date_format"))}</Text>
          </Grid>
          <Grid item>
            <Text fontSize={15}>
              {t("tour_overview.collection_point", {
                count: props.item.collection_points!.length,
              })}
            </Text>
          </Grid>
        </Grid>
        <Grid item container alignItems="center" spacing={1} justifyContent="flex-start" sx={{ mt: 0.5 }}>
          {user?.role === UserRole.SUPER_ADMIN && (
            <Grid item>
              <TourGenerationIcon withoutContainers={!!item.parameters?.without_containers} />
            </Grid>
          )}
          {item.materials.map((material) => (
            <Grid item key={`material_badge_${item.id}_${material.id}`}>
              <Badge color="primary" fontSize={12}>
                {material.name}
              </Badge>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  )
}
