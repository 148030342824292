import { Grid, TextField } from "@mui/material"
import React, { FunctionComponent, useCallback, useMemo } from "react"
import { useTranslation } from "react-i18next"
import { DayOfWeek } from "../../../../../utils/day"
import { isOperatingTimeValid } from "../../../../../utils/vehicle-operating-times"
import { ICustomOperatingTime } from "../tour-generation-vehicle-selection"

const OPERATING_TIME_STEP = 0.5

interface TourGenerationVehicleConfigurationOperatingTimesProps {
  dayOfWeek: DayOfWeek
  customOperatingTime: ICustomOperatingTime
  onCustomOperatingTimeChange: (customOperatingTime: ICustomOperatingTime) => void
  isActive: boolean
}

export const TourGenerationVehicleConfigurationOperatingTimes: FunctionComponent<
  TourGenerationVehicleConfigurationOperatingTimesProps
> = (props) => {
  const { t } = useTranslation()
  const { dayOfWeek, customOperatingTime, onCustomOperatingTimeChange, isActive } = props

  const { minTourDuration, maxTourDuration } = customOperatingTime

  const setMinOperatingTimes = useCallback(
    (value: number) => {
      onCustomOperatingTimeChange({
        day: dayOfWeek,
        minTourDuration: value,
        maxTourDuration: maxTourDuration,
      })
    },
    [maxTourDuration, dayOfWeek, onCustomOperatingTimeChange],
  )

  const setMaxOperatingTimes = useCallback(
    (value: number) => {
      console.log(value)
      onCustomOperatingTimeChange({
        day: dayOfWeek,
        minTourDuration: minTourDuration,
        maxTourDuration: value,
      })
    },
    [minTourDuration, dayOfWeek, onCustomOperatingTimeChange],
  )

  const areOperatingTimesValid = useMemo(
    () => isOperatingTimeValid(minTourDuration, maxTourDuration),
    [minTourDuration, maxTourDuration],
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        <TextField
          size="small"
          error={!areOperatingTimesValid}
          disabled={!isActive}
          label={t("vehicle_overview.data.min_tour_duration")}
          type="number"
          inputProps={{ min: 0, max: 24, step: OPERATING_TIME_STEP }}
          fullWidth
          variant="outlined"
          value={customOperatingTime?.minTourDuration}
          onChange={(ev) => setMinOperatingTimes(Number(ev.target.value))}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        <TextField
          size="small"
          error={!areOperatingTimesValid}
          disabled={!isActive}
          label={t("vehicle_overview.data.max_tour_duration")}
          type="number"
          inputProps={{ min: 0, max: 24, step: OPERATING_TIME_STEP }}
          fullWidth
          variant="outlined"
          value={customOperatingTime?.maxTourDuration}
          onChange={(ev) => setMaxOperatingTimes(Number(ev.target.value))}
        />
      </Grid>
    </Grid>
  )
}
