import { Box, CircularProgress, Grid } from "@mui/material"
import React, { FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { ContentContainer } from "../../../partials/layout/content/content-container"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { RouteContext } from "./route-context"
import { RouteDetailsTable } from "./route-details-table"
import { RouteDetailsMap } from "./route-details-map"
import { LocationSelect } from "../../../partials/location-select/location-select"
import { useMutation } from "@apollo/client"
import {
  UpdateRouteLocationResult,
  UpdateRouteLocationVariables,
  UPDATE_ROUTE_LOCATION_MUTATION,
} from "../../../../api/graphql/mutations/update-route-location"
import { toast } from "react-toastify"

interface IRouteDetailsProps {}

export const RouteDetails: FunctionComponent<IRouteDetailsProps> = (props) => {
  const { t } = useTranslation()
  const { routeLoading, route } = useContext(RouteContext)
  const [updateRouteLocation] = useMutation<UpdateRouteLocationResult, UpdateRouteLocationVariables>(
    UPDATE_ROUTE_LOCATION_MUTATION,
  )

  const update = async (newLocationId: number) => {
    if (!route) return

    const result = await updateRouteLocation({
      variables: {
        routeId: route.id,
        locationId: String(newLocationId),
      },
    })

    if ((result as any).errors) {
      toast.error(t("routes_management.location_select.error"))
    } else {
      toast.success(t("routes_management.location_select.success"))
      route.location_id = newLocationId
    }
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageHeading>{t("routes_management.title")}</PageHeading>
          {!routeLoading && route && (
            <Box mt={3} mb={-1}>
              <LocationSelect defaultId={route.location_id} handleChange={update} />
            </Box>
          )}
        </Grid>
      </Grid>
      {routeLoading && !route && <CircularProgress sx={{ position: "absolute", left: "50%", top: "50%" }} />}
      {!routeLoading && route && (
        <Grid item xs={12}>
          <Grid item>
            <ContentContainer>
              <RouteDetailsMap />
            </ContentContainer>
          </Grid>
          <Grid item>
            <ContentContainer>
              <RouteDetailsTable />
            </ContentContainer>
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
