import React, { FunctionComponent, useCallback, useState, useEffect } from "react"
import { Theme, Typography, Grid, Box, SxProps } from "@mui/material"
import { Accept, useDropzone } from "react-dropzone"
import lodash from "lodash"

interface IDropzoneProps {
  files: File[]
  text: string
  updateFiles: (files: File[]) => void
  accept: Accept
  multiple?: boolean
  onAbort?: () => void
  onError?: () => void
  sx?: SxProps<Theme>
  underline?: React.ReactNode
}

export const Dropzone: FunctionComponent<IDropzoneProps> = (props) => {
  const { updateFiles, text, files, accept, onAbort, onError, sx, multiple = false, underline } = props
  const [currentFile, setCurrentFile] = useState<File>()

  useEffect(() => {
    if (currentFile) {
      updateFiles(multiple ? files.concat(currentFile) : [currentFile])
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [currentFile])

  const onDrop = useCallback((acceptedFiles: File[]) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader()
      reader.onabort = () => {
        if (lodash.isFunction(onAbort)) {
          onAbort()
        }
      }
      reader.onerror = () => {
        if (lodash.isFunction(onError)) {
          onError()
        }
      }
      reader.onload = () => {
        setCurrentFile(file)
      }
      reader.readAsArrayBuffer(file)
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const { getRootProps, getInputProps } = useDropzone({ onDrop, accept, multiple })

  return (
    <Box
      {...getRootProps()}
      sx={{
        borderColor: "primary.main",
        borderWidth: 1,
        borderStyle: "solid",
        display: "flex",
        alignItems: "center",
        p: 3,
        cursor: "pointer",
        ...sx,
      }}
    >
      <input {...getInputProps()} />
      <Grid container direction="column" justifyContent="center" alignItems="center">
        <Grid item>
          <Typography
            sx={{
              textAlign: "center",
              color: "#979797",
              fontSize: "16px",
              fontWeight: "bold",
            }}
          >
            {text}
          </Typography>
        </Grid>
        <Grid
          item
          onClick={(e) => e.stopPropagation()}
          sx={{
            cursor: "auto",
          }}
        >
          {underline}
        </Grid>
      </Grid>
    </Box>
  )
}
