import React, { FunctionComponent, useState, useContext } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Typography,
  FormControlLabel,
  Radio,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { TourGenerationContext } from "../../../../context/tour-generation-context"

interface IConcurrentTourGenerationsDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
}

export const ConcurrentTourGenerationsDialog: FunctionComponent<IConcurrentTourGenerationsDialogProps> = (props) => {
  const { t } = useTranslation()
  const { open, onClose, onConfirm } = props
  const [keepGenerations, setKeepGenerations] = useState<boolean>(true)

  const { setKeepConcurrentGenerations } = useContext(TourGenerationContext)

  const onConfirmSettings = () => {
    setKeepConcurrentGenerations(keepGenerations)
    onConfirm()
  }

  return (
    <Dialog open={open} onClose={onClose} maxWidth="lg">
      <DialogTitle>
        <Typography>{t("tour_generation.concurrent_tour_dialog.title")}</Typography>
      </DialogTitle>
      <DialogContent sx={{ pt: 0 }}>
        <Typography>{t("tour_generation.concurrent_tour_dialog.description")}</Typography>
        <Grid container direction="column">
          <Grid item>
            <FormControlLabel
              control={<Radio checked={keepGenerations} onClick={() => setKeepGenerations(true)} />}
              label={t("tour_generation.concurrent_tour_dialog.keep_concurrent_tour")}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={<Radio checked={!keepGenerations} onClick={() => setKeepGenerations(false)} />}
              label={t("tour_generation.concurrent_tour_dialog.replace_concurrent_tour")}
            />
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Grid container item direction="row" spacing={2} justifyContent="flex-end">
          <Grid item>
            <Button onClick={onClose} color="inherit">
              {t("cancel")}
            </Button>
          </Grid>
          <Grid item>
            <Button onClick={onConfirmSettings} variant="contained" color="primary">
              {t("continue")}
            </Button>
          </Grid>
        </Grid>
      </DialogActions>
    </Dialog>
  )
}
