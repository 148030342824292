import React, { FunctionComponent } from "react"
import { IconButtonProps, IconButton, useTheme } from "@mui/material"
import { ExpandMore } from "@mui/icons-material"

interface ExpandIconProps extends IconButtonProps {
  expanded: boolean
}

export const ExpandIcon: FunctionComponent<ExpandIconProps> = (props) => {
  const { expanded, sx, ...other } = props
  const theme = useTheme()

  return (
    <IconButton
      {...other}
      sx={{
        ...props.sx,
        transform: !expanded ? "rotate(0deg)" : "rotate(180deg)",
        ml: "auto",
        transition: theme.transitions.create("transform", {
          duration: theme.transitions.duration.shortest,
        }),
      }}
    >
      <ExpandMore />
    </IconButton>
  )
}
