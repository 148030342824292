import React, { FunctionComponent, useCallback, useContext, useState } from "react"
import { Grid, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Text } from "../../../../partials/wrapper/text"
import { DeparturePointContext } from "./context/departure-point-context"
import { DeparturePointListItem } from "./departure-point-list-item"
import { DeparturePointManagementDialog } from "./departure-point-management-dialog"

interface IDeparturePointManagementProps {}

export const DeparturePointManagement: FunctionComponent<IDeparturePointManagementProps> = (props) => {
  const { t } = useTranslation()
  const [managementDialogOpen, setManagementDialogOpen] = useState<boolean>(false)
  const { departurePoints, partnerId } = useContext(DeparturePointContext)

  const toggleManagementDialog = useCallback(
    () => setManagementDialogOpen(!managementDialogOpen),
    [setManagementDialogOpen, managementDialogOpen],
  )

  return (
    <>
      <DeparturePointManagementDialog open={managementDialogOpen} onClose={toggleManagementDialog} />
      <Grid container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between">
          <Text bold>{t("partner_overview.departure_point.heading")}</Text>
          <Button color="primary" variant="contained" onClick={toggleManagementDialog} disabled={!partnerId}>
            {t("partner_overview.departure_point.create_departure_point")}
          </Button>
        </Grid>
        <Grid item container sx={{ overflowY: "scroll", flex: 1 }} spacing={1}>
          {departurePoints.map((departurePoint) => (
            <Grid key={departurePoint.id} item xs={12}>
              <DeparturePointListItem departurePoint={departurePoint} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}
