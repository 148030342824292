import React, { FunctionComponent } from "react"
import { Grid, Skeleton, useTheme } from "@mui/material"
import { Card } from "./model/card"
import { CardTile } from "./card-tile"
import { CARD_TILE_WIDTH, CARD_TILE_HEIGHT } from "../../../../utils/constants"

interface ICardsContainerProps {
  cards: Card[]
  justifyContent?: "space-around" | "space-between" | "space-evenly" | "center" | "flex-end" | "flex-start" | undefined
  secondary?: boolean
  loading?: boolean
}

export const CardsContainer: FunctionComponent<ICardsContainerProps> = (props) => {
  const { cards, secondary, loading } = props
  const theme = useTheme()

  return (
    <Grid
      container
      direction={"row"}
      spacing={2}
      alignItems={"stretch"}
      justifyContent={props.justifyContent ? props.justifyContent : "flex-start"}
      sx={{ minHeight: CARD_TILE_HEIGHT + parseFloat(theme.spacing(2)) }}
    >
      {!loading
        ? cards.map((card, index) => (
            <Grid item key={index}>
              <CardTile
                headingText={card.heading}
                contentText={card.content}
                secondary={secondary || false}
                descriptionText={card.description}
              />
            </Grid>
          ))
        : [1, 2, 3, 4].map((number) => (
            <Grid item key={number}>
              <Skeleton height={CARD_TILE_HEIGHT} width={CARD_TILE_WIDTH} style={{ transform: "scale(1)" }} />
            </Grid>
          ))}
    </Grid>
  )
}
