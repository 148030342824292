import React, { FunctionComponent, Fragment, useState, useContext } from "react"
import { Card, Grid, Button, TextField, Avatar, SnackbarContent, Box } from "@mui/material"
import { gql, useApolloClient } from "@apollo/client"
import LockOutlinedIcon from "@mui/icons-material/LockOutlined"
import { useTranslation } from "react-i18next"
import IconButton from "@mui/material/IconButton"
import CloseIcon from "@mui/icons-material/Close"

import { ApiManager } from "../../../api/rest/api-manager"
import { Text } from "../../partials/wrapper/text"
import { PATH } from "../../../router/router"
import { UserService } from "../../../services/user-service"
import { jwtDecode } from "jwt-decode"
import { UserGroups } from "../../../models/user-groups"
import { UserContext } from "../../../context/user-context"
import { useNavigate } from "react-router-dom"
import { LOGO_PATH } from "../../../styles/theme"

interface ILoginPageProps {}

export const LoginPage: FunctionComponent<ILoginPageProps> = (props) => {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const [email, setEmail] = useState<string>("")
  const [password, setPassword] = useState<string>("")
  const [shownError, setShownError] = useState<string | null>(null)
  const [isEmailError, setIsEmailError] = useState<boolean>(false)
  const [isPasswordError, setIsPasswordError] = useState<boolean>(false)
  const [isLoginDisabled, setIsLoginDisabled] = useState<boolean>(false)
  const client = useApolloClient()
  const { loadUser } = useContext(UserContext)

  const onSnackBarClose = () => {
    setShownError(null)
  }

  const onLoginButtonClicked = async () => {
    setIsLoginDisabled(true)
    const validEmail = isValidEmail(email)
    const validPassword = isValidPassword(password)
    setIsEmailError(!validEmail)
    setIsPasswordError(!validPassword)

    if (validEmail && validPassword) {
      try {
        const res = await ApiManager.login(email, password)
        if (res.status >= 300) {
          throw Error()
        }

        const token = res.data.token
        const payload = jwtDecode(token) as any
        const role = payload && payload.role

        if (UserGroups.PORTAL_ACCESS.includes(role)) {
          UserService.login(token)
          client.writeQuery({
            query: gql`
              query {
                authStatus @client {
                  __typename
                  status
                }
              }
            `,
            data: {
              authStatus: {
                __typename: "authStatus",
                status: "loggedIn",
              },
            },
          })

          await loadUser()

          navigate(PATH.DASHBOARD.route, { replace: true })
        } else {
          setShownError(t("errors.no_access"))
          setIsEmailError(false)
          setIsPasswordError(false)
          setIsLoginDisabled(false)
        }
      } catch (error) {
        console.log(error)
        setShownError(t("errors.wrong_login"))
        setIsEmailError(true)
        setIsPasswordError(true)
        setIsLoginDisabled(false)
      }
    } else {
      setShownError(t("errors.wrong_login"))
      setIsLoginDisabled(false)
    }
  }

  const isValidEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email)
  }

  const isValidPassword = (password: string) => {
    return password.length > 0
  }

  const onEnter = (ev: any) => {
    if (ev.key === "Enter") {
      onLoginButtonClicked()
    }
  }

  return (
    <Fragment>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", height: "100%" }}
      >
        <Grid item>
          <Card raised sx={{ height: 370, width: 320 }}>
            <Grid container direction="column" sx={{ height: "100%" }}>
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                sx={{ height: 50, p: 1, backgroundColor: "secondary.dark" }}
              >
                <Box component="img" src={LOGO_PATH} sx={{ width: "60%" }} alt="" />
              </Grid>

              <Grid container direction="column" alignItems="center" sx={{ p: 1, flex: 1 }}>
                <Avatar sx={{ m: 1, backgroundColor: "secondary.dark" }}>
                  <LockOutlinedIcon />
                </Avatar>
                <Text variant="h5">{t("sign_in")}</Text>
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  id="email"
                  label={t("email_address")}
                  name="email"
                  autoComplete="email"
                  autoFocus
                  error={isEmailError}
                  value={email}
                  onChange={(ev) => {
                    setEmail(ev.target.value)
                  }}
                  onKeyPress={onEnter}
                />
                <TextField
                  size="small"
                  variant="outlined"
                  margin="normal"
                  required
                  fullWidth
                  name="password"
                  label={t("password")}
                  type="password"
                  id="password"
                  autoComplete="current-password"
                  error={isPasswordError}
                  value={password}
                  onChange={(ev) => setPassword(ev.target.value)}
                  onKeyPress={onEnter}
                />
              </Grid>
              <Button
                sx={{ m: 1 }}
                variant="contained"
                type="button"
                color="primary"
                onClick={onLoginButtonClicked}
                disabled={isLoginDisabled}
              >
                {t("login")}
              </Button>
            </Grid>
          </Card>
        </Grid>
        <Grid>
          {shownError && (
            <SnackbarContent
              sx={{ backgroundColor: "error.dark", mt: 1 }}
              message={shownError}
              action={[
                <IconButton key="close" aria-label="close" color="inherit" onClick={onSnackBarClose}>
                  <CloseIcon />
                </IconButton>,
              ]}
            />
          )}
        </Grid>
      </Grid>
    </Fragment>
  )
}
