import React, { FunctionComponent, useCallback, useContext, useState } from "react"
import { Grid, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Text } from "../../../../partials/wrapper/text"
import { DisposalTrailerLocationContext } from "./context/disposal-trailer-location-context"
import { DisposalTrailerLocationListItem } from "./disposal-trailer-location-list-item"
import { DisposalTrailerLocationManagementDialog } from "./disposal-trailer-location-management-dialog"

interface IDisposalTrailerLocationManagementProps {}

export const DisposalTrailerLocationManagement: FunctionComponent<IDisposalTrailerLocationManagementProps> = (
  props,
) => {
  const { t } = useTranslation()
  const [managementDialogOpen, setManagementDialogOpen] = useState<boolean>(false)
  const { disposalTrailerLocations, partnerId } = useContext(DisposalTrailerLocationContext)

  const toggleManagementDialog = useCallback(
    () => setManagementDialogOpen(!managementDialogOpen),
    [setManagementDialogOpen, managementDialogOpen],
  )

  return (
    <>
      <DisposalTrailerLocationManagementDialog open={managementDialogOpen} onClose={toggleManagementDialog} />
      <Grid container direction="column" spacing={1}>
        <Grid item container justifyContent="space-between">
          <Text bold>{t("partner_overview.disposal_trailer_location.heading")}</Text>
          <Button color="primary" variant="contained" onClick={toggleManagementDialog} disabled={!partnerId}>
            {t("partner_overview.disposal_trailer_location.create_disposal_trailer_location")}
          </Button>
        </Grid>
        <Grid item container sx={{ overflowY: "scroll", flex: 1 }} spacing={1}>
          {disposalTrailerLocations.map((disposalTrailerLocation) => (
            <Grid key={disposalTrailerLocation.id} item xs={12}>
              <DisposalTrailerLocationListItem disposalTrailerLocation={disposalTrailerLocation} />
            </Grid>
          ))}
        </Grid>
      </Grid>
    </>
  )
}
