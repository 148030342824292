import React, { FunctionComponent, SyntheticEvent, useMemo } from "react"
import { Card, Checkbox, FormControlLabel, Grid, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DayOfWeek, isSameDay, isSameWeekDay } from "../../../../../utils/day"
import { ICustomDeparturePoint, ICustomOperatingTime, IVehicleEntry } from "../tour-generation-vehicle-selection"
import { TourGenerationVehicleConfigurationDeparturePoint } from "./tour-generation-vehicle-configuration-departure-point"
import { TourGenerationVehicleConfigurationOperatingTimes } from "./tour-generation-vehicle-configuration-operating-times"

interface ITourGenerationVehicleConfigurationDayProps {
  vehicle: IVehicleEntry
  onDaySelectionChanged: (days: DayOfWeek[]) => void
  dayOfWeek: DayOfWeek
  toggleCustomDepartureAndEndPoint: () => void
  toggleCustomOperatingTime: () => void
  customDepartureAndEndPoint: boolean
  customOperatingTimeEnabled: boolean
  onCustomDeparturePointEntryChange: (customDeparturePoint: ICustomDeparturePoint) => void
  onCustomOperatingTimeEntryChange: (customOperatingTime: ICustomOperatingTime) => void
}

export const TourGenerationVehicleConfigurationDay: FunctionComponent<ITourGenerationVehicleConfigurationDayProps> = (
  props,
) => {
  const { t } = useTranslation()
  const {
    vehicle,
    onDaySelectionChanged,
    dayOfWeek,
    customDepartureAndEndPoint,
    customOperatingTimeEnabled,
    toggleCustomDepartureAndEndPoint,
    toggleCustomOperatingTime,
    onCustomDeparturePointEntryChange,
    onCustomOperatingTimeEntryChange,
  } = props
  const { availableDays, days } = vehicle
  const theme = useTheme()

  const handleChangeDaySelection = (dayOfWeek: DayOfWeek) => (event: SyntheticEvent, checked: boolean) => {
    if (checked && !days.some(isSameWeekDay(dayOfWeek))) {
      onDaySelectionChanged([...days, dayOfWeek])
    }
    if (!checked && days.some(isSameWeekDay(dayOfWeek))) {
      onDaySelectionChanged(days.filter((day) => !isSameWeekDay(day)(dayOfWeek)))
    }
    if (!checked && customDepartureAndEndPoint) {
      handleChangeCustomDeparturePoint(false)
    }
    if (!checked && customOperatingTimeEnabled) {
      handleChangeCustomOperatingTime(false)
    }
  }

  const handleChangeCustomDeparturePoint = (checked: boolean) => {
    toggleCustomDepartureAndEndPoint()
    if (!checked) {
      onCustomDeparturePointEntryChange({
        day: dayOfWeek,
        departurePoint: undefined,
        endPoint: undefined,
      })
    }
  }

  const handleChangeCustomOperatingTime = (checked: boolean) => {
    toggleCustomOperatingTime()
    if (!checked) {
      onCustomOperatingTimeEntryChange({
        day: dayOfWeek,
        minTourDuration: undefined,
        maxTourDuration: undefined,
      })
    } else if (checked) {
      const standardOperatingTime = vehicle.standardOperatingTimes.find(
        (operatingTime) => operatingTime.day === dayOfWeek.day,
      )
      onCustomOperatingTimeEntryChange({
        day: dayOfWeek,
        minTourDuration: standardOperatingTime?.min_tour_duration,
        maxTourDuration: standardOperatingTime?.max_tour_duration,
      })
    }
  }

  const customSelected = useMemo(() => !!customDepartureAndEndPoint, [customDepartureAndEndPoint])

  const customOperatingTimesSelected = useMemo(() => !!customOperatingTimeEnabled, [customOperatingTimeEnabled])

  const customDeparturePointEntry: ICustomDeparturePoint | undefined = vehicle.customDeparturePoints.find(
    (customEntry) => customEntry.day.day === dayOfWeek.day && customEntry.day.week === dayOfWeek.week,
  )

  const customOperatingTimeEntry = vehicle.customOperatingTimes.find((customEntry) => {
    return isSameWeekDay(customEntry.day)(dayOfWeek)
  })

  const disableCustomDeparturePoint = useMemo(
    () => !availableDays.some(isSameDay(dayOfWeek)) || !days.some(isSameWeekDay(dayOfWeek)),
    [availableDays, days, dayOfWeek],
  )

  return (
    <Card sx={{ p: 1 }}>
      <Grid container direction="column" spacing={1}>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={days.some(isSameWeekDay(dayOfWeek))}
                  disabled={!availableDays.map((d) => d as string).includes(dayOfWeek.day)}
                  onChange={handleChangeDaySelection(dayOfWeek)}
                  style={{
                    color: availableDays.map((d) => d as string).some((d) => d === dayOfWeek.day)
                      ? theme.palette.primary.light
                      : "#bfbfbf",
                  }}
                />
              }
              label={t(`weekdays.${dayOfWeek.day}_short`)}
            />
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  style={{
                    color: disableCustomDeparturePoint ? "#bfbfbf" : theme.palette.primary.light,
                  }}
                  disabled={disableCustomDeparturePoint}
                  checked={customSelected}
                  onChange={(event, checked) => handleChangeCustomDeparturePoint(checked)}
                />
              }
              label={t("tour_generation.use_custom_point")}
            />
            <FormControlLabel
              control={
                <Checkbox
                  style={{
                    color: disableCustomDeparturePoint ? "#bfbfbf" : theme.palette.primary.light,
                  }}
                  disabled={disableCustomDeparturePoint}
                  checked={customOperatingTimesSelected}
                  onChange={(event, checked) => handleChangeCustomOperatingTime(checked)}
                />
              }
              label={t("tour_generation.use_custom_operating_times")}
            />
          </Grid>
        </Grid>
        {customSelected && (
          <Grid item>
            <TourGenerationVehicleConfigurationDeparturePoint
              dayOfWeek={dayOfWeek}
              onCustomDeparturePointEntryChange={onCustomDeparturePointEntryChange}
              customDeparturePointEntry={customDeparturePointEntry}
            />
          </Grid>
        )}
        {customOperatingTimesSelected && customOperatingTimeEntry && (
          <Grid item sx={{ mt: 2 }}>
            <TourGenerationVehicleConfigurationOperatingTimes
              isActive={customOperatingTimesSelected}
              dayOfWeek={dayOfWeek}
              onCustomOperatingTimeChange={onCustomOperatingTimeEntryChange}
              customOperatingTime={customOperatingTimeEntry}
            />
          </Grid>
        )}
      </Grid>
    </Card>
  )
}
