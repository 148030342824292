import { Divider, Grid } from "@mui/material"
import React, { FunctionComponent, PropsWithChildren } from "react"
import { Text } from "../../wrapper/text"

interface IDividerTextProps extends PropsWithChildren<Record<never, never>> {}

export const DividerText: FunctionComponent<IDividerTextProps> = (props) => {
  return (
    <Grid container item xs={12} justifyContent="space-between" alignItems="center" spacing={2}>
      <Grid item xs>
        <Divider />
      </Grid>
      <Grid item>
        <Text bold>{props.children}</Text>
      </Grid>
      <Grid item xs>
        <Divider />
      </Grid>
    </Grid>
  )
}
