import React, { FunctionComponent, useContext } from "react"
import { Card, Grid, CardActionArea } from "@mui/material"
import { User } from "../../../../api/graphql/queries/get-users"
import { UserManagementContext } from "../context/user-management-context"
import { Text } from "../../../partials/wrapper/text"
import { Badge } from "./badge"
import { useTranslation } from "react-i18next"

interface IUserListItemProps {
  user: User
}

export const UserListItem: FunctionComponent<IUserListItemProps> = (props) => {
  const { user } = props
  const { t } = useTranslation()
  const { selectedUser, updateSelectedUser } = useContext(UserManagementContext)

  const isSelected = selectedUser && selectedUser.id === user.id

  return (
    <Card
      sx={{ m: 1, boxShadow: isSelected ? "0 0 4px 0 rgba(75, 151, 62, 0.5)" : undefined }}
      onClick={() => updateSelectedUser(user)}
    >
      <CardActionArea sx={{ height: "100%", p: 1 }}>
        <Grid container sx={{ height: "100%" }} direction="column">
          <Grid item>
            <Text bold noWrap>
              {user.email || "-"}
            </Text>
          </Grid>
          <Grid item>
            <Text fontSize={15} noWrap>
              {`${user.first_name || "-"} ${user.last_name || "-"}`}
            </Text>
          </Grid>
          <Grid item container justifyContent="flex-end">
            {user.role && <Badge color="primary">{t(`user_role.${user.role}`)}</Badge>}
          </Grid>
        </Grid>
      </CardActionArea>
    </Card>
  )
}
