import React, { FunctionComponent } from "react"
import { Grid, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { UserManagementContextProvider } from "./context/user-management-context"
import { PageHeading } from "../../partials/layout/heading/page-heading"
import { RefetchProvider } from "../../../context/refetch-context"
import { UserManagementContent } from "./partials/user-management-content"
import { UserManagementSelector } from "./partials/user-management-selector"

interface IUserManagementPageProps {}

export const UserManagementPage: FunctionComponent<IUserManagementPageProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()

  return (
    <RefetchProvider>
      <UserManagementContextProvider>
        <Grid container sx={{ height: "calc(100vh - 56px)", display: "flex", flexFlow: "column" }}>
          <Grid item sx={{ p: `0 ${theme.spacing(1)}`, flex: "0 0 auto", mb: 1, width: "100%" }}>
            <PageHeading>{t("user_management.heading")}</PageHeading>
          </Grid>
          <Grid
            container
            item
            direction="row"
            sx={{ p: `0 ${theme.spacing(1)}px`, flex: "1 1 auto", overflowY: "auto", height: "100%" }}
          >
            <UserManagementSelector />
            <Grid
              sx={{
                display: "flex",
                flex: 1,
                flexDirection: "column",
                height: "100%",
                pl: 2,
                pr: 2,
                ml: 2,
                overflowY: "auto",
              }}
            >
              <Grid item>
                <UserManagementContent />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </UserManagementContextProvider>
    </RefetchProvider>
  )
}
