import React, { createContext, FC, PropsWithChildren, useContext, useState } from "react"

interface IUnsavedDataContext {
  hasUnsavedData: boolean
  setHasUnsavedData: (hasUnsavedData: boolean) => void
  unsavedDataTitle: string
  setUnsavedDataTitle: (title: string) => void
  unsavedDataContent: string
  setUnsavedDataContent: (content: string) => void
}

export const UnsavedDataContext = createContext<IUnsavedDataContext>({} as IUnsavedDataContext)

export const useUnsavedDataContext = (): IUnsavedDataContext => {
  return useContext(UnsavedDataContext)
}

const useUnsavedDataProvider = (): IUnsavedDataContext => {
  const [hasUnsavedData, setHasUnsavedData] = useState<boolean>(false)
  const [unsavedDataTitle, setUnsavedDataTitle] = useState<string>("")
  const [unsavedDataContent, setUnsavedDataContent] = useState<string>("")

  return {
    hasUnsavedData,
    setHasUnsavedData,
    setUnsavedDataContent,
    setUnsavedDataTitle,
    unsavedDataContent,
    unsavedDataTitle,
  }
}

export const UnsavedDataProvider: FC<PropsWithChildren<Record<never, never>>> = (props) => {
  const value = useUnsavedDataProvider()
  return <UnsavedDataContext.Provider value={value}>{props.children}</UnsavedDataContext.Provider>
}
