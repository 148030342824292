import React, { FunctionComponent, useEffect, useState } from "react"
import { Button, Card, Grid, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { toast } from "react-toastify"
import { useMutation } from "@apollo/client"

import { DisposalMerchantDeleteDialog } from "./disposal-merchant-delete-dialog"
import { DisposalMerchantMaterials } from "./disposal-merchant-materials"
import { DisposalMerchantOpeningHours } from "./disposal-merchant-opening-hours"
import { DisposalMerchantVacationTimes } from "./disposal-merchant-vacation-times"
import { Text } from "../../../partials/wrapper/text"
import { useSelectedDisposalMerchant } from "../../../../context/selected-disposal-merchant-context"
import {
  CREATE_DISPOSAL_MERCHANT_MUTATION,
  CreateDisposalMerchantResult,
} from "../../../../api/graphql/mutations/create-disposal-merchant"
import { CREATE_DISPOSAL_MERCHANT_VACATION_TIMES_MUTATION } from "../../../../api/graphql/mutations/create-disposal-merchant-vacation-times"
import { UPDATE_DISPOSAL_MERCHANT_MUTATION } from "../../../../api/graphql/mutations/update-disposal-merchant"
import { UPDATE_OPENING_HOUR_MUTATION } from "../../../../api/graphql/mutations/update-opening-hours"
import { UPDATE_DISPOSAL_MERCHANT_VACATION_TIME_MUTATION } from "../../../../api/graphql/mutations/update-disposal-merchant-vacation-time"
import { REMOVE_DISPOSAL_MERCHANT_MUTATION } from "../../../../api/graphql/mutations/remove-disposal-merchant"
import { useRefetch } from "../../../../context/refetch-context"
import {
  DisposalMerchantStatsMaterial,
  DisposalMerchantStatsOpeningHours,
  DisposalMerchantStatsVacationTimes,
} from "../../../../api/graphql/queries/get-disposal-merchant-stats-with-id"
import { getInitialDisposalMerchantOpeningHours } from "../../../../utils/day"
import { isIE } from "../../../../utils/browser"
import moment from "moment"
import lodash from "lodash"
import { DisposalMerchantLocationData } from "./disposal-merchant-location-data"
import { defaultLocation, toGeoFloat } from "../../../../utils/map"
import { DisposalMerchantDistrictSelector } from "./disposal-merchant-district-selector"
import { DisposalMerchantTownSelector } from "./disposal-merchant-town-selector"
import { ILocationCollectionItem } from "./location-assignment-dialog"
import * as _ from "lodash"

interface IDisposalMerchantDataProps {}

export const DisposalMerchantData: FunctionComponent<IDisposalMerchantDataProps> = (props) => {
  const { t } = useTranslation()
  const [id, setId] = useState<number>(0)
  const [name, setName] = useState<string>("")
  const [street, setStreet] = useState<string>("")
  const [postal, setPostal] = useState<string>("")
  const [isPostalValid, setIsPostalValid] = useState<boolean>(true)
  const [town, setTown] = useState<string>("")
  const [contactName, setContactName] = useState<string>("")
  const [phoneNumber, setPhoneNumber] = useState<string>("")
  const [latitude, setLatitude] = useState<string>(`${defaultLocation.latitude}`)
  const [longitude, setLongitude] = useState<string>(`${defaultLocation.longitude}`)
  const [email, setEmail] = useState<string>("")
  const [materials, setMaterials] = useState<DisposalMerchantStatsMaterial[]>([])
  const [disposalMerchantToOpeningHours, setDisposalMerchantToOpeningHours] = useState<
    DisposalMerchantStatsOpeningHours[]
  >([])
  const [disposalMerchantVacationTimes, setDisposalMerchantVacationTimes] = useState<
    DisposalMerchantStatsVacationTimes[]
  >([])
  const [deletedVacationTimeIds, setDeletedVacationTimeIds] = useState<number[]>([])
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState<boolean>(false)
  const {
    selectedDisposalMerchantStats,
    setSelectedDisposalMerchantId,
    setNeedToRefetch: setNeedToRefetchDisposalMerchant,
  } = useSelectedDisposalMerchant()
  const [isSaveCreateButtonDisabled, setIsSaveCreateButtonDisabled] = useState<boolean>(false)
  const [selectedDistricts, setSelectedDistricts] = useState<ILocationCollectionItem[]>([])
  const [selectedTowns, setSelectedTowns] = useState<ILocationCollectionItem[]>([])
  const { setNeedToRefetch } = useRefetch()
  const [createDisposalMerchant] = useMutation(CREATE_DISPOSAL_MERCHANT_MUTATION)

  const [createDisposalMerchantVacationTimes] = useMutation(CREATE_DISPOSAL_MERCHANT_VACATION_TIMES_MUTATION)

  const [updateDisposalMerchant] = useMutation(UPDATE_DISPOSAL_MERCHANT_MUTATION)

  const [updateOpeningHour] = useMutation(UPDATE_OPENING_HOUR_MUTATION)
  const [updateVacationTime] = useMutation(UPDATE_DISPOSAL_MERCHANT_VACATION_TIME_MUTATION)

  const [removeDisposalMerchant] = useMutation(REMOVE_DISPOSAL_MERCHANT_MUTATION)

  const clearFields = () => {
    setName("")
    setStreet("")
    setPostal("")
    setTown("")
    setContactName("")
    setPhoneNumber("")
    setEmail("")
    setLatitude(`${defaultLocation.latitude}`)
    setLongitude(`${defaultLocation.longitude}`)
    setMaterials([])
    setSelectedDistricts([])
    setSelectedTowns([])
    setDisposalMerchantToOpeningHours(getInitialDisposalMerchantOpeningHours([], "08:00", "16:30"))
    setDisposalMerchantVacationTimes([])
    setDeletedVacationTimeIds([])
  }

  useEffect(() => {
    if (selectedDisposalMerchantStats) {
      setId(selectedDisposalMerchantStats.disposal_merchant.id)
      setName(selectedDisposalMerchantStats.disposal_merchant.name)
      setStreet(selectedDisposalMerchantStats.disposal_merchant.street || "")
      setPostal(
        selectedDisposalMerchantStats.disposal_merchant.postal
          ? selectedDisposalMerchantStats.disposal_merchant.postal.toString()
          : "",
      )
      setTown(selectedDisposalMerchantStats.disposal_merchant.town || "")
      setContactName(selectedDisposalMerchantStats.disposal_merchant.contact_name || "")
      setPhoneNumber(selectedDisposalMerchantStats.disposal_merchant.phone_number || "")
      setEmail(selectedDisposalMerchantStats.disposal_merchant.email || "")
      setLatitude(`${selectedDisposalMerchantStats.disposal_merchant.latitude || ""}`)
      setLongitude(`${selectedDisposalMerchantStats.disposal_merchant.longitude || ""}`)
      setMaterials(selectedDisposalMerchantStats.disposal_merchant.materials || [])
      setDisposalMerchantToOpeningHours(
        getInitialDisposalMerchantOpeningHours(
          selectedDisposalMerchantStats.disposal_merchant.disposalMerchantToOpeningHours || [],
          "08:00",
          "16:30",
        ),
      )
      setDisposalMerchantVacationTimes(
        (selectedDisposalMerchantStats.disposal_merchant.vacation_times || [])
          .sort((a, b) => moment(a.from).diff(moment(b.from)))
          .filter((vacationTime) => moment(vacationTime.to).diff(moment().utc().startOf("day")) >= 0),
      )
      setDeletedVacationTimeIds([])
      setSelectedDistricts(selectedDisposalMerchantStats.disposal_merchant.districts)
      setSelectedTowns(selectedDisposalMerchantStats.disposal_merchant.towns)
    } else {
      clearFields()
    }
  }, [selectedDisposalMerchantStats])

  const validateFields = (): string => {
    if (!name || name === "") {
      return "name_required"
    }
    if (!validateVacationTimes()) {
      return "overlapping_vacation_times"
    }
    if (!validateOpeningHours()) {
      return "invalid_opening_hours"
    }
    if (selectedDistricts.length === 0) {
      return "district_required"
    }
    const errorTemp: boolean[] = new Array(selectedDistricts.length).fill(false)
    for (let i = 0; i < selectedDistricts.length; i++) {
      errorTemp[i] = !_.some(selectedTowns, { district_id: Number(selectedDistricts[i].id) })
    }
    if (_.includes(errorTemp, true) && !_.every(errorTemp)) {
      return "wrong_towns"
    }
    return ""
  }

  const validateVacationTimes = (): boolean => {
    return !lodash.some(
      disposalMerchantVacationTimes.map(
        (vacationTime1) =>
          disposalMerchantVacationTimes.filter(
            (vacationTime2) =>
              vacationTime1 !== vacationTime2 &&
              moment(vacationTime1.to).diff(vacationTime2.to) <= 0 &&
              moment(vacationTime1.to).diff(vacationTime2.from) >= 0,
          ).length > 0,
      ),
    )
  }

  const validateOpeningHours = (): boolean => {
    return !disposalMerchantToOpeningHours.some((item) => {
      const from = moment(item.openingHour.from, "HH:mm")
      const to = moment(item.openingHour.to, "HH:mm")

      return !from.isValid() || !to.isValid() || !from.isSameOrBefore(to)
    })
  }

  const onDeleteDisposalMerchantClicked = () => {
    setIsDeleteDialogOpen(true)
  }
  const onSaveDisposalMerchantClicked = async () => {
    const validationError = validateFields()
    if (validationError) {
      toast.error(t(`disposal_merchant_overview.data.${validationError}`))
      return
    }
    setIsSaveCreateButtonDisabled(true)
    if (selectedDisposalMerchantStats) {
      try {
        let res = await updateDisposalMerchant({
          variables: {
            id,
            name,
            town,
            postal: parseInt(postal),
            street,
            contact_name: contactName,
            phone_number: phoneNumber,
            latitude: toGeoFloat(latitude),
            longitude: toGeoFloat(longitude),
            email,
            materials: materials.length
              ? materials.map((material) => ({
                  id: parseInt(material.id),
                  name: material.name,
                }))
              : null,
            opening_hours: disposalMerchantToOpeningHours
              .filter((openingHoursOfDay) => !openingHoursOfDay.openingHour.id)
              .map((openingHoursOfDay) => ({
                active: openingHoursOfDay.active,
                from: openingHoursOfDay.openingHour.from,
                to: openingHoursOfDay.openingHour.to,
                day: openingHoursOfDay.openingHour.day,
              })),
            districtIds: selectedDistricts.map((district) => district.id),
            townIds: selectedTowns.length > 0 ? selectedTowns.map((town) => town.id) : undefined,
            deletedVacationTimeIds,
          },
        })

        await Promise.all(
          disposalMerchantToOpeningHours
            .filter((openingHoursOfDay) => !!openingHoursOfDay.openingHour.id)
            .map(async (openingHoursOfDay) => {
              await updateOpeningHour({
                variables: {
                  id: openingHoursOfDay.openingHour.id,
                  opening_hour: {
                    active: openingHoursOfDay.active,
                    from: openingHoursOfDay.openingHour.from,
                    to: openingHoursOfDay.openingHour.to,
                    day: openingHoursOfDay.openingHour.day,
                  },
                },
              })
            }),
        )

        await Promise.all(
          disposalMerchantVacationTimes
            .filter((vacationTime) => !!vacationTime.id)
            .map(async (vacationTime) => {
              await updateVacationTime({
                variables: {
                  id: vacationTime.id,
                  vacationTime: {
                    from: vacationTime.from,
                    to: vacationTime.to,
                  },
                },
              })
            }),
        )

        res = await createDisposalMerchantVacationTimes({
          variables: {
            disposalMerchantId: id,
            vacationTimes: disposalMerchantVacationTimes.filter((vacationTime) => !vacationTime.id),
          },
        })

        if (res && (res as any).errors && (res as any).errors!.length > 0) throw new Error()
        toast.info(t("disposal_merchant_overview.data.disposal_merchant_updated"))
        setNeedToRefetch(true)
        setNeedToRefetchDisposalMerchant(true)
      } catch (e) {
        toast.error(t("disposal_merchant_overview.data.could_not_update"))
      }
    } else {
      try {
        let res = await createDisposalMerchant({
          variables: {
            name,
            town,
            postal: parseInt(postal),
            street,
            contact_name: contactName,
            phone_number: phoneNumber,
            email,
            latitude: toGeoFloat(latitude),
            longitude: toGeoFloat(longitude),
            materials: materials.length
              ? materials.map((material) => ({
                  id: parseInt(material.id),
                  name: material.name,
                }))
              : null,
            districtIds: selectedDistricts.map((district) => district.id),
            townIds: selectedTowns.length > 0 ? selectedTowns.map((town) => town.id) : undefined,
            opening_hours: disposalMerchantToOpeningHours.map((openingHoursOfDay) => ({
              active: openingHoursOfDay.active,
              from: openingHoursOfDay.openingHour.from,
              to: openingHoursOfDay.openingHour.to,
              day: openingHoursOfDay.openingHour.day,
            })),
          },
        })

        if (res.data) {
          res = await createDisposalMerchantVacationTimes({
            variables: {
              disposalMerchantId: (res.data as CreateDisposalMerchantResult).createDisposalMerchant.id,
              vacationTimes: disposalMerchantVacationTimes,
            },
          })
        }

        if (res && (res as any).errors && (res as any).errors!.length > 0) throw new Error()
        toast.info(t("disposal_merchant_overview.data.disposal_merchant_created"))
        setNeedToRefetch(true)
        clearFields()
      } catch (e) {
        toast.error(t("disposal_merchant_overview.data.could_not_create"))
      }
    }
    setIsSaveCreateButtonDisabled(false)
  }

  const deleteDisposalMerchant = async () => {
    try {
      const res = await removeDisposalMerchant({ variables: { id } })
      if (res && (res as any).errors && (res as any)!.errors!.length > 0) throw new Error()

      toast.info(t("disposal_merchant_overview.data.disposal_merchant_deleted"))
      setNeedToRefetch(true)
    } catch (e) {
      toast.error(t("disposal_merchant_overview.data.could_not_delete"))
    }

    setSelectedDisposalMerchantId(undefined)
    setIsDeleteDialogOpen(false)
  }

  return (
    <Card
      sx={{
        minHeight: 310,
        height: isIE ? "100%" : "calc(100vh - 110px)",
        overflowY: "auto",
      }}
    >
      <Grid container direction="column" sx={{ p: 1, minHeight: "100%" }}>
        <Grid container item direction={"row"} spacing={2} sx={{ mb: 1, flex: isIE ? "1 0 0" : "1 1 auto" }}>
          <Grid item container direction="column" spacing={2}>
            <Grid item>
              <Text sx={{ fontSize: 18 }} bold>
                {t("disposal_merchant_overview.data.title")}
              </Text>
            </Grid>
            <Grid item>
              <TextField
                size="small"
                id="outlined-search"
                fullWidth
                label={t("disposal_merchant_overview.data.name")}
                type="search"
                required
                variant="outlined"
                placeholder="Saubermacher"
                value={name}
                onChange={(ev) => {
                  setName(ev.target.value)
                }}
              />
            </Grid>
            <Grid item container spacing={1}>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  id="outlined-search"
                  label={t("disposal_merchant_overview.data.street")}
                  type="search"
                  fullWidth
                  variant="outlined"
                  placeholder="Neue Straße 3"
                  value={street}
                  onChange={(ev) => {
                    setStreet(ev.target.value)
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  error={!isPostalValid}
                  id="outlined-search"
                  label={t("disposal_merchant_overview.data.postal")}
                  type="search"
                  fullWidth
                  variant="outlined"
                  placeholder="8020"
                  value={postal}
                  onChange={(ev) => {
                    setPostal(ev.target.value.toString())
                    setIsPostalValid(ev.target.value && !/^\d+$/.test(ev.target.value) ? false : true)
                  }}
                />
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  id="outlined-search"
                  label={t("disposal_merchant_overview.data.town")}
                  type="search"
                  fullWidth
                  variant="outlined"
                  placeholder="Graz"
                  value={town}
                  onChange={(ev) => {
                    setTown(ev.target.value)
                  }}
                />
              </Grid>
            </Grid>
            <Grid item container direction="column" spacing={2}>
              <Grid item>
                <Card>
                  <Grid container spacing={1} sx={{ p: 1, minHeight: "100%" }}>
                    <Grid item>
                      <Text>{t("disposal_merchant_overview.data.contact_person")}</Text>
                    </Grid>
                    <Grid item container spacing={1}>
                      <Grid item xs={4}>
                        <TextField
                          size="small"
                          id="name"
                          label={t("disposal_merchant_overview.data.name")}
                          type="search"
                          fullWidth
                          variant="outlined"
                          placeholder="Max Mustermann"
                          value={contactName}
                          onChange={(ev) => {
                            setContactName(ev.target.value)
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          size="small"
                          id="phone_number"
                          label={t("disposal_merchant_overview.data.phone_number")}
                          type="search"
                          fullWidth
                          variant="outlined"
                          placeholder="+43 664 1234567"
                          value={phoneNumber}
                          onChange={(ev) => {
                            setPhoneNumber(ev.target.value)
                          }}
                        />
                      </Grid>
                      <Grid item xs={4}>
                        <TextField
                          size="small"
                          id="email"
                          label={t("disposal_merchant_overview.data.email")}
                          type="search"
                          fullWidth
                          variant="outlined"
                          placeholder="mustermann@muster.at"
                          value={email}
                          onChange={(ev) => {
                            setEmail(ev.target.value)
                          }}
                        />
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            </Grid>
          </Grid>
          <Grid item container spacing={2}>
            <Grid item xs={12} lg={5}>
              {/* Card for DisposalMerchant Materials Management */}
              <DisposalMerchantMaterials selectedMaterials={materials} updateSelectedMaterials={setMaterials} />
            </Grid>
            <Grid item xs={12} lg={7}>
              {/* Card for DisposalMerchant Vacation Times Management */}
              <DisposalMerchantVacationTimes
                vacationTimes={disposalMerchantVacationTimes}
                updateVacationTimes={setDisposalMerchantVacationTimes}
                addIdToDeletedVacationTimeIds={(id: number) =>
                  setDeletedVacationTimeIds(deletedVacationTimeIds.concat(id))
                }
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            {/* Card for DisposalMerchant Districts */}
            <DisposalMerchantDistrictSelector
              selectedDistricts={selectedDistricts}
              setSelectedDistricts={setSelectedDistricts}
              selectedTowns={selectedTowns}
              setSelectedTowns={setSelectedTowns}
            />
          </Grid>
          <Grid container item xs={12}>
            {/* Card for DisposalMerchant Towns */}
            <DisposalMerchantTownSelector
              selectedDistricts={selectedDistricts}
              selectedTowns={selectedTowns}
              setSelectedTowns={setSelectedTowns}
            />
          </Grid>
          <Grid container item xs={12}>
            <Grid item lg={2} />
            <Grid item xs={12}>
              {/* Card for DisposalMerchant Opening Hours Management */}
              <DisposalMerchantOpeningHours
                openingHours={disposalMerchantToOpeningHours}
                updateOpeningHours={setDisposalMerchantToOpeningHours}
              />
            </Grid>
          </Grid>
          <Grid container item xs={12}>
            <Grid item lg={2} />
            <Grid item xs={12}>
              {/* Card for DisposalMerchant Opening Hours Management */}
              <DisposalMerchantLocationData
                latitude={latitude}
                longitude={longitude}
                updateLatitude={setLatitude}
                updateLongitude={setLongitude}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid sx={{ mb: 1 }} item container justifyContent={"space-between"}>
          <Button
            variant="contained"
            type="button"
            disabled={!selectedDisposalMerchantStats}
            onClick={onDeleteDisposalMerchantClicked}
            color="error"
          >
            {t("disposal_merchant_overview.data.delete_disposal_merchant")}
          </Button>
          <Button
            variant="contained"
            type="button"
            color="primary"
            onClick={onSaveDisposalMerchantClicked}
            disabled={isSaveCreateButtonDisabled || !isPostalValid}
          >
            {selectedDisposalMerchantStats
              ? t("disposal_merchant_overview.data.save_disposal_merchant")
              : t("disposal_merchant_overview.data.create_disposal_merchant")}
          </Button>
        </Grid>
      </Grid>
      <DisposalMerchantDeleteDialog
        onDelete={deleteDisposalMerchant}
        onClose={() => setIsDeleteDialogOpen(false)}
        open={isDeleteDialogOpen}
      />
    </Card>
  )
}
