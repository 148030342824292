import React, { FC, PropsWithChildren, useCallback, useState } from "react"
import { useCountyFilter } from "../../../../hooks/use-county-filter"
import { useMaterialFilter } from "../../../../hooks/use-material-filter"
import { useTownFilter } from "../../../../hooks/use-town-filter"
import { useDistrictFilter } from "../../../../context/DistrictFilterContext"
import { SelectOption } from "../../../partials/customselect/custom-select-component-commons"
import { HouseholdContainerSortingAttribute, OrderBy } from "../../../../api/graphql/graphql-global-types"

import { useDebounce } from "../../../../utils/useDebounce"
import { useSelectedRegion } from "../../../../hooks/use-selected-region"

interface IHouseholdOverviewFilter {
  selectedCounties: SelectOption[]
  selectedTowns: SelectOption[]
  selectedMaterials: SelectOption[]
  address: string
  showWarnings: boolean
  pagination: IHouseholdTablePagination
}

interface IHouseholdOverviewFilterContext {
  countyOptions: SelectOption[]
  materialOptions: SelectOption[]
  townOptions: SelectOption[]
  resetFilter: () => void
  filter: IHouseholdOverviewFilter
  updateFilter: (newFilter: IHouseholdOverviewFilter) => void
  address: string
  setAddress: (value: string) => void
  setPagination: (value: IHouseholdTablePagination) => void
}

interface IHouseholdTablePagination {
  page: number
  pagesize: number
  orderBy: HouseholdContainerSortingAttribute
  sortingType: OrderBy
}

const DEFAULT_PAGINATION: IHouseholdTablePagination = {
  page: 0,
  pagesize: 10,
  orderBy: HouseholdContainerSortingAttribute.ID,
  sortingType: OrderBy.ASC,
}

const DEFAULT_FILTER: IHouseholdOverviewFilter = {
  selectedCounties: [],
  selectedMaterials: [],
  selectedTowns: [],
  address: "",
  showWarnings: false,
  pagination: DEFAULT_PAGINATION,
}

export const HouseholdOverviewFilterContext = React.createContext<IHouseholdOverviewFilterContext>({
  countyOptions: [],
  materialOptions: [],
  townOptions: [],
  resetFilter: () => null,
  filter: DEFAULT_FILTER,
  updateFilter: () => null,
  address: "",
  setAddress: () => null,
  setPagination: () => null,
})

export const HouseholdOverviewFilterContextProvider: FC<PropsWithChildren<Record<never, never>>> = (props) => {
  const { variables } = useSelectedRegion()
  const { selectedDistrict } = useDistrictFilter()

  const [filter, setFilter] = useState<IHouseholdOverviewFilter>(DEFAULT_FILTER)
  const [address, setAddress] = useState<string>("")

  useDebounce(address, 1000, (debouncedAddress) => {
    if (debouncedAddress !== filter.address) {
      updateFilter({ ...filter, address: debouncedAddress })
    }
  })

  const updateFilter = useCallback(
    (filter: IHouseholdOverviewFilter) => setFilter({ ...filter, pagination: DEFAULT_PAGINATION }),
    [],
  )

  const { options: countyOptions } = useCountyFilter(
    selectedDistrict ? Number(selectedDistrict?.id) : null,
    (result) => {
      setFilter({
        ...filter,
        selectedCounties: filter.selectedCounties.filter((entry) =>
          result.counties.find((county) => county.id === entry.id),
        ),
      })
    },
  )
  const { options: materialOptions, setSelectedMaterials } = useMaterialFilter(() => setSelectedMaterials([]))
  const { options: townOptions } = useTownFilter(
    {
      id: Number(variables.id),
      type: variables.type,
      counties: filter.selectedCounties.map((county) => county.id),
    },
    (result) =>
      setFilter({
        ...filter,
        selectedTowns: filter.selectedTowns.filter((entry) =>
          result.getTownsWithRegionID.find((town) => entry.id === town.id),
        ),
      }),
  )

  const resetFilter = useCallback(() => {
    setFilter(DEFAULT_FILTER)
    setAddress("")
  }, [setFilter])

  const setPagination = useCallback(
    (pagination: IHouseholdTablePagination) => {
      setFilter({ ...filter, pagination })
    },
    [filter],
  )

  return (
    <HouseholdOverviewFilterContext.Provider
      value={{
        countyOptions,
        materialOptions,
        townOptions,
        resetFilter,
        filter,
        updateFilter,
        address,
        setAddress,
        setPagination,
      }}
    >
      {props.children}
    </HouseholdOverviewFilterContext.Provider>
  )
}
