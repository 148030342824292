/*global google*/
import React, { FunctionComponent, Fragment, useState, useEffect } from "react"
import { Box, Card } from "@mui/material"
import { CustomMarker } from "./custom-marker/custom-marker"
import { CustomFlatRouteMarker } from "../../pages/route-details/partials/custom-flat-route-marker"
import { GoogleMap, Polyline } from "@react-google-maps/api"
import { PlaceSearcher } from "./place-searcher"

export interface IMarker {
  id: number
  lat: number
  lng: number
  type?: string
  status?: string
  sequenceNumber?: number
  collection_point_id?: number
  filllevel?: number
  onDemand?: boolean
  doNotDisplayPin?: boolean
  flatRouteMarker?: boolean
}

export interface ICenterOption {
  id: number
  lat: number
  lng: number
}

interface ICustomMapProps {
  markers?: IMarker[]
  center?: ICenterOption
  height?: string
  width?: string
  isTour?: boolean
  shouldSort?: boolean
  onMapClicked?: (e: google.maps.MapMouseEvent) => void
  onMarkerClicked?: (id: number) => void
  selectedMarkerId?: number
  placeSearch?: boolean
  zoom?: number
  disableZoom?: boolean
  disableInfoWindow?: boolean
  displayPolyline?: boolean
  doRenderCustomInfoWindow?: boolean
  renderCustomInfoWindow?: (markerId: number) => JSX.Element | ""
  doRenderCustomPolylines?: boolean
  renderCustomPolylines?: () => JSX.Element | ""
  disableFitBounds?: boolean
  referenceDate?: Date
}

export const CustomMap: FunctionComponent<ICustomMapProps> = (props) => {
  const {
    height,
    width,
    center,
    markers,
    isTour,
    shouldSort,
    onMapClicked,
    onMarkerClicked,
    selectedMarkerId,
    zoom,
    disableZoom,
    disableInfoWindow,
    displayPolyline,
    doRenderCustomInfoWindow,
    renderCustomInfoWindow,
    doRenderCustomPolylines,
    renderCustomPolylines = () => {
      return ""
    },
    disableFitBounds,
    referenceDate,
  } = props

  const [selectedMarker, setSelectedMarker] = useState<number>(-1)
  const [mapInstance, setMapInstance] = useState<google.maps.Map>()

  useEffect(() => {
    if (onMarkerClicked) {
      onMarkerClicked(selectedMarker)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarker])

  useEffect(() => {
    if (mapInstance && !disableFitBounds) {
      fitMapBounds(mapInstance)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [markers])

  let sortedMarkers: IMarker[] = []

  if (markers) {
    if (shouldSort) {
      sortedMarkers = markers.sort(
        (marker1: IMarker, marker2: IMarker) => marker1.sequenceNumber! - marker2.sequenceNumber!,
      )
    } else {
      sortedMarkers = markers
    }
  }

  const onMapInit = (map: google.maps.Map) => {
    setMapInstance(map)
    fitMapBounds(map)
  }

  const fitMapBounds = (map: google.maps.Map) => {
    if (sortedMarkers.length > 1) {
      const bounds = new google.maps.LatLngBounds()

      sortedMarkers.forEach((marker) => {
        bounds.extend(new google.maps.LatLng(marker.lat, marker.lng))
      })

      map.fitBounds(bounds)
    } else if (sortedMarkers.length === 1) {
      map.setCenter(new google.maps.LatLng(sortedMarkers[0].lat, sortedMarkers[0].lng))
      if (!disableZoom) {
        map.setZoom(15)
      }
    }
  }

  const renderPlaceSearcher = () => {
    return (
      <Box sx={{ position: "absolute", bottom: 10, left: 10 }}>
        <PlaceSearcher />
      </Box>
    )
  }

  const isSelected = (id: number) =>
    selectedMarkerId !== undefined && selectedMarkerId !== null ? selectedMarkerId === id : false

  const renderMarkerContent = () => {
    if (isTour) {
      return sortedMarkers.map((marker, idx) => (
        <CustomMarker
          key={`${marker.id}_${idx}`}
          id={marker.id}
          isSelected={isSelected(marker.id)}
          lat={marker.lat}
          lng={marker.lng}
          type={marker!.type}
          status={marker.status}
          number={
            marker!.sequenceNumber === null || marker!.sequenceNumber === undefined || marker!.sequenceNumber === -1
              ? ""
              : String(marker!.sequenceNumber)
          }
          doRenderCustomInfoWindow={doRenderCustomInfoWindow}
          renderCustomInfoWindow={renderCustomInfoWindow}
          onClick={(id) => setSelectedMarker(id)}
          collection_point_id={marker.collection_point_id}
          disableInfoWindow={disableInfoWindow}
          disableZoom={disableZoom}
          onDemand={marker.onDemand}
          referenceDate={referenceDate}
        ></CustomMarker>
      ))
    } else {
      return sortedMarkers
        .filter((marker) => !marker.doNotDisplayPin)
        .map((marker, idx) => {
          if (marker.flatRouteMarker === true) {
            return (
              <CustomFlatRouteMarker
                key={`${marker.id}_${idx}`}
                id={marker.id}
                isSelected={isSelected(marker.id)}
                lat={marker.lat}
                lng={marker.lng}
                onClick={(id) => setSelectedMarker(id)}
                disableInfoWindow={disableInfoWindow}
                doRenderCustomInfoWindow={doRenderCustomInfoWindow}
                renderCustomInfoWindow={renderCustomInfoWindow}
              />
            )
          }

          return (
            <CustomMarker
              key={`${marker.id}_${idx}`}
              id={marker.id}
              filllevel={marker.filllevel}
              isSelected={isSelected(marker.id)}
              lat={marker.lat}
              lng={marker.lng}
              onClick={(id) => setSelectedMarker(id)}
              disableInfoWindow={disableInfoWindow}
              disableZoom={disableZoom}
              doRenderCustomInfoWindow={doRenderCustomInfoWindow}
              renderCustomInfoWindow={renderCustomInfoWindow}
              onDemand={marker.onDemand}
              referenceDate={referenceDate}
            />
          )
        })
    }
  }

  const getPolylines = () => {
    if (!displayPolyline) {
      return ""
    }

    if (doRenderCustomPolylines) {
      return renderCustomPolylines()
    }

    const pathCoordinates = markers?.map((marker) => {
      return { lat: marker.lat, lng: marker.lng }
    })

    return (
      <Polyline
        path={pathCoordinates}
        options={{
          strokeColor: "#000000",
          strokeOpacity: 1,
          strokeWeight: 2,
        }}
      />
    )
  }

  return (
    <Fragment>
      <Card>
        <GoogleMap
          onClick={onMapClicked}
          onLoad={onMapInit}
          mapContainerStyle={{
            height: height || "250px",
            width: width || "100%",
          }}
          zoom={zoom || 15}
          center={center}
          options={{ gestureHandling: "cooperative" }}
        >
          {getPolylines()}
          {markers && renderMarkerContent()}
          {props.placeSearch && renderPlaceSearcher()}
        </GoogleMap>
      </Card>
    </Fragment>
  )
}
