import React, { FunctionComponent, Fragment, useState, ChangeEvent } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  IconButton,
  TextField,
  Backdrop,
  CircularProgress,
  List,
  ListItem,
  Typography,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { Dropzone } from "../../../partials/layout/dropzone/dropzone"
import { Cancel } from "@mui/icons-material"
import { useMutation } from "@apollo/client"
import {
  UPLOAD_ROUTES_MUTATION,
  UploadRoutesResult,
  UploadRoutesVariables,
} from "../../../../api/graphql/mutations/upload-routes"
import { toast } from "react-toastify"
import { useRefetch } from "../../../../context/refetch-context"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import { RoutesForLocation } from "../../../../api/graphql/queries/get-routes-for-location"
import { LocationSelect } from "../../../partials/location-select/location-select"

interface IImportRouteDialogProps {
  open: boolean
  onClose: () => void
  routes: RoutesForLocation[]
}

interface IFile {
  file: File
  tourName: string
}

export const ImportRouteDialog: FunctionComponent<IImportRouteDialogProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [files, setFiles] = useState<IFile[]>([])
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false)
  const { open, onClose, routes } = props
  const { setNeedToRefetch } = useRefetch()
  const [selectedLocation, setSelectedLocation] = useState<number | undefined>(undefined)

  const [uploadRoutes] = useMutation<UploadRoutesResult, UploadRoutesVariables>(UPLOAD_ROUTES_MUTATION)

  const upload = async () => {
    setConfirmOpen(false)
    setUploadLoading(true)
    const result = await uploadRoutes({
      variables: {
        routes: files,
        driverLocationId: Number(selectedLocation).toString(),
      },
    })
    setUploadLoading(false)

    if (result.data?.uploadRoutes) {
      toast.info(t("routes_management.routes_uploaded"))
      handleClose()
    } else {
      toast.error(t("routes_management.could_not_upload_routes"))
    }
    setNeedToRefetch(true)
  }

  const checkForDuplicates = () => {
    if (routes.some((r) => files.find((f) => f.tourName === r.name))) {
      setConfirmOpen(true)
    } else {
      upload()
    }
  }

  const handleClose = () => {
    setFiles([])
    onClose()
  }

  const handleChangeTourName = (index: number) => (event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    const copy = files.slice()
    copy[index].tourName = event.target.value
    setFiles(copy)
  }

  const handleRemoveFile = (index: number) => {
    const copy = files.slice()
    copy.splice(index, 1)
    setFiles(copy)
  }

  return (
    <Fragment>
      <ConfirmDialog
        confirmText={t("routes_management.replace")}
        onClose={() => setConfirmOpen(false)}
        onConfirm={() => upload()}
        open={confirmOpen}
        heading={t("routes_management.replace_routes_title")}
      >
        <Typography sx={{ fontWeight: "bold" }}>{t("routes_management.replace_routes_text")}</Typography>
        <List component="ul">
          {routes
            .filter((r) => files.find((f) => f.tourName === r.name))
            .map((r) => (
              <ListItem key={`route_${r.id}`}>{r.name}</ListItem>
            ))}
        </List>
      </ConfirmDialog>
      <Backdrop open={uploadLoading} sx={{ zIndex: theme.zIndex.modal + 1 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>{t("routes_management.import_routes")}</DialogTitle>
        <DialogContent sx={{ maxWidth: 600, padding: 2 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item sx={{ mt: 1.5 }}>
              <LocationSelect handleChange={setSelectedLocation} />
            </Grid>
            <Grid item>
              <Dropzone
                accept={{ ".gpx": [] }}
                text={t("routes_management.dropzone_text")}
                updateFiles={(newFiles) =>
                  setFiles(
                    newFiles.map((file, index) => ({
                      ...files[index],
                      tourName: files[index]?.tourName || "",
                      file,
                    })),
                  )
                }
                files={files.map((file) => file.file)}
                multiple
              />
            </Grid>
            <Grid item container direction="column">
              {files.map((file, index) => (
                <Grid item container direction="row" alignItems="center" key={`${file.file.name}_${index}`} spacing={1}>
                  <Grid item container spacing={1} xs>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        label={t("routes_management.filename")}
                        type="search"
                        fullWidth
                        variant="outlined"
                        value={file.file.name}
                        disabled
                      />
                    </Grid>
                    <Grid item xs={6}>
                      <TextField
                        size="small"
                        label={t("routes_management.name")}
                        type="search"
                        fullWidth
                        required
                        variant="outlined"
                        value={file.tourName}
                        onChange={handleChangeTourName(index)}
                      />
                    </Grid>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => handleRemoveFile(index)}>
                      <Cancel color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs>
              <Button onClick={handleClose} autoFocus variant="contained" fullWidth color="inherit">
                {t("routes_management.cancel")}
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                onClick={() => checkForDuplicates()}
                variant="contained"
                color="primary"
                fullWidth
                disabled={files.length === 0 || files.some((file) => !file.tourName || !selectedLocation)}
              >
                {t("routes_management.import")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
