import React, { ChangeEvent, FunctionComponent, useCallback, useContext, useEffect, useMemo, useState } from "react"
import {
  Button,
  Checkbox,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material"
import moment, { Moment } from "moment"
import { useTranslation } from "react-i18next"
import { MinimalTour } from "../../../../api/graphql/queries/get-minimal-tours-with-region-id"
import { useLazyQuery } from "@apollo/client"
import {
  GET_TOURS_AS_EXCEL_FILE_QUERY,
  GetToursAsExcelFileResult,
  GetToursAsExcelFileVariables,
} from "../../../../api/graphql/queries/get-tours-as-excel-file"
import { toast } from "react-toastify"
import { DownloadManagerContext } from "../../../partials/download-manager/download-manager-context"

interface ITourExportDialogProps {
  open: boolean
  onConfirm: () => void
  onClose: () => void
  tours: MinimalTour[]
  dateFrom: Moment | null
  dateTo: Moment | null
}

export const TourExportDialog: FunctionComponent<ITourExportDialogProps> = (props) => {
  const { t } = useTranslation()
  const { dateFrom, dateTo, onClose, open, tours } = props
  const [checked, setChecked] = useState<boolean[]>([])
  const { setTriggerQuery } = useContext(DownloadManagerContext)

  useEffect(() => {
    setChecked(() => {
      return tours.map((tour: MinimalTour) => true)
    })
  }, [tours])

  const showError = useCallback(() => {
    toast.error(t("export.unknown_error"))
  }, [t])

  const onRequestCompleted = useCallback(
    (data: GetToursAsExcelFileResult) => {
      if (!data.getToursAsExcelFile) {
        showError()
      }
      setTriggerQuery(true)
    },
    [showError, setTriggerQuery],
  )

  const [getToursAsExcelFile, { loading: excelLoading }] = useLazyQuery<
    GetToursAsExcelFileResult,
    GetToursAsExcelFileVariables
  >(GET_TOURS_AS_EXCEL_FILE_QUERY, {
    onCompleted: onRequestCompleted,
    onError: showError,
    fetchPolicy: "no-cache",
  })

  const period = useMemo(() => {
    return `${t("tour_overview.tour_export_dialog.time_range")}: ${moment(
      dateFrom || moment().utc().startOf("day"),
    ).format(t("date_format"))} - ${moment(dateTo || moment().utc().startOf("day")).format(t("date_format"))}`
  }, [dateFrom, dateTo, t])

  const handleListItemChange = useCallback(
    (checked: boolean, index: number) => {
      setChecked((checkedList: boolean[]) => {
        return checkedList.map((c: boolean, i: number) => {
          if (i === index) return checked
          return c
        })
      })
    },
    [setChecked],
  )

  const listItems = useMemo(() => {
    return tours.map((tour: MinimalTour, idx: number) => {
      const labelId = `checkbox-list-label-${tour.id}`
      return (
        <ListItem key={tour.id}>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={checked[idx]}
              onChange={(e: ChangeEvent<HTMLInputElement>) => handleListItemChange(e.target.checked, idx)}
              inputProps={{ "aria-labelledby": labelId }}
              color="primary"
            />
          </ListItemIcon>
          <ListItemText
            id={labelId}
            primary={`${moment(tour.date).format(t("date_format"))} - ${tour.vehicle.licence_plate}`}
          />
        </ListItem>
      )
    })
  }, [t, tours, checked, handleListItemChange])

  const disabledBtn = useMemo(() => {
    return checked.every((singleCheck: boolean) => !singleCheck)
  }, [checked])

  const handleExportBtnClick = useCallback(() => {
    const tourIds: string[] = tours
      .filter((tour: MinimalTour, idx: number) => checked[idx] && tour)
      .map((tour: MinimalTour) => tour.id.toString())

    getToursAsExcelFile({
      variables: {
        from: dateFrom,
        to: dateTo || moment.utc(),
        tourIds,
      },
    })
  }, [dateFrom, dateTo, tours, checked, getToursAsExcelFile])

  return (
    <Dialog open={open} onClose={onClose} sx={{ minWidth: 650, minHeight: 670 }}>
      <DialogTitle id="export-dialog-title">
        <Typography variant="h4" align="center">
          {t("tour_overview.tour_export_dialog.title")}
        </Typography>
        <Typography variant="subtitle1">{t("tour_overview.tour_export_dialog.subtitle")}</Typography>
      </DialogTitle>
      <DialogContent>
        <Typography sx={{ fontWeight: "bold" }}>{period}</Typography>
        <List>{listItems}</List>
      </DialogContent>
      <DialogActions>
        <Button color="primary" onClick={onClose}>
          {t("tour_overview.tour_export_dialog.cancel_btn")}
        </Button>
        <Button color="primary" disabled={disabledBtn} onClick={handleExportBtnClick}>
          {excelLoading && <CircularProgress size={12} />}
          {!excelLoading && t("tour_overview.tour_export_dialog.export_btn")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
