import React, { Fragment, FunctionComponent, useCallback, useContext, useState } from "react"
import { Card, CardHeader, CardContent, Collapse, IconButton, Divider, Typography, useTheme, Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ExpandIcon } from "../expand-icon/expand-icon"
import { Close } from "@mui/icons-material"
import { DownloadItem } from "./download-item"
import { DownloadManagerContext } from "./download-manager-context"
import { ConfirmDialog } from "../layout/dialog/confirm-dialog"

interface IDownloadManagerProps {}

export const DownloadManager: FunctionComponent<IDownloadManagerProps> = (props) => {
  const [expanded, setExpanded] = useState<boolean>(true)
  const { fileExports, cancelAllFileExport, cancelDialogParams, downloadsFinished } = useContext(DownloadManagerContext)
  const theme = useTheme()

  const handleExpandClick = () => {
    setExpanded(!expanded)
  }

  const onCancelAllFileExportsClicked = useCallback(() => {
    cancelAllFileExport()
  }, [cancelAllFileExport])

  const { t } = useTranslation()

  if (!fileExports.length) {
    return null
  }

  return (
    <>
      <ConfirmDialog
        open={cancelDialogParams.open}
        heading={cancelDialogParams.heading}
        text={cancelDialogParams.text}
        confirmText={t("yes")}
        negativeText={t("no")}
        onConfirm={cancelDialogParams.confirmCallack}
        onClose={cancelDialogParams.cancelCallback}
      />
      <Card
        sx={{ zIndex: theme.zIndex.modal + 1, position: "fixed", right: theme.spacing(2), bottom: theme.spacing(2) }}
        data-testid="download-manager-card"
      >
        <CardHeader
          title={
            <Typography sx={{ fontWeight: 500 }}>
              {t(`export.${downloadsFinished ? "downloads_finished" : "preparing_downloads"}`)}
            </Typography>
          }
          sx={{ backgroundColor: "primary.main", color: "primary.contrastText", p: 1, pl: 2 }}
          action={
            <Box sx={{ mt: 0.5, mr: 1 }}>
              <ExpandIcon
                size="small"
                sx={{ color: "primary.contrastText" }}
                expanded={expanded}
                onClick={handleExpandClick}
              />
              <IconButton size="small" sx={{ color: "primary.contrastText" }} onClick={onCancelAllFileExportsClicked}>
                <Close />
              </IconButton>
            </Box>
          }
        />
        <Collapse in={expanded} timeout="auto">
          <CardContent sx={{ width: 250, p: 1, "&:last-child": { pb: 1 } }}>
            {fileExports.map((fileExport, index) => (
              <Fragment key={`download_item_${fileExport.id}`}>
                <DownloadItem fileExport={fileExport} />
                {index !== fileExports.length - 1 && (
                  <Divider
                    sx={{ ml: `-${theme.spacing(2)}`, mb: 1, mt: 1, width: `calc(100% + ${theme.spacing(4)})` }}
                  />
                )}
              </Fragment>
            ))}
          </CardContent>
        </Collapse>
      </Card>
    </>
  )
}
