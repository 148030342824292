import React, { FunctionComponent, PropsWithChildren } from "react"
import { useQuery, useApolloClient } from "@apollo/client"
import gql from "graphql-tag"
import { Navigate, Outlet, Route } from "react-router-dom"
import { PATH } from "../../../router/router"
import { UserService } from "../../../services/user-service"
import { UserGroups } from "../../../models/user-groups"

interface IAuthRouteProps extends PropsWithChildren<Record<never, never>> {
  path?: string
  component?: any
  redirectTo?: string
}

const GET_AUTH = gql`
  query {
    authStatus @client {
      status
    }
  }
`

export const AuthRoute: FunctionComponent<IAuthRouteProps> = (props) => {
  const { data, loading } = useQuery(GET_AUTH, { fetchPolicy: "cache-only" })
  const { path, component, redirectTo, children } = props
  const client = useApolloClient()

  if (loading) return null

  if (
    !data?.authStatus?.status ||
    data?.authStatus?.status === "loggedOut" ||
    !UserGroups.PORTAL_ACCESS.includes(UserService.getRole())
  ) {
    UserService.logout(client)
    return <Route path="*" element={<Navigate to={PATH.LOGIN.route} />} />
  }

  if (!redirectTo) {
    return <Route path={path} Component={component} children={children} />
  }
  return <Route path={path} element={<Navigate to={redirectTo} />} />
}

export const PrivateRoute: FunctionComponent<IAuthRouteProps> = (props) => {
  const { data, loading } = useQuery(GET_AUTH, { fetchPolicy: "cache-only" })
  const { path, redirectTo } = props
  const client = useApolloClient()

  if (loading) {
    return null
  }

  if (
    !data?.authStatus?.status ||
    data?.authStatus?.status === "loggedOut" ||
    !UserGroups.PORTAL_ACCESS.includes(UserService.getRole())
  ) {
    UserService.logout(client)
    return <Route path="*" element={<Navigate to={PATH.LOGIN.route} />} />
  }

  if (!redirectTo) {
    return <Outlet />
  }

  return <Route path={path} element={<Navigate to={redirectTo} />} />
}
