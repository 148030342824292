import React, { FunctionComponent } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogContentText,
  Backdrop,
  CircularProgress,
} from "@mui/material"
import { useTranslation } from "react-i18next"

interface IResetDialogProps {
  loading: boolean
  open: boolean
  onClose: () => void
  onReset: () => void
}

export const ResetDialog: FunctionComponent<IResetDialogProps> = (props) => {
  const { t } = useTranslation()
  const { loading } = props
  return (
    <>
      <Backdrop open={loading}>
        <CircularProgress color="inherit" />
      </Backdrop>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">{t("reset.header")}</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} color="primary" disabled={loading}>
            {t("no")}
          </Button>
          <Button onClick={props.onReset} color="primary" autoFocus disabled={loading}>
            {t("yes")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}
