import { Checkbox, FormControlLabel, Grid, useTheme } from "@mui/material"
import React, { FunctionComponent, SyntheticEvent } from "react"
import { useTranslation } from "react-i18next"
import { Day } from "../../../api/graphql/graphql-global-types"
import { dayToNumber } from "../../../utils/day"
import { TimePicker } from "./time-picker"

export interface OpeningHour {
  id?: string
  day: Day
  from: string // HH:mm
  to: string // HH:mm
  active?: boolean
}

interface IOpeningHoursPickerProps {
  openingHours: OpeningHour[]
  updateOpeningHours: (openingHours: OpeningHour[]) => void
  showCheckboxes?: boolean
  // returns an error message for an openingHour
  // returns an empty string if no error exists
  getErrorMsg?: (openingHour: OpeningHour) => string
}

export const OpeningHoursPicker: FunctionComponent<IOpeningHoursPickerProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { openingHours, updateOpeningHours, showCheckboxes, getErrorMsg = () => "" } = props

  // sort by weekday
  const openingHoursSortedByDay = openingHours.sort((a, b) => dayToNumber(a.day) - dayToNumber(b.day))

  const handleChangeCheckBox =
    (openingHour: OpeningHour, index: number) => (event: SyntheticEvent, checked: boolean) => {
      const copy: OpeningHour[] = JSON.parse(JSON.stringify(openingHoursSortedByDay))

      openingHour.active = checked

      copy[index] = openingHour
      updateOpeningHours(copy)
    }

  const handleChangeFromTime = (openingHour: OpeningHour, index: number) => (dateTime: moment.Moment | null) => {
    const copy: OpeningHour[] = JSON.parse(JSON.stringify(openingHoursSortedByDay))

    // check if dateTime is not null
    if (dateTime) {
      openingHour.from = dateTime.format("HH:mm")
    }

    copy[index] = openingHour
    updateOpeningHours(copy)
  }

  const handleChangeToTime = (openingHour: OpeningHour, index: number) => (dateTime: moment.Moment | null) => {
    const copy: OpeningHour[] = JSON.parse(JSON.stringify(openingHoursSortedByDay))

    if (dateTime) {
      openingHour.to = dateTime.format("HH:mm")
    }

    copy[index] = openingHour
    updateOpeningHours(copy)
  }

  return (
    <>
      {openingHoursSortedByDay.map((openingHour, index) => (
        <Grid
          container
          direction="row"
          alignItems={showCheckboxes ? "flex-end" : "center"}
          spacing={2}
          key={openingHour.day}
        >
          <Grid item xs={showCheckboxes ? 2 : 1}>
            {showCheckboxes ? (
              <FormControlLabel
                control={
                  <Checkbox
                    checked={!!openingHour.active}
                    onChange={handleChangeCheckBox(openingHour, index)}
                    style={{
                      color: theme.palette.primary.light,
                      padding: `0 ${theme.spacing(1)}`,
                    }}
                  />
                }
                label={t(`weekdays.${openingHour.day}_short`)}
              />
            ) : (
              <>{t(`weekdays.${openingHour.day}_short`)}</>
            )}
          </Grid>
          <Grid item xs>
            <TimePicker
              time={openingHour.from}
              onChange={handleChangeFromTime(openingHour, index)}
              sx={{ m: 0 }}
              disabled={showCheckboxes && !openingHour.active}
              required
              label={t("date_picker.from")}
              error={getErrorMsg(openingHour)}
            />
          </Grid>
          <Grid item xs>
            <TimePicker
              time={openingHour.to}
              onChange={handleChangeToTime(openingHour, index)}
              sx={{ m: 0 }}
              disabled={showCheckboxes && !openingHour.active}
              required
              label={t("date_picker.to")}
              error={getErrorMsg(openingHour)}
            />
          </Grid>
        </Grid>
      ))}
    </>
  )
}
