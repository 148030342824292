import React, { FC, useCallback } from "react"
import { Button, CircularProgress, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useNavigate } from "react-router-dom"
import { useCollectionPointAdministrationContext } from "../collection-point-administration-context"
import { PATH } from "../../../../router/router"
import { Add } from "@mui/icons-material"
import { CollectionPointsFilter } from "../../collection-points/partials/collection-points-filter"
import { CollectionPointAdministrationSelector } from "./collection-point-administration-selector"
import { CollectionPointAdministrationData } from "./collection-point-administration-data"

interface CollectionPointAdministrationPageContentProps {}

export const CollectionPointAdministrationPageContent: FC<CollectionPointAdministrationPageContentProps> = () => {
  const { t } = useTranslation()
  const { setSelectedCollectionPoint, isLoading } = useCollectionPointAdministrationContext()
  const navigate = useNavigate()

  const onFilterUpdated = () => {
    setSelectedCollectionPoint(undefined)
  }

  const handleCreateButtonClicked = useCallback(() => {
    setSelectedCollectionPoint(undefined)
    navigate(PATH.COLLECTIONPOINTS_ADMINISTRATION.route)
  }, [setSelectedCollectionPoint, navigate])

  return (
    <>
      <Grid container item direction="column" sx={{ width: 300, minHeight: "100%" }} spacing={3}>
        <Grid
          item
          container
          justifyContent="space-between"
          direction="row"
          alignItems="center"
          sx={{ flex: "0 0 auto" }}
        >
          <Grid item xs>
            <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
              {t("collection_point_administration.create_collection_point")}
            </Typography>
          </Grid>
          <Grid item>
            <Button type="button" variant="outlined" onClick={handleCreateButtonClicked} fullWidth color="primary">
              <Add color="primary" />
            </Button>
          </Grid>
        </Grid>
        <Grid item sx={{ flex: "0 0 auto" }}>
          <CollectionPointsFilter onFilterUpdated={onFilterUpdated} variant="administration" />
        </Grid>
        <Grid item sx={{ maxWidth: "100%", marginBottom: 2 }}>
          <CollectionPointAdministrationSelector />
        </Grid>
      </Grid>
      <Grid item xs sx={{ minHeight: "100%" }}>
        {isLoading ? (
          <Grid item sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Grid>
        ) : (
          <CollectionPointAdministrationData />
        )}
      </Grid>
    </>
  )
}
