import React from "react"
import { Theme, Grid, SxProps } from "@mui/material"

interface IHeadingProps {
  children: any
  sx?: SxProps<Theme>
}

export const ContentContainer: React.FunctionComponent<IHeadingProps> = (props) => {
  const { children } = props
  return <Grid sx={{ ...props.sx, mt: 2 }}>{children}</Grid>
}
