import React, { useContext, useMemo } from "react"
import { HouseholdOverviewStatusContext } from "../context/household-overview-status-context"
import { Card, Grid, Skeleton, SxProps, Theme, Typography, useTheme } from "@mui/material"
import { HouseholdOverviewFilterContext } from "../context/household-overview-filter-context"
import { useTranslation } from "react-i18next"

interface IHouseholdOverviewStatusCardsProps {}

export const HouseholdOverviewStatusCards: React.FC<IHouseholdOverviewStatusCardsProps> = (props) => {
  const { households, loading } = useContext(HouseholdOverviewStatusContext)
  const { materialOptions } = useContext(HouseholdOverviewFilterContext)
  const theme = useTheme()
  const { t } = useTranslation()

  const { materialContainerDictionary, amountChipped } = useMemo(() => {
    const dict: { [key: string]: number } = {}

    let amountContainers = 0
    let amountIntendedContainers = 0

    households.forEach((household) =>
      household.status.materialStatus.forEach((status) => {
        dict[status.materialId] = (dict[status.materialId] || 0) + status.amountContainers

        amountContainers += status.amountContainers
        amountIntendedContainers += status.amountIntendedContainers
      }),
    )

    return {
      materialContainerDictionary: dict,
      amountChipped: Math.round((amountContainers / amountIntendedContainers) * 100),
    }
  }, [households])

  const cardSx: SxProps<Theme> = useMemo(
    () => ({
      pt: 1,
      pb: 1,
      pl: 2,
      pr: 2,
      minWidth: 270,
      backgroundColor: "primary.main",
      color: "primary.contrastText",
      display: "flex",
    }),
    [],
  )

  return (
    <Grid container justifyContent="center" spacing={3}>
      <Grid item sx={{ display: "flex" }}>
        <Card sx={cardSx}>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
              {loading && <Skeleton variant="text" width={120} sx={{ ...theme.typography.subtitle1 }} />}
              {!loading && (
                <Typography variant="subtitle1">{t("household_overview.status.collection_points")}</Typography>
              )}
            </Grid>
            <Grid item>
              {loading && <Skeleton variant="text" width={60} sx={{ ...theme.typography.h6 }} />}
              {!loading && <Typography variant="h6">{households.length}</Typography>}
            </Grid>
            <Grid item>
              {loading && <Skeleton variant="text" width={120} sx={{ ...theme.typography.subtitle1 }} />}
              {!loading && <Typography variant="subtitle1">{t("household_overview.status.total")}</Typography>}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item sx={{ display: "flex" }}>
        <Card sx={cardSx}>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
              {loading && <Skeleton variant="text" width={150} sx={{ ...theme.typography.subtitle1 }} />}
              {!loading && (
                <Typography variant="subtitle1">{t("household_overview.status.amount_chambers")}</Typography>
              )}
            </Grid>
            <Grid item>
              {loading && <Skeleton variant="text" width={100} sx={{ ...theme.typography.h6 }} />}
              {!loading && (
                <Typography variant="h6">{Object.values(materialContainerDictionary).join(" | ")}</Typography>
              )}
            </Grid>
            <Grid item>
              {loading && <Skeleton variant="text" width={210} sx={{ ...theme.typography.subtitle1 }} />}
              {!loading && (
                <Typography variant="subtitle1">
                  {Object.keys(materialContainerDictionary)
                    .map((materialId) => materialOptions.find((option) => option.id === materialId)?.name)
                    .join(" | ")}
                </Typography>
              )}
            </Grid>
          </Grid>
        </Card>
      </Grid>
      <Grid item sx={{ display: "flex" }}>
        <Card sx={cardSx}>
          <Grid container direction="column" alignItems="center" justifyContent="center">
            <Grid item>
              {loading && <Skeleton variant="text" width={120} sx={{ ...theme.typography.subtitle1 }} />}
              {!loading && (
                <Typography variant="subtitle1">{t("household_overview.status.amount_containers_chipped")}</Typography>
              )}
            </Grid>
            <Grid item>
              {loading && <Skeleton variant="text" width={80} sx={{ ...theme.typography.h6 }} />}
              {!loading && <Typography variant="h6">{amountChipped}%</Typography>}
            </Grid>
          </Grid>
        </Card>
      </Grid>
    </Grid>
  )
}
