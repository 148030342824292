import React, { FunctionComponent } from "react"
import { Grid, TextField, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Text } from "../../../partials/wrapper/text"
import { VehicleToMaterial } from "../../../../api/graphql/queries/get-vehicle-with-id"

interface ITourGenerationVehicleMaterialsProps {
  updateSelectedMaterials: (materials: VehicleToMaterial[]) => void
  selectedMaterials: VehicleToMaterial[]
  notEditable?: boolean
}

export const TourGenerationVehicleMaterials: FunctionComponent<ITourGenerationVehicleMaterialsProps> = (props) => {
  const { t } = useTranslation()
  const { selectedMaterials, updateSelectedMaterials, notEditable } = props

  const updateMaterials = (amount: number, index: number): void => {
    const copy: VehicleToMaterial[] = JSON.parse(JSON.stringify(selectedMaterials))
    copy[index].amount = isNaN(amount) || amount <= 0 ? 1 : amount
    updateSelectedMaterials(copy)
  }

  return (
    <Grid container direction="column">
      <Grid item container alignItems="center" justifyContent="space-between">
        <Grid item>
          <Text bold>{t("vehicle_overview.data.chambers")}</Text>
        </Grid>
      </Grid>
      <Grid item container direction="column" spacing={1}>
        {selectedMaterials.map((material, index) => (
          <Grid
            item
            container
            direction="row"
            key={`material${index}`}
            justifyContent="space-between"
            alignItems="center"
          >
            <Grid item xs>
              <Typography>{material.material.name}</Typography>
            </Grid>
            <Grid item xs>
              {!notEditable && (
                <TextField
                  size="small"
                  label={t("vehicle_overview.data.chamber_amount")}
                  type="number"
                  inputProps={{ min: 0 }}
                  fullWidth
                  variant="outlined"
                  value={material.amount}
                  onChange={(ev) => {
                    updateMaterials(parseInt(ev.target.value, 10), index)
                  }}
                />
              )}
              {!!notEditable && <Text>{material.amount}</Text>}
            </Grid>
          </Grid>
        ))}
      </Grid>
    </Grid>
  )
}
