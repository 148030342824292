import React, { FunctionComponent, useState, Fragment, useEffect, useContext } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { Grid, CircularProgress, Card as MaterialCard, Skeleton, useTheme } from "@mui/material"
import { PageHeading } from "../../partials/layout/heading/page-heading"
import { ContentContainer } from "../../partials/layout/content/content-container"
import { Card } from "../../partials/layout/card/model/card"
import { MapsHelper } from "../../partials/maps/maps-helper"
import { CardsContainer } from "../../partials/layout/card/cards-container"
import {
  RegionStatsResult,
  RegionStatsVariables,
  GET_REGION_STATS_QUERY,
} from "../../../api/graphql/queries/get-region-stats"
import { CustomMap, IMarker } from "../../partials/maps/custom-map"
import { CardInfoHelper } from "../../../utils/CardInfoHelper"
import { EmptyingStats } from "../../../api/graphql/queries/get-collection-point-stats-with-id"
import { RegionStats } from "./partials/region-stats"
import { PartnerStats } from "./partials/partner-stats"
import { RegionSelect } from "../../partials/region-select/region-select"
import { CustomSnackBarContent } from "../../partials/wrapper/CustomSnackBarContent"
import { UserRole } from "../../../api/graphql/graphql-global-types"
import { UserService } from "../../../services/user-service"
import {
  GET_MINIMAL_COLLECTIONPOINTS_FOR_MAP_QUERY,
  GetMinimalCollectionPointsForMapResult,
  GetMinimalCollectionPointsForMapVariables,
} from "../../../api/graphql/queries/get-minimal-collection-points-for-map"
import lodash from "lodash"
import { INFO_CARD_HEIGHT, INFO_CARD_WIDTH } from "../../../utils/constants"
import { DaysOverfilledChart } from "./partials/days-overfilled-chart"
import { UserContext } from "../../../context/user-context"
import { EmptyingsPerHourChart } from "./partials/emptyings-per-hour-chart"
import { CollectedAmountPerHourChart } from "./partials/collected-amount-per-hour-chart"
import { TopOverfilledCollectionPointsChart } from "./partials/top-overfilled-collection-points-chart"
import { useSelectedRegion } from "../../../hooks/use-selected-region"

interface IDashboardPageProps {}

export const DashboardPage: FunctionComponent<IDashboardPageProps> = () => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [selectedCollectionPoint, setSelectedCollectionPoint] = useState<number>()
  const [cards, setCards] = useState<Card[]>([])
  const [markers, setMarkers] = useState<IMarker[]>([])
  const { variables, skip } = useSelectedRegion()
  const { problemSensorInterval } = useContext(UserContext)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const { data: collectionPointsData, loading: collectionPointsLoading } = useQuery<
    GetMinimalCollectionPointsForMapResult,
    GetMinimalCollectionPointsForMapVariables
  >(GET_MINIMAL_COLLECTIONPOINTS_FOR_MAP_QUERY, {
    variables: {
      id: Number(variables.id),
      type: variables.type,
    },
    onCompleted: () =>
      !lodash.isNil(collectionPointsData?.getMinimalCollectionPointsWithRegionID)
        ? setMarkers(
            MapsHelper.getMarkersWithFilllevels(
              collectionPointsData?.getMinimalCollectionPointsWithRegionID || [],
              problemSensorInterval,
            ),
          )
        : null,
    skip,
  })

  const { data: regionStatsData, loading: regionStatsLoading } = useQuery<RegionStatsResult, RegionStatsVariables>(
    GET_REGION_STATS_QUERY,
    {
      variables: {
        id: String(variables.id),
        type: variables.type,
      },
      onCompleted: () => createCards(),
      skip: collectionPointsData === undefined || collectionPointsLoading,
    },
  )

  const createCards = () => {
    if (regionStats) {
      setCards(
        [
          regionStats.container_group_infos.length > 0
            ? CardInfoHelper.getContainerInfoGroupCard(regionStats.container_group_infos)
            : undefined,
          regionStats.last_emptying && CardInfoHelper.getLastEmptyingCard(regionStats.last_emptying as EmptyingStats),
          regionStats.next_emptying && CardInfoHelper.getNextEmptyingCard(regionStats.next_emptying as EmptyingStats),
          regionStats.collection_amount &&
            CardInfoHelper.getLast30DaysCollectedAmountCard(regionStats.collection_amount),
        ].filter((card) => card) as Card[],
      )
    }
  }

  const regionStats = regionStatsData ? regionStatsData.getRegionStats : undefined

  return (
    <Fragment>
      <PageHeading>{t("collection_points.heading")}</PageHeading>
      {/* Region Select */}
      <ContentContainer>
        <MaterialCard style={{ padding: 15 }}>
          <RegionSelect />
        </MaterialCard>
      </ContentContainer>
      {collectionPointsLoading && (
        <ContentContainer>
          <Grid container justifyContent="center" alignItems="center">
            <CircularProgress />
          </Grid>
        </ContentContainer>
      )}
      {markers.length > 0 && !collectionPointsLoading && (
        <Fragment>
          <ContentContainer>
            <CardsContainer justifyContent="center" cards={cards} loading={regionStatsLoading} />
          </ContentContainer>
          <ContentContainer>
            <Grid container direction="row">
              <Grid sx={{ height: "100%", width: "100%" }}>
                <CustomMap
                  height={"400px"}
                  center={
                    selectedCollectionPoint
                      ? markers.find((marker) => marker.id === selectedCollectionPoint)
                      : undefined
                  }
                  onMarkerClicked={(id: number) => {
                    setSelectedCollectionPoint(id)
                  }}
                  markers={markers}
                />
              </Grid>
            </Grid>
          </ContentContainer>
          {UserService.getRole() !== UserRole.SUPER_ADMIN && (
            <ContentContainer>
              <Grid container justifyContent="center" spacing={2} alignItems="stretch">
                {regionStatsLoading && (
                  <Fragment>
                    <Grid
                      item
                      sx={{
                        [theme.breakpoints.down("xs")]: {
                          width: "100%",
                        },
                      }}
                    >
                      <Skeleton width={INFO_CARD_WIDTH} height={INFO_CARD_HEIGHT} style={{ transform: "scale(1)" }} />
                    </Grid>
                    <Grid
                      item
                      sx={{
                        [theme.breakpoints.down("xs")]: {
                          width: "100%",
                        },
                      }}
                    >
                      <Skeleton width={INFO_CARD_WIDTH} height={INFO_CARD_HEIGHT} style={{ transform: "scale(1)" }} />
                    </Grid>
                  </Fragment>
                )}
                {!lodash.isNil(regionStats) && (
                  <Fragment>
                    <Grid
                      item
                      sx={{
                        [theme.breakpoints.down("xs")]: {
                          width: "100%",
                        },
                      }}
                    >
                      <RegionStats data={regionStats!} />
                    </Grid>
                    {regionStats.collection_partners!.map((partner, idx) => (
                      <Grid
                        item
                        key={idx}
                        sx={{
                          [theme.breakpoints.down("xs")]: {
                            width: "100%",
                          },
                        }}
                      >
                        <PartnerStats partner={partner} />
                      </Grid>
                    ))}
                  </Fragment>
                )}
              </Grid>
            </ContentContainer>
          )}
          {UserService.getRole() === UserRole.SUPER_ADMIN && (
            <ContentContainer>
              <Grid container direction="row" justifyContent="center" spacing={2}>
                <DaysOverfilledChart />
                <TopOverfilledCollectionPointsChart />
                <EmptyingsPerHourChart />
                <CollectedAmountPerHourChart />
              </Grid>
            </ContentContainer>
          )}
        </Fragment>
      )}
      {(!regionStats || markers.length === 0) && !regionStatsLoading && !collectionPointsLoading && (
        <CustomSnackBarContent
          sx={{ pt: 1 }}
          variant="info"
          message={t(`errors.no_${UserService.hasAssociationFilter() ? "association" : "district"}_dashboard_data`)}
        />
      )}
    </Fragment>
  )
}
