import React, { FunctionComponent, useState } from "react"
import { Grid, Card, Button, IconButton, useTheme } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { Text } from "../../../partials/wrapper/text"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { SelectPair } from "../../../partials/layout/selection/select-pair"
import { GETMATERIALS_QUERY, MaterialsResult } from "../../../../api/graphql/queries/get-materials"
import { DisposalMerchantStatsMaterial } from "../../../../api/graphql/queries/get-disposal-merchant-stats-with-id"

interface IDisposalMerchantMaterialsProps {
  updateSelectedMaterials: (materials: DisposalMerchantStatsMaterial[]) => void
  selectedMaterials: DisposalMerchantStatsMaterial[]
}

export const DisposalMerchantMaterials: FunctionComponent<IDisposalMerchantMaterialsProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { selectedMaterials, updateSelectedMaterials } = props

  const [materialId, setMaterialId] = useState<string>("")

  let materials: SelectPair[] = []
  const { data: materialsData } = useQuery<MaterialsResult>(GETMATERIALS_QUERY)

  if (materialsData) {
    materials = materialsData!.getMaterials.map((material) => new SelectPair(material.id.toString(), material.name))
    if (materials.length && !materialId) {
      setMaterialId(materials[0].value)
    }
  }

  const getMaterialsForSelect = (selectedMaterial: DisposalMerchantStatsMaterial): SelectPair[] => {
    return materials.filter(
      (material) =>
        material.value === selectedMaterial.id.toString() ||
        !selectedMaterials.find((m) => m.id.toString() === material.value),
    )
  }

  const updateMaterials = (newValue: string, index: number): void => {
    const copy = selectedMaterials.slice()
    copy[index] = materialsData!.getMaterials.find(
      (material) => material.id.toString() === newValue,
    ) as DisposalMerchantStatsMaterial
    updateSelectedMaterials(copy)
  }

  const getNextAvailableMaterial = (): DisposalMerchantStatsMaterial => {
    return materialsData!.getMaterials.find(
      (material) => !selectedMaterials.find((selectedMaterial) => material.id === selectedMaterial.id),
    ) as DisposalMerchantStatsMaterial
  }

  return (
    <Card sx={{ pl: 2, pr: 2, pb: 2, height: `calc(100% - ${theme.spacing(2)})` }}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Text bold>{t("disposal_merchant_overview.data.materials")}</Text>
          </Grid>
          <Grid item>
            <Button
              type="button"
              variant="outlined"
              sx={{
                borderWidth: 0.5,
                borderRadius: 1,
                borderColor: "primary.main",
                mt: 1,
                mb: 1,
              }}
              onClick={() => updateSelectedMaterials(selectedMaterials.concat(getNextAvailableMaterial()))}
              disabled={selectedMaterials.length === materials.length}
              fullWidth
            >
              <AddIcon sx={{ color: selectedMaterials.length === materials.length ? "disabled" : "primary.main" }} />
            </Button>
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={2}>
          {selectedMaterials.length ? (
            selectedMaterials.map((material, index) => (
              <Grid
                item
                container
                direction="row"
                key={`material${index}`}
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs>
                  <OutlinedSelect
                    options={getMaterialsForSelect(material)}
                    name={t("disposal_merchant_overview.data.material")}
                    onValueChange={(newValue) => updateMaterials(newValue, index)}
                    value={material.id.toString()}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    color="error"
                    onClick={() =>
                      updateSelectedMaterials(selectedMaterials.filter((selectedMaterial, i) => index !== i))
                    }
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          ) : (
            <Text textAlign="center">{t("disposal_merchant_overview.data.no_materials")}</Text>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}
