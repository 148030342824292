import React, { FunctionComponent, useCallback, useContext, useEffect, useMemo } from "react"
import { Grid, CircularProgress, IconButton, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Check, Close, GetApp, ScheduleSharp, Warning } from "@mui/icons-material"
import { FileExportStatus } from "../../../api/graphql/graphql-global-types"
import { DownloadManagerContext, IFileExportItem } from "./download-manager-context"

interface IDownloadItemProps {
  fileExport: IFileExportItem
}

const REMOVE_AFTER_MILLIS = 15000

export const DownloadItem: FunctionComponent<IDownloadItemProps> = (props) => {
  const { fileExport } = props
  const { t } = useTranslation()
  const { removeCompletedExport, cancelSingleFileExport } = useContext(DownloadManagerContext)

  useEffect(() => {
    if (fileExport.completedAt) {
      const timeout = REMOVE_AFTER_MILLIS - (new Date().getTime() - fileExport.completedAt.getTime())

      const timer = setTimeout(() => {
        removeCompletedExport(fileExport.id)
      }, timeout)
      return () => clearTimeout(timer)
    }
  }, [fileExport, removeCompletedExport])

  const onCancelClicked = useCallback(() => {
    if (fileExport.status !== FileExportStatus.DOWNLOADED) {
      cancelSingleFileExport(fileExport.id)
    } else {
      removeCompletedExport(fileExport.id)
    }
  }, [cancelSingleFileExport, fileExport, removeCompletedExport])

  const Icon = useMemo(() => {
    switch (fileExport.status) {
      case FileExportStatus.REQUESTED: {
        return <ScheduleSharp sx={{ display: "flex" }} fontSize="small" />
      }
      case FileExportStatus.PROCESSING:
      case FileExportStatus.SUCCESS: {
        return <CircularProgress sx={{ display: "flex" }} size={20} />
      }
      case FileExportStatus.ERROR: {
        return <Warning sx={{ display: "flex" }} fontSize="small" />
      }
      case FileExportStatus.DOWNLOADED: {
        return <Check sx={{ display: "flex" }} fontSize="small" />
      }
      default: {
        return null
      }
    }
  }, [fileExport.status])

  const tooltipText = useMemo(() => {
    switch (fileExport.status) {
      case FileExportStatus.REQUESTED:
        return "queued"
      case FileExportStatus.PROCESSING:
      case FileExportStatus.SUCCESS:
        return "processing"
      case FileExportStatus.ERROR:
        return "error"
      case FileExportStatus.DOWNLOADED:
        return "finished"
    }
  }, [fileExport.status])

  return (
    <Grid container direction="row" spacing={1} alignItems="center">
      <Grid item>
        <GetApp sx={{ display: "flex" }} fontSize="small" />
      </Grid>
      <Grid
        item
        xs
        sx={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", fontSize: "body2.fontSize" }}
      >
        {fileExport.filename}
      </Grid>
      {Icon && (
        <Grid item>
          <Tooltip title={t(`export.download_item.${tooltipText}`)} placement="top" arrow>
            {Icon}
          </Tooltip>
        </Grid>
      )}
      <Grid item>
        <IconButton size="small" onClick={onCancelClicked}>
          <Close />
        </IconButton>
      </Grid>
    </Grid>
  )
}
