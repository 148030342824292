import React, { FunctionComponent, useState, Fragment } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  Grid,
  Typography,
  IconButton,
  Backdrop,
  CircularProgress,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { Dropzone } from "../../../partials/layout/dropzone/dropzone"
import { Cancel } from "@mui/icons-material"
import {
  UPLOAD_IMAGE_FOR_COLLECTION_POINT_MUTATION,
  UploadImageForCollectionPointResult,
  UploadImageForCollectionPointVariables,
} from "../../../../api/graphql/mutations/upload-image-for-collection-point"
import { useMutation } from "@apollo/client"
import { toast } from "react-toastify"
import { ExecutionResult } from "graphql"
import lodash from "lodash"
import { useCollectionPointAdministrationContext } from "../collection-point-administration-context"

interface ICollectionPointAdministrationAddImageDialogProps {
  open: boolean
  onClose: () => void
  onImagesAdded: () => void
}

export const CollectionPointAdministrationAddImageDialog: FunctionComponent<
  ICollectionPointAdministrationAddImageDialogProps
> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { open, onClose, onImagesAdded } = props
  const [uploadLoading, setUploadLoading] = useState<boolean>(false)

  const [files, setFiles] = useState<File[]>([])

  const { selectedCollectionPoint } = useCollectionPointAdministrationContext()

  const [uploadImage] = useMutation<UploadImageForCollectionPointResult, UploadImageForCollectionPointVariables>(
    UPLOAD_IMAGE_FOR_COLLECTION_POINT_MUTATION,
    {
      context: {
        headers: {
          "apollo-require-preflight": true,
        },
      },
    },
  )

  const uploadClicked = async () => {
    setUploadLoading(true)
    const results: ExecutionResult<UploadImageForCollectionPointResult>[] = []
    await Promise.all(
      files.map(async (file) => {
        const result = await uploadImage({
          variables: {
            collection_point_id: selectedCollectionPoint!.id,
            file,
          },
        })
        results.push(result)
      }),
    )
    if (!results.some((result) => lodash.isNil(result.data))) {
      toast.info(t("collection_point_administration.images.images_uploaded"))
      setFiles([])
      onImagesAdded()
    } else {
      toast.error(t("collection_point_administration.images.could_not_upload_images"))
    }
    setUploadLoading(false)
  }

  return (
    <Fragment>
      <Backdrop open={uploadLoading} sx={{ zIndex: theme.zIndex.modal + 1 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle>{t("collection_point_administration.images.add")}</DialogTitle>
        <DialogContent sx={{ maxWidth: 350, p: 2 }}>
          <Grid container direction="column" spacing={2}>
            <Grid item>
              <Dropzone
                text={t("collection_point_administration.images.dropzone_text")}
                accept={{ "image/*": [] }}
                updateFiles={setFiles}
                files={files}
                multiple
              />
            </Grid>
            <Grid item container direction="column">
              {files.map((file) => (
                <Grid item container direction="row" justifyContent="space-between" alignItems="center" key={file.name}>
                  <Grid item>
                    <Typography>{file.name}</Typography>
                  </Grid>
                  <Grid item>
                    <IconButton onClick={() => setFiles(files.filter((f) => f.name !== file.name))}>
                      <Cancel color="error" />
                    </IconButton>
                  </Grid>
                </Grid>
              ))}
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions sx={{ p: 2 }}>
          <Grid container direction="row" spacing={2}>
            <Grid item xs>
              <Button onClick={onClose} autoFocus variant="contained" color="inherit" fullWidth>
                {t("collection_point_administration.images.cancel")}
              </Button>
            </Grid>
            <Grid item xs>
              <Button
                onClick={() => uploadClicked()}
                variant="contained"
                color="primary"
                fullWidth
                disabled={files.length === 0}
              >
                {t("collection_point_administration.images.upload")}
              </Button>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
