import React, { FunctionComponent, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Grid, useTheme } from "@mui/material"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { PartnerSelector } from "./partner-selector"
import { PartnerData } from "./partner-data"
import { RefetchProvider } from "../../../../context/refetch-context"
import { UserService } from "../../../../services/user-service"
import { UserGroups } from "../../../../models/user-groups"

interface IPartnerOverviewProps {}

export const PartnerOverview: FunctionComponent<IPartnerOverviewProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Grid container direction="column" sx={{ height: "calc(100vh - 56px)", display: "flex", flexFlow: "column" }}>
      <Grid item sx={{ p: `0 ${theme.spacing(1)}`, flex: "0 0 auto", mb: 1, width: "100%" }}>
        <PageHeading>{t("partner_overview.heading")}</PageHeading>
      </Grid>
      <Grid
        container
        item
        direction="row"
        sx={{ p: `0 ${theme.spacing(1)}`, flex: "1 1 auto", overflowY: "auto", height: "100%" }}
      >
        <RefetchProvider>
          {UserService.isInUserGroup(UserGroups.PARTNER_SELECTOR) && <PartnerSelector />}
          <Grid
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: "100%",
              pl: UserService.isInUserGroup(UserGroups.PARTNER_SELECTOR) ? 2 : 0,
              pr: 2,
              ml: UserService.isInUserGroup(UserGroups.PARTNER_SELECTOR) ? 2 : 0,
              overflowY: "auto",
            }}
          >
            <PartnerData />
          </Grid>
        </RefetchProvider>
      </Grid>
    </Grid>
  )
}
