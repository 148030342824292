import React, { FunctionComponent, SyntheticEvent } from "react"
import { Card, Grid, FormControlLabel, Checkbox, TextField, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Text } from "../../../partials/wrapper/text"
import { VehicleOperatingTime } from "../../../../api/graphql/queries/get-vehicle-with-id"
import { roundToNearest } from "../../../../utils/math"

const TIME_STEP = 0.5

interface IVehicleOperatingTimesProps {
  operatingTimes: VehicleOperatingTime[]
  updateOperatingTimes: (operatingTimes: VehicleOperatingTime[]) => void
  isOperatingTimeValid: (operatingTime: VehicleOperatingTime) => boolean
}

export const VehicleOperatingTimes: FunctionComponent<IVehicleOperatingTimesProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { operatingTimes, updateOperatingTimes, isOperatingTimeValid } = props

  const updateActiveOfOperatingTimesEntry = (isActive: boolean, index: number) => {
    const copy = operatingTimes.slice()
    copy[index].active = isActive
    updateOperatingTimes(copy)
  }

  const updateMinTourDurationOfOperatingTimesEntry = (operatingTime: number, index: number) => {
    const copy = operatingTimes.slice()
    copy[index].min_tour_duration = roundToNearest(operatingTime, TIME_STEP)
    updateOperatingTimes(copy)
  }

  const updateMaxTourDurationOfOperatingTimesEntry = (operatingTime: number, index: number) => {
    const copy = operatingTimes.slice()
    copy[index].max_tour_duration = roundToNearest(operatingTime, TIME_STEP)
    updateOperatingTimes(copy)
  }

  return (
    <Card sx={{ p: 2, height: `calc(100% - ${theme.spacing(2)}px)` }}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item>
          <Text bold>{t("vehicle_overview.data.operating_time")}</Text>
        </Grid>
        <Grid item container direction="column" spacing={1}>
          {/* Grid for managing operating hours per day */}
          {operatingTimes.map((operatingTimesOfDay, index) => (
            <Grid container item direction="row" alignItems="center" spacing={2} key={operatingTimesOfDay.day}>
              <Grid item xs={2}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={operatingTimesOfDay.active}
                      onChange={(event: SyntheticEvent, checked: boolean) =>
                        updateActiveOfOperatingTimesEntry(checked, index)
                      }
                      sx={{ color: "primary.light" }}
                    />
                  }
                  label={t(`weekdays.${operatingTimesOfDay.day}_short`)}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  size="small"
                  error={!isOperatingTimeValid(operatingTimesOfDay)}
                  disabled={!operatingTimesOfDay.active}
                  label={t("vehicle_overview.data.min_tour_duration")}
                  type="number"
                  inputProps={{ min: 0, max: 24, step: TIME_STEP }}
                  fullWidth
                  variant="outlined"
                  value={operatingTimesOfDay.min_tour_duration}
                  onChange={(ev) => updateMinTourDurationOfOperatingTimesEntry(Number(ev.target.value), index)}
                />
              </Grid>
              <Grid item xs={5}>
                <TextField
                  size="small"
                  error={!isOperatingTimeValid(operatingTimesOfDay)}
                  disabled={!operatingTimesOfDay.active}
                  label={t("vehicle_overview.data.max_tour_duration")}
                  type="number"
                  inputProps={{ min: 0, max: 24, step: TIME_STEP }}
                  fullWidth
                  variant="outlined"
                  value={operatingTimesOfDay.max_tour_duration}
                  onChange={(ev) => updateMaxTourDurationOfOperatingTimesEntry(Number(ev.target.value), index)}
                />
              </Grid>
            </Grid>
          ))}
        </Grid>
      </Grid>
    </Card>
  )
}
