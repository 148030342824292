import React, { FunctionComponent, useContext, useMemo, useState } from "react"
import { Card, Grid, TextField, Autocomplete } from "@mui/material"
import { Text } from "../../../partials/wrapper/text"
import { DeparturePointContext } from "../../partner-overview/partials/departure-point/context/departure-point-context"
import { DeparturePoint } from "../../../../api/graphql/queries/get-departure-points"
import { useTranslation } from "react-i18next"
import { Cancel, Check } from "@mui/icons-material"

export const formatDeparturePoint = (departurePoint?: DeparturePoint) =>
  departurePoint ? `${departurePoint.id} - ${departurePoint.name}` : ""

interface IVehicleOperationPointProps {
  id: number | null
  setId: (id: number | null) => void
  heading: string
  error?: boolean
  setError?: (hasError: boolean) => void
}

export const VehicleOperationPoint: FunctionComponent<IVehicleOperationPointProps> = (props) => {
  const { t } = useTranslation()

  const { heading, id, setId, error = false, setError } = props
  const { departurePoints, departurePointsLoading } = useContext(DeparturePointContext)

  const selected = useMemo(
    () => departurePoints.find((departurePoint) => departurePoint.id === id),
    [departurePoints, id],
  )

  const [inputText, setInputText] = useState<string>(formatDeparturePoint(selected))

  return (
    <Card sx={{ p: 2 }}>
      <Grid container direction="row" spacing={2}>
        <Grid item xs={12}>
          <Text bold>{heading}</Text>
        </Grid>

        <Grid item xs={12}>
          {!departurePointsLoading && departurePoints.length > 0 && (
            <Autocomplete<DeparturePoint>
              loading={departurePointsLoading}
              options={departurePoints}
              getOptionLabel={formatDeparturePoint}
              inputValue={inputText}
              onInputChange={(ev, value) => setInputText(value)}
              onChange={(ev: any, value: DeparturePoint | null | string) => {
                if (setError) {
                  setError(false)
                }
                if (typeof value !== "string") {
                  setId(value?.id || null)
                }
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="small"
                  variant="outlined"
                  error={error}
                  required
                  fullWidth
                  label={t("assign")}
                  InputProps={{
                    ...params.InputProps,
                  }}
                />
              )}
            />
          )}
          {!departurePointsLoading && departurePoints.length <= 0 && (
            <Text>{t("partner_overview.departure_point.no_departure_points_available_for_collection_partner")}</Text>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container justifyContent="center" sx={{ background: "rgba(0, 0, 0, 0.1)", p: 1, borderRadius: 1 }}>
            {selected ? <Check /> : <Cancel />}
            <Text>
              {selected
                ? formatDeparturePoint(selected)
                : t("partner_overview.departure_point.no_departure_point_assigned").toUpperCase()}
            </Text>
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
