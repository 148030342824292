import React, { FunctionComponent, useCallback, useState } from "react"
import { Grid, Card, Typography, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CollectionPointAdministrationFormData } from "./collection-point-administration-form-data"
import {
  CollectionPointAdministrationLocationData,
  ICollectionPointLocationData,
} from "./collection-point-administration-location-data"
import { Cancel, Settings } from "@mui/icons-material"
import { defaultLocation } from "../../../../utils/map"
import { useMutation } from "@apollo/client"
import {
  CREATE_COLLECTION_POINT_MUTATION,
  CreateCollectionPointResult,
  CreateCollectionPointVariables,
} from "../../../../api/graphql/mutations/create-collection-point"
import {
  UPSERT_COLLECTION_POINT_OPENING_HOURS_MUTATION,
  UpsertCollectionPointOpeningHoursResult,
  UpsertCollectionPointOpeningHoursVariables,
} from "../../../../api/graphql/mutations/upsert-collection-point-opening-hours-id"
import { toast } from "react-toastify"
import lodash from "lodash"
import { AdminstrationCollectionPoint } from "../../../../api/graphql/queries/get-administration-collection-points-with-region-id"
import {
  REMOVE_COLLECTION_POINT_MUTATION,
  RemoveCollectionPointResult,
  RemoveCollectionPointVariables,
} from "../../../../api/graphql/mutations/remove-collection-point"
import { DeleteDialog } from "../../../partials/layout/dialog/delete-dialog"
import { CollectionPointAdministrationImages } from "./collection-point-administration-images"
import { ContainerAdministration } from "./container-administration"
import { useRefetch } from "../../../../context/refetch-context"
import { OpeningHoursDialog } from "../../../partials/dialogs/opening-hours-dialog"
import { OpeningHour } from "../../../partials/date-picker/opening-hours-picker"
import { useCollectionPointAdministrationContext } from "../collection-point-administration-context"

interface ICollectionPointAdministrationDataProps {}

export const CollectionPointAdministrationData: FunctionComponent<ICollectionPointAdministrationDataProps> = (
  props,
) => {
  const { t } = useTranslation()

  const [deleteDialogOpen, setDeleteDialogOpen] = useState(false)
  const [editOpeningHoursDialogOpen, setEditOpeningHoursDialogOpen] = useState(false)

  const { formData, setSelectedCollectionPoint, selectedCollectionPoint } = useCollectionPointAdministrationContext()

  const [locationData, setLocationData] = useState<ICollectionPointLocationData>({
    street: "",
    postal: "",
    place: "",
    isPostalValid: true,
    latitude: defaultLocation.latitude,
    longitude: defaultLocation.longitude,
  })

  const [createCollectionPoint] = useMutation<CreateCollectionPointResult, CreateCollectionPointVariables>(
    CREATE_COLLECTION_POINT_MUTATION,
  )

  const [upsertCollectionPointOpeningHours, { loading: upsertCollectionPointOpeningHoursLoading }] = useMutation<
    UpsertCollectionPointOpeningHoursResult,
    UpsertCollectionPointOpeningHoursVariables
  >(UPSERT_COLLECTION_POINT_OPENING_HOURS_MUTATION, {
    onCompleted: (data) => {
      if (data?.upsertCollectionPointOpeningHours == null)
        return toast.error(t("collection_point_administration.errors.opening_hour_upsert_error"))
      setNeedToRefetch(true)
      setEditOpeningHoursDialogOpen(false)
    },
    onError: () => {
      toast.error(t("collection_point_administration.errors.opening_hour_upsert_error"))
    },
  })

  const [removeCollectionPoint] = useMutation<RemoveCollectionPointResult, RemoveCollectionPointVariables>(
    REMOVE_COLLECTION_POINT_MUTATION,
  )

  const { setNeedToRefetch } = useRefetch()

  const onClickCreateCollectionPoint = async () => {
    const { messages, isValid } = validateFields()
    if (!isValid) {
      const jsx = (
        <div>
          {messages.map((message) => (
            <Typography key={message}>{message}</Typography>
          ))}
        </div>
      )
      toast.error(jsx)
      return
    }

    const result = await createCollectionPoint({
      variables: {
        id: selectedCollectionPoint ? selectedCollectionPoint.id : null,
        cadastral_name: formData.cadastralName,
        cadastral_nr: formData.cadastralNumber || null,
        description: formData.description,
        location_number: formData.locationNumber,
        town_id: String(formData.town_id),
        fixed_unload_interval: formData.fixedUnloadInterval || null,
        fixedInterval: formData.fixedInterval,
        latitude: locationData.latitude,
        longitude: locationData.longitude,
        place: locationData.place,
        postal: locationData.postal || null,
        street: locationData.street,
        overfill_rate: 0,
        overfill_manipulation_time: 0,
        extrapolation_weightings: formData.extrapolationWeightings,
      },
    })

    const collectionPoint = result.data && result.data.createCollectionPoint
    if (!lodash.isNil(collectionPoint)) {
      if (lodash.isNil(selectedCollectionPoint)) {
        toast.info(t("collection_point_administration.collection_point_created"))
      } else {
        toast.info(t("collection_point_administration.collection_point_updated"))
      }

      setSelectedCollectionPoint(
        !lodash.isNil(collectionPoint) ? (collectionPoint as AdminstrationCollectionPoint) : undefined,
      )
      setNeedToRefetch(true)
    } else {
      if (lodash.isNil(selectedCollectionPoint)) {
        toast.error(t("collection_point_administration.could_not_create_collection_point"))
      } else {
        toast.error(t("collection_point_administration.could_not_update_collection_point"))
      }
    }
  }

  const validateFields = () => {
    let messages = []
    if (!formData.description || !formData.town_id || !formData.areExtrapolationWeightingsValid) {
      messages.push(t("collection_point_administration.errors.required"))
    }
    if (!formData.isCadastralNumberValid) {
      messages.push(t("collection_point_administration.errors.cadastral_number_invalid"))
    }
    if (!locationData.isPostalValid) {
      messages.push(t("collection_point_administration.errors.postal_invalid"))
    }
    if (!formData.areExtrapolationWeightingsValid) {
      messages.push(t("collection_point_administration.errors.weighting_invalid"))
    }
    return {
      messages,
      isValid: messages.length === 0,
    }
  }

  const updateOpeningHours = useCallback(
    async (openingHours: OpeningHour[], accessibleWithTrailer: boolean, overfillingAllowed: boolean) => {
      if (!selectedCollectionPoint?.id) return

      await upsertCollectionPointOpeningHours({
        variables: {
          collectionPointId: selectedCollectionPoint.id,
          openingHours: openingHours.map((oh) => ({
            day: oh.day,
            from: oh.from,
            to: oh.to,
          })),
          accessibleWithTrailer,
          overfillingAllowed,
        },
      })
    },
    [selectedCollectionPoint, upsertCollectionPointOpeningHours],
  )

  const deleteConfirmed = async () => {
    const result = await removeCollectionPoint({
      variables: {
        id: Number(selectedCollectionPoint!.id),
      },
    })

    if (result.data) {
      toast.info(t("collection_point_administration.collection_point_deleted"))
      setDeleteDialogOpen(false)
      setSelectedCollectionPoint(undefined)
      setNeedToRefetch(true)
    } else {
      setDeleteDialogOpen(false)
      toast.error(t("collection_point_administration.could_not_delete_collection_point"))
    }
  }

  return (
    <Grid container direction="column" spacing={2} sx={{ height: "100%" }} wrap="nowrap">
      <Grid item>
        <Card sx={{ p: 2 }}>
          <OpeningHoursDialog
            open={editOpeningHoursDialogOpen}
            onClose={() => setEditOpeningHoursDialogOpen(false)}
            onConfirm={updateOpeningHours}
            openingHours={selectedCollectionPoint?.opening_hours ?? []}
            accessibleWithTrailer={selectedCollectionPoint?.accessible_with_trailer!}
            overfillingAllowed={selectedCollectionPoint?.overfilling_allowed!}
            loading={upsertCollectionPointOpeningHoursLoading}
          />
          <DeleteDialog
            open={deleteDialogOpen}
            onClose={() => setDeleteDialogOpen(false)}
            content={t("collection_point_administration.delete_dialog_text_collection_point")}
            onDelete={() => deleteConfirmed()}
          />
          <Grid container direction="column" spacing={3}>
            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
                  {t("collection_point_administration.collection_point_info")}
                </Typography>
              </Grid>
              {!lodash.isNil(selectedCollectionPoint) && (
                <Grid item container direction="row" justifyContent="flex-end" spacing={1} xs>
                  <Grid item sx={{ cursor: "pointer" }} onClick={() => setEditOpeningHoursDialogOpen(true)}>
                    <Typography>{t("collection_point_administration.collection_point_settings")}</Typography>
                  </Grid>
                  <Grid item sx={{ cursor: "pointer" }} onClick={() => setEditOpeningHoursDialogOpen(true)}>
                    <Settings color="primary" width={24} height={24} />
                  </Grid>

                  <Grid item sx={{ cursor: "pointer" }} onClick={() => setDeleteDialogOpen(true)}>
                    <Typography>{t("collection_point_administration.delete_collection_point")}</Typography>
                  </Grid>
                  <Grid item sx={{ cursor: "pointer" }} onClick={() => setDeleteDialogOpen(true)}>
                    <Cancel color="error" width={24} height={24} />
                  </Grid>
                </Grid>
              )}
            </Grid>
            <Grid item container direction="row" spacing={2}>
              <Grid item container direction="column" xs={4} justifyContent="space-between">
                <Grid item>
                  <CollectionPointAdministrationFormData />
                </Grid>
                {!lodash.isNil(selectedCollectionPoint) && (
                  <Grid item>
                    <CollectionPointAdministrationImages />
                  </Grid>
                )}
              </Grid>
              <Grid item container direction="column" xs={8}>
                <CollectionPointAdministrationLocationData updateLocationData={setLocationData} />
              </Grid>
            </Grid>
            <Grid item container direction="row" justifyContent="space-between" alignItems="center">
              <Grid item>
                <Button
                  sx={{ width: 260 }}
                  type="button"
                  variant="contained"
                  onClick={() => setSelectedCollectionPoint(undefined)}
                  fullWidth
                  color="inherit"
                >
                  {t("collection_point_administration.data.cancel")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  sx={{ width: 260 }}
                  type="button"
                  variant="contained"
                  onClick={onClickCreateCollectionPoint}
                  fullWidth
                  color="primary"
                >
                  {lodash.isNil(selectedCollectionPoint)
                    ? t("collection_point_administration.data.create")
                    : t("collection_point_administration.data.save")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Card>
      </Grid>
      {selectedCollectionPoint && (
        <Grid item xs sx={{ flex: "1" }}>
          <ContainerAdministration />
        </Grid>
      )}
    </Grid>
  )
}
