import { Grid, Table, TableCell, TableHead, TableRow, TableBody, TableContainer } from "@mui/material"
import React, { FunctionComponent, useContext } from "react"
import { useTranslation } from "react-i18next"
import { EventType } from "../../../../api/graphql/graphql-global-types"
import { getRouteWithId_getRouteWithId_stops } from "../../../../api/graphql/queries/types/getRouteWithId"
import { ContentContainer } from "../../../partials/layout/content/content-container"
import { Text } from "../../../partials/wrapper/text"
import { RouteContext } from "./route-context"

interface IRouteDetailsTableProps {}

export const RouteDetailsTable: FunctionComponent<IRouteDetailsTableProps> = (props) => {
  const { t } = useTranslation()
  const { route } = useContext(RouteContext)
  const stopCount = route ? route?.stops.length : 0

  const sortAndFilterStops = (stops: getRouteWithId_getRouteWithId_stops[]) => {
    const filtered = stops.filter(
      (stop) => stop.events.length && stop.events.some((event) => event.event_type === EventType.BULK && event.info),
    )
    const sorted = filtered.sort((a, b) => a.sequence_number - b.sequence_number)
    return sorted
  }

  const getStopRelevantEventInfos = (stop: getRouteWithId_getRouteWithId_stops) => {
    const relevantInfos = stop.events
      .filter((event) => event.event_type === EventType.BULK && event.info)
      .map((event) => event.info as string)

    return relevantInfos ? relevantInfos.join(", ") : t("routes_management.erros.no_relevant_info")
  }

  return (
    <Grid container direction="column" spacing={2}>
      <Grid>
        <Grid item>
          <ContentContainer>
            <TableContainer style={{ maxHeight: 400 }}>
              <Table size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">{t("routes_management.table.sequence_number")}</TableCell>
                    <TableCell align="center">{t("routes_management.table.lat")}</TableCell>
                    <TableCell align="center">{t("routes_management.table.long")}</TableCell>
                    <TableCell align="center">{t("routes_management.table.info")}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {route &&
                    sortAndFilterStops(route.stops).map((stop, index) => (
                      <TableRow key={stop.id}>
                        <TableCell align="center" sx={{ borderBottom: index === stopCount - 1 ? "none" : undefined }}>
                          {stop.sequence_number}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: index === stopCount - 1 ? "none" : undefined }}>
                          {stop.latitude}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: index === stopCount - 1 ? "none" : undefined }}>
                          {stop.longitude}
                        </TableCell>
                        <TableCell align="center" sx={{ borderBottom: index === stopCount - 1 ? "none" : undefined }}>
                          {getStopRelevantEventInfos(stop)}
                        </TableCell>
                      </TableRow>
                    ))}
                </TableBody>
              </Table>
              {route?.stops && route.stops.length <= 0 && (
                <Grid container item style={{ height: "20vh" }} justifyContent={"center"} alignContent={"center"}>
                  <Text color="textSecondary">{t("routes_management.table.empty")}</Text>
                </Grid>
              )}
            </TableContainer>
          </ContentContainer>
        </Grid>
      </Grid>
    </Grid>
  )
}
