import React, { FunctionComponent, useState, useEffect } from "react"
import { Grid, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { CustomMap, ICenterOption, IMarker } from "../../../partials/maps/custom-map"
import { defaultLocation } from "../../../../utils/map"
import lodash from "lodash"
import { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { useDebounce } from "../../../../utils/useDebounce"
import { useCollectionPointAdministrationContext } from "../collection-point-administration-context"

export interface ICollectionPointLocationData {
  street: string
  place: string
  postal: string
  isPostalValid: boolean
  latitude: number
  longitude: number
}

interface ICollectionPointAdministrationLocationDataProps {
  updateLocationData: (locationData: ICollectionPointLocationData) => void
}

export const CollectionPointAdministrationLocationData: FunctionComponent<
  ICollectionPointAdministrationLocationDataProps
> = (props) => {
  const { t } = useTranslation()
  const { updateLocationData } = props
  const [street, setStreet] = useState<string>("")
  const [place, setPlace] = useState<string>("")
  const [postal, setPostal] = useState<string>("")
  const [isPostalValid, setIsPostalValid] = useState<boolean>(true)
  const [latitude, setLatitude] = useState<number>(defaultLocation.latitude)
  const [longitude, setLongitude] = useState<number>(defaultLocation.longitude)

  const { selectedCollectionPoint } = useCollectionPointAdministrationContext()

  const [address, setAddress] = useState<string>("")
  const debouncedAddress = useDebounce(address, 1000)

  useEffect(() => {
    let newStreet = ""
    let newPlace = ""
    let newPostal = ""
    let newLatitude = defaultLocation.latitude
    let newLongitude = defaultLocation.longitude
    const newIsPostalValid = true
    if (selectedCollectionPoint) {
      newStreet = selectedCollectionPoint.street || ""
      newPlace = selectedCollectionPoint.place || ""
      newPostal = String(selectedCollectionPoint.postal || "")
      newLatitude = selectedCollectionPoint.latitude || defaultLocation.latitude
      newLongitude = selectedCollectionPoint.longitude || defaultLocation.longitude
    }

    setStreet(newStreet)
    setPlace(newPlace)
    setPostal(newPostal)
    setIsPostalValid(newIsPostalValid)
    setLatitude(newLatitude)
    setLongitude(newLongitude)

    updateCollectionPointLocationData(
      {
        street: newStreet,
        place: newPlace,
        postal: newPostal,
        isPostalValid: newIsPostalValid,
        latitude: newLatitude,
        longitude: newLongitude,
      } as ICollectionPointLocationData,
      false,
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedCollectionPoint])

  const updateCollectionPointLocationData = async (data: any, updateAddress: boolean = true) => {
    if (updateAddress && (!lodash.isNil(data.place) || !lodash.isNil(data.postal) || !lodash.isNil(data.street))) {
      setAddress(`${data.place || place} ${data.postal || postal} ${data.street || street}`)
    }
    updateLocationData({
      street,
      place,
      postal,
      isPostalValid,
      latitude,
      longitude,
      ...data,
    })
  }

  useEffect(() => {
    if (debouncedAddress.trim().length > 0) {
      geocodeByAddress(debouncedAddress).then(async (result) => {
        const location = await getLatLng(result[0])
        if (location.lat && location.lng) {
          setLatitude(location.lat)
          setLongitude(location.lng)
          updateLocationData({
            street,
            place,
            postal,
            isPostalValid,
            latitude: location.lat,
            longitude: location.lng,
          })
        }
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedAddress])

  return (
    <Grid container direction="row" spacing={1}>
      <Grid item xs={12}>
        <TextField
          size="small"
          label={t("collection_point_administration.data.street")}
          type="search"
          fullWidth
          variant="outlined"
          value={street}
          onChange={(ev) => {
            setStreet(ev.target.value)
            updateCollectionPointLocationData({ street: ev.target.value })
          }}
        />
      </Grid>
      <Grid item xs={4}>
        <TextField
          size="small"
          label={t("collection_point_administration.data.postal")}
          type="search"
          error={!isPostalValid}
          fullWidth
          variant="outlined"
          value={postal}
          onChange={(ev) => {
            const newIsPostalValid = ev.target.value && !/^\d+$/.test(ev.target.value) ? false : true
            setPostal(ev.target.value)
            setIsPostalValid(newIsPostalValid)
            updateCollectionPointLocationData({
              postal: ev.target.value,
              isPostalValid: newIsPostalValid,
            })
          }}
        />
      </Grid>
      <Grid item xs={8}>
        <TextField
          size="small"
          label={t("collection_point_administration.data.place")}
          type="search"
          fullWidth
          variant="outlined"
          value={place}
          onChange={(ev) => {
            setPlace(ev.target.value)
            updateCollectionPointLocationData({ place: ev.target.value })
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          size="small"
          label={t("departure_point.data.lat")}
          fullWidth
          variant="outlined"
          value={latitude}
          onChange={(ev) => {
            setLatitude(Number(ev.target.value))
            updateCollectionPointLocationData({
              latitude: Number(ev.target.value),
            })
          }}
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          size="small"
          label={t("departure_point.data.long")}
          fullWidth
          variant="outlined"
          value={longitude}
          onChange={(ev) => {
            setLongitude(Number(ev.target.value))
            updateCollectionPointLocationData({
              longitude: Number(ev.target.value),
            })
          }}
        />
      </Grid>
      <Grid item xs={12}>
        <CustomMap
          disableInfoWindow
          disableZoom
          center={
            {
              lat: latitude,
              lng: longitude,
            } as ICenterOption
          }
          onMapClicked={(event) => {
            if (!event?.latLng) {
              return
            }

            const newLatitude = parseFloat(event.latLng.lat().toFixed(7))
            const newLongitude = parseFloat(event.latLng.lng().toFixed(7))
            setLatitude(newLatitude)
            setLongitude(newLongitude)
            updateCollectionPointLocationData({
              latitude: newLatitude,
              longitude: newLongitude,
            })
          }}
          markers={[
            {
              lat: latitude,
              lng: longitude,
            } as IMarker,
          ]}
        />
      </Grid>
    </Grid>
  )
}
