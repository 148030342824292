import { useQuery } from "@apollo/client"
import { Chip, CircularProgress, Grid } from "@mui/material"
import React, { FunctionComponent } from "react"
import { DriverLocationResult, GET_DRIVER_LOCATIONS_QUERY } from "../../../../../api/graphql/queries/get-driver-locations"
import { sortASC } from "../../../../../utils/sort"
import { ILocationCollectionItem } from "./location-assignment-dialog"

interface IGuidedDriverLocationSelectionProps {
  selectedItems: ILocationCollectionItem[]
  onClick: (item: ILocationCollectionItem) => void
  searchString: string
}

export const GuidedDriverLocationSelection: FunctionComponent<IGuidedDriverLocationSelectionProps> = (
  props
) => {
  const { onClick, selectedItems, searchString } = props
  const { data, loading } = useQuery<DriverLocationResult>(GET_DRIVER_LOCATIONS_QUERY)

  const locations =
    data?.driverLocations
      .sort((a, b) => sortASC(a.name, b.name))
      .filter((driverLocation) =>
      driverLocation.name.toLowerCase().includes(searchString.toLowerCase())
      ) || []

  return (
    <Grid container spacing={1}>
      {loading && (
        <Grid item container justifyContent="center">
          <CircularProgress color="primary" />
        </Grid>
      )}
      {!loading &&
        locations.map((driverLocation) => (
          <Grid item key={driverLocation.id}>
            <Chip
              label={driverLocation.name}
              color={
                selectedItems.find(
                  (item) => item.id === driverLocation.id.toString() && item.name === driverLocation.name
                )
                  ? "primary"
                  : "default"
              }
              onClick={() => {
                const mappedLocation = {
                  id: driverLocation.id.toString(),
                  name: driverLocation.name
                }
                onClick(mappedLocation)
              }}
            />
          </Grid>
        ))}
    </Grid>
  )
}
