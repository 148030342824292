import React, { FunctionComponent } from "react"
import { TextField, Grid, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ExtrapolationWeightingsInput } from "../../../../api/graphql/graphql-global-types"
import "../../../../styles/css/number-input-style.css"
import { Info } from "@mui/icons-material"
import { Text } from "../../../partials/wrapper/text"
import lodash from "lodash"

interface IExtrapolationWeightingsProps {
  weightings: ExtrapolationWeightingsInput
  onChange: (day: keyof ExtrapolationWeightingsInput, newValue: number | undefined) => void
}

export const ExtrapolationWeightings: FunctionComponent<IExtrapolationWeightingsProps> = (props) => {
  const { t } = useTranslation()
  const { weightings, onChange } = props

  const validValues = Object.values(weightings).filter((value) => lodash.isNumber(value))
  const error = validValues.every((value) => value <= 0)

  return (
    <Grid container sx={{ mb: 1 }}>
      <Grid container item sx={{ mb: 1 }}>
        <Text>{t("collection_point_administration.data.extrapolation_weighting")}</Text>
        <Tooltip title={t("collection_point_administration.data.extrapolation_weighting_info") as string}>
          <Info sx={{ pl: 1 }} color="primary" />
        </Tooltip>
      </Grid>
      <Grid container item>
        <ExtrapolationWeighting
          title={t("weekdays.monday_short")}
          value={weightings?.monday}
          error={error}
          onChange={(newValue) => onChange("monday", newValue)}
        />
        <ExtrapolationWeighting
          title={t("weekdays.tuesday_short")}
          value={weightings?.tuesday}
          error={error}
          onChange={(newValue) => onChange("tuesday", newValue)}
        />
        <ExtrapolationWeighting
          title={t("weekdays.wednesday_short")}
          value={weightings?.wednesday}
          error={error}
          onChange={(newValue) => onChange("wednesday", newValue)}
        />
        <ExtrapolationWeighting
          title={t("weekdays.thursday_short")}
          value={weightings?.thursday}
          error={error}
          onChange={(newValue) => onChange("thursday", newValue)}
        />
        <ExtrapolationWeighting
          title={t("weekdays.friday_short")}
          value={weightings?.friday}
          error={error}
          onChange={(newValue) => onChange("friday", newValue)}
        />
        <ExtrapolationWeighting
          title={t("weekdays.saturday_short")}
          value={weightings?.saturday}
          error={error}
          onChange={(newValue) => onChange("saturday", newValue)}
        />
        <ExtrapolationWeighting
          title={t("weekdays.sunday_short")}
          value={weightings?.sunday}
          error={error}
          onChange={(newValue) => onChange("sunday", newValue)}
        />
      </Grid>
    </Grid>
  )
}

interface IExtrapolationWeightingProps {
  title: string
  value: number | undefined | null
  error: boolean
  onChange: (newValue: number | undefined) => void
}

const ExtrapolationWeighting: FunctionComponent<IExtrapolationWeightingProps> = (props) => {
  const { title, value, error, onChange } = props
  const valid = !error && lodash.isNumber(value) && value >= 0
  return (
    <TextField
      size="small"
      value={value ?? ""}
      placeholder={"100"}
      error={!valid}
      type="number"
      label={title}
      required
      sx={{ mb: 1, flexGrow: 1, flexBasis: 55, maxWidth: 70 }}
      variant="outlined"
      InputLabelProps={{ shrink: true }}
      onChange={(e) => {
        const input = parseFloat(e.target.value)
        if (Number.isFinite(input)) {
          onChange(input)
        } else {
          onChange(undefined)
        }
      }}
    />
  )
}
