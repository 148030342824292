/*global google*/
import { InfoWindow, Marker, useGoogleMap } from "@react-google-maps/api"
import React, { FunctionComponent, useEffect, useState } from "react"

interface ICustomFlatRouteMarkerProps {
  id: number
  lat: number
  lng: number
  onClick?: (id: number) => void
  isSelected: boolean
  number?: string
  disableInfoWindow?: boolean
  doRenderCustomInfoWindow?: boolean
  renderCustomInfoWindow?: (markerId: number) => JSX.Element | ""
}

export const CustomFlatRouteMarker: FunctionComponent<ICustomFlatRouteMarkerProps> = (props) => {
  const {
    id,
    lat,
    lng,
    disableInfoWindow,
    doRenderCustomInfoWindow,
    renderCustomInfoWindow = () => {
      return ""
    },
  } = props
  const [isOpen, setIsOpen] = useState<boolean>(false)
  const [markerOpacity, setMarkerOpacity] = useState<number>(0)
  const instance = useGoogleMap()

  useEffect(() => {
    if (!props.isSelected) {
      setIsOpen(false)
    } else if (instance) {
      instance.setZoom(15)
      if (!isOpen) {
        onMarkerClicked()
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.isSelected, instance])

  const onMarkerClicked = () => {
    if (instance && !disableInfoWindow) {
      setMarkerOpacity(0)
      setIsOpen(true)
    }
  }

  const getIcon = () => {
    return {
      url: "http://maps.google.com/mapfiles/kml/shapes/placemark_circle.png",
      anchor: new google.maps.Point(16, 16),
    }
  }

  const getInfoWindowContent = () => {
    if (doRenderCustomInfoWindow) {
      return renderCustomInfoWindow(id)
    }
  }

  const onMouseOver = () => {
    setMarkerOpacity(1)
  }

  const onMouseOut = () => {
    setMarkerOpacity(0)
  }

  return (
    <Marker
      key={`map_${id}`}
      position={{
        lat: lat,
        lng: lng,
      }}
      icon={getIcon()}
      onClick={onMarkerClicked}
      onMouseOver={onMouseOver}
      onMouseOut={onMouseOut}
      opacity={markerOpacity}
    >
      {isOpen && (
        <InfoWindow
          onCloseClick={() => {
            setIsOpen(false)
          }}
          position={{
            lat,
            lng,
          }}
        >
          {getInfoWindowContent()}
        </InfoWindow>
      )}
    </Marker>
  )
}
