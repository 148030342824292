import React, { FunctionComponent } from "react"
import { Card, Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { ImportRouteButton } from "./import-route-button"
import { RoutesTable } from "./routes-table"
import { useRefetch } from "../../../../context/refetch-context"
import { useQuery } from "@apollo/client"
import {
  RoutesForLocationResult,
  GET_ROUTES_FOR_LOCATION,
} from "../../../../api/graphql/queries/get-routes-for-location"
import { useDriverLocation } from "../../../../context/driver-location-filter-context"
import { DriverLocationSelect } from "./driver-location-select"

interface IRoutesOverviewProps {}

export const RoutesOverview: FunctionComponent<IRoutesOverviewProps> = (props) => {
  const { t } = useTranslation()
  const { needToRefetch, setNeedToRefetch } = useRefetch()
  const { driverLocationId } = useDriverLocation()

  const { loading, data } = useQuery<RoutesForLocationResult>(GET_ROUTES_FOR_LOCATION, {
    fetchPolicy: needToRefetch ? "network-only" : "cache-first",
    onCompleted: () => setNeedToRefetch(false),
    variables: {
      locationId: driverLocationId,
    },
  })

  return (
    <Grid container direction="column" spacing={2}>
      <Grid item container direction="row" justifyContent="space-between" alignItems="center">
        <Grid item>
          <PageHeading>{t("routes_management.title")}</PageHeading>
        </Grid>
        <Grid item>
          <ImportRouteButton routes={data?.getRoutesForLocation || []} />
        </Grid>
      </Grid>
      <Grid item>
        <Card sx={{ p: 2 }}>
          <Typography>{t("guidance_administration.filter_driver_location")}</Typography>
          <DriverLocationSelect />
        </Card>
        <RoutesTable routes={data?.getRoutesForLocation || []} loading={loading} />
      </Grid>
    </Grid>
  )
}
