import React, { FunctionComponent, useEffect, useState } from "react"
import { Autocomplete, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import Chip from "@mui/material/Chip"
import { PredefinedSelections, SelectOption, SortGroupEnum } from "./custom-select-component-commons"

interface IMultiSelectProps {
  id: string
  title: string
  currentlySelectedValues: SelectOption[]
  availableSelectOptions: SelectOption[]
  setCurrentlySelectedValues: (selectedValues: SelectOption[]) => void
  error?: boolean
  helperText?: string
  disabled?: boolean
}

export const CustomAutocomplete: FunctionComponent<IMultiSelectProps> = (props) => {
  const { t } = useTranslation()
  const {
    title,
    currentlySelectedValues,
    availableSelectOptions,
    setCurrentlySelectedValues,
    error,
    helperText,
    disabled = false,
  } = props
  const [selectOpenState, setSelectOpenState] = useState<boolean>(false)
  const [sortedAvailableSelectOptions, setSortedAvailableSelectOptions] = useState<SelectOption[]>([])
  const [isSorted, setIsSorted] = useState<boolean>(false)

  useEffect(() => {
    const sortedAvailableSelectOptions = Array.from(availableSelectOptions)
    sortedAvailableSelectOptions.unshift(PredefinedSelections.SelectAll)
    sortedAvailableSelectOptions.unshift(PredefinedSelections.ClearSelection)
    setSortedAvailableSelectOptions(sortedAvailableSelectOptions)
  }, [availableSelectOptions])

  const handleChange = (values: SelectOption[]) => {
    if (values.includes(PredefinedSelections.ClearSelection)) {
      setCurrentlySelectedValues([])
      setSelectOpenState(false)
    } else if (values.includes(PredefinedSelections.SelectAll)) {
      setCurrentlySelectedValues(
        availableSelectOptions.filter(
          (selectOption) =>
            selectOption !== PredefinedSelections.SelectAll && selectOption !== PredefinedSelections.ClearSelection,
        ),
      )
      setSelectOpenState(false)
    } else {
      setCurrentlySelectedValues(values)
      setSelectOpenState(true)
    }

    sortAndSetAvailableSelectOptions(values)
  }

  function sortAndSetAvailableSelectOptions(values: SelectOption[]) {
    let updatedSortedAvailableSelectOptions = Array.from(sortedAvailableSelectOptions)
    updatedSortedAvailableSelectOptions.forEach((selectOption) => {
      if (selectOption.sortGroup === SortGroupEnum.First || selectOption.sortGroup === SortGroupEnum.Second) {
        return
      }

      if (values.map((x) => x.id).includes(selectOption.id)) {
        selectOption.sortGroup = SortGroupEnum.Third
      } else {
        selectOption.sortGroup = SortGroupEnum.Fourth
      }
    })

    updatedSortedAvailableSelectOptions.sort((option1, option2) => {
      const sortGroupComparison = option1.sortGroup - option2.sortGroup
      if (sortGroupComparison === 0) {
        return option1.name.localeCompare(option2.name)
      }

      return sortGroupComparison
    })

    setSortedAvailableSelectOptions(updatedSortedAvailableSelectOptions)
    setIsSorted(true)
  }

  const handleIsSelected = (option: SelectOption, value: SelectOption) => {
    return option.id === value.id
  }

  const handleOpen = () => {
    if (!isSorted) {
      sortAndSetAvailableSelectOptions(currentlySelectedValues)
    }
    setSelectOpenState(true)
  }

  return (
    <Autocomplete
      multiple
      disabled={disabled}
      limitTags={1}
      id={props.id}
      autoSelect={false}
      options={sortedAvailableSelectOptions}
      getOptionLabel={(selectOption) => selectOption.name}
      value={currentlySelectedValues}
      renderInput={(params) => (
        <TextField error={error} helperText={helperText} {...params} size="small" variant="outlined" label={title} />
      )}
      open={selectOpenState}
      onChange={(e, values) => handleChange(values)}
      onClose={() => setSelectOpenState(false)}
      onOpen={handleOpen}
      disableCloseOnSelect
      renderTags={(selected) => {
        const label =
          selected.length === 1 ? selected[0].name : `${selected.length} ${t("custom_autocomplete.tagText")}`
        return <Chip sx={{ mb: 1.5, height: 20 }} label={label}></Chip>
      }}
      renderOption={(props, selectOption) => (
        <li {...props} id={selectOption.id}>
          {selectOption.name}
        </li>
      )}
      isOptionEqualToValue={handleIsSelected}
    />
  )
}
