import React, { FunctionComponent } from "react"
import { Card, Grid, Button, IconButton, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Text } from "../../../partials/wrapper/text"
import { DisposalMerchantStatsVacationTimes } from "../../../../api/graphql/queries/get-disposal-merchant-stats-with-id"
import { DatePicker } from "@mui/x-date-pickers/DatePicker"
import AddIcon from "@mui/icons-material/Add"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import moment from "moment"

interface IDisposalMerchantVacationTimesProps {
  vacationTimes: DisposalMerchantStatsVacationTimes[]
  updateVacationTimes: (vacationTimes: DisposalMerchantStatsVacationTimes[]) => void
  addIdToDeletedVacationTimeIds: (id: number) => void
}

export const DisposalMerchantVacationTimes: FunctionComponent<IDisposalMerchantVacationTimesProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { vacationTimes, updateVacationTimes, addIdToDeletedVacationTimeIds } = props

  const handleChangeStartDate =
    (vacationTime: DisposalMerchantStatsVacationTimes) => (dateTime: moment.Moment | null, value: any) => {
      vacationTime.from = (dateTime as moment.Moment).utc(true).toDate()

      if (moment(vacationTime.to).diff(dateTime as moment.Moment) < 0) {
        vacationTime.to = (dateTime as moment.Moment).utc(true).toDate()
      }

      updateVacationTimes(vacationTimes.slice())
    }

  const handleChangeEndDate =
    (vacationTime: DisposalMerchantStatsVacationTimes) => (dateTime: moment.Moment | null, value: any) => {
      vacationTime.to = (dateTime as moment.Moment).utc(true).toDate()

      if (moment(vacationTime.from).diff(dateTime as moment.Moment) > 0) {
        vacationTime.from = (dateTime as moment.Moment).utc(true).toDate()
      }

      updateVacationTimes(vacationTimes.slice())
    }

  return (
    <Card
      sx={{
        padding: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
        height: `calc(100% - ${theme.spacing(2)})`,
      }}
    >
      <Grid container direction="column" spacing={2}>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Text bold>{t("disposal_merchant_overview.data.vacation_times")}</Text>
          </Grid>
          <Grid item>
            <Button
              type="button"
              variant="outlined"
              sx={{
                borderWidth: 0.5,
                borderRadius: 1,
                borderColor: theme.palette.primary.main,
                margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
              }}
              onClick={() =>
                updateVacationTimes(
                  vacationTimes.concat({
                    from: moment().utc(true).startOf("day").toDate(),
                    to: moment().utc(true).startOf("day").toDate(),
                  } as DisposalMerchantStatsVacationTimes),
                )
              }
              fullWidth
            >
              <AddIcon color="primary" />
            </Button>
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={2}>
          {vacationTimes.length ? (
            vacationTimes.map((vacationTime, index) => (
              <Grid
                item
                container
                direction="row"
                alignItems="flex-end"
                justifyContent="space-between"
                key={`vacationTime${index}`}
              >
                <Grid item container xs={11} spacing={2}>
                  <Grid item xs>
                    <DatePicker
                      label={t("date_picker.from")}
                      format={t("date_format")}
                      value={moment(vacationTime.from)}
                      onChange={handleChangeStartDate(vacationTime)}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                        },
                      }}
                    />
                  </Grid>
                  <Grid item xs>
                    <DatePicker
                      label={t("date_picker.to")}
                      format={t("date_format")}
                      value={moment(vacationTime.to)}
                      onChange={handleChangeEndDate(vacationTime)}
                      minDate={moment().utc(true).startOf("day")}
                      slotProps={{
                        textField: {
                          fullWidth: true,
                          size: "small",
                        },
                      }}
                    />
                  </Grid>
                </Grid>
                <Grid item xs={1}>
                  <IconButton
                    color="error"
                    onClick={() => {
                      if (vacationTime.id) {
                        addIdToDeletedVacationTimeIds(vacationTime.id)
                      }
                      updateVacationTimes(vacationTimes.filter((vacationTime, i) => index !== i))
                    }}
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          ) : (
            <Text textAlign="center">{t("disposal_merchant_overview.data.no_vacation_times")}</Text>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}
