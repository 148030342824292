import { Checkbox, FormControl, FormControlLabel, FormGroup, Grid, Radio, RadioGroup } from "@mui/material"
import React, { useState } from "react"
import { useTranslation } from "react-i18next"
import { ConfirmDialog } from "../../../../partials/layout/dialog/confirm-dialog"
import { DuplicateAction } from "./use-duplicate-handler-hook"

interface ICollectionPoint {
  collectionPointId: string | null
  description: string | null
  latitude: number
  longitude: number
}

interface IUploadCollectionPointsConfirmDialogProps<T> {
  open: boolean
  onClose: () => void
  onConfirm: (action: DuplicateAction, forAll: boolean) => void
  current: T | undefined
}

export const UploadCollectionPointsConfirmDialog = <T extends ICollectionPoint>(
  props: IUploadCollectionPointsConfirmDialogProps<T>,
) => {
  const { t } = useTranslation()
  const { open, onClose, onConfirm, current } = props

  const [rememberAction, setRememberAction] = useState(false)
  const [action, setAction] = useState(DuplicateAction.Exclude)

  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      onConfirm={() => onConfirm(action, rememberAction)}
      allowMultipleConfirms
      heading={t("collection_point_administration.upload.duplicate_dialog.title")}
      text={t("collection_point_administration.upload.duplicate_dialog.body", {
        description: current?.description,
        latitude: current?.latitude,
        longitude: current?.longitude,
      })}
      confirmText={t("ok")}
    >
      <Grid container sx={{ width: 500 }}>
        <Grid container item spacing={4}>
          <Grid item>
            <FormControl>
              <RadioGroup value={action} onChange={(e, value) => setAction(value as DuplicateAction)}>
                <FormControlLabel
                  value={DuplicateAction.Exclude}
                  control={<Radio />}
                  label={t("collection_point_administration.upload.duplicate_dialog.options.exclude")}
                />
                <FormControlLabel
                  value={DuplicateAction.Include}
                  control={<Radio />}
                  label={t("collection_point_administration.upload.duplicate_dialog.options.include")}
                />
              </RadioGroup>
            </FormControl>
          </Grid>

          <Grid item>
            <FormGroup>
              <FormControlLabel
                control={<Checkbox checked={rememberAction} onChange={(e, checked) => setRememberAction(checked)} />}
                label={t("collection_point_administration.upload.duplicate_dialog.remember_action")}
              />
            </FormGroup>
          </Grid>
        </Grid>
      </Grid>
    </ConfirmDialog>
  )
}
