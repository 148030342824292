import React, { FC } from "react"
import { Grid, useTheme } from "@mui/material"
import { useLocation, useParams } from "react-router-dom"
import { CollectionPointAdministrationProvider } from "../collection-point-administration-context"
import { PATH } from "../../../../router/router"
import { CollectionPointAdministrationPageContent } from "./collection-point-administration-page-content"
import { RefetchProvider } from "../../../../context/refetch-context"

interface CollectionPointAdministrationPageProps {}

export const CollectionPointAdministrationPage: FC<CollectionPointAdministrationPageProps> = () => {
  const location = useLocation()
  const params = useParams<{ collectionPointId: string }>()
  const theme = useTheme()

  const collectionPointId =
    location.pathname !== PATH.COLLECTIONPOINTS_ADMINISTRATION_UPLOAD.route
      ? Number(params.collectionPointId)
      : undefined

  return (
    <RefetchProvider>
      <CollectionPointAdministrationProvider collectionPointId={collectionPointId}>
        <Grid container direction="row" spacing={2} sx={{ height: `calc(100vh - ${theme.spacing(7)})` }}>
          <CollectionPointAdministrationPageContent />
        </Grid>
      </CollectionPointAdministrationProvider>
    </RefetchProvider>
  )
}
