import React, { FunctionComponent, Fragment } from "react"
import { Card, Grid } from "@mui/material"
import { Text } from "../../../partials/wrapper/text"
import { useSelectedVehicle } from "../../../../context/selected-vehicle-context"
import { Vehicles } from "../../../../api/graphql/queries/get-vehicles-with-partner-id"

interface IVehicleItemProps {
  item: Vehicles
}

export const VehicleItem: FunctionComponent<IVehicleItemProps> = (props) => {
  const { selectedVehicleId, setSelectedVehicleId } = useSelectedVehicle()
  const { item } = props

  const onClick = () => {
    setSelectedVehicleId(props.item.id)
  }

  const isSelected = () => {
    if (!selectedVehicleId) return false
    return selectedVehicleId === props.item.id
  }

  return (
    <Fragment>
      <Card
        sx={{
          m: 1,
          "&:hover": { cursor: "pointer" },
          boxShadow: isSelected() ? "0 0 4px 0 rgba(75, 151, 62, 0.5)" : undefined,
        }}
        onClick={onClick}
      >
        <Grid sx={{ height: "100%", p: 1 }} container direction="row">
          <Grid item xs={12}>
            <Text bold noWrap>
              {item.licence_plate || "-"}
            </Text>
          </Grid>
          <Grid item container xs={12} justifyContent="space-between">
            <Grid item>
              <Text fontSize={15} noWrap>
                {item.type || "-"}
              </Text>
            </Grid>
            <Grid item>
              <Text fontSize={15} noWrap>
                {item.district.name}
              </Text>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
