import React, { FunctionComponent, useCallback } from "react"
import { Table, TableHead, TableRow, TableCell, TableBody, Grid, Skeleton, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  TourExportPreviewCollectionPoint,
  TourExportPreviewMaterial,
} from "../../../../api/graphql/queries/tour-export-preview"
import lodash from "lodash"
import moment from "moment"
import { Badge } from "../../user-management/partials/badge"
import { UserService } from "../../../../services/user-service"
import { FIXED_UNLOAD_INTERVAL_ON_DEMAND } from "../../../../utils/constants"

export enum CollectionPointStatus {
  planned = "planned",
  added = "added",
  removed = "removed",
}

export interface ITourExportPreviewCollectionPoint extends TourExportPreviewCollectionPoint {
  status: CollectionPointStatus
}

interface ITourExportPreviewTableProps {
  loading: boolean
  collectionPoints: ITourExportPreviewCollectionPoint[]
  materials: TourExportPreviewMaterial[]
  date: Date
  showDiff: boolean
}

export const TourExportPreviewTable: FunctionComponent<ITourExportPreviewTableProps> = (props) => {
  const { t } = useTranslation()
  const { loading, collectionPoints, materials, date, showDiff } = props
  const theme = useTheme()

  const getFilllevelInfo = (collectionPoint: TourExportPreviewCollectionPoint, materialId: string) => {
    const material = collectionPoint.materials.find((material) => material.material.id === materialId)
    if (lodash.isNil(material) || lodash.isNil(material.filllevel_percentage) || lodash.isNil(material.filllevel)) {
      return "-"
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {Math.round(material.filllevel_percentage)}%
        </Grid>
        <Grid item xs={12}>
          (
          {material.filllevel.toLocaleString(UserService.getLanguage(), {
            maximumFractionDigits: 2,
          })}
          kg)
        </Grid>
      </Grid>
    )
  }

  const getAddress = (collectionPoint: TourExportPreviewCollectionPoint) => {
    if (!collectionPoint.postal && !collectionPoint.town && !collectionPoint.street) {
      return "-"
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {collectionPoint.street || "-"}
        </Grid>
        <Grid item xs={12}>
          {collectionPoint.postal || collectionPoint.town || collectionPoint.place
            ? `${collectionPoint.postal || ""} ${collectionPoint.place || collectionPoint.town || ""}`
            : "-"}
        </Grid>

        {collectionPoint.place && collectionPoint.town && collectionPoint.place !== collectionPoint.town && (
          <Grid item xs={12}>
            {collectionPoint.town}
          </Grid>
        )}
      </Grid>
    )
  }

  const getFixedUnloadIntervalInfo = (collectionPoint: TourExportPreviewCollectionPoint) => {
    if (lodash.isNil(collectionPoint.fixed_unload_interval) || collectionPoint.fixed_unload_interval === 0) {
      return "-"
    }
    return (
      <Grid container spacing={1}>
        <Grid item xs={12}>
          {collectionPoint.fixed_unload_interval === FIXED_UNLOAD_INTERVAL_ON_DEMAND
            ? t("collection_point_administration.data.unload_interval_on_demand")
            : t("tour_generation.preview_table.fixed_unload_interval_weeks", {
                count: Number(collectionPoint.fixed_unload_interval),
              })}
        </Grid>
        <Grid item xs={12}>
          {t("tour_generation.preview_table.last_emptying", {
            date: moment(collectionPoint.last_emptying).format(t("date_format")),
          })}
        </Grid>
      </Grid>
    )
  }

  const getColorFromStatus = useCallback((status: CollectionPointStatus) => {
    switch (status) {
      case CollectionPointStatus.added:
        return "#4B963E"
      case CollectionPointStatus.removed:
        return "#E86161"
      case CollectionPointStatus.planned:
        return "#606060"
    }
  }, [])

  return (
    <Table stickyHeader>
      <TableHead>
        <TableRow>
          {showDiff && (
            <TableCell align="center" sx={{ width: 40 }} rowSpan={2}>
              {t("tour_generation.preview_table.collection_point_status")}
            </TableCell>
          )}
          <TableCell align="center" sx={{ width: 40 }} rowSpan={2}>
            {t("tour_generation.preview_table.id")}
          </TableCell>
          <TableCell align="center" rowSpan={2}>
            {t("tour_generation.preview_table.description")}
          </TableCell>
          <TableCell align="center" rowSpan={2}>
            {t("tour_generation.preview_table.address")}
          </TableCell>
          <TableCell align="center" rowSpan={2}>
            {t("tour_generation.preview_table.fixed_unload_interval")}
          </TableCell>
          {(loading || materials.length > 0) && (
            <TableCell
              align="center"
              sx={{ borderRight: "none", pb: 0, width: lodash.max([materials.length * 120, 240]) }}
              rowSpan={1}
              colSpan={materials.length}
            >
              <Grid container direction="column" sx={{ marginLeft: -1, width: `calc(100% + ${theme.spacing(2)})` }}>
                <Grid
                  item
                  sx={{
                    pt: 2,
                    pb: 2,
                    width: `${100 / materials.length}%`,
                    borderRight: "1px solid #bfc2c3",
                    "&:last-child": { borderRight: "none" },
                  }}
                >
                  {t("tour_generation.preview_table.materials", {
                    date: moment(date).format(t("date_format")),
                  })}
                </Grid>
                <Grid container direction="row" justifyContent="space-around">
                  {materials.map((material) => (
                    <Grid
                      item
                      key={`material${material.material.id}`}
                      sx={{
                        pt: 2,
                        pb: 2,
                        width: `${100 / materials.length}%`,
                        borderRight: "1px solid #bfc2c3",
                        "&:last-child": { borderRight: "none" },
                      }}
                    >
                      {material.material.name}
                    </Grid>
                  ))}
                </Grid>
              </Grid>
            </TableCell>
          )}
        </TableRow>
      </TableHead>
      <TableBody>
        {loading && (
          <TableRow>
            {showDiff && (
              <TableCell align="center" sx={{ borderBottom: "none" }}>
                <Skeleton height={50} />
              </TableCell>
            )}
            <TableCell align="center" sx={{ width: 40, borderBottom: "none" }}>
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center" sx={{ borderBottom: "none" }}>
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center" sx={{ borderBottom: "none" }}>
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center" sx={{ borderBottom: "none" }}>
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center" sx={{ borderRight: "none", borderBottom: "none" }}>
              <Skeleton height={50} />
            </TableCell>
          </TableRow>
        )}
        {!loading &&
          collectionPoints.map((cp, index) => (
            <TableRow key={cp.id}>
              {showDiff && (
                <TableCell align="center">
                  <Badge
                    sx={{
                      p: 0.5,
                      margin: "auto",
                      color: getColorFromStatus(cp.status),
                      borderColor: getColorFromStatus(cp.status),
                    }}
                  >
                    {t(`tour_generation.preview_table.status.${cp.status}`)}
                  </Badge>
                </TableCell>
              )}
              <TableCell
                align="center"
                sx={{ width: 40, borderBottom: index === collectionPoints.length - 1 ? "none" : undefined }}
              >
                {cp.id}
              </TableCell>
              <TableCell
                align="center"
                sx={{ borderBottom: index === collectionPoints.length - 1 ? "none" : undefined }}
              >
                {cp.description}
              </TableCell>
              <TableCell
                align="center"
                sx={{ borderBottom: index === collectionPoints.length - 1 ? "none" : undefined }}
              >
                {getAddress(cp)}
              </TableCell>
              <TableCell
                align="center"
                sx={{ borderBottom: index === collectionPoints.length - 1 ? "none" : undefined }}
              >
                {getFixedUnloadIntervalInfo(cp)}
              </TableCell>
              {materials.map((material) => (
                <TableCell
                  align="center"
                  key={`material${material.material.id}`}
                  sx={{ width: 120, borderBottom: index === collectionPoints.length - 1 ? "none" : undefined }}
                >
                  {getFilllevelInfo(cp, material.material.id)}
                </TableCell>
              ))}
            </TableRow>
          ))}
      </TableBody>
    </Table>
  )
}
