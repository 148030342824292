import React, { useState, useContext, Fragment, useEffect } from "react"
import { Outlet, useNavigate } from "react-router-dom"
import { Sidebar } from "../../partials/sidebar/sidebar"
import { Grid } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { DRAWER_WIDTH_OPEN, getTheme } from "../../../styles/theme"
import { UserService } from "../../../services/user-service"
import { UserContext } from "../../../context/user-context"
import { isPortalUpdateAvailable, getRemotePortalVersion, updatePortal } from "../../../utils/portal-update"
import { Button, Snackbar } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Trans } from "react-i18next"
import { DownloadManager } from "../../partials/download-manager/download-manager"
import { DownloadManagerContextProvider } from "../../partials/download-manager/download-manager-context"
import { UnsavedDataPrompt } from "../../partials/unsaved-data-prompt/unsaved-data-prompt"

const CHECK_PORTAL_VERSION_POLL_INTERVAL = 60 * 1000

interface IPortalProps {}

export const Portal: React.FunctionComponent<IPortalProps> = () => {
  const { t } = useTranslation()

  const [isUpdateAvailable, setIsUpdateAvailable] = useState<boolean>(false)
  const { user, isUserLoading } = useContext(UserContext)
  const navigate = useNavigate()

  useEffect(() => {
    const interval = setInterval(async () => {
      setIsUpdateAvailable(await isPortalUpdateAvailable())
    }, CHECK_PORTAL_VERSION_POLL_INTERVAL)
    return () => clearInterval(interval)
  }, [])

  if (!user && UserService.isUserLoggedIn()) {
    return <Fragment />
  }

  if (!user && !isUserLoading) {
    navigate("/login")
    return <Fragment />
  }

  const userTheme = getTheme(user!.theme)

  return (
    <>
      <UnsavedDataPrompt />
      <ThemeProvider theme={userTheme}>
        <DownloadManagerContextProvider>
          <DownloadManager />
          <Grid container direction={"column"}>
            <Grid item>
              <Sidebar />
            </Grid>
            <Grid item sx={{ p: 2, mt: 3, ml: `${DRAWER_WIDTH_OPEN}px`, width: `calc(100% - ${DRAWER_WIDTH_OPEN}px)` }}>
              <Outlet />
            </Grid>
          </Grid>
          <Snackbar
            anchorOrigin={{
              vertical: "bottom",
              horizontal: "right",
            }}
            color="primary"
            open={isUpdateAvailable}
            message={<Trans i18nKey="portal.update_available" values={{ version: getRemotePortalVersion() }} />}
            action={
              <React.Fragment>
                <Button color="primary" size="small" onClick={() => updatePortal()}>
                  <strong>{t("portal.update")}</strong>
                </Button>
              </React.Fragment>
            }
          />
        </DownloadManagerContextProvider>
      </ThemeProvider>
    </>
  )
}
