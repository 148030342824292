import "react-app-polyfill/ie11"
import "react-app-polyfill/stable"
import React from "react"
import { createRoot } from "react-dom/client"
import { App } from "./components/pages/app/app"
import { I18nextProvider } from "react-i18next"
import "moment/locale/de"

import i18n, { I18N_LOCAL_STORAGE } from "./utils/i18n"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import "react-image-lightbox/style.css"
import "./index.css"
import { LoadScript } from "@react-google-maps/api"
import { getBaseGraphqlApiUrl, getBaseSubscriptionApiUrl } from "./utils/getApiUrl"
import { AssociationFilterProvider } from "./context/AssociationFilterContext"
import { DistrictFilterProvider } from "./context/DistrictFilterContext"
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider"
import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment"
import { UnsavedDataProvider } from "./context/unsaved-data-context"
import i18next from "i18next"
import { PATH } from "./router/router"
import store from "store"
import { STORE_KEYS, UserService } from "./services/user-service"
import { CollectionPointsFilterProvider } from "./context/CollectionPointsFilterContext"
import { UserContextProvider } from "./context/user-context"
import { getMainDefinition } from "@apollo/client/utilities"
import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  defaultDataIdFromObject,
  DefaultOptions,
  HttpLink,
  InMemoryCache,
  split,
} from "@apollo/client"
import { GraphQLWsLink } from "@apollo/client/link/subscriptions"
import { onError } from "@apollo/client/link/error"
import { setContext } from "@apollo/client/link/context"
import gql from "graphql-tag"
import createUploadLink from "apollo-upload-client/createUploadLink.mjs"
import { createClient } from "graphql-ws"
import { config } from "./utils/config"

const BASE_API_URI = getBaseGraphqlApiUrl()
const GOOGLEMAPSAPIKEY = config.REACT_APP_GOOGLE_MAPS_API_KEY

const defaultOptions = {
  watchQuery: { fetchPolicy: "no-cache", errorPolicy: "all" },
  query: { fetchPolicy: "no-cache", errorPolicy: "all" },
  mutate: { errorPolicy: "all" },
} as DefaultOptions

const appCache = new InMemoryCache({
  dataIdFromObject(responseObject) {
    switch (responseObject.__typename) {
      case "StopInfo":
        return `StopInfo:${(responseObject as any).stop_id}`
      default:
        return defaultDataIdFromObject(responseObject)
    }
  },
})

appCache.writeQuery({
  query: gql`
    query {
      authStatus @client {
        __typename
        status
      }
    }
  `,
  data: {
    authStatus: {
      __typename: "authStatus",
      status: store.get(STORE_KEYS.TOKEN) ? "loggedIn" : "loggedOut",
    },
  },
})

const httpLink = new HttpLink({
  uri: BASE_API_URI,
  credentials: "same-origin",
})

const logout = () => {
  UserService.logout(client)
  appCache.writeQuery({
    query: gql`
      query {
        authStatus @client {
          __typename
          status
        }
      }
    `,
    data: {
      authStatus: {
        __typename: "authStatus",
        status: "loggedIn",
      },
    },
  })
  window.location.href = `${window.location.origin}${PATH.LOGIN.route}`
}

const subscriptionClient = createClient({
  url: getBaseSubscriptionApiUrl(),
  connectionParams: () => ({
    authorization: `Bearer ${store.get(STORE_KEYS.TOKEN)}`,
    language: String(store.get(I18N_LOCAL_STORAGE)).split("_")[0],
  }),
  lazy: true,
})

const wsLink = new GraphQLWsLink(subscriptionClient)

const uploadLink = createUploadLink({ uri: getBaseGraphqlApiUrl() })

const splitLink = split(
  ({ query }) => {
    const definition = getMainDefinition(query)
    return (
      definition.variableDefinitions?.some(
        (varDefinition) => (varDefinition.type as any)?.type?.name?.value === "Upload",
      ) || false
    )
  },
  uploadLink,
  split(
    ({ query }) => {
      const definition = getMainDefinition(query)
      return definition.kind === "OperationDefinition" && definition.operation === "subscription"
    },
    wsLink,
    httpLink,
  ),
)

const authLink = setContext((_, { headers }) => {
  const token = store.get(STORE_KEYS.TOKEN)
  return {
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
      language: String(store.get(I18N_LOCAL_STORAGE)).split("_")[0],
    },
  }
})

const client = new ApolloClient({
  link: ApolloLink.from([
    onError((errors) => {
      console.log(errors)
      const { graphQLErrors, networkError } = errors
      if (graphQLErrors)
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (!window.location.pathname.includes("login")) {
            if (
              message === "Context creation failed: Authentication token is invalid, please log in" ||
              message === "Access denied! You need to be authorized to perform this action!"
            ) {
              logout()
            } else if (message === "Access denied! You don't have permission for this action!") {
              toast.error(i18next.t("forbidden"))
            } else if (message === "Argument Validation Error") {
              toast.error(i18next.t("general_api_error"))
            }

            console.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
          }
        })
      if (networkError) console.log(`[Network error]: ${JSON.stringify(networkError)}`)
    }),
    authLink,
    splitLink,
  ]),
  cache: appCache,
  defaultOptions,
})

UserService.switchLanguage()

const container = document.getElementById("root")
const root = createRoot(container as HTMLElement)

root.render(
  <LoadScript
    id="script-loader"
    googleMapsApiKey={GOOGLEMAPSAPIKEY}
    libraries={["places", "visualization"]}
    region="at"
  >
    <I18nextProvider i18n={i18n}>
      <ApolloProvider client={client}>
        <LocalizationProvider dateAdapter={AdapterMoment}>
          <UnsavedDataProvider>
            <UserContextProvider>
              <AssociationFilterProvider>
                <DistrictFilterProvider>
                  <CollectionPointsFilterProvider>
                    <ToastContainer />
                    <App />
                  </CollectionPointsFilterProvider>
                </DistrictFilterProvider>
              </AssociationFilterProvider>
            </UserContextProvider>
          </UnsavedDataProvider>
        </LocalizationProvider>
      </ApolloProvider>
    </I18nextProvider>
  </LoadScript>,
)
