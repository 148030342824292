import React, { FunctionComponent, Fragment, useState, useContext } from "react"
import { Grid, CircularProgress, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Badge } from "../../../components/pages/user-management/partials/badge"
import { Person } from "@mui/icons-material"
import lodash from "lodash"
import { UserContext } from "../../../context/user-context"
import { getLocalPortalVersion } from "../../../utils/portal-update"

interface IUserInfoProps {}

export const UserInfo: FunctionComponent<IUserInfoProps> = (props) => {
  const { t } = useTranslation()
  const [isFullnameOverflowing, setIsFullnameOverflowing] = useState(false)
  const [isEmailOverflowing, setIsEmailOverflowing] = useState(false)
  const { user, isUserLoading } = useContext(UserContext)

  const fullname = [user?.first_name, user?.last_name].filter((namePart) => !!namePart).join(" ")

  return (
    <Grid
      container
      direction="column"
      sx={{ backgroundColor: "secondary.dark", color: "secondary.contrastText", padding: 2 }}
      justifyContent="center"
      alignContent="center"
    >
      {isUserLoading && (
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignContent="center"
          spacing={2}
          sx={{ backgroundColor: "secondary.dark", color: "secondary.contrastText", padding: 2 }}
        >
          <Grid item sx={{ color: "white" }}>
            <Person />
          </Grid>
          <Grid item>
            <CircularProgress size={24} />
          </Grid>
        </Grid>
      )}
      {!isUserLoading && (
        <Fragment>
          <Tooltip
            PopperProps={{
              style: {
                visibility: isFullnameOverflowing ? "visible" : "hidden",
              },
            }}
            placement="top"
            title={fullname}
          >
            <Grid
              item
              sx={{
                textAlign: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                fontWeight: 600,
              }}
              ref={(ref) => setIsFullnameOverflowing(lodash.isNil(ref) ? false : ref.offsetWidth < ref.scrollWidth)}
            >
              {fullname}
            </Grid>
          </Tooltip>
          <Tooltip
            PopperProps={{
              style: {
                visibility: isEmailOverflowing ? "visible" : "hidden",
              },
            }}
            placement="top"
            title={user?.email || ""}
          >
            <Grid
              item
              sx={{
                textAlign: "center",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
                width: "100%",
                fontSize: 14,
              }}
              ref={(ref) => setIsEmailOverflowing(lodash.isNil(ref) ? false : ref.offsetWidth < ref.scrollWidth)}
            >
              {user?.email || ""}
            </Grid>
          </Tooltip>
          {user?.role && (
            <Grid item sx={{ display: "flex", mt: 1, justifyContent: "center" }}>
              <Badge color="primary" fontSize={12}>
                {t(`user_role.${user?.role}`)}
              </Badge>
            </Grid>
          )}
          <Grid
            item
            sx={{
              textAlign: "center",
              whiteSpace: "nowrap",
              overflow: "hidden",
              textOverflow: "ellipsis",
              width: "100%",
              fontSize: 12,
              mt: 0.5,
              mb: -0.5,
              fontWeight: "bold",
            }}
          >
            {getLocalPortalVersion() ?? t("portal.sidebar.no_version")}
          </Grid>
        </Fragment>
      )}
    </Grid>
  )
}
