import React, { FunctionComponent } from "react"
import { Card, Grid, TextField } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Text } from "../../../partials/wrapper/text"
import { CustomMap, IMarker, ICenterOption } from "../../../partials/maps/custom-map"
import { toGeoFloat } from "../../../../utils/map"

interface IDisposalMerchantLocationDataProps {
  latitude: string
  longitude: string
  updateLatitude: (lat: string) => void
  updateLongitude: (long: string) => void
}

export const DisposalMerchantLocationData: FunctionComponent<IDisposalMerchantLocationDataProps> = (props) => {
  const { latitude, longitude, updateLatitude, updateLongitude } = props
  const { t } = useTranslation()
  return (
    <Card sx={{ p: 2 }}>
      <Grid container direction="row" spacing={2}>
        <Grid item>
          <Text bold>{t("disposal_merchant_overview.data.location")}</Text>
        </Grid>
        <Grid item container direction="row" spacing={2}>
          <Grid item xs={6}>
            <TextField
              size="small"
              label={t("departure_point.data.lat")}
              fullWidth
              variant="outlined"
              value={latitude}
              onChange={(ev) => {
                updateLatitude(ev.target.value)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label={t("departure_point.data.long")}
              fullWidth
              variant="outlined"
              value={longitude}
              onChange={(ev) => {
                updateLongitude(ev.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12} sx={{ width: "100%" }}>
            <CustomMap
              disableZoom
              disableInfoWindow
              center={
                {
                  lat: toGeoFloat(latitude),
                  lng: toGeoFloat(longitude),
                } as ICenterOption
              }
              onMapClicked={(event) => {
                if (!event?.latLng) {
                  return
                }
                updateLatitude(`${event.latLng.lat()}`)
                updateLongitude(`${event.latLng.lng()}`)
              }}
              markers={[
                {
                  lat: toGeoFloat(latitude),
                  lng: toGeoFloat(longitude),
                } as IMarker,
              ]}
            />
          </Grid>
        </Grid>
      </Grid>
    </Card>
  )
}
