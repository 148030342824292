import React, { FunctionComponent, useState, useCallback, useContext } from "react"
import { Card, Grid, IconButton } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Text } from "../../../../partials/wrapper/text"
import { Delete, Edit } from "@mui/icons-material"
import { DisposalTrailerLocationManagementDialog } from "./disposal-trailer-location-management-dialog"
import { DeleteDialog } from "../../../../partials/layout/dialog/delete-dialog"
import { useMutation } from "@apollo/client"
import { DisposalTrailerLocationContext } from "./context/disposal-trailer-location-context"
import { toast } from "react-toastify"
import { DisposalTrailerLocation } from "../../../../../api/graphql/queries/get-disposal-trailer-locations-by-collection-partner"
import {
  DeleteDisposalTrailerLocationResult,
  DeleteDisposalTrailerLocationVariables,
  DELETE_DISPOSAL_TRAILER_LOCATION_MUTATION,
} from "../../../../../api/graphql/mutations/delete-disposal-trailer-location"

interface IDisposalTrailerLocationListItemProps {
  disposalTrailerLocation: DisposalTrailerLocation
}

export const DisposalTrailerLocationListItem: FunctionComponent<IDisposalTrailerLocationListItemProps> = (props) => {
  const { t } = useTranslation()
  const [managementDialogOpen, setManagementDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const { disposalTrailerLocation } = props
  const { refetchDisposalTrailerLocations } = useContext(DisposalTrailerLocationContext)

  const toggleManagementDialog = useCallback(
    () => setManagementDialogOpen(!managementDialogOpen),
    [setManagementDialogOpen, managementDialogOpen],
  )

  const toggleDeleteDialog = useCallback(
    () => setDeleteDialogOpen(!deleteDialogOpen),
    [setDeleteDialogOpen, deleteDialogOpen],
  )

  const [removeDisposalTrailerLocationMutation, { loading: removeLoading }] = useMutation<
    DeleteDisposalTrailerLocationResult,
    DeleteDisposalTrailerLocationVariables
  >(DELETE_DISPOSAL_TRAILER_LOCATION_MUTATION, {
    onCompleted: (data) => {
      switch (data?.deleteDisposalTrailerLocation.__typename) {
        case "DisposalTrailerLocationDeleteSuccess":
          toast.success(t("partner_overview.disposal_trailer_location.success.deleted"))
          break
        case "RequiredError":
          toast.error(t("partner_overview.disposal_trailer_location.errors.required_by_tour_generation"))
          break
        case "DisposalTrailerLocationDeleteError":
          toast.error(t("api_errors.delete", { entity: t("trailer_location") }))
          break
        default:
          if (data?.deleteDisposalTrailerLocation) {
            toast.error(t("generic_error"))
          }
      }
      toggleDeleteDialog()
      refetchDisposalTrailerLocations()
    },
  })

  const onRemove = useCallback(() => {
    removeDisposalTrailerLocationMutation({
      variables: { disposal_trailer_location_id: disposalTrailerLocation.id },
    })
  }, [removeDisposalTrailerLocationMutation, disposalTrailerLocation])

  return (
    <>
      <DisposalTrailerLocationManagementDialog
        disposalTrailerLocation={disposalTrailerLocation}
        open={managementDialogOpen}
        onClose={toggleManagementDialog}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={toggleDeleteDialog}
        content={t("partner_overview.disposal_trailer_location.delete_dialog_text_disposal_trailer_location")}
        onDelete={onRemove}
      />
      <Card sx={{ p: 1 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Text>{disposalTrailerLocation.name || "no name"}</Text>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <IconButton color="primary" size="small" onClick={toggleManagementDialog} disabled={removeLoading}>
                  <Edit />
                </IconButton>
              </Grid>
              <Grid item>
                <IconButton color="primary" size="small" onClick={toggleDeleteDialog} disabled={removeLoading}>
                  <Delete />
                </IconButton>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}
