import React, { FunctionComponent, Fragment } from "react"
import { Card, Grid } from "@mui/material"

import { Text } from "../../../partials/wrapper/text"
import { useSelectedPartner } from "../../../../context/selected-partner-context"
import { CollectionPartner } from "../../../../api/graphql/queries/get-collection-partners"

interface IPartnerItemProps {
  item: CollectionPartner
}

export const PartnerItem: FunctionComponent<IPartnerItemProps> = (props) => {
  const { selectedPartnerId, setSelectedPartnerId } = useSelectedPartner()
  const { item } = props

  const onClick = () => {
    setSelectedPartnerId(props.item.id)
  }

  const isSelected = () => {
    if (!selectedPartnerId) return false
    return selectedPartnerId === props.item.id
  }

  const postalTownString = item.postal && item.town ? `${item.postal} ${item.town}` : item.postal || item.town

  return (
    <Fragment>
      <Card
        sx={{
          height: 90,
          margin: 1,
          width: 270,
          "&:hover": {
            cursor: "pointer",
          },
          boxShadow: isSelected() ? "0 0 4px 0 rgba(75, 151, 62, 0.5)" : undefined,
        }}
        onClick={onClick}
      >
        <Grid sx={{ height: "100%", p: 1 }} container direction="row">
          <Grid item xs={12}>
            <Text bold noWrap>
              {item.name}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text fontSize={15} noWrap>
              {item.street || "-"}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text fontSize={15} noWrap>
              {postalTownString || "-"}
            </Text>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
