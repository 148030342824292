import React, { FunctionComponent, useState, useCallback, useContext, useMemo, PropsWithChildren } from "react"
import { Card, Grid, IconButton, Tooltip } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DeparturePoint } from "../../../../../api/graphql/queries/get-departure-points"
import { Text } from "../../../../partials/wrapper/text"
import { Delete, Edit } from "@mui/icons-material"
import { DeparturePointManagementDialog } from "./departure-point-management-dialog"
import { DeleteDialog } from "../../../../partials/layout/dialog/delete-dialog"
import {
  RemoveDeparturePointResult,
  RemoveDeparturePointVariables,
  REMOVE_DEPARTURE_POINT_MUTATION,
} from "../../../../../api/graphql/mutations/remove-departure-point"
import { useMutation } from "@apollo/client"
import { DeparturePointContext } from "./context/departure-point-context"
import { toast } from "react-toastify"

interface IDeparturePointListItemProps {
  departurePoint: DeparturePoint
}

export const DeparturePointListItem: FunctionComponent<IDeparturePointListItemProps> = (props) => {
  const { t } = useTranslation()
  const [managementDialogOpen, setManagementDialogOpen] = useState<boolean>(false)
  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const { departurePoint } = props
  const { refetchDeparturePoints } = useContext(DeparturePointContext)

  const toggleManagementDialog = useCallback(
    () => setManagementDialogOpen(!managementDialogOpen),
    [setManagementDialogOpen, managementDialogOpen],
  )

  const toggleDeleteDialog = useCallback(
    () => setDeleteDialogOpen(!deleteDialogOpen),
    [setDeleteDialogOpen, deleteDialogOpen],
  )

  const [removeDeparturePointMutation, { loading: removeLoading }] = useMutation<
    RemoveDeparturePointResult,
    RemoveDeparturePointVariables
  >(REMOVE_DEPARTURE_POINT_MUTATION, {
    onCompleted: refetchDeparturePoints,
    onError: () => toast.error(t("generic_error")),
  })

  const onRemove = useCallback(() => {
    removeDeparturePointMutation({
      variables: { id: `${departurePoint.id}` },
    })
  }, [removeDeparturePointMutation, departurePoint])

  const isDeletable = departurePoint.vehicleCount <= 0 && departurePoint.tourCount <= 0
  const DeleteTooltipWrapper = useMemo(() => {
    if (isDeletable) return ({ children }: PropsWithChildren<{}>) => <>{children}</>

    return (props: any) => (
      <Tooltip title={t("partner_overview.departure_point.not_deletable")}>{props.children}</Tooltip>
    )
  }, [isDeletable, t])

  return (
    <>
      <DeparturePointManagementDialog
        departurePoint={departurePoint}
        open={managementDialogOpen}
        onClose={toggleManagementDialog}
      />
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={toggleDeleteDialog}
        content={t("partner_overview.departure_point.delete_dialog_text_departure_point")}
        onDelete={onRemove}
      />
      <Card sx={{ p: 1 }}>
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Text>{departurePoint.name || "no name"}</Text>
          </Grid>
          <Grid item>
            <Grid container>
              <Grid item>
                <IconButton color="primary" size="small" onClick={toggleManagementDialog} disabled={removeLoading}>
                  <Edit />
                </IconButton>
              </Grid>
              <DeleteTooltipWrapper>
                <Grid item>
                  <IconButton
                    color="primary"
                    size="small"
                    onClick={toggleDeleteDialog}
                    disabled={removeLoading || !isDeletable}
                  >
                    <Delete />
                  </IconButton>
                </Grid>
              </DeleteTooltipWrapper>
            </Grid>
          </Grid>
        </Grid>
      </Card>
    </>
  )
}
