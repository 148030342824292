import React, { FunctionComponent, useState } from "react"
import { Grid, Card as MaterialCard, Button, TextField, useTheme, Box } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import {
  GETCOLLECTIONPARTNERS_QUERY,
  CollectionPartnersResult,
} from "../../../../api/graphql/queries/get-collection-partners"
import { PartnerItem } from "./partner-item"

import { useSelectedPartner } from "../../../../context/selected-partner-context"
import { useRefetch } from "../../../../context/refetch-context"
import { ContentContainer } from "../../../partials/layout/content/content-container"

interface IPartnerSelectorProps {}

export const PartnerSelector: FunctionComponent<IPartnerSelectorProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [partnerName, setPartnerName] = useState<string>("")
  const { setSelectedPartnerId } = useSelectedPartner()

  const { needToRefetch, setNeedToRefetch } = useRefetch()

  const { data } = useQuery<CollectionPartnersResult>(GETCOLLECTIONPARTNERS_QUERY, {
    fetchPolicy: needToRefetch ? "network-only" : "cache-first",
    onCompleted: () => setNeedToRefetch(false),
  })

  const onFilterApplyClicked = () => {}

  const onFilterRemoveClicked = () => {}

  const onAddNewPartnerClicked = () => {
    setSelectedPartnerId(undefined)
  }

  return (
    <ContentContainer sx={{ height: `calc(100% - ${theme.spacing(2)})`, display: "flex", flexDirection: "column" }}>
      <Grid container sx={{ p: 0, mb: 2, flex: "0 0 auto" }} direction="column">
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            sx={{
              borderWidth: 0.5,
              borderRadius: 1,
              borderColor: "primary.main",
              margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
            }}
            onClick={onAddNewPartnerClicked}
            fullWidth
            color="inherit"
          >
            <AddIcon color="primary" />
          </Button>
        </Grid>
        <Grid item>
          <MaterialCard sx={{ p: 2, width: 270 }}>
            <Grid container direction={"column"} spacing={1}>
              <Grid item>
                <TextField
                  size="small"
                  fullWidth
                  id="outlined-search"
                  label={t("partner_overview.filter.partner_name")}
                  type="search"
                  variant="outlined"
                  placeholder="Saubermacher"
                  value={partnerName}
                  onChange={(ev) => {
                    setPartnerName(ev.target.value)
                  }}
                />
              </Grid>

              <Grid item>
                <Button fullWidth variant="contained" type="button" color="primary" onClick={onFilterApplyClicked}>
                  {t("collection_points.filter.apply_filter")}
                </Button>
              </Grid>
              <Grid item>
                <Button fullWidth variant="contained" type="button" onClick={onFilterRemoveClicked} color="inherit">
                  {t("collection_points.filter.remove_filter")}
                </Button>
              </Grid>
            </Grid>
          </MaterialCard>
        </Grid>
      </Grid>
      {data && (
        <Box sx={{ p: 0, flex: "1 1 auto", overflowY: "auto" }}>
          {data.getCollectionPartners.map((partner, index) => (
            <PartnerItem key={index} item={partner} />
          ))}
        </Box>
      )}
    </ContentContainer>
  )
}
