import React, { FunctionComponent, useContext, useState, useEffect } from "react"
import {
  Button,
  Grid,
  Card,
  TextField,
  CircularProgress,
  MenuItem,
  Select,
  InputLabel,
  FormControl,
  useTheme,
  Box,
} from "@mui/material"
import { Add, Clear } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { ContentContainer } from "../../../partials/layout/content/content-container"
import { UserManagementContext } from "../context/user-management-context"
import { UserListItem } from "./user-list-item"
import { User } from "../../../../api/graphql/queries/get-users"
import { UserRole } from "../../../../api/graphql/graphql-global-types"
import { executeCallbackOnEnter } from "../../../../utils/form"
import { UserService } from "../../../../services/user-service"

const EMPTY_VALUE = "----"

interface IUserManagementSelectorProps {}

export const UserManagementSelector: FunctionComponent<IUserManagementSelectorProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const [filterFirstName, setFilterFirstName] = useState<string>("")
  const [filterLastName, setFilterLastName] = useState<string>("")
  const [filterRole, setFilterRole] = useState<UserRole | string>(EMPTY_VALUE)
  const [filteredUsers, setFilteredUsers] = useState<User[]>([])

  const { users, usersLoading, toggleCreateMode, isInCreateMode } = useContext(UserManagementContext)

  const filterUsers = () => {
    const newFilteredUsers = users.filter(
      (user) =>
        (filterFirstName ? user.first_name.toLowerCase().includes(filterFirstName.toLowerCase()) : true) &&
        (filterLastName ? user.last_name.toLowerCase().includes(filterLastName.toLowerCase()) : true) &&
        (filterRole !== EMPTY_VALUE ? user.role === filterRole : true),
    )
    setFilteredUsers(newFilteredUsers)
  }

  const resetFilter = () => {
    setFilterLastName("")
    setFilterFirstName("")
    setFilterRole(EMPTY_VALUE)
    setFilteredUsers(users)
  }

  //Set Filtered Users after initial Load
  useEffect(() => {
    setFilteredUsers(users)
  }, [setFilteredUsers, users])

  return (
    <ContentContainer sx={{ height: `calc(100% - ${theme.spacing(2)})`, display: "flex", flexDirection: "column" }}>
      <Grid sx={{ p: 0, marginBottom: 2, flex: "0 0 auto" }}>
        <Grid item>
          <Button
            type="button"
            variant="outlined"
            sx={{
              borderWidth: 0.5,
              borderRadius: 1,
              borderColor: "primary.main",
              margin: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
            }}
            onClick={toggleCreateMode}
            fullWidth
            color="inherit"
          >
            {!isInCreateMode && <Add color="primary" />}
            {isInCreateMode && <Clear color="primary" />}
          </Button>
        </Grid>
        <Grid item>
          <Card sx={{ p: 2, width: 270 }}>
            <Grid container direction={"column"} spacing={1}>
              <Grid item>
                <TextField
                  size="small"
                  fullWidth
                  type="search"
                  variant="outlined"
                  placeholder={t("user_management.filter.first_name")}
                  value={filterFirstName}
                  onChange={(event) => setFilterFirstName(event.target.value)}
                  onKeyDown={executeCallbackOnEnter(filterUsers)}
                />
              </Grid>
              <Grid item>
                <TextField
                  size="small"
                  fullWidth
                  type="search"
                  variant="outlined"
                  placeholder={t("user_management.filter.last_name")}
                  value={filterLastName}
                  onChange={(event) => setFilterLastName(event.target.value)}
                  onKeyDown={executeCallbackOnEnter(filterUsers)}
                />
              </Grid>
              <Grid item>
                <FormControl fullWidth variant="outlined" size="small">
                  <InputLabel id="role-label">{t("user_management.filter.role")}</InputLabel>
                  <Select
                    variant="outlined"
                    labelId="role-label"
                    value={filterRole}
                    displayEmpty
                    fullWidth
                    required
                    onChange={(event) => setFilterRole(event.target.value as UserRole)}
                    label={t("user_management.filter.role")}
                  >
                    <MenuItem key={EMPTY_VALUE} value={EMPTY_VALUE}>
                      {t(`user_role.all`)}
                    </MenuItem>
                    {Object.values(UserService.getAvailableRoles()).map((userRole) => (
                      <MenuItem key={userRole} value={userRole}>
                        {t(`user_role.${userRole}`)}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item>
                <Button variant="contained" type="button" fullWidth onClick={resetFilter} color="inherit">
                  {t("user_management.filter.remove_filter")}
                </Button>
              </Grid>
              <Grid item>
                <Button variant="contained" type="button" color="primary" fullWidth onClick={filterUsers}>
                  {t("user_management.filter.apply_filter")}
                </Button>
              </Grid>
            </Grid>
          </Card>
        </Grid>
      </Grid>

      <Box sx={{ flex: "1 1 auto", overflowY: "auto" }}>
        {!usersLoading &&
          filteredUsers.map((user) => (
            <Grid item key={user.email}>
              <UserListItem user={user} />
            </Grid>
          ))}

        {usersLoading && (
          <Grid item container justifyContent="center">
            <CircularProgress />
          </Grid>
        )}
      </Box>
    </ContentContainer>
  )
}
