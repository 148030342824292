import React, { FC, useState } from "react"
import { Button, Card, Chip, Grid, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Text } from "../../../partials/wrapper/text"
import { LocationType } from "../../user-management/helper/get-options-for-role"
import { sortASC } from "../../../../utils/sort"
import { ILocationCollectionItem, LocationAssignmentDialog } from "./location-assignment-dialog"
import * as _ from "lodash"

interface IDisposalMerchantDistrictSelectorProps {
  selectedDistricts: ILocationCollectionItem[]
  setSelectedDistricts: (locations: ILocationCollectionItem[]) => void
  selectedTowns: ILocationCollectionItem[]
  setSelectedTowns: (locations: ILocationCollectionItem[]) => void
}

export const DisposalMerchantDistrictSelector: FC<IDisposalMerchantDistrictSelectorProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { selectedDistricts, setSelectedDistricts, selectedTowns, setSelectedTowns } = props
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const onSave = (items: ILocationCollectionItem[]) => {
    const sorted = items.sort((a, b) => sortASC(a.name, b.name))
    setSelectedDistricts(sorted)
    // On district change, keep the selected towns from the remaining districts
    const newSelectedTowns = selectedTowns.filter((t) => {
      if (t.district_id) {
        return _.some(sorted, { id: t.district_id.toString() })
      }
      return false
    })
    setSelectedTowns(newSelectedTowns)
  }

  return (
    <>
      <Card
        sx={{
          p: `0 ${theme.spacing(2)} ${theme.spacing(2)}`,
          height: `calc(100% - ${theme.spacing(2)})`,
          width: "100%",
        }}
      >
        <Grid container direction="column" spacing={2} justifyContent="center">
          <Grid container item alignItems="center" justifyContent="space-between">
            <Grid item>
              <Text bold>{t(`location_type.${LocationType.DISTRICT}`)}</Text>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setIsDialogOpen(true)}
                    sx={{ m: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0` }}
                  >
                    {t("user_management.data.assign", {
                      collection: t(`location_type.${LocationType.DISTRICT}`),
                    })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {selectedDistricts.length > 0 && (
            <Grid item container spacing={1}>
              {selectedDistricts.map((item) => (
                <Grid item key={item.id}>
                  <Chip label={item.name} />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Card>

      <LocationAssignmentDialog
        type={LocationType.DISTRICT}
        collection={selectedDistricts}
        open={isDialogOpen}
        onSave={onSave}
        onClose={() => setIsDialogOpen(false)}
        selectedDistricts={selectedDistricts}
      />
    </>
  )
}
