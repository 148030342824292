import React, { FunctionComponent, useRef, MutableRefObject } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { Card, Grid, Skeleton, useTheme } from "@mui/material"
import { UserService } from "../../../../services/user-service"
import moment from "moment"
import {
  GET_AVERAGE_DAYS_OVERFILLED_FOR_REGION_QUERY,
  AverageDaysOverfilledForRegionResult,
  AverageDaysOverfilledForRegionVariables,
} from "../../../../api/graphql/queries/get-days-overfilled-for-region"
import {
  ResponsiveContainer,
  CartesianGrid,
  XAxis,
  YAxis,
  ReferenceLine,
  Label,
  BarChart,
  Bar,
  Tooltip,
} from "recharts"
import { Text } from "../../../partials/wrapper/text"
import lodash from "lodash"
import { useSelectedRegion } from "../../../../hooks/use-selected-region"

interface IDaysOverfilledChartProps {}

export const DaysOverfilledChart: FunctionComponent<IDaysOverfilledChartProps> = (props) => {
  const { t } = useTranslation()
  const { variables, skip } = useSelectedRegion()
  const ref = useRef(null)
  const theme = useTheme()

  const { data, loading } = useQuery<AverageDaysOverfilledForRegionResult, AverageDaysOverfilledForRegionVariables>(
    GET_AVERAGE_DAYS_OVERFILLED_FOR_REGION_QUERY,
    {
      variables: {
        id: String(variables.id),
        type: variables.type,
        startDate: moment().startOf("day").subtract(12, "months"),
        endDate: moment().startOf("day").subtract(1, "month"),
      },
      skip,
    },
  )

  const getData = () => {
    if (!data) {
      return []
    }

    return data.getAverageDaysOverfilledForRegion.averageDaysPerMonth.map((entry) => ({
      date: moment()
        .year(entry.year)
        .month(entry.month - 1)
        .locale(UserService.getLanguage())
        .format("MMM"),
      value: Math.round(entry.days * 10) / 10,
    }))
  }

  // defined width for chart in order to have similar bar sizes
  const chartWidth = 140 + getData().length * 55
  const cardWidth = (ref as MutableRefObject<any>).current?.clientWidth - parseFloat(theme.spacing(4))
  const useCardWidth = chartWidth > cardWidth || loading

  return (
    <Grid item ref={ref} xs={12} md={6}>
      <Card sx={{ p: 1 }} ref={ref}>
        <Text variant="h6" bold>
          {t("overfilled_days_chart.days_overfilled")}
        </Text>
        {loading ? (
          <Skeleton width="100%" height={300} style={{ transform: "scale(1)" }} />
        ) : (
          <ResponsiveContainer width={lodash.max([useCardWidth ? cardWidth : chartWidth, 300])} height={300}>
            <BarChart
              data={getData()}
              margin={{
                left: 70,
                top: 70,
                right: 70,
                bottom: 70,
              }}
            >
              <CartesianGrid vertical={false} strokeDasharray="3 3" />"
              <XAxis dataKey="date" />
              <YAxis
                tickFormatter={(value) =>
                  Number(value).toLocaleString(UserService.getLanguage(), {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  })
                }
              >
                <Label position="top" offset={16}>
                  {t("overfilled_days_chart.average_days_label")}
                </Label>
              </YAxis>
              <Bar dataKey="value" fill={theme.palette.primary.light} />
              <Tooltip
                formatter={(value) => [
                  `${Number(value).toLocaleString(UserService.getLanguage(), {
                    minimumFractionDigits: 1,
                    maximumFractionDigits: 1,
                  })} ${t("overfilled_days_chart.days")}`,
                ]}
                cursor={false}
              />
              <ReferenceLine
                y={data?.getAverageDaysOverfilledForRegion.average}
                strokeWidth={2}
                label={
                  <Label position="insideBottomRight">
                    {t("overfilled_days_chart.average_days", {
                      days: data?.getAverageDaysOverfilledForRegion.average.toLocaleString(UserService.getLanguage(), {
                        minimumFractionDigits: 1,
                        maximumFractionDigits: 1,
                      }),
                    })}
                  </Label>
                }
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Card>
    </Grid>
  )
}
