import React, { FunctionComponent, Fragment } from "react"
import { Dialog, DialogContent, DialogActions, Button, DialogContentText } from "@mui/material"
import { useTranslation } from "react-i18next"

interface IPartnerDeleteDialogProps {
  open: boolean
  onClose: () => void
  onDelete: () => void
}

export const PartnerDeleteDialog: FunctionComponent<IPartnerDeleteDialogProps> = (props) => {
  const { t } = useTranslation()
  return (
    <Fragment>
      <Dialog
        open={props.open}
        onClose={props.onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {t("partner_overview.data.delete_prompt")}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={props.onClose} autoFocus color="inherit">
            {t("no")}
          </Button>
          <Button onClick={props.onDelete} color="error">
            {t("partner_overview.data.agree_on_delete")}
          </Button>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
