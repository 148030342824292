import React, { FunctionComponent, ReactNode } from "react"
import { Card, CardContent, Grid, useTheme } from "@mui/material"
import { Text } from "../../wrapper/text"
import { CARD_TILE_WIDTH, CARD_TILE_HEIGHT } from "../../../../utils/constants"

interface ICardTileProps {
  headingText: ReactNode
  contentText: ReactNode
  secondary?: boolean
  descriptionText?: ReactNode
}

export const CardTile: FunctionComponent<ICardTileProps> = (props) => {
  const theme = useTheme()
  const { contentText, headingText, descriptionText, secondary } = props

  return (
    <Card
      sx={{
        width: CARD_TILE_WIDTH,
        height: CARD_TILE_HEIGHT,
        cursor: "pointer",
        textAlign: "center",
        backgroundColor: secondary ? "#fff" : "primary.main",
        color: secondary ? "primary.main" : "primary.contrastText",
      }}
    >
      <CardContent sx={{ height: `calc(100% - ${theme.spacing(4)})`, padding: theme.spacing(2) }}>
        <Grid
          container
          alignItems="stretch"
          justifyContent="center"
          direction="column"
          sx={{
            height: "100%",
          }}
        >
          <Grid item sx={{ height: "33%", position: "relative" }}>
            <Text
              variant={"subtitle2"}
              sx={{
                width: "100%",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                msTransform: "translate(-50%, -50%)",
                lineHeight: 1,
                color: secondary ? "#000" : undefined,
              }}
            >
              {headingText}
            </Text>
          </Grid>
          <Grid item sx={{ height: "33%", position: "relative" }}>
            <Text
              variant={"h6"}
              sx={{
                width: "100%",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                msTransform: "translate(-50%, -50%)",
                lineHeight: 1,
              }}
            >
              {contentText}
            </Text>
          </Grid>
          <Grid item sx={{ height: "33%", position: "relative" }}>
            <Text
              variant={"subtitle2"}
              sx={{
                width: "100%",
                position: "absolute",
                left: "50%",
                top: "50%",
                transform: "translate(-50%, -50%)",
                msTransform: "translate(-50%, -50%)",
                lineHeight: 1,
                color: secondary ? "#000" : undefined,
              }}
            >
              {descriptionText}
            </Text>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  )
}
