import React, { FunctionComponent } from "react"
import { Grid, Button, Link, useTheme, Box } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import {
  GET_VEHICLES_WITH_PARTNER_ID,
  VehiclesResult,
  VehiclesVariables,
} from "../../../../api/graphql/queries/get-vehicles-with-partner-id"
import { useSelectedPartner } from "../../../../context/selected-partner-context"
import { useSelectedVehicle } from "../../../../context/selected-vehicle-context"
import { useRefetch } from "../../../../context/refetch-context"
import { ContentContainer } from "../../../partials/layout/content/content-container"
import { PATH } from "../../../../router/router"
import { KeyboardArrowLeft } from "@mui/icons-material"
import { Text } from "../../../partials/wrapper/text"
import { VehicleItem } from "./vehicle-item"
import lodash from "lodash"
import { useNavigate } from "react-router-dom"

interface IVehicleSelectorProps {}

export const VehicleSelector: FunctionComponent<IVehicleSelectorProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { selectedPartnerId } = useSelectedPartner()
  const { setSelectedVehicleId } = useSelectedVehicle()

  const { needToRefetch, setNeedToRefetch } = useRefetch()
  const navigate = useNavigate()

  const { data } = useQuery<VehiclesResult, VehiclesVariables>(GET_VEHICLES_WITH_PARTNER_ID, {
    fetchPolicy: needToRefetch ? "network-only" : "cache-first",
    onCompleted: () => setNeedToRefetch(false),
    skip: !selectedPartnerId,
    variables: {
      partner_id: String(selectedPartnerId),
    },
  })

  const onAddNewVehicleClicked = () => {
    setSelectedVehicleId(undefined)
  }

  return (
    <ContentContainer sx={{ height: `calc(100% - ${theme.spacing(2)})`, display: "flex", flexDirection: "column" }}>
      <Grid sx={{ p: 0, m: `0 ${theme.spacing(1)} ${theme.spacing(2)}`, flex: "0 0 auto" }}>
        <Grid container item spacing={1} alignItems="center" justifyContent="space-between">
          <Grid item>
            <Link onClick={() => navigate(PATH.PARTNER_OVERVIEW.route)} sx={{ cursor: "pointer" }}>
              <Grid container>
                <Grid item>
                  <KeyboardArrowLeft />
                </Grid>
                <Grid item>
                  <Text>{t("vehicle_overview.back_to_partner_overview")}</Text>
                </Grid>
              </Grid>
            </Link>
          </Grid>
          <Grid item>
            <Button
              type="button"
              variant="outlined"
              sx={{
                borderWidth: 0.5,
                borderRadius: 1,
                borderColor: "primary.main",
                m: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
              }}
              onClick={onAddNewVehicleClicked}
              fullWidth
              color="inherit"
            >
              <AddIcon color="primary" />
            </Button>
          </Grid>
        </Grid>
      </Grid>
      {data && (
        <Box sx={{ width: 270, p: 0, overflowY: "auto" }}>
          {lodash.sortBy(data.getVehiclesWithPartnerId, [(vehicle) => vehicle.licence_plate]).map((vehicle, index) => (
            <VehicleItem key={index} item={vehicle} />
          ))}
        </Box>
      )}
    </ContentContainer>
  )
}
