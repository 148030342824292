import React, { FC, useCallback, useMemo } from "react"
import { Grid, TextField, FormControlLabel, Checkbox, Tooltip, Autocomplete } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import {
  GET_TOWNS_WITH_REGION_ID_QUERY,
  GetTownsWithRegionIDResult,
  GetTownsWithRegionIDVariables,
  Town,
} from "../../../../api/graphql/queries/get-towns-with-region-id"
import lodash from "lodash"
import { UserService } from "../../../../services/user-service"
import { ExtrapolationWeightingsInput } from "../../../../api/graphql/graphql-global-types"
import { ExtrapolationWeightings } from "./collection-point-administration-extrapolation-weightings"
import { FIXED_UNLOAD_INTERVAL_ON_DEMAND } from "../../../../utils/constants"
import { useCollectionPointAdministrationContext } from "../collection-point-administration-context"
import { Info } from "@mui/icons-material"
import { useSelectedRegion } from "../../../../hooks/use-selected-region"

export const CollectionPointAdministrationFormData: FC = (props) => {
  const { t } = useTranslation()
  const { formData, updateFormData } = useCollectionPointAdministrationContext()
  const { variables, skip } = useSelectedRegion()

  const {
    data: townsData,
    loading: townsLoading,
    error: townsError,
  } = useQuery<GetTownsWithRegionIDResult, GetTownsWithRegionIDVariables>(GET_TOWNS_WITH_REGION_ID_QUERY, {
    variables: {
      id: Number(variables.id),
      type: variables.type,
      counties: [],
    },
    skip,
  })

  const updateExtrapolationWeightings = useCallback(
    (day: keyof ExtrapolationWeightingsInput, newValue: number | undefined) => {
      const merged = {
        ...formData.extrapolationWeightings,
        [day]: newValue,
      }
      // validate
      const values = Object.values(merged)
      const allNegative = values.every((value) => lodash.isNumber(value) && value <= 0)
      const allValid = values.every((value) => lodash.isNumber(value) && value >= 0)
      const valid = allValid && !allNegative

      updateFormData({ extrapolationWeightings: merged, areExtrapolationWeightingsValid: valid })
    },
    [formData, updateFormData],
  )

  const town = useMemo(
    () => townsData?.getTownsWithRegionID.find((town) => Number(town.id) === formData.town_id) || null,
    [formData, townsData],
  )

  const unloadIntervalOptions = useMemo(() => {
    const options = Array(20)
      .fill(null)
      .map((_, i) => i + 1)
    options.unshift(FIXED_UNLOAD_INTERVAL_ON_DEMAND)
    return options
  }, [])

  return (
    <Grid container direction="column" spacing={1}>
      <Grid item>
        <TextField
          size="small"
          label={t("collection_point_administration.data.description")}
          type="search"
          fullWidth
          required
          variant="outlined"
          value={formData.description}
          onChange={(ev) => updateFormData({ description: ev.target.value })}
        />
      </Grid>
      <Grid item>
        <TextField
          size="small"
          label={t("collection_point_administration.data.location_number")}
          type="search"
          fullWidth
          variant="outlined"
          value={formData.locationNumber}
          onChange={(ev) => updateFormData({ locationNumber: ev.target.value })}
        />
      </Grid>
      {!UserService.isIndustry() && (
        <>
          <Grid item>
            <TextField
              size="small"
              label={t("collection_point_administration.data.cadastral_name")}
              type="search"
              fullWidth
              variant="outlined"
              value={formData.cadastralName}
              onChange={(ev) => updateFormData({ cadastralName: ev.target.value })}
            />
          </Grid>
          <Grid item>
            <TextField
              size="small"
              label={t("collection_point_administration.data.cadastral_number")}
              error={!formData.isCadastralNumberValid}
              type="search"
              fullWidth
              variant="outlined"
              value={formData.cadastralNumber}
              onChange={(ev) =>
                updateFormData({
                  cadastralNumber: ev.target.value,
                  isCadastralNumberValid: ev.target.value && !/^\d+$/.test(ev.target.value) ? false : true,
                })
              }
            />
          </Grid>
        </>
      )}
      {townsData && !townsLoading && !townsError && (
        <Grid item>
          <Autocomplete<Town>
            id="towns"
            options={lodash.uniqBy(townsData!.getTownsWithRegionID, "name")}
            getOptionLabel={(option) => (option.town_code ? `${option.name} - ${option.town_code}` : option.name)}
            onChange={(_event: React.ChangeEvent<{}>, town: Town | null) => {
              updateFormData({ town_id: town ? Number(town.id) : null })
            }}
            value={town || null}
            renderInput={(params) => (
              <TextField
                {...params}
                size="small"
                required
                fullWidth
                label={t("collection_point_administration.data.town")}
                variant="outlined"
              />
            )}
          />
        </Grid>
      )}
      <Grid item>
        <Autocomplete<number>
          id="fixed_unload_interval"
          options={unloadIntervalOptions}
          getOptionLabel={(option) =>
            option === FIXED_UNLOAD_INTERVAL_ON_DEMAND
              ? t("collection_point_administration.data.unload_interval_on_demand")
              : t("collection_point_administration.unit.week", { count: option })
          }
          onChange={(_event: React.ChangeEvent<{}>, value: number | null) =>
            updateFormData({
              fixedUnloadInterval: value,
              fixedInterval: value === null || value === -1 ? false : formData.fixedInterval,
            })
          }
          value={formData.fixedUnloadInterval}
          renderInput={(params) => (
            <TextField
              {...params}
              size="small"
              fullWidth
              label={t("collection_point_administration.data.fixed_unload_interval")}
              variant="outlined"
            />
          )}
        />
      </Grid>
      <Grid item>
        <Grid container alignItems="center" spacing={0}>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  checked={formData.fixedInterval}
                  onChange={() => updateFormData({ fixedInterval: !formData.fixedInterval })}
                  color="primary"
                  disabled={formData.fixedUnloadInterval === null || formData.fixedUnloadInterval === -1}
                />
              }
              label={t("collection_point_administration.data.fixed_interval")}
            />
          </Grid>
          <Grid item sx={{ display: "flex" }}>
            <Tooltip title={t("collection_point_administration.data.fixed_interval_info") as string}>
              <Info color="primary" />
            </Tooltip>
          </Grid>
        </Grid>
      </Grid>
      <Grid container item>
        <ExtrapolationWeightings
          weightings={formData.extrapolationWeightings}
          onChange={updateExtrapolationWeightings}
        />
      </Grid>
    </Grid>
  )
}
