import React, { FunctionComponent, Fragment, useState } from "react"
import { Grid, Button, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Add } from "@mui/icons-material"
import { CustomLightBox } from "../../../partials/layout/lightbox/custom-lightbox"
import { getImagesFromCollectionPointImages, getImageTypesFromCollectionPointImages } from "../../../../utils/lightbox"
import {
  GETINITIALIMAGESWITHCOLLECTIONPOINTIDTYPE,
  ImageResult,
} from "../../../../api/graphql/queries/get-initial-images-with-collection-point-id"
import { useQuery } from "@apollo/client"
import lodash from "lodash"
import { CollectionPointAdministrationAddImageDialog } from "./collection-point-administration-add-image-dialog"
import { useCollectionPointAdministrationContext } from "../collection-point-administration-context"

interface ICollectionPointAdministrationImagesProps {}

export const CollectionPointAdministrationImages: FunctionComponent<ICollectionPointAdministrationImagesProps> = (
  props,
) => {
  const { t } = useTranslation()
  const [showImages, setShowImages] = useState<boolean>(false)
  const [addImageDialogOpen, setAddImageDialogOpen] = useState<boolean>(false)
  const [needToRefetchImages, setNeedToRefetchImages] = useState<boolean>(false)

  const { selectedCollectionPoint } = useCollectionPointAdministrationContext()

  const { data: imagesData } = useQuery<ImageResult>(GETINITIALIMAGESWITHCOLLECTIONPOINTIDTYPE, {
    variables: {
      collection_point_id: Number(selectedCollectionPoint ? selectedCollectionPoint.id : null),
    },
    skip: lodash.isNil(selectedCollectionPoint),
    fetchPolicy: needToRefetchImages ? "network-only" : "cache-first",
    onCompleted: () => setNeedToRefetchImages(false),
  })

  return (
    <Fragment>
      {/* Image-LightBox */}
      {showImages && selectedCollectionPoint && (
        <CustomLightBox
          images={getImagesFromCollectionPointImages(imagesData!.getInitialImagesWithCollectionPointId)}
          imageTypes={getImageTypesFromCollectionPointImages(imagesData!.getInitialImagesWithCollectionPointId)}
          onClose={() => setShowImages(false)}
        />
      )}
      {/* Dialog for uploading images */}
      {addImageDialogOpen && (
        <CollectionPointAdministrationAddImageDialog
          open={addImageDialogOpen}
          onClose={() => setAddImageDialogOpen(false)}
          onImagesAdded={() => {
            setAddImageDialogOpen(false)
            setNeedToRefetchImages(true)
          }}
        />
      )}
      <Grid container justifyContent="space-between" direction="row" alignItems="center">
        <Grid item xs>
          <Typography>{t("collection_point_administration.images.add")}</Typography>
        </Grid>
        <Grid item container direction="row" justifyContent="flex-end" spacing={1} sx={{ flex: 1 }}>
          <Grid item>
            <Button
              type="button"
              variant="outlined"
              onClick={() => setAddImageDialogOpen(true)}
              fullWidth
              color="primary"
            >
              <Add color="primary" />
            </Button>
          </Grid>
          {selectedCollectionPoint && imagesData && imagesData.getInitialImagesWithCollectionPointId.length > 0 && (
            <Grid item>
              <Button type="button" variant="contained" onClick={() => setShowImages(true)} fullWidth color="primary">
                {t("collection_point_administration.images.view")}
              </Button>
            </Grid>
          )}
        </Grid>
      </Grid>
    </Fragment>
  )
}
