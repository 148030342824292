import React, { FunctionComponent, PropsWithChildren } from "react"
import { Box, SxProps, Theme } from "@mui/material"

interface IBadgeProps extends PropsWithChildren<Record<never, never>> {
  color?: "primary" | "secondary"
  fontSize?: number
  sx?: SxProps<Theme>
}

export const Badge: FunctionComponent<IBadgeProps> = (props) => {
  const { children, color = "primary", fontSize, sx } = props
  return (
    <Box
      sx={{
        borderWidth: 2,
        borderStyle: "solid",
        borderColor: `${color}.main`,
        color: `${color}.main`,
        fontSize,
        borderRadius: 1,
        p: "1px",
        fontWeight: "bold",
        width: "fit-content",
        textTransform: "uppercase",
        ...sx,
      }}
    >
      {children}
    </Box>
  )
}
