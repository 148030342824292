import React, { FunctionComponent, useCallback } from "react"
import { Grid, Typography, Card, CircularProgress } from "@mui/material"
import { AdminstrationCollectionPoint } from "../../../../api/graphql/queries/get-administration-collection-points-with-region-id"
import lodash from "lodash"
import { useTranslation } from "react-i18next"
import { useCollectionPointAdministrationContext } from "../collection-point-administration-context"
import { PATH, replaceUriParameter } from "../../../../router/router"
import { useNavigate } from "react-router-dom"

interface ICollectionPointAdministrationSelectorProps {}

export const CollectionPointAdministrationSelector: FunctionComponent<
  ICollectionPointAdministrationSelectorProps
> = () => {
  const { t } = useTranslation()
  const {
    selectedCollectionPoint,
    setSelectedCollectionPoint,
    collectionPointsLoading,
    collectionPointsError,
    collectionPointsData,
  } = useCollectionPointAdministrationContext()

  const navigate = useNavigate()

  const onCardClicked = useCallback(
    (collectionPoint: AdminstrationCollectionPoint) => {
      setSelectedCollectionPoint(collectionPoint)
      const url = replaceUriParameter(
        `${PATH.COLLECTIONPOINTS_ADMINISTRATION_PRE_SELECT.route}`,
        "collectionPointId",
        collectionPoint.id,
      )
      navigate(url)
    },
    [setSelectedCollectionPoint, navigate],
  )

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
          {t("collection_point_administration.collection_points")}
        </Typography>
      </Grid>
      {!collectionPointsLoading && !collectionPointsError && !lodash.isNil(collectionPointsData) && (
        <Grid item sx={{ width: 260, maxHeight: 500, overflowY: "auto" }} xs={12}>
          <Grid container>
            {collectionPointsData!.getCollectionPointsWithRegionID.entries.map((collectionPoint) => (
              <Grid item xs={12} key={collectionPoint.id}>
                <Card
                  sx={{
                    p: 2,
                    m: 1,
                    "&:hover": {
                      cursor: "pointer",
                    },
                    boxShadow:
                      collectionPoint.id === selectedCollectionPoint?.id
                        ? "0 0 4px 0 rgba(75, 151, 62, 0.5)"
                        : undefined,
                  }}
                  onClick={() => onCardClicked(collectionPoint)}
                >
                  <Grid container direction="column" spacing={1}>
                    <Grid
                      item
                      sx={{
                        width: "100%",
                        whiteSpace: "nowrap",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        fontWeight: "bold",
                      }}
                    >
                      {collectionPoint.description || "-"}
                    </Grid>
                    <Grid item container direction="column">
                      <Grid
                        item
                        sx={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {collectionPoint.street || "-"}
                      </Grid>
                      <Grid
                        item
                        sx={{ width: "100%", whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis" }}
                      >
                        {`${collectionPoint.postal || ""} ${collectionPoint.place || collectionPoint.town.name}`}
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))}
          </Grid>
        </Grid>
      )}
      {collectionPointsLoading && (
        <Grid item sx={{ pt: 2, width: "100%", display: "flex", justifyContent: "center" }}>
          <Grid container justifyContent="center">
            <CircularProgress />
          </Grid>
        </Grid>
      )}
    </Grid>
  )
}
