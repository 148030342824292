import React, { FunctionComponent } from "react"
import { useQuery } from "@apollo/client"
import { Grid, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DriverLocationResult, GET_DRIVER_LOCATIONS_QUERY } from "../../../../api/graphql/queries/get-driver-locations"
import { useRefetch } from "../../../../context/refetch-context"
import { DriverLocationData } from "./driver-location-data"
import { DriverLocationSelection } from "./driver-location-selection"

interface IDriverLocationAdministrationProps {}

export const DriverLocationAdministration: FunctionComponent<IDriverLocationAdministrationProps> = (props) => {
  const { t } = useTranslation()
  const { needToRefetch, setNeedToRefetch } = useRefetch()
  const { loading, data } = useQuery<DriverLocationResult>(GET_DRIVER_LOCATIONS_QUERY, {
    fetchPolicy: needToRefetch ? "network-only" : "cache-first",
    onCompleted: () => setNeedToRefetch(false),
  })

  return (
    <Grid container direction="column" spacing={2} sx={{ height: "calc(100vh - 56px)" }}>
      <Grid container item direction="row" spacing={2} sx={{ p: 2 }}>
        <Grid item xs>
          <DriverLocationData />
        </Grid>
      </Grid>
      <Grid item>
        <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
          {t("guidance_administration.driver_location.title")}
        </Typography>
      </Grid>
      <Grid item xs={12} sx={{ flex: "1", overflowY: "auto" }}>
        <DriverLocationSelection driverLocations={data?.driverLocations || []} loading={loading} />
      </Grid>
    </Grid>
  )
}
