import React, { FunctionComponent } from "react"
import { Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Menu } from "@mui/icons-material"
import { Text } from "../../../partials/wrapper/text"

interface IUserManagementEmptyStateProps {}

export const UserManagementEmptyState: FunctionComponent<IUserManagementEmptyStateProps> = (props) => {
  const { t } = useTranslation()

  return (
    <Grid item xs={12} sx={{ height: "100%", width: "100%" }}>
      <Grid
        spacing={2}
        container
        sx={{ height: "100%", width: "100%" }}
        justifyContent="center"
        alignItems="center"
        alignContent="center"
        direction="row"
      >
        <Grid item xs="auto">
          <Grid container>
            <Menu color="secondary" />
          </Grid>
        </Grid>
        <Grid item xs="auto">
          <Text color="secondary" align="center">
            {t("user_management.please_select_user")}
          </Text>
        </Grid>
      </Grid>
    </Grid>
  )
}
