import React, { FC, PropsWithChildren, useContext, useMemo } from "react"
import { HouseholdOverviewFilterContext } from "./household-overview-filter-context"
import {
  HOUSEHOLD_STATUSES_QUERY,
  HouseholdStatusesResult,
  HouseholdStatusesVariables,
  HouseholdWithStatus,
} from "../../../../api/graphql/queries/household-statuses"
import { useQuery } from "@apollo/client"
import { useSelectedRegion } from "../../../../hooks/use-selected-region"

interface IHouseholdOverviewStatusContext {
  loading: boolean
  households: HouseholdWithStatus[]
}

export const HouseholdOverviewStatusContext = React.createContext<IHouseholdOverviewStatusContext>({
  loading: false,
  households: [],
})

export const HouseholdOverviewStatusContextProvider: FC<PropsWithChildren<Record<never, never>>> = (props) => {
  const { filter } = useContext(HouseholdOverviewFilterContext)
  const { skip, variables } = useSelectedRegion()

  const { data, loading, called } = useQuery<HouseholdStatusesResult, HouseholdStatusesVariables>(
    HOUSEHOLD_STATUSES_QUERY,
    {
      variables: {
        countyIds: filter.selectedCounties.map((option) => option.id),
        materialIds: filter.selectedMaterials.map((option) => option.id),
        regionType: variables.type,
        regionId: variables.id,
        townIds: filter.selectedTowns.map((option) => option.id),
        address: filter.address ? filter.address : null,
        showWarnings: filter.showWarnings,
      },
      skip,
    },
  )

  const households: HouseholdWithStatus[] = useMemo(
    () => (data?.households.filter((entry) => entry.status.color) as HouseholdWithStatus[]) || [],
    [data],
  )

  return (
    <HouseholdOverviewStatusContext.Provider value={{ loading: loading || !called, households }}>
      {props.children}
    </HouseholdOverviewStatusContext.Provider>
  )
}
