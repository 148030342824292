import React, { Fragment, FunctionComponent, useContext, useEffect, useState } from "react"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  TablePagination,
  TableContainer,
  Tooltip,
  Grid,
  IconButton,
  Backdrop,
  CircularProgress,
  Chip,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  TOUR_GENERATIONS_QUERY,
  TourGeneration,
  TourGenerationsResult,
  TourGenerationsVariables,
} from "../../../../api/graphql/queries/tour-generations"
import {
  TOUR_EXPORT_PREVIEW_QUERY,
  TourExportPreviewResult,
  TourExportPreviewVariables,
} from "../../../../api/graphql/queries/tour-export-preview"
import { useMutation, useLazyQuery, useApolloClient } from "@apollo/client"
import moment from "moment"
import { Check, ScheduleSharp, ReportProblem, HighlightOff, Delete } from "@mui/icons-material"
import { useRefetch } from "../../../../context/refetch-context"
import lodash from "lodash"
import CachedIcon from "@mui/icons-material/Cached"
import ListAltTwoToneIcon from "@mui/icons-material/ListAltTwoTone"
import { REGENERATE_TOUR_MUTATION, RegenerateTourResult } from "../../../../api/graphql/mutations/regenerate-tour"
import {
  ABORT_TOUR_CALCULATION_MUTATION,
  AbortTourCalculationResult,
  AbortTourCalculationVariables,
} from "../../../../api/graphql/mutations/abort-tour-calculation"
import {
  DELETE_FINISHED_OR_CANCELLED_TOUR_GENERATION,
  DeleteTourGenerationResult,
  DeleteTourGenerationVariables,
} from "../../../../api/graphql/mutations/delete-tour-generation"
import {
  CANCEL_SCHEDULED_TOUR_GENERATION_MUTATION,
  CancelScheduledTourGeneration,
  CancelScheduledTourGenerationVariables,
} from "../../../../api/graphql/mutations/cancel_scheduled_tour_generation"
import { regenerateTourVariables } from "../../../../api/graphql/mutations/types/regenerateTour"
import { toast } from "react-toastify"
import { dayToNumber } from "../../../../utils/day"
import { Day, UserRole } from "../../../../api/graphql/graphql-global-types"
import { TourParametersDialog } from "./tour-parameters-dialog"
import { ConfirmTourGenerationDialog } from "./confirm-tour-generation-dialog"
import { convertCollectionPointsToPreviewEntries } from "../../../../utils/tourPreview"
import { UserService } from "../../../../services/user-service"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import { CustomIcon } from "../../../partials/layout/icon/icon"
import { TourGenerationContext } from "../../../../context/tour-generation-context"
import { TableBodySkeleton } from "../../../partials/layout/table/table-body-skeleton"
import { TourGenerationIcon } from "../../../partials/icons/tour-generation-icon"

interface ITourGenerationTableProps {}

interface PaginationVariables {
  districtId: string | undefined
  page: number
  pageSize: number
}

export const TourGenerationTable: FunctionComponent<ITourGenerationTableProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()

  const [pagination, setPagination] = useState<PaginationVariables>({ districtId: undefined, page: 0, pageSize: 10 })
  const { needToRefetch, setNeedToRefetch } = useRefetch()
  const [selectedTourParams, setSelectedTourParams] = useState<TourGeneration | null>(null)
  const [confirmTourGenerationDialogOpen, setConfirmTourGenerationDialogOpen] = useState<boolean>(false)
  const [confirmAbortCalculationDialogOpen, setConfirmAbortCalculationDialogOpen] = useState<boolean>(false)
  const [confirmCancelScheduledCalculationDialogOpen, setConfirmCancelScheduledCalculationDialogOpen] =
    useState<boolean>(false)
  const [confirmDeleteGenerationOpen, setConfirmDeleteGenerationOpen] = useState<boolean>(false)
  const [tourParametersDialogOpen, setTourParametersDialogOpen] = useState<boolean>(false)
  const { district, vehicleDaysInput } = useContext(TourGenerationContext)

  const { cache } = useApolloClient()

  const [getTourGenerations, { data: tourGenerationsData, loading: tourGenerationsLoading }] = useLazyQuery<
    TourGenerationsResult,
    TourGenerationsVariables
  >(TOUR_GENERATIONS_QUERY, {
    variables: pagination,
    fetchPolicy: needToRefetch ? "network-only" : "cache-first",
    onCompleted: () => onTourGenerationsQueryCompleted(),
  })

  const [regenerateTour, { loading: regenerateTourLoading }] = useMutation<
    RegenerateTourResult,
    regenerateTourVariables
  >(REGENERATE_TOUR_MUTATION)

  const [generatePreview, { data, loading }] = useLazyQuery<TourExportPreviewResult, TourExportPreviewVariables>(
    TOUR_EXPORT_PREVIEW_QUERY,
    {
      onCompleted: ({ tourExportPreview }) => {
        if (tourExportPreview.noRecycles) {
          toast.error(t("tour_generation.data.could_not_generate_no_recyclers"))
        }
      },
    },
  )

  const [abortCalculation] = useMutation<AbortTourCalculationResult, AbortTourCalculationVariables>(
    ABORT_TOUR_CALCULATION_MUTATION,
  )

  const [deleteFinishedOrCancelledTourGeneration] = useMutation<
    DeleteTourGenerationResult,
    DeleteTourGenerationVariables
  >(DELETE_FINISHED_OR_CANCELLED_TOUR_GENERATION)

  const [cancelScheduledCalculation] = useMutation<
    CancelScheduledTourGeneration,
    CancelScheduledTourGenerationVariables
  >(CANCEL_SCHEDULED_TOUR_GENERATION_MUTATION)

  useEffect(() => {
    if (district) {
      pagination.districtId = district.id
      getTourGenerations({ variables: pagination })
    }
  }, [district, getTourGenerations, pagination])

  useEffect(() => {
    if (needToRefetch && district) {
      pagination.districtId = district.id
      getTourGenerations({ variables: pagination })

      cache.evict({ id: "ROOT_QUERY", fieldName: "tourGenerations" })
    }
  }, [needToRefetch, district, pagination, getTourGenerations, cache])

  const onTourGenerationsQueryCompleted = () => {
    setNeedToRefetch(false)
  }

  const handleChangePage = (event: any, page: number) => {
    setPagination({ ...pagination, page })
  }

  const handleChangeRowsPerPage = (event: any) => {
    setPagination({ districtId: district?.id, page: 0, pageSize: parseInt(event.target.value, 10) })
  }

  const canRegenerateTour = (tour: TourGeneration): boolean => {
    const isInFuture = moment(tour.start_date).isAfter(moment())
    const isCurrentlyCalculating = !tour.is_connected_to_tour && !tour.message
    return isInFuture && !tour.hasChild && !isCurrentlyCalculating
  }

  const canAbort = (tour: TourGeneration): boolean => {
    if (!tour.max_termination_time) {
      return false
    }
    const isOpen = !tour.is_connected_to_tour && !tour.message

    return (
      isOpen && moment().diff(moment(tour.schedule_date || tour.inserted_at), "seconds") < tour.max_termination_time
    )
  }

  const isScheduled = (tour: TourGeneration): boolean => {
    if (!tour.schedule_date) {
      return false
    }

    return moment(tour.schedule_date).diff(moment(), "seconds") > 0
  }

  const onClickRegenerate = async (tour: TourGeneration) => {
    setSelectedTourParams(tour)
    setConfirmTourGenerationDialogOpen(true)
    generatePreview({
      variables: {
        district_id: tour.district.id,
        start_date: tour.start_date,
        threshold: tour.threshold,
        material_ids: tour.materials.map((material) => material.id),
        week_count: tour.week_count,
        reference_date: tour.reference_date,
        threshold_min: Number(tour.threshold_min),
        version: tour.version,
        town_ids: tour.towns ? tour.towns.map((town) => town.id.toString()) : [],
        tour_without_containers: tour.without_containers,
        vehicles: vehicleDaysInput,
      },
    })
  }

  const onClickAbort = async (tour: TourGeneration) => {
    setSelectedTourParams(tour)
    setConfirmAbortCalculationDialogOpen(true)
  }

  const onAbortCalculation = () => {
    setConfirmAbortCalculationDialogOpen(false)
    abortCalculation({
      variables: {
        tour_parameters_id: selectedTourParams!.id,
      },
    })
      .then((result) => {
        if (result && !(result as any).errors && result.data && result.data.abortTourCalculation.error !== "true") {
          toast.info(t("tour_generation.abort_calculation.abort_successfully"))
        } else {
          toast.error(t("tour_generation.abort_calculation.could_not_abort"))
        }
        setNeedToRefetch(true)
        setSelectedTourParams(null)
      })
      .catch((e) => {
        toast.error(t("tour_generation.abort_calculation.could_not_abort"))
        setNeedToRefetch(true)
        setSelectedTourParams(null)
      })
  }

  const onClickCancelScheduled = async (tour: TourGeneration) => {
    setSelectedTourParams(tour)
    setConfirmCancelScheduledCalculationDialogOpen(true)
  }

  const onCancelScheduledCalculation = () => {
    setConfirmCancelScheduledCalculationDialogOpen(false)
    cancelScheduledCalculation({
      variables: {
        tour_id: selectedTourParams!.id,
      },
    })
      .then((result) => {
        if (
          result &&
          !(result as any).errors &&
          result.data &&
          result.data.cancelScheduledTourGeneration.error !== "true"
        ) {
          toast.info(t("tour_generation.cancel_scheduled_calculation.cancelled_successfully"))
        } else {
          toast.error(t("tour_generation.cancel_scheduled_calculation.could_not_cancel"))
        }
        setNeedToRefetch(true)
        setSelectedTourParams(null)
      })
      .catch((e) => {
        toast.error(t("tour_generation.cancel_scheduled_calculation.could_not_cancel"))
        setNeedToRefetch(true)
        setSelectedTourParams(null)
      })
  }

  const onRegenerateTour = () => {
    setConfirmTourGenerationDialogOpen(false)
    regenerateTour({
      variables: {
        tourUuid: selectedTourParams!.id,
        preview_entries: convertCollectionPointsToPreviewEntries(data?.tourExportPreview.collectionPoints || []),
      },
    })
      .then((result) => {
        if (result && !(result as any).errors && result.data && result.data.error !== "true") {
          toast.info(t("tour_generation.data.tour_generation_successfully"))
        } else {
          toast.error(t("tour_generation.data.could_not_generate"))
        }
        setNeedToRefetch(true)
        setSelectedTourParams(null)
      })
      .catch((e) => {
        toast.error(t("tour_generation.data.could_not_generate"))
        setNeedToRefetch(true)
        setSelectedTourParams(null)
      })
  }

  const tourGenerations = [] as number[]

  const canDeleteTourGeneration = (tourGeneration: TourGeneration) => {
    return (
      UserService.isInUserGroup([UserRole.SUPER_ADMIN]) &&
      (tourGeneration.is_connected_to_tour || tourGeneration.is_generation_aborted)
    )
  }

  const onClickDeleteTourGeneration = async (tour: TourGeneration) => {
    setSelectedTourParams(tour)
    setConfirmDeleteGenerationOpen(true)
  }

  const onDeleteTourGeneration = () => {
    setConfirmDeleteGenerationOpen(false)
    deleteFinishedOrCancelledTourGeneration({
      variables: {
        tour_parameters_id: selectedTourParams!.id,
      },
    })
      .then((result) => {
        if (
          result &&
          !(result as any) &&
          result.data &&
          result.data.deleteFinishedOrCancelledTourGeneration.error !== "true"
        ) {
          toast.info(t("tour_generation.delete_generation.delete_generation_successful"))
        } else {
          toast.error(t("tour_generation.delete_generation.could_not_delete"))
        }
        setNeedToRefetch(true)
        setSelectedTourParams(null)
      })
      .catch((e) => {
        toast.error(t("tour_generation.delete_generation.could_not_abort"))
        setNeedToRefetch(true)
        setSelectedTourParams(null)
      })
  }

  return (
    <Fragment>
      <ConfirmTourGenerationDialog
        open={confirmTourGenerationDialogOpen}
        onClose={() => setConfirmTourGenerationDialogOpen(false)}
        onConfirm={() => onRegenerateTour()}
        date={selectedTourParams?.start_date}
        loading={loading}
        collectionPoints={data?.tourExportPreview.collectionPoints || []}
        materials={data?.tourExportPreview.materials || []}
        districtName={district?.name || "-"}
        isGenerateBtnDisabled={data?.tourExportPreview.noRecycles === true}
      />
      <TourParametersDialog
        open={tourParametersDialogOpen}
        onClose={() => {
          setTourParametersDialogOpen(false)
          setSelectedTourParams(null)
        }}
        tourParametersId={selectedTourParams?.id}
      />
      <ConfirmDialog
        heading={t("tour_generation.abort_calculation.heading")}
        text={t("tour_generation.abort_calculation.text")}
        confirmText={t("tour_generation.abort_calculation.abort_calculation")}
        onConfirm={onAbortCalculation}
        open={confirmAbortCalculationDialogOpen}
        onClose={() => setConfirmAbortCalculationDialogOpen(false)}
        negativeText={t("tour_generation.abort_calculation.continue_calculation")}
      />
      <ConfirmDialog
        heading={t("tour_generation.cancel_scheduled_calculation.heading")}
        text={t("tour_generation.cancel_scheduled_calculation.text")}
        confirmText={t("tour_generation.cancel_scheduled_calculation.delete")}
        onConfirm={onCancelScheduledCalculation}
        open={confirmCancelScheduledCalculationDialogOpen}
        onClose={() => setConfirmCancelScheduledCalculationDialogOpen(false)}
        showNegativeButton={true}
      />
      <ConfirmDialog
        heading={t("tour_generation.delete_generation.heading")}
        text={t("tour_generation.delete_generation.text")}
        confirmText={t("tour_generation.delete_generation.delete_generation")}
        onConfirm={onDeleteTourGeneration}
        open={confirmDeleteGenerationOpen}
        onClose={() => setConfirmDeleteGenerationOpen(false)}
        showNegativeButton={true}
      />
      <Backdrop open={regenerateTourLoading} sx={{ zIndex: theme.zIndex.modal + 1 }}>
        <CircularProgress color="primary" />
      </Backdrop>

      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align="center">{t("tour_generation.table.version")}</TableCell>
              <TableCell align="center">{t("tour_generation.table.first_week")}</TableCell>
              <TableCell align="center">{t("tour_generation.table.district")}</TableCell>
              <TableCell align="center">{t("tour_generation.table.week_count")}</TableCell>
              <TableCell align="center">{t("tour_generation.table.reference_date")}</TableCell>
              <TableCell align="center">{t("tour_generation.table.threshold")}</TableCell>
              <TableCell align="center">{t("tour_generation.table.driving_days")}</TableCell>
              <TableCell align="center">{t("tour_generation.table.materials")}</TableCell>
              <TableCell align="center">{t("tour_generation.table.status")}</TableCell>
              <TableCell align="center">{t("tour_generation.table.details")}</TableCell>
              <TableCell align="center" sx={{ borderRight: "none" }}>
                {t("tour_generation.table.actions")}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {tourGenerationsData &&
              !tourGenerationsLoading &&
              tourGenerationsData.tourGenerations.entries.map((tourGeneration, idx) => (
                <TableRow
                  key={idx}
                  hover
                  onClick={() => null}
                  sx={{ borderBottom: idx === tourGenerations.length ? "none" : undefined }}
                >
                  <TableCell align="center">
                    <Grid container alignItems="center" justifyContent="center">
                      {tourGeneration.without_containers && (
                        <Grid item>
                          <TourGenerationIcon withoutContainers={tourGeneration.without_containers} />
                        </Grid>
                      )}
                      <Grid item>{tourGeneration.version}</Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="center">
                    <Grid container direction="column">
                      <Grid item>
                        {t("tour_generation.table.calendar_week_formatted", {
                          week: moment(tourGeneration.start_date).format("WW"),
                          year: moment(tourGeneration.start_date).format("YYYY"),
                        })}
                      </Grid>
                      <Grid item>
                        {`${moment(tourGeneration.start_date)
                          .locale(UserService.getLanguage())
                          .format(t("day_of_month_format"))} - ${moment(tourGeneration.start_date)
                          .endOf("week")
                          .locale(UserService.getLanguage())
                          .format(t("day_of_month_format"))}`}
                      </Grid>
                    </Grid>
                  </TableCell>
                  <TableCell align="center">{tourGeneration.district.name}</TableCell>
                  <TableCell align="center">{tourGeneration.week_count}</TableCell>
                  <TableCell align="center">
                    {tourGeneration.reference_date &&
                      moment.utc(tourGeneration.reference_date).format(t("date_format"))}
                  </TableCell>
                  <TableCell align="center">
                    {tourGeneration.without_containers ? "-" : tourGeneration.threshold}
                  </TableCell>
                  <TableCell align="center">
                    <Grid container direction="row" spacing={1} justifyContent="center">
                      {lodash
                        .uniq(tourGeneration.driving_days?.map((d) => d.day))
                        .sort((a, b) => (dayToNumber(a as Day) > dayToNumber(b as Day) ? 1 : -1))
                        .map((day) => (
                          <Grid item key={`${idx}_${day}`}>
                            <Chip label={t(`weekdays.${day}_short`)} color="primary" sx={{ width: 50 }} />
                          </Grid>
                        ))}
                    </Grid>
                  </TableCell>
                  <TableCell align="center">
                    <Grid container direction="row" spacing={1} justifyContent="center">
                      {tourGeneration.materials.map((material) => (
                        <Grid item key={`material_${idx}_${material.id}`}>
                          <Chip label={material.name} color="primary" />
                        </Grid>
                      ))}
                    </Grid>
                  </TableCell>
                  <TableCell align="center">
                    {tourGeneration.is_connected_to_tour && <Check color="primary" />}
                    {!tourGeneration.is_connected_to_tour &&
                      !tourGeneration.message &&
                      !isScheduled(tourGeneration) && (
                        <Tooltip
                          placement="top"
                          title={
                            t("tour_generation.table.generation_started", {
                              date: moment(tourGeneration.schedule_date || tourGeneration.inserted_at).format(
                                t("date_time_format"),
                              ),
                            }) as string
                          }
                        >
                          <ScheduleSharp sx={{ color: "#bfc2c3" }} />
                        </Tooltip>
                      )}
                    {isScheduled(tourGeneration) && (
                      <Tooltip
                        placement="top"
                        title={
                          t("tour_generation.table.generation_scheduled", {
                            date: moment(tourGeneration.schedule_date).format(t("date_time_format")),
                          }) as string
                        }
                      >
                        <div>
                          {/* div is used as a wrapper here as it seems that a svg within a tooltip is not working */}
                          <CustomIcon name="schedule" />
                        </div>
                      </Tooltip>
                    )}
                    {!tourGeneration.is_connected_to_tour && tourGeneration.message && (
                      <Tooltip placement="top" title={tourGeneration.message}>
                        <ReportProblem sx={{ color: "#ffcc00" }} />
                      </Tooltip>
                    )}
                  </TableCell>
                  <TableCell>
                    <Grid container justifyContent={"center"} alignItems={"center"}>
                      <Tooltip
                        title={t("tour_generation.actions.show_details") as string}
                        PopperProps={{
                          style: {
                            visibility: tourGeneration.hasPreview ? "visible" : "hidden",
                          },
                        }}
                      >
                        <span>
                          {/* span avoids tooltip error caused by disabled iconbutton */}
                          <IconButton
                            onClick={() => {
                              setSelectedTourParams(tourGeneration)
                              setTourParametersDialogOpen(true)
                            }}
                            color={"primary"}
                            disabled={!tourGeneration.hasPreview}
                          >
                            <ListAltTwoToneIcon />
                          </IconButton>
                        </span>
                      </Tooltip>
                    </Grid>
                  </TableCell>
                  <TableCell sx={{ borderRight: "none" }}>
                    <Grid container justifyContent={"center"} alignItems={"center"}>
                      <Grid item>
                        {canRegenerateTour(tourGeneration) && (
                          <Tooltip title={t("tour_generation.actions.regenerate") as string}>
                            <IconButton onClick={() => onClickRegenerate(tourGeneration)} color={"primary"}>
                              <CachedIcon />
                            </IconButton>
                          </Tooltip>
                        )}
                        {canAbort(tourGeneration) && !isScheduled(tourGeneration) && (
                          <Tooltip title={t("tour_generation.actions.abort_calculation") as string}>
                            <IconButton onClick={() => onClickAbort(tourGeneration)}>
                              <HighlightOff color="error" />
                            </IconButton>
                          </Tooltip>
                        )}

                        {isScheduled(tourGeneration) && (
                          <Tooltip title={t("tour_generation.actions.abort_scheduled_calculation") as string}>
                            <IconButton onClick={() => onClickCancelScheduled(tourGeneration)}>
                              <Delete color="error" />
                            </IconButton>
                          </Tooltip>
                        )}

                        {canDeleteTourGeneration(tourGeneration) && (
                          <Tooltip title={t("tour_generation.actions.delete_tour_generation") as string}>
                            <IconButton onClick={() => onClickDeleteTourGeneration(tourGeneration)}>
                              <Delete color="error" />
                            </IconButton>
                          </Tooltip>
                        )}
                      </Grid>
                    </Grid>
                  </TableCell>
                </TableRow>
              ))}
            {tourGenerationsLoading && <TableBodySkeleton columns={10} rows={pagination.pageSize} />}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[10, 25, 100]}
          align="right"
          component="div"
          count={tourGenerationsData?.tourGenerations.totalEntries || 0}
          rowsPerPage={tourGenerationsData?.tourGenerations.pageSize || 10}
          page={tourGenerationsData?.tourGenerations.page || 0}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </Fragment>
  )
}
