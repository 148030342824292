import React, { FunctionComponent, useState, useEffect } from "react"
import { Card, Grid, Button, Typography } from "@mui/material"
import { Add } from "@mui/icons-material"
import { useTranslation } from "react-i18next"
import { ContainerAdministrationCard } from "./container-administration-card"
import { GET_MANUFACTURERS_QUERY, ManufacturersResult } from "../../../../api/graphql/queries/get-manufacturers"
import { useQuery } from "@apollo/client"
import { GETMATERIALS_QUERY, MaterialsResult } from "../../../../api/graphql/queries/get-materials"
import { GET_CONTAINER_TYPES_QUERY, ContainerTypesResult } from "../../../../api/graphql/queries/get-container-types"

import {
  GET_CONTAINERS_WITH_COLLECTION_POINT_ID_QUERY,
  GetContainersVariables,
  GetContainersResult,
} from "../../../../api/graphql/queries/get-containers-with-collection-point-id"
import { useCollectionPointAdministrationContext } from "../collection-point-administration-context"

export interface IEditableContainer {
  id: string | null
  sensor_id: string | null
  number: string | null
  material_id: string | null
  manufacturer_id: string | null
  type_id: string | null
  emptying_requested: boolean
  collection_point?: {
    accessible_with_trailer: boolean
    overfilling_allowed: boolean
  }
}

interface IContainerAdministrationProps {}

export const ContainerAdministration: FunctionComponent<IContainerAdministrationProps> = (props) => {
  const { t } = useTranslation()
  const [containers, setContainers] = useState<IEditableContainer[]>([])
  const { data: manufacturersData } = useQuery<ManufacturersResult>(GET_MANUFACTURERS_QUERY, {
    fetchPolicy: "cache-and-network",
  })
  const { data: materialsData } = useQuery<MaterialsResult>(GETMATERIALS_QUERY, { fetchPolicy: "cache-and-network" })
  const { data: containerTypesData } = useQuery<ContainerTypesResult>(GET_CONTAINER_TYPES_QUERY, {
    fetchPolicy: "cache-and-network",
  })
  const { selectedCollectionPoint } = useCollectionPointAdministrationContext()
  const [refetchContainers, setRefetchContainers] = useState<boolean>()

  const { data: containersData } = useQuery<GetContainersResult, GetContainersVariables>(
    GET_CONTAINERS_WITH_COLLECTION_POINT_ID_QUERY,
    {
      variables: {
        collection_point_id: selectedCollectionPoint!.id,
      },
      fetchPolicy: refetchContainers ? "network-only" : "cache-first",
      onCompleted: () => setRefetchContainers(false),
    },
  )

  useEffect(() => {
    if (containersData) {
      setContainers(
        containersData.getContainersWithCollectionPointId.map((entry) => ({
          id: entry.id,
          sensor_id: entry.sensor_id,
          number: entry.number,
          material_id: entry.material.id,
          manufacturer_id: entry.manufacturer?.id || null,
          type_id: entry.type?.id || null,
          emptying_requested: !!entry.emptying_requested_at,
          collection_point: entry.collection_point,
        })),
      )
    }
  }, [containersData])

  useEffect(() => {
    if (selectedCollectionPoint?.id) {
      setRefetchContainers(true)
    }
  }, [selectedCollectionPoint])

  const onCreateContainerClicked = () => {
    setContainers(
      containers.concat({
        id: null,
        sensor_id: null,
        number: null,
        material_id: null,
        manufacturer_id: null,
        type_id: null,
        emptying_requested: false,
        collection_point: {
          accessible_with_trailer: false,
          overfilling_allowed: false,
        },
      }),
    )
  }

  return (
    <Card sx={{ p: 2 }}>
      <Grid container direction="column" spacing={2}>
        <Grid item container direction="row" justifyContent="flex-end" alignItems="center" spacing={2}>
          <Grid item>
            <Typography sx={{ fontSize: 18, fontWeight: "bold" }}>
              {t("collection_point_administration.create_container")}
            </Typography>
          </Grid>
          <Grid item>
            <Button type="button" variant="outlined" onClick={onCreateContainerClicked} fullWidth color="primary">
              <Add color="primary" />
            </Button>
          </Grid>
        </Grid>
        {manufacturersData && materialsData && containerTypesData && (
          <Grid container item direction="row" spacing={1}>
            {containers &&
              containers.map((container, index) => (
                <Grid key={`${selectedCollectionPoint?.id}-${index}-${container.id}`} item xs={6}>
                  <ContainerAdministrationCard
                    manufacturers={manufacturersData ? manufacturersData.getManufacturers : []}
                    materials={materialsData ? materialsData.getMaterials : []}
                    containerTypes={containerTypesData ? containerTypesData.getContainerTypes : []}
                    container={container}
                    onContainerRemoved={() => setContainers(containers.filter((c) => c !== container))}
                    onContainerUpdated={(c) => {
                      const copy = containers.slice()
                      copy[index] = c
                      setContainers(copy)
                      setRefetchContainers(true)
                    }}
                  />
                </Grid>
              ))}
          </Grid>
        )}
      </Grid>
    </Card>
  )
}
