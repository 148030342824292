import React, { FC, PropsWithChildren, useContext, useMemo, useState } from "react"
import { ApolloError, useQuery } from "@apollo/client"
import {
  HOUSEHOLD_CONTAINERS_QUERY,
  HouseholdContainer,
  HouseholdContainerVariables,
  HouseholdContainersResult,
} from "../../../../api/graphql/queries/household-containers"
import { HouseholdOverviewFilterContext } from "./household-overview-filter-context"
import { GETMATERIALS_QUERY, Material, MaterialsResult } from "../../../../api/graphql/queries/get-materials"
import {
  ContainerType,
  ContainerTypesResult,
  GET_CONTAINER_TYPES_QUERY,
} from "../../../../api/graphql/queries/get-container-types"
import { useSelectedRegion } from "../../../../hooks/use-selected-region"

export interface HouseholdContainerForTable extends HouseholdContainer {
  material?: Material
  containerType?: ContainerType
}

interface IHouseholdOverviewTableContext {
  householdContainers: HouseholdContainerForTable[]
  totalResults: number
  loading: boolean
  error: ApolloError | undefined
}

export const HouseholdOverviewTableContext = React.createContext<IHouseholdOverviewTableContext>({
  householdContainers: [],
  totalResults: 0,
  loading: false,
  error: undefined,
})

export const HouseholdOverviewTableContextProvider: FC<PropsWithChildren<Record<never, never>>> = (props) => {
  const { filter } = useContext(HouseholdOverviewFilterContext)
  const { skip, variables } = useSelectedRegion()
  const { data: materialsData } = useQuery<MaterialsResult>(GETMATERIALS_QUERY, { fetchPolicy: "cache-first" })
  const { data: containerTypesData } = useQuery<ContainerTypesResult>(GET_CONTAINER_TYPES_QUERY, {
    fetchPolicy: "cache-first",
  })
  const [totalResults, setTotalResults] = useState<number>(0)

  const { data, loading, error, called } = useQuery<HouseholdContainersResult, HouseholdContainerVariables>(
    HOUSEHOLD_CONTAINERS_QUERY,
    {
      variables: {
        countyIds: filter.selectedCounties.map((option) => option.id),
        materialIds: filter.selectedMaterials.map((option) => option.id),
        regionType: variables.type,
        regionId: variables.id,
        order: { orderBy: filter.pagination.orderBy, sortingType: filter.pagination.sortingType },
        page: filter.pagination.page,
        pagesize: filter.pagination.pagesize,
        townIds: filter.selectedTowns.map((option) => option.id),
        address: filter.address ? filter.address : null,
        showWarnings: filter.showWarnings,
      },
      skip,
      onCompleted: (result) => setTotalResults(result.householdContainers.totalEntries),
      onError: () => setTotalResults(0),
    },
  )

  const householdContainers: HouseholdContainerForTable[] = useMemo(
    () =>
      data?.householdContainers.entries.map((entry) => ({
        ...entry,
        material: materialsData?.getMaterials.find((material) => material.id === entry.materialId),
        containerType: containerTypesData?.getContainerTypes.find((containerType) => containerType.id === entry.typeId),
      })) || [],
    [data, materialsData, containerTypesData],
  )

  return (
    <HouseholdOverviewTableContext.Provider
      value={{ householdContainers, loading: loading || !called, error, totalResults }}
    >
      {props.children}
    </HouseholdOverviewTableContext.Provider>
  )
}
