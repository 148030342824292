import React, { FunctionComponent } from "react"
import { Grid, Skeleton } from "@mui/material"
import { useTranslation } from "react-i18next"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { useQuery } from "@apollo/client"
import { SelectPair } from "../../../partials/layout/selection/select-pair"
import { CustomSnackBarContent } from "../../../partials/wrapper/CustomSnackBarContent"
import { DriverLocationResult, GET_DRIVER_LOCATIONS_QUERY } from "../../../../api/graphql/queries/get-driver-locations"
import { useDriverLocation } from "../../../../context/driver-location-filter-context"

interface IDriverLocationSelectProps {}

export const DriverLocationSelect: FunctionComponent<IDriverLocationSelectProps> = (props) => {
  const { t } = useTranslation()
  const { driverLocationId, setDriverLocationId } = useDriverLocation()

  const {
    data: driverLocationData,
    loading: driverLocationsLoading,
    error: driverLocationsError,
  } = useQuery<DriverLocationResult>(GET_DRIVER_LOCATIONS_QUERY)

  if (driverLocationsError) {
    return <CustomSnackBarContent variant="error" message={t("errors.generic")} />
  }
  if (driverLocationsLoading) {
    return (
      <Grid container justifyContent="center" alignItems="center">
        <Skeleton height={50} width="100%" />
      </Grid>
    )
  }

  const driverLocations = driverLocationData
    ? driverLocationData.driverLocations.map(
        (driverLocation) => new SelectPair(driverLocation.id.toString(), driverLocation.name),
      )
    : []
  driverLocations.push(new SelectPair("", "none"))

  if (!driverLocationId) {
    if (driverLocations.find((driverLocation) => driverLocation.value === driverLocationId)) {
      setDriverLocationId(driverLocationId)
    } else {
      setDriverLocationId("")
    }
  }

  return (
    <OutlinedSelect
      options={driverLocations}
      name={t("guidance_administration.driver_location.title")}
      onValueChange={(newValue) => setDriverLocationId(newValue)}
      value={driverLocationId ? driverLocationId : ""}
    />
  )
}
