import React, { FunctionComponent, Fragment, useState } from "react"
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid,
  Button,
  Typography,
  CircularProgress,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import {
  TourExportPreviewCollectionPoint,
  TourExportPreviewMaterial,
} from "../../../../api/graphql/queries/tour-export-preview"
import { TourExportPreviewTable, CollectionPointStatus } from "./tour-export-preview-table"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import { UserService } from "../../../../services/user-service"
import { ScheduleTourGenerationDialog } from "./schedule-tour-generation-dialog"
import {
  GET_TOUR_EXPORT_PREVIEW_AS_EXCEL_FILE_QUERY,
  GetTourExportPreviewAsExcelFileResult,
  GetTourExportPreviewAsExcelFileVariables,
} from "../../../../api/graphql/queries/get-tour-export-preview-as-excel-file"
import { useLazyQuery } from "@apollo/client"
import { ContentType, downloadBase64 } from "../../../../utils/browser"
import {
  TourExportPreviewCollectionPointInput,
  TourExportPreviewMaterialInput,
} from "../../../../api/graphql/graphql-global-types"

interface IConfirmTourGenerationDialogProps {
  open: boolean
  onClose: () => void
  onConfirm: () => void
  date: Date
  loading: boolean
  collectionPoints: TourExportPreviewCollectionPoint[]
  materials: TourExportPreviewMaterial[]
  districtName: string
  isGenerateBtnDisabled: boolean
}

export const ConfirmTourGenerationDialog: FunctionComponent<IConfirmTourGenerationDialogProps> = (props) => {
  const { t } = useTranslation()
  const { open, onClose, onConfirm, date, loading, collectionPoints, materials, districtName, isGenerateBtnDisabled } =
    props

  const [confirmDialogOpen, setConfirmDialogOpen] = useState<boolean>(false)
  const [scheduleDialogOpen, setScheduleDialogOpen] = useState<boolean>(false)

  const [getTourExportPreviewAsExcelFile, { loading: excelLoading }] = useLazyQuery<
    GetTourExportPreviewAsExcelFileResult,
    GetTourExportPreviewAsExcelFileVariables
  >(GET_TOUR_EXPORT_PREVIEW_AS_EXCEL_FILE_QUERY, {
    onCompleted: (data) => downloadBase64(data.getTourExportPreviewAsExcelFile, ContentType.EXCEL),
    fetchPolicy: "no-cache",
  })
  const onGenerationConfirmed = () => {
    setConfirmDialogOpen(false)
    setScheduleDialogOpen(false)
    onConfirm()
  }

  const mapCollectionPointsToCollectionPointInputs = (): TourExportPreviewCollectionPointInput[] =>
    collectionPoints.map((cp) => ({
      id: cp.id,
      location_number: cp.location_number,
      description: cp.description,
      street: cp.street,
      place: cp.place,
      fixed_unload_interval: cp.fixed_unload_interval,
      postal: cp.postal,
      town: cp.town,
      last_emptying: cp.last_emptying,
      materials: cp.materials.map((material) => ({
        filllevel: material.filllevel,
        filllevel_percentage: material.filllevel_percentage,
        max_amount: material.max_amount,
        material: { id: Number(material.material.id), name: material.material.name },
      })),
    }))

  const mapMaterialsToMaterialInputs = (): TourExportPreviewMaterialInput[] =>
    materials.map((material) => ({
      material: { id: Number(material.material.id), name: material.material.name },
      filllevel: material.filllevel,
    }))

  return (
    <Fragment>
      <ConfirmDialog
        open={confirmDialogOpen}
        heading={t("tour_generation.confirm_dialog.confirm_title")}
        text={t("tour_generation.confirm_dialog.confirm_text")}
        confirmText={t("tour_generation.confirm_dialog.generate")}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => onGenerationConfirmed()}
      />
      <ScheduleTourGenerationDialog
        onClose={() => setScheduleDialogOpen(false)}
        open={scheduleDialogOpen}
        onConfirm={() => onGenerationConfirmed()}
      />
      <Dialog open={open} onClose={onClose} maxWidth="lg">
        <DialogTitle>
          <Grid container direction="column">
            <Grid item>{t("tour_generation.confirm_dialog.title")}</Grid>
            {!loading && (
              <Grid item container direction="row" justifyContent="space-between" sx={{ textAlign: "right" }}>
                <Grid item>
                  <Typography sx={{ fontWeight: "bold" }}>
                    {t("tour_generation.confirm_dialog.collection_point_info", {
                      count: collectionPoints.length || 0,
                    })}
                  </Typography>
                </Grid>
                <Grid item>
                  {materials.map((material) => (
                    <Typography sx={{ fontWeight: "bold" }} key={`material${material.material.name}`}>
                      {material.material.name}:{" "}
                      {Math.round(material.filllevel).toLocaleString(UserService.getLanguage())}kg
                    </Typography>
                  ))}
                </Grid>
              </Grid>
            )}
          </Grid>
        </DialogTitle>
        <DialogContent sx={{ pt: 0 }}>
          <TourExportPreviewTable
            loading={loading}
            collectionPoints={collectionPoints.map((cp) => ({ ...cp, status: CollectionPointStatus.planned })) || []}
            materials={materials || []}
            date={date}
            showDiff={false}
          />
        </DialogContent>
        <DialogActions>
          <Grid container direction="row" spacing={2} justifyContent="space-between">
            <Grid item>
              <Button onClick={onClose} autoFocus variant="contained" color="inherit">
                {t("tour_generation.confirm_dialog.cancel")}
              </Button>
            </Grid>
            <Grid container item direction="row" spacing={2} justifyContent="flex-end" xs>
              <Grid item>
                <Button
                  onClick={() =>
                    getTourExportPreviewAsExcelFile({
                      variables: {
                        tour_export_preview: {
                          districtName: districtName,
                          collectionPoints: mapCollectionPointsToCollectionPointInputs(),
                          materials: mapMaterialsToMaterialInputs(),
                        },
                      },
                    })
                  }
                  variant="contained"
                  color="primary"
                  disabled={loading || excelLoading}
                >
                  {excelLoading && <CircularProgress size={24} />}
                  {!excelLoading && t("tour_generation.confirm_dialog.export")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setScheduleDialogOpen(true)}
                  variant="contained"
                  color="primary"
                  disabled={loading || isGenerateBtnDisabled}
                >
                  {t("tour_generation.confirm_dialog.schedule")}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  onClick={() => setConfirmDialogOpen(true)}
                  variant="contained"
                  color="primary"
                  disabled={loading || isGenerateBtnDisabled}
                >
                  {t("tour_generation.confirm_dialog.generate")}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </DialogActions>
      </Dialog>
    </Fragment>
  )
}
