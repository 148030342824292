import React, { FC } from "react"
import { UploadCollectionPointsProvider } from "./upload-collection-points-context"
import { UploadCollectionPointsPageContent } from "./upload-collection-points-page-content"

interface IUploadCollectionPointsPageProps {}

export const UploadCollectionPointsPage: FC<IUploadCollectionPointsPageProps> = () => {
  return (
    <UploadCollectionPointsProvider>
      <UploadCollectionPointsPageContent />
    </UploadCollectionPointsProvider>
  )
}
