import React, { useContext, useEffect, Fragment } from "react"
import { Navigate, Route, RouterProvider, createBrowserRouter, createRoutesFromElements } from "react-router-dom"
import { Portal } from "../portal/portal"
import { BASE_PATH, PATH } from "../../../router/router"
import { LoginPage } from "../login/login-page"
import { UserContext } from "../../../context/user-context"
import { UserService } from "../../../services/user-service"
import { StyledEngineProvider } from "@mui/material"
import { ThemeProvider } from "@mui/material/styles"
import { getTheme } from "../../../styles/theme"
import { useQuery } from "@apollo/client"
import { DEFAULT_THEME_QUERY, DefaultThemeResult } from "../../../api/graphql/queries/default-theme"
import { PrivateRoute } from "../../partials/wrapper/auth.route"
import { UserRole } from "../../../api/graphql/graphql-global-types"

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="/">
      <Route path={PATH.LOGIN.route} element={<LoginPage />} />
      <Route path={BASE_PATH} element={<PrivateRoute />}>
        <Route element={<Portal />}>
          {UserService.getAvailablePaths().map((key, index) => {
            const Component = PATH[key].component
            return (
              <Route key={index} path={PATH[key].route} element={<Component />}>
                {PATH[key].children?.map((child, childIndex) => {
                  const ChildComponent = child.component
                  return <Route key={`${index}-${childIndex}`} path={child.route} element={<ChildComponent />} />
                })}
                {PATH[key].redirectTo && <Route path="*" element={<Navigate to={String(PATH[key].redirectTo)} />} />}
              </Route>
            )
          })}
          <Route
            path="*"
            element={
              <Navigate
                to={
                  UserService.getRole() === UserRole.GUIDANCE_ADMIN
                    ? PATH.ROUTES_OVERVIEW.route
                    : PATH.COLLECTIONPOINTS.route
                }
              />
            }
          />
        </Route>
      </Route>
      <Route path="*" element={<Navigate to={PATH.LOGIN.route} />} />
      <Route path="/" element={<Navigate to={PATH.LOGIN.route} />} />
    </Route>,
  ),
)

export const App: React.FunctionComponent = () => {
  const { user, loadUser } = useContext(UserContext)

  const { data, loading } = useQuery<DefaultThemeResult>(DEFAULT_THEME_QUERY, {})

  useEffect(() => {
    if (!user && UserService.isUserLoggedIn()) {
      loadUser()
    }
  }, [user, loadUser])

  if (loading || !data) {
    return <Fragment />
  }

  const theme = getTheme(data!.defaultTheme)

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <RouterProvider router={router} />
      </ThemeProvider>
    </StyledEngineProvider>
  )
}
