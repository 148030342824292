import React, { FunctionComponent, useState, useEffect, useRef } from "react"
import { IconButton, Grid, Typography, TextField, useTheme, Box } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DesktopDatePicker, DesktopDatePickerProps, PickersDayProps, DatePickerToolbarProps } from "@mui/x-date-pickers"
import moment, { Moment } from "moment"
import lodash from "lodash"

// remove "onChange" from props as it returns nullable value (use "onWeekChanged" instead)

type WeekPickerProps = Omit<DesktopDatePickerProps<Moment>, "onChange"> & {
  label?: string
  value?: moment.Moment
  onWeekChanged: (date: moment.Moment) => void
  minDate?: moment.Moment
  maxDate?: moment.Moment
}

export const WeekPicker: FunctionComponent<WeekPickerProps> = (props) => {
  const { t } = useTranslation()
  const { label, value, minDate, maxDate, onWeekChanged, ...rest } = props
  const [selectedDate, setSelectedDate] = useState<moment.Moment>(moment())
  const theme = useTheme()
  const anchorRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (!lodash.isNil(value)) {
      setSelectedDate(value)
    }
  }, [value])

  const renderToolbar = (props: DatePickerToolbarProps<moment.Moment>) => {
    return (
      <Grid
        container
        sx={{ backgroundColor: theme.palette.primary.main, color: theme.palette.primary.contrastText, p: 2 }}
        justifyContent="flex-start"
        direction="column"
      >
        <Grid item>
          <Typography variant="subtitle1" sx={{ color: "rgba(255, 255, 255, 0.54)" }}>
            {props.value?.format("YYYY")}
          </Typography>
        </Grid>
        <Grid item>
          <Typography variant="h4">{t("week_picker.week_number", { week: value?.format("WW") })}</Typography>
        </Grid>
      </Grid>
    )
  }

  const handleWeekChange = (date: moment.Moment | null) => {
    if (!lodash.isNil(date)) {
      setSelectedDate(date)
      onWeekChanged(date.startOf("week"))
    }
  }

  const renderWrappedWeekDay = (props: PickersDayProps<moment.Moment>) => {
    const { day, outsideCurrentMonth } = props
    const selected = selectedDate ? selectedDate : moment()
    const firstDay = selected.clone().startOf("week")
    const lastDay = selected.clone().endOf("week")

    const isHighlighted = day.isSameOrBefore(lastDay) && day.isSameOrAfter(firstDay)
    const firstHighlighted = day.isSame(firstDay, "day")
    const lastHighlighted = day.isSame(lastDay, "day")

    let textColor = (maxDate && day.isAfter(maxDate)) || (minDate && day.isBefore(minDate)) ? "#bfbfbf" : undefined

    if (!textColor && outsideCurrentMonth && isHighlighted) {
      textColor = "#676767"
    } else if (!textColor && outsideCurrentMonth) {
      textColor = theme.palette.text.disabled
    } else if (!textColor) {
      textColor = "inherit"
    }

    return (
      <Box
        sx={{
          backgroundColor: isHighlighted ? theme.palette.primary.main : undefined,
          color: isHighlighted ? theme.palette.common.white : undefined,
          borderTopLeftRadius: firstHighlighted ? "50%" : undefined,
          borderBottomLeftRadius: firstHighlighted ? "50%" : undefined,
          borderTopRightRadius: lastHighlighted ? "50%" : undefined,
          borderBottomRightRadius: lastHighlighted ? "50%" : undefined,
        }}
      >
        <IconButton
          sx={{
            width: 36,
            height: 36,
            fontSize: theme.typography.caption.fontSize,
            margin: "0 2px",
            color: textColor,
          }}
          onClick={() => handleWeekChange(day.clone())}
        >
          <span> {day.clone().format("DD")} </span>
        </IconButton>
      </Box>
    )
  }

  const formatWeekSelectLabel = (date: moment.Moment | null) => {
    return date
      ? t("week_picker.calendar_week_formatted", {
          week: date.format("WW"),
          year: date.format("YYYY"),
        })
      : ""
  }

  return (
    <Box ref={anchorRef}>
      <DesktopDatePicker
        displayWeekNumber
        label={label || t("week_picker.calendar_week")}
        value={selectedDate}
        onChange={handleWeekChange}
        slotProps={{
          ...rest.slotProps,
          popper: {
            placement: "bottom-start",
            anchorEl: anchorRef.current || undefined,
            sx: {
              "& .MuiPickersLayout-root": {
                display: "block",
              },
            },
          },
        }}
        slots={{
          toolbar: renderToolbar,
          day: renderWrappedWeekDay,
          textField: (params) => (
            <TextField {...params} size="small" value={formatWeekSelectLabel(selectedDate)} fullWidth />
          ),
        }}
        minDate={minDate}
        maxDate={maxDate}
        {...rest}
      />
    </Box>
  )
}
