import React, { FC, useState } from "react"
import { Button, Card, Chip, Grid, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { Text } from "../../../partials/wrapper/text"
import { LocationType } from "../../user-management/helper/get-options-for-role"
import { sortASC } from "../../../../utils/sort"
import { ILocationCollectionItem, LocationAssignmentDialog } from "./location-assignment-dialog"

interface IDisposalMerchantTownSelectorProps {
  selectedDistricts: ILocationCollectionItem[]
  selectedTowns: ILocationCollectionItem[]
  setSelectedTowns: (locations: ILocationCollectionItem[]) => void
}

export const DisposalMerchantTownSelector: FC<IDisposalMerchantTownSelectorProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { selectedDistricts, selectedTowns, setSelectedTowns } = props
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false)

  const onSave = (items: ILocationCollectionItem[]) => {
    const sorted = items.sort((a, b) => sortASC(a.name, b.name))
    setSelectedTowns(sorted)
  }

  return (
    <>
      <Card
        sx={{
          pr: 2,
          pl: 2,
          height: `calc(100% - ${theme.spacing(2)}px)`,
          width: "100%",
        }}
      >
        <Grid container direction="column" spacing={2} justifyContent="center">
          <Grid container item alignItems="center" justifyContent="space-between">
            <Grid item>
              <Text bold>{t(`location_type.${LocationType.TOWN}`)}</Text>
            </Grid>
            <Grid item>
              <Grid container spacing={1}>
                <Grid item>
                  <Button
                    color="primary"
                    variant="contained"
                    onClick={() => setIsDialogOpen(true)}
                    sx={{ mt: 1, mb: 1 }}
                    disabled={selectedDistricts.length === 0}
                  >
                    {t("user_management.data.assign", {
                      collection: t(`location_type.${LocationType.TOWN}`),
                    })}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          {selectedTowns.length > 0 && (
            <Grid item container spacing={1}>
              {selectedTowns.map((item) => (
                <Grid item key={item.id}>
                  <Chip label={item.name} />
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </Card>

      <LocationAssignmentDialog
        type={LocationType.TOWN}
        collection={selectedTowns}
        open={isDialogOpen}
        onSave={onSave}
        onClose={() => setIsDialogOpen(false)}
        selectedDistricts={selectedDistricts}
      />
    </>
  )
}
