import React, { FunctionComponent, Fragment } from "react"
import { Card, Grid, useTheme } from "@mui/material"

import { Text } from "../../../partials/wrapper/text"
import { INFO_CARD_WIDTH, INFO_CARD_HEIGHT } from "../../../../utils/constants"

interface IInfoCardProps {
  header: string
  header2?: string
  children?: any
}

export const InfoCard: FunctionComponent<IInfoCardProps> = (props) => {
  const theme = useTheme()
  return (
    <Fragment>
      <Card
        sx={{
          width: INFO_CARD_WIDTH + parseFloat(theme.spacing(2)),
          height: INFO_CARD_HEIGHT,
          pb: 1,
          [theme.breakpoints.down("xs")]: {
            width: "100%",
          },
        }}
      >
        <Grid>
          <Text sx={{ m: 1 }} variant="h6">
            {props.header}
          </Text>
          {props.header2 && (
            <Text sx={{ m: 1 }} variant="subtitle2">
              {props.header2}
            </Text>
          )}
          {props.children}
        </Grid>
      </Card>
    </Fragment>
  )
}
