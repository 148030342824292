import React, { FunctionComponent, useEffect, useMemo } from "react"
import { useParams } from "react-router-dom"
import { Grid, CircularProgress, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { RefetchProvider } from "../../../../context/refetch-context"
import { VehicleSelector } from "./vehicle-selector"
import { VehicleData } from "./vehicle-data"
import { useSelectedPartner } from "../../../../context/selected-partner-context"

interface IVehicleOverviewProps {}

export const VehicleOverview: FunctionComponent<IVehicleOverviewProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { setSelectedPartnerId, selectedPartner, loading: partnerLoading } = useSelectedPartner()
  const params = useParams<{ partnerID: string }>()
  const partnerID = useMemo(() => parseInt(String(params.partnerID), 10), [params.partnerID])

  useEffect(() => {
    if (!isNaN(partnerID)) {
      setSelectedPartnerId(partnerID)
    }
  }, [partnerID, setSelectedPartnerId])

  return (
    <Grid container direction="row" sx={{ height: "calc(100vh - 56px)", display: "flex" }}>
      <RefetchProvider>
        <Grid item sx={{ height: "100%" }}>
          <VehicleSelector />
        </Grid>
        <Grid container item direction="column" xs sx={{ height: "100%", padding: `0 ${theme.spacing(1)}`, flex: "1" }}>
          {partnerLoading ? (
            <Grid container justifyContent="center" alignItems="center">
              <CircularProgress />
            </Grid>
          ) : (
            <Grid item sx={{ pl: 2 }}>
              <PageHeading>
                {selectedPartner
                  ? selectedPartner.name
                  : t("vehicle_overview.partner_not_found", {
                      partnerID,
                    })}
              </PageHeading>
            </Grid>
          )}
          <Grid sx={{ display: "flex", flex: 1, overflowY: "auto" }}>
            <VehicleData />
          </Grid>
        </Grid>
      </RefetchProvider>
    </Grid>
  )
}
