import { SvgIconProps, Tooltip } from "@mui/material"
import PostAddIcon from "@mui/icons-material/PostAdd"
import DeleteIcon from "@mui/icons-material/Delete"
import React, { FC } from "react"
import { useTranslation } from "react-i18next"

interface ITourGenerationIconProps extends SvgIconProps {
  withoutContainers: boolean
}

export const TourGenerationIcon: FC<ITourGenerationIconProps> = (props) => {
  const { withoutContainers, ...rest } = props

  if (withoutContainers) {
    return <WithoutContainersIcon {...rest} />
  }
  return <WithContainersIcon {...rest} />
}

interface IWithoutContainersIconProps extends SvgIconProps {}

const WithoutContainersIcon: FC<IWithoutContainersIconProps> = (props) => {
  const { ...rest } = props
  const { t } = useTranslation()
  return (
    <Tooltip placement="top" title={t("tour_overview.tour_without_containers") as string}>
      <PostAddIcon color="primary" {...rest} />
    </Tooltip>
  )
}

interface IWithContainersIconProps extends SvgIconProps {}
const WithContainersIcon: FC<IWithContainersIconProps> = (props) => {
  const { ...rest } = props
  const { t } = useTranslation()
  return (
    <Tooltip placement="top" title={t("tour_overview.tour_with_containers") as string}>
      <DeleteIcon color="primary" {...rest} />
    </Tooltip>
  )
}
