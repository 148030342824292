import React, { FunctionComponent, Fragment } from "react"
import { Grid } from "@mui/material"

import { Text } from "../../../partials/wrapper/text"

interface IInfoProps {
  amount: string
  description: string
}

export const Info: FunctionComponent<IInfoProps> = (props) => {
  return (
    <Fragment>
      <Grid sx={{ pl: 1, pr: 10, pt: 0.5, pb: 0.5 }} container direction="row">
        <Grid sx={{ width: 60 }}>
          <Text bold color="primary">
            {props.amount}
          </Text>
        </Grid>
        <Grid sx={{ flex: 1, mr: 2.5 }}>
          <Text sx={{ wordWrap: "break-word" }}>{props.description}</Text>
        </Grid>
      </Grid>
    </Fragment>
  )
}
