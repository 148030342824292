import React, { createContext, FunctionComponent, PropsWithChildren, useState } from "react"
import {
  ContainerWarning,
  GetCurrentContainerWarningsResult,
  GetCurrentContainerWarningsVariables,
  GET_CURRENT_CONTAINER_WARNINGS_QUERY,
} from "../api/graphql/queries/get-current-container-warnings"
import { useMutation, useQuery } from "@apollo/client"
import { ContainerWarningStatus } from "../api/graphql/graphql-global-types"
import { toast } from "react-toastify"
import {
  UpdateContainerWarningStatusResult,
  UpdateContainerWarningStatusVariables,
  UPDATE_CONTAINER_WARNING_STATUS_MUTATION,
} from "../api/graphql/mutations/update-container-warning"
import { useTranslation } from "react-i18next"
import { useSelectedRegion } from "../hooks/use-selected-region"

interface IContainerWarningsContext {
  warnings: ContainerWarning[]
  setWarnings: (warnings: ContainerWarning[]) => void
  update: (id: string, status: ContainerWarningStatus) => void
  updateLoading: boolean
  refetch: () => void
  loading: boolean
  error: boolean
}

export const ContainerWarningsContext = createContext<IContainerWarningsContext>({
  warnings: [],
  setWarnings: (warnings) => {},
  update: () => {},
  updateLoading: false,
  refetch: () => {},
  loading: false,
  error: false,
})

export const ContainerWarningsContextProvider: FunctionComponent<PropsWithChildren<Record<never, never>>> = (props) => {
  const { t } = useTranslation()
  const { variables, skip } = useSelectedRegion()

  const [warnings, setWarnings] = useState<ContainerWarning[]>([])

  const { loading, error, refetch } = useQuery<GetCurrentContainerWarningsResult, GetCurrentContainerWarningsVariables>(
    GET_CURRENT_CONTAINER_WARNINGS_QUERY,
    {
      variables: {
        regionId: variables.id,
        regionType: variables.type,
      },
      skip,
      onCompleted: (data) => {
        if (data?.getCurrentContainerWarnings) {
          setWarnings(data.getCurrentContainerWarnings)
        }
      },
      onError: () => {
        toast.error(t("container_warnings.table.error"))
      },
    },
  )

  const [updateContainerWarningStatus, { loading: updateLoading }] = useMutation<
    UpdateContainerWarningStatusResult,
    UpdateContainerWarningStatusVariables
  >(UPDATE_CONTAINER_WARNING_STATUS_MUTATION, {
    onError: () => toast.error(t("container_warnings.table.update_error")),
    onCompleted: (data) => {
      if (data == null) {
        toast.error(t("container_warnings.table.update_error"))
        return
      }
      toast.success(t("container_warnings.table.update_success"))
    },
  })

  const update = async (id: string, status: ContainerWarningStatus) => {
    const result = await updateContainerWarningStatus({
      variables: { containerWarningId: id, status },
    })
    if (result.data?.updateContainerWarningStatus) {
      // remove updated warning with new one
      setWarnings([...warnings.filter((warning) => warning.id !== id), result.data.updateContainerWarningStatus])
    }
  }

  return (
    <ContainerWarningsContext.Provider
      value={{
        warnings,
        setWarnings,
        update,
        updateLoading,
        loading: loading,
        error: !!error,
        refetch,
      }}
    >
      {props.children}
    </ContainerWarningsContext.Provider>
  )
}
