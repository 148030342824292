import { gt, coerce, valid } from "semver"
import { config } from "./config"

const localPortalVersion = valid(coerce(config.REACT_APP_VERSION_INFO))
let remotePortalVersion = localPortalVersion

export const isPortalUpdateAvailable = async (): Promise<boolean> =>
  await fetch("/meta.json")
    .then((response) => response.json())
    .then((meta) => {
      remotePortalVersion = valid(coerce(meta.version))
      console.log(`Local version: ${localPortalVersion}, Remote version: ${remotePortalVersion}`)  ;
      
      if (!remotePortalVersion) {
        throw new Error("Remote version could not be read. Check /meta.json for a correct semver format.")
      }
      if (!localPortalVersion) {
        throw new Error("Local portal version is not set. Check if the according env-variable is set.")
      }

      return gt(remotePortalVersion, localPortalVersion)
    })
    .catch((err) => {
      console.log(err)
      // ignore errors to avoid false positives (e.g. user gets constantly update notifications)
      return false
    })

export const updatePortal = () => {
  if (caches) {
    // Service worker cache should be cleared with caches.delete()
    caches.keys().then((names) => {
      for (const name of names) {
        caches.delete(name)
      }
    })
  }
  // delete browser cache and hard reload
  window.location.reload()
}

export const getLocalPortalVersion = (): string | null => localPortalVersion
export const getRemotePortalVersion = (): string | null => remotePortalVersion
