import React, { FunctionComponent } from "react"
import { Typography } from "@mui/material"
import { TypographyProps } from "@mui/material/Typography"

interface ITextProps extends TypographyProps {
  bold?: boolean
}

export const Text: FunctionComponent<ITextProps> = (props) => {
  const { bold, sx, ...rest } = props
  return (
    <Typography {...rest} sx={{ ...sx, ...(bold && { fontWeight: "bold" }) }}>
      {props.children}
    </Typography>
  )
}
