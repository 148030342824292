import React, { FunctionComponent, useMemo } from "react"
import { RouteContextProvider } from "./partials/route-context"
import { RouteDetails } from "./partials/route-details"
import { RefetchProvider } from "../../../context/refetch-context"
import { useParams } from "react-router-dom"

interface IRouteDetailsPageProps {}

export const RouteDetailsPage: FunctionComponent<IRouteDetailsPageProps> = (props) => {
  const params = useParams<{ routeId: string }>()
  const routeId = useMemo(() => parseInt(String(params.routeId), 10), [params.routeId])
  return (
    <RefetchProvider>
      <RouteContextProvider routeId={routeId}>
        <RouteDetails />
      </RouteContextProvider>
    </RefetchProvider>
  )
}
