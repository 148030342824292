import React, { FunctionComponent, useCallback } from "react"
import { Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocation, useNavigate } from "react-router-dom"
import { PATH } from "../../../../../router/router"

interface IUploadCollectionPointsButtonProps {}

export const UploadCollectionPointsButton: FunctionComponent<IUploadCollectionPointsButtonProps> = () => {
  const { t } = useTranslation()
  const navigate = useNavigate()

  const location = useLocation()

  const handleButtonClicked = useCallback(() => {
    if (location.pathname !== PATH.COLLECTIONPOINTS_ADMINISTRATION_UPLOAD.route) {
      navigate(PATH.COLLECTIONPOINTS_ADMINISTRATION_UPLOAD.route)
    }
  }, [location, navigate])

  return (
    <Button color="primary" variant="contained" fullWidth onClick={handleButtonClicked}>
      {t("collection_point_administration.upload.upload_collection_points")}
    </Button>
  )
}
