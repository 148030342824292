import React, { FunctionComponent, PropsWithChildren, useEffect, useState } from "react"
import {
  Button,
  DialogContentText,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from "@mui/material"
import ErrorOutlineRoundedIcon from "@mui/icons-material/ErrorOutlineRounded"
import { useTranslation } from "react-i18next"

interface IConfirmDialogProps extends PropsWithChildren<Record<never, never>> {
  open: boolean
  onConfirm: () => void
  onClose: () => void
  heading: string
  confirmText: string
  text?: string
  showNegativeButton?: boolean
  negativeText?: string
  loading?: boolean
  error?: boolean
  disabled?: boolean
  allowMultipleConfirms?: boolean
  size?: "lg" | "xs" | "sm" | "md" | "xl"
}

export const ConfirmDialog: FunctionComponent<IConfirmDialogProps> = (props) => {
  const { t } = useTranslation()

  const {
    open,
    onClose,
    onConfirm,
    heading,
    text,
    confirmText,
    children,
    showNegativeButton = true,
    negativeText,
    loading,
    error,
    disabled,
    size,
    allowMultipleConfirms,
  } = props
  const [localDisabled, setLocalDisabled] = useState<boolean>(false)

  useEffect(() => {
    if (open && !loading && !error) {
      setLocalDisabled(false)
    } else {
      setLocalDisabled(true)
    }
  }, [open, loading, error])

  const handleConfirmClick = () => {
    // allow only a single click
    if (!allowMultipleConfirms && localDisabled) {
      return
    }
    setLocalDisabled(true)
    onConfirm()
  }
  return (
    <Dialog open={open} onClose={onClose} maxWidth={size}>
      <DialogTitle>{heading}</DialogTitle>
      <DialogContent>
        {text && <DialogContentText sx={{ whiteSpace: "pre-line" }}>{text}</DialogContentText>}
        {children}
      </DialogContent>
      <DialogActions>
        {showNegativeButton && (
          <Button onClick={onClose} variant="contained" color="inherit" disabled={loading}>
            {negativeText || t("cancel")}
          </Button>
        )}
        <Button onClick={handleConfirmClick} color={"primary"} variant="contained" disabled={disabled || loading}>
          {error && <ErrorOutlineRoundedIcon />}
          {!error && loading && <CircularProgress sx={{ color: "primary.contrastText" }} size={24} />}
          {!error && !loading && confirmText}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
