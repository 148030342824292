import React, { FunctionComponent, createContext, useState, useEffect, PropsWithChildren } from "react"
import { useLazyQuery } from "@apollo/client"
import { getUsersResult, User, GET_USERS_QUERY } from "../../../../api/graphql/queries/get-users"
import { ApolloError } from "@apollo/client"
import { useRefetch } from "../../../../context/refetch-context"
import { sortASC } from "../../../../utils/sort"

interface IUserManagementContext {
  users: User[]
  selectedUser: User | undefined
  updateSelectedUser: (user: User | undefined) => void
  usersLoading: boolean
  usersError: ApolloError | undefined
  isInCreateMode: boolean
  toggleCreateMode: () => void
}

export const UserManagementContext = createContext<IUserManagementContext>({
  users: [],
  selectedUser: undefined,
  updateSelectedUser: (user: User | undefined) => {},
  usersLoading: false,
  usersError: undefined,
  isInCreateMode: false,
  toggleCreateMode: () => {},
})

interface IUserManagementContextProviderProps extends PropsWithChildren<Record<never, never>> {}

export const UserManagementContextProvider: FunctionComponent<IUserManagementContextProviderProps> = (props) => {
  const [usersQuery, { data, loading: usersLoading, error: usersError }] = useLazyQuery<getUsersResult>(GET_USERS_QUERY)
  const [selectedUser, setSelectedUser] = useState<User | undefined>(undefined)
  const [isInCreateMode, setIsInCreateMode] = useState<boolean>(false)

  const { needToRefetch, setNeedToRefetch } = useRefetch()

  //initial Load
  useEffect(() => {
    usersQuery()
  }, [usersQuery])

  //refetch
  useEffect(() => {
    if (needToRefetch) {
      usersQuery()
      setNeedToRefetch(false)
    }
  }, [needToRefetch, setNeedToRefetch, usersQuery])

  const toggleCreateMode = () => {
    setIsInCreateMode(!isInCreateMode)
    setSelectedUser(undefined)
  }

  const updateSelectedUser = (user: User | undefined) => {
    setIsInCreateMode(false)
    setSelectedUser(selectedUser !== user ? user : undefined)
  }

  const users = data ? data.getUsers.sort((a, b) => sortASC(a.email, b.email)) : []

  return (
    <UserManagementContext.Provider
      value={{
        users,
        selectedUser,
        updateSelectedUser,
        usersLoading,
        usersError,
        isInCreateMode,
        toggleCreateMode,
      }}
    >
      {props.children}
    </UserManagementContext.Provider>
  )
}
