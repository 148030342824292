import { Grid } from "@mui/material"
import React, { FunctionComponent, useCallback, useContext } from "react"
import { useTranslation } from "react-i18next"
import { DayOfWeek } from "../../../../../utils/day"
import { DeparturePointContext } from "../../../partner-overview/partials/departure-point/context/departure-point-context"
import { VehicleOperationPoint } from "../../../vehicle-overview/partials/vehicle-operation-point"
import { ICustomDeparturePoint } from "../tour-generation-vehicle-selection"

interface ITourGenerationVehicleConfigurationDeparturePointProps {
  dayOfWeek: DayOfWeek
  customDeparturePointEntry?: ICustomDeparturePoint
  onCustomDeparturePointEntryChange: (customDeparturePoint: ICustomDeparturePoint) => void
}

export const TourGenerationVehicleConfigurationDeparturePoint: FunctionComponent<ITourGenerationVehicleConfigurationDeparturePointProps> = (
  props,
) => {
  const { t } = useTranslation()
  const { departurePoints } = useContext(DeparturePointContext)
  const { dayOfWeek, customDeparturePointEntry, onCustomDeparturePointEntryChange } = props

  const setDepartureOrEndPointId = useCallback(
    (id: number | null, isEndPoint: boolean) => {
      const foundDeparturePoint = departurePoints.find((departurePoint) => departurePoint.id === id)

      if (foundDeparturePoint) {
        onCustomDeparturePointEntryChange({
          day: dayOfWeek,
          departurePoint: isEndPoint ? customDeparturePointEntry?.departurePoint : foundDeparturePoint,
          endPoint: isEndPoint ? foundDeparturePoint : customDeparturePointEntry?.endPoint,
        })
      }
    },
    [departurePoints, dayOfWeek, customDeparturePointEntry, onCustomDeparturePointEntryChange],
  )

  return (
    <Grid container spacing={1}>
      <Grid item xs={12} md={6}>
        {/* Card for managing starting point of the vehicle */}
        <VehicleOperationPoint
          id={customDeparturePointEntry?.departurePoint?.id || null}
          setId={(id) => setDepartureOrEndPointId(id, false)}
          heading={t("vehicle_overview.data.starting_point")}
        />
      </Grid>
      <Grid item xs={12} md={6}>
        {/* Card for managing end point of the vehicle */}
        <VehicleOperationPoint
          id={customDeparturePointEntry?.endPoint?.id || null}
          setId={(id) => setDepartureOrEndPointId(id, true)}
          heading={t("vehicle_overview.data.end_point")}
        />
      </Grid>
    </Grid>
  )
}
