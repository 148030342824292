import React, { FunctionComponent } from "react"
import { Grid, Chip, CircularProgress } from "@mui/material"
import { useQuery } from "@apollo/client"
import {
  GET_DISTRICTS_FOR_USER_MANAGEMENT_QUERY,
  DistrictsResult,
} from "../../../../../api/graphql/queries/get-districts-for-user-management"
import { sortASC } from "../../../../../utils/sort"
import { ILocationCollectionItem } from "./location-assignment-dialog"

interface IDistrictSelectionProps {
  selectedItems: ILocationCollectionItem[]
  onClick: (item: ILocationCollectionItem) => void
  searchString: string
  selectedPartnerId?: number | null
}

export const DistrictSelection: FunctionComponent<IDistrictSelectionProps> = (props) => {
  const { onClick, selectedItems, searchString, selectedPartnerId } = props

  const { data, loading } = useQuery<DistrictsResult>(GET_DISTRICTS_FOR_USER_MANAGEMENT_QUERY, {
    variables: {
      collection_partner_id: selectedPartnerId,
    },
  })

  const districts =
    data?.getDistrictsForUserManagement
      .sort((a, b) => sortASC(a.name, b.name))
      .filter((district) => district.name.toLowerCase().includes(searchString.toLowerCase())) || []

  return (
    <Grid container spacing={1}>
      {loading && (
        <Grid item container justifyContent="center">
          <CircularProgress color="primary" />
        </Grid>
      )}
      {!loading &&
        districts.map((district) => (
          <Grid item key={district.id}>
            <Chip
              label={district.name}
              color={
                selectedItems.find((item) => item.id === district.id && item.name === district.name)
                  ? "primary"
                  : "default"
              }
              onClick={() => onClick(district)}
            />
          </Grid>
        ))}
    </Grid>
  )
}
