import React, { FunctionComponent, Fragment } from "react"
import { Theme, Grid, SxProps } from "@mui/material"
import { Text } from "../../../../partials/wrapper/text"

const FONT_SIZE = 13

export interface ITourOverviewMapMarkerTooltipPropertyProps {
  name: string
  content?: string | null
  sx?: {
    root?: SxProps<Theme> | undefined
    name?: SxProps<Theme> | undefined
    content?: SxProps<Theme> | undefined
  }
}

export const TourOverviewMapMarkerTooltipProperty: FunctionComponent<ITourOverviewMapMarkerTooltipPropertyProps> = (
  props,
) => {
  const { name, content, sx } = props

  const contentStyle = {
    ...sx?.content,
    ...sx?.root,
  } as SxProps<Theme>


  return (
    <Fragment>
      <Grid item container xs={6} justifyContent="space-between" alignItems="flex-start" sx={{ pr: 0.5, ...sx?.root }}>
        <Text bold sx={{ fontSize: FONT_SIZE, ...sx?.name }}>
          {name}
        </Text>
        <Text bold sx={{ fontSize: FONT_SIZE, ...sx?.name }}>
          :
        </Text>
      </Grid>
      <Grid item xs={6}>
        <Text bold={false} sx={{ whiteSpace: "break-spaces", fontSize: FONT_SIZE, ...contentStyle }}>
          {content}
        </Text>
      </Grid>
    </Fragment>
  )
}
