import React, { FunctionComponent } from "react"
import { FormControl, InputLabel, Select, OutlinedInput, MenuItem } from "@mui/material"
import { useTranslation } from "react-i18next"
import { tmpNames } from "../../../../utils/tempNamelist"

interface ITmpVehicleDriverSelectProps {
  driver: string | null
  updateDriver: (driver: string | null) => void
}

export const TmpVehicleDriverSelect: FunctionComponent<ITmpVehicleDriverSelectProps> = (props) => {
  const { t } = useTranslation()
  const { driver, updateDriver } = props

  const inputLabel = React.useRef<HTMLLabelElement>(null)

  return (
    <FormControl variant="outlined" fullWidth size="small">
      <InputLabel ref={inputLabel} htmlFor="outlined-select">
        {t("vehicle_overview.data.driver")}
      </InputLabel>
      <Select
        value={driver || ""}
        onChange={(event) => updateDriver((event.target.value as string) || null)}
        inputProps={{
          name: "driver",
          id: "outlined-select",
        }}
        input={
          <OutlinedInput
            name="outlined-input"
            id="outlined-input"
            label={t("vehicle_overview.data.driver")}
            size="small"
          />
        }
      >
        <MenuItem value="">
          <em>{t("vehicle_overview.data.no_driver")}</em>
        </MenuItem>
        {tmpNames.map((name) => (
          <MenuItem key={name} value={name}>
            {name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}
