import React, { FunctionComponent, useImperativeHandle, useEffect, useContext, useCallback } from "react"
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  TablePagination,
  Grid,
  TableSortLabel,
  Tooltip,
  Skeleton,
} from "@mui/material"
import { Link } from "react-router-dom"
import { useTranslation } from "react-i18next"
import OpenInNewIcon from "@mui/icons-material/OpenInNew"
import { BASE_PATH, PATH } from "../../../../router/router"
import moment from "moment"
import { CollectionPoint } from "../../../../api/graphql/queries/get-collection-points-with-region-id"
import {
  CollectionPointSortingAttribute,
  ContainerWarningType,
  OrderBy,
} from "../../../../api/graphql/graphql-global-types"
import { ReportProblem } from "@mui/icons-material"
import { hasContainerIssues, hasDefectiveSensors, hasOpenLids } from "../../../../utils/collectionpoint"
import { Material } from "../../../../api/graphql/queries/get-materials"
import {
  useCollectionPointsFilter,
  ICollectionPointTableProps,
} from "../../../../context/CollectionPointsFilterContext"
import { UserContext } from "../../../../context/user-context"
import lodash from "lodash"

interface ICollectionPointsTableProps {
  materials: Material[]
  data: CollectionPoint[]
  onRowClicked: (id: number) => void
  selectedRow: number | undefined
  loading: boolean
  rowNumber: number
  onPaginationChanged: (tableProps: ICollectionPointTableProps) => void
  onSortingChanged: (tableProps: ICollectionPointTableProps) => void
  ref: React.Ref<unknown>
}

const CollectionPointsTableWithoutRef: FunctionComponent<ICollectionPointsTableProps> = (props) => {
  const { tableProps, setTableProps, scrollPosition, setScrollPosition } = useCollectionPointsFilter()
  const { t } = useTranslation()
  const { problemSensorInterval, showOpenLidWarning, showDefectiveSensorWarning } = useContext(UserContext)

  const handleChangePage = (event: any, page: any) => {
    const newTableProps = { ...tableProps, page }
    setTableProps(newTableProps)
    props.onPaginationChanged(newTableProps)
  }

  const handleChangeRowsPerPage = (event: any) => {
    const newTableProps = { ...tableProps, page: 0, pagesize: parseInt(event.target.value, 10) }
    setTableProps(newTableProps)
    props.onPaginationChanged(newTableProps)
  }

  const { data: collectionPoints, onRowClicked, selectedRow, onSortingChanged, materials, ref } = props
  const containerTypes: { [key: string]: string } = {}
  collectionPoints.forEach((collectionPoint) => {
    collectionPoint.collectionPointFilllevels.forEach((filllevel) => {
      containerTypes[filllevel.material_id] =
        materials.find((material) => material.id === filllevel.material_id)?.name || ""
    })
  })
  const rowRefs: { [key: number]: any } = {}

  const scrollToRow = (id: number) => {
    window.scrollTo(0, rowRefs[id].offsetParent.offsetTop + rowRefs[id].offsetTop)
  }

  const getFillLevel = (collectionPoint: CollectionPoint, id: any) => {
    const num = collectionPoint.collectionPointFilllevels.find((filllevel) => filllevel.material_id === id)!.filllevel

    if (num >= 0) return `${num.toFixed()} %`

    return "-"
  }

  useImperativeHandle(ref, () => ({
    scrollToRow: (id: number) => {
      scrollToRow(id)
    },
  }))

  useEffect(() => {
    if (collectionPoints.length !== 0 && scrollPosition !== 0) {
      window.scrollTo(0, scrollPosition)
      setScrollPosition(0)
    }
  }, [collectionPoints, scrollPosition, setScrollPosition])

  const handleClickSorting = (attribute: CollectionPointSortingAttribute, material: string | null) => {
    let newSortingType
    if (attribute === tableProps.order.orderBy && material === tableProps.order.materialIdToSortBy) {
      newSortingType = tableProps.order.sortingType === OrderBy.ASC ? OrderBy.DESC : OrderBy.ASC
    } else {
      newSortingType = OrderBy.ASC
    }
    const newTableProps = {
      ...tableProps,
      order: {
        orderBy: attribute,
        sortingType: newSortingType,
        materialIdToSortBy: material,
      },
      page: 0,
    } as ICollectionPointTableProps
    setTableProps(newTableProps)
    onSortingChanged(newTableProps)
  }

  // TODO: refactor all warnings to be displayed from "containerWarnings" field resolver
  const getCollectionPointFeedbackWarnings = useCallback(
    (collectionPoint: CollectionPoint) => {
      const warnings = lodash.uniqBy(
        collectionPoint.containers
          .flatMap((container) => container.containerWarnings)
          .filter((warning) =>
            [
              ContainerWarningType.COLLECTION_POINT_SWITCHED_TO_DRIVER_FEEDBACK,
              ContainerWarningType.NOT_ENOUGH_FEEDBACK_DATA,
            ].includes(warning.warning_type),
          ),
        (entry) => entry.warning_type,
      )

      return warnings.map((warning, index) => (
        <Grid key={`warning_${collectionPoint.id}_${index}`} item sx={{ display: "flex" }}>
          <Tooltip
            placement="top"
            title={
              t(`collection_points.warnings.${warning.warning_type}`, {
                date: moment(warning.since).format(t("date_format")),
              }) as string
            }
          >
            <ReportProblem sx={{ fill: "#dd8800" }} />
          </Tooltip>
        </Grid>
      ))
    },
    [t],
  )

  const renderSkeleton = () => {
    const rows = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    return (
      <Table aria-label="collection points table">
        <TableHead>
          <TableRow>
            <TableCell align="center">
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center">
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center">
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center">
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center">
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center">
              <Skeleton height={50} />
            </TableCell>
            <TableCell align="center">
              <Skeleton height={50} width={50} />
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((num) => (
            <TableRow key={num} hover selected={num === 1}>
              <TableCell align="center">
                <Skeleton height={10} />
              </TableCell>
              <TableCell align="center">
                <Skeleton height={10} />
              </TableCell>
              <TableCell align="center">
                <Skeleton height={10} />
              </TableCell>
              <TableCell align="center">
                <Skeleton height={10} />
              </TableCell>
              <TableCell align="center">
                <Skeleton height={10} />
                <Skeleton height={10} />
              </TableCell>
              <TableCell align="center">
                <Skeleton height={10} />
                <Skeleton height={10} />
              </TableCell>
              <TableCell align="center">
                <Grid container alignItems="center" justifyContent="center">
                  <Skeleton height={40} width={40} />
                </Grid>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  const renderTable = () => {
    return (
      <Table aria-label="collection points table">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ minWidth: "60px!important" }}>
              <TableSortLabel
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    color:
                      tableProps.order.orderBy !== CollectionPointSortingAttribute.ID
                        ? "rgba(0, 0, 0, 0.1)"
                        : undefined,
                  },
                }}
                direction={
                  tableProps.order.sortingType === OrderBy.ASC ||
                  tableProps.order.orderBy !== CollectionPointSortingAttribute.ID
                    ? "asc"
                    : "desc"
                }
                onClick={() => handleClickSorting(CollectionPointSortingAttribute.ID, null)}
              >
                {t("collection_points.table.id").toUpperCase()}
              </TableSortLabel>
            </TableCell>
            <TableCell align="center" sx={{ maxWidth: 200 }}>
              {t("collection_points.table.description")}
            </TableCell>
            <TableCell align="center" sx={{ maxWidth: 200 }}>
              {t("collection_points.table.address")}
            </TableCell>
            <TableCell align="center">
              <TableSortLabel
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    color:
                      tableProps.order.orderBy !== CollectionPointSortingAttribute.NEXT_EMPTYING
                        ? "rgba(0, 0, 0, 0.1)"
                        : undefined,
                  },
                }}
                direction={
                  tableProps.order.sortingType === OrderBy.ASC ||
                  tableProps.order.orderBy !== CollectionPointSortingAttribute.NEXT_EMPTYING
                    ? "asc"
                    : "desc"
                }
                onClick={() => handleClickSorting(CollectionPointSortingAttribute.NEXT_EMPTYING, null)}
              >
                {t("collection_points.table.date_next_emptying")}
              </TableSortLabel>
            </TableCell>
            <TableCell align="center">
              <TableSortLabel
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    color:
                      tableProps.order.orderBy !== CollectionPointSortingAttribute.LAST_EMTPYING
                        ? "rgba(0, 0, 0, 0.1)"
                        : undefined,
                  },
                }}
                direction={
                  tableProps.order.sortingType === OrderBy.ASC ||
                  tableProps.order.orderBy !== CollectionPointSortingAttribute.LAST_EMTPYING
                    ? "asc"
                    : "desc"
                }
                onClick={() => handleClickSorting(CollectionPointSortingAttribute.LAST_EMTPYING, null)}
              >
                {t("collection_points.table.date_last_emptying")}
              </TableSortLabel>
            </TableCell>
            {Object.keys(containerTypes).map((id: string) => (
              <TableCell key={id} align="center" sx={{ maxWidth: 200 }}>
                <TableSortLabel
                  sx={{
                    "& .MuiTableSortLabel-icon": {
                      color:
                        tableProps.order.orderBy !== CollectionPointSortingAttribute.FILLLEVEL ||
                        tableProps.order.materialIdToSortBy !== id
                          ? "rgba(0, 0, 0, 0.1)"
                          : undefined,
                    },
                  }}
                  direction={
                    tableProps.order.sortingType === OrderBy.ASC ||
                    tableProps.order.orderBy !== CollectionPointSortingAttribute.FILLLEVEL ||
                    tableProps.order.materialIdToSortBy !== id
                      ? "asc"
                      : "desc"
                  }
                  onClick={() => handleClickSorting(CollectionPointSortingAttribute.FILLLEVEL, id)}
                >
                  {containerTypes[Number(id)]}
                </TableSortLabel>
              </TableCell>
            ))}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {collectionPoints.map((collectionPoint) => (
            <TableRow
              ref={(ref: any) => {
                rowRefs[Number(collectionPoint.id)] = ref
              }}
              key={collectionPoint.id}
              hover
              selected={selectedRow && Number(collectionPoint.id) === selectedRow ? true : false}
              onClick={() => onRowClicked(Number(collectionPoint.id))}
            >
              <TableCell align="center" sx={{ minWidth: "60px!important" }}>
                <Grid container alignItems="center" justifyContent="center" direction="column">
                  <Grid item>{Number(collectionPoint.id)}</Grid>
                  <Grid container alignItems="center" justifyContent="center">
                    {hasContainerIssues(collectionPoint, problemSensorInterval) && (
                      <Grid item sx={{ display: "flex" }}>
                        <Tooltip
                          placement="top"
                          title={
                            t("collection_points.last_send_warning", {
                              days: problemSensorInterval,
                            }) as string
                          }
                        >
                          <ReportProblem sx={{ fill: "#dd8800" }} />
                        </Tooltip>
                      </Grid>
                    )}
                    {showDefectiveSensorWarning && hasDefectiveSensors(collectionPoint) && (
                      <Grid item sx={{ display: "flex" }}>
                        <Tooltip placement="top" title={t("collection_points.defective_sensor_warning") as string}>
                          <ReportProblem sx={{ fill: "#dd8800" }} />
                        </Tooltip>
                      </Grid>
                    )}
                    {getCollectionPointFeedbackWarnings(collectionPoint)}
                    {showOpenLidWarning && hasOpenLids(collectionPoint) && (
                      <Grid item sx={{ display: "flex" }}>
                        <Tooltip placement="top" title={t("collection_points.open_lid_warning") as string}>
                          <ReportProblem sx={{ fill: "#ffcc00" }} />
                        </Tooltip>
                      </Grid>
                    )}
                  </Grid>
                </Grid>
              </TableCell>
              <TableCell align="center" sx={{ maxWidth: 200 }}>
                {collectionPoint.description}
              </TableCell>
              <TableCell align="center" sx={{ maxWidth: 200 }}>
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {collectionPoint.street}
                  </Grid>
                  <Grid item xs={12}>
                    {`${collectionPoint.postal || ""} ${collectionPoint.place || collectionPoint.town.name}`}
                  </Grid>
                  {collectionPoint.place &&
                    collectionPoint.town.name &&
                    collectionPoint.place !== collectionPoint.town.name && (
                      <Grid item xs={12}>
                        {collectionPoint.town.name}
                      </Grid>
                    )}
                </Grid>
              </TableCell>
              <TableCell align="center">
                {collectionPoint.next_emptying ? moment(collectionPoint.next_emptying).format(t("date_format")) : "-"}
              </TableCell>
              <TableCell align="center">
                {collectionPoint.last_emptying ? moment(collectionPoint.last_emptying).format(t("date_format")) : "-"}
              </TableCell>
              {Object.keys(containerTypes).map((id) => (
                <TableCell key={`${id}_1`} align="center" sx={{ maxWidth: 200 }}>
                  {collectionPoint.collectionPointFilllevels.find((filllevel) => filllevel.material_id === id) !==
                  undefined ? (
                    <Grid container spacing={1}>
                      <Grid item xs={12}>
                        {getFillLevel(collectionPoint, id)}
                      </Grid>
                      <Grid item xs={12}>
                        {t("collection_points.table.count_containers", {
                          count: collectionPoint.collectionPointFilllevels.find(
                            (filllevel) => filllevel.material_id === id,
                          )!.count_containers,
                        })}
                      </Grid>
                    </Grid>
                  ) : (
                    "-"
                  )}
                </TableCell>
              ))}
              <TableCell align="center">
                <Link
                  onClick={() => setScrollPosition(window.pageYOffset)}
                  to={`${BASE_PATH}/${PATH.COLLECTIONPOINT.id}/${collectionPoint.id}`}
                >
                  <IconButton>
                    <OpenInNewIcon />
                  </IconButton>
                </Link>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    )
  }

  return (
    <Paper sx={{ width: "100%", overflowX: "auto", overflow: "auto" }}>
      {props.loading === true ? renderSkeleton() : renderTable()}

      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.rowNumber}
        rowsPerPage={tableProps.pagesize}
        page={tableProps.page}
        slotProps={{
          actions: {
            previousButton: {
              "aria-label": "previous page",
            },
            nextButton: {
              "aria-label": "next page",
            },
          },
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}

const CollectionPointsTable = React.forwardRef((props: ICollectionPointsTableProps, ref) => (
  <CollectionPointsTableWithoutRef {...props} ref={ref} />
))

export { CollectionPointsTable }
