import React, { FunctionComponent, useRef, MutableRefObject } from "react"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { Card, Grid, Skeleton, useTheme } from "@mui/material"
import { UserService } from "../../../../services/user-service"
import moment from "moment"
import {
  TOP_OVERFILLED_COLLECTION_POINTS_PER_MONTH_QUERY,
  TopOverfilledCollectionPointsResult,
  TopOverfilledCollectionPointsVariables,
  OverfilledEntry,
} from "../../../../api/graphql/queries/top-overfilled-collection-points-per-month"
import { ResponsiveContainer, CartesianGrid, XAxis, YAxis, Label, BarChart, Bar, Tooltip } from "recharts"
import { CHART_COLORS } from "../../../../styles/theme"
import { Text } from "../../../partials/wrapper/text"
import lodash from "lodash"
import { useSelectedRegion } from "../../../../hooks/use-selected-region"

interface ICollectionPointMapping {
  key: string
  collectionPointId: string
}

interface IChartDataEntry {
  [key: string]: ICollectionPointMapping[] | number
  collectionPointMapping: ICollectionPointMapping[]
}

interface ITopOverfilledCollectionPointsChartProps {}

export const TopOverfilledCollectionPointsChart: FunctionComponent<ITopOverfilledCollectionPointsChartProps> = (
  props,
) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const { variables, skip } = useSelectedRegion()
  const ref = useRef(null)
  const rowNum = 5

  const { data, loading } = useQuery<TopOverfilledCollectionPointsResult, TopOverfilledCollectionPointsVariables>(
    TOP_OVERFILLED_COLLECTION_POINTS_PER_MONTH_QUERY,
    {
      variables: {
        ...variables,
        startDate: moment().startOf("day").subtract(12, "months"),
        endDate: moment().startOf("day").subtract(1, "month"),
        rowNum,
      },
      skip,
    },
  )

  const getChartDataEntry = (entries: OverfilledEntry[]) => {
    const chartDataEntry: IChartDataEntry = { collectionPointMapping: [] }

    entries.forEach((entry, index) => {
      const key = `cp${index}`
      chartDataEntry[key] = entry.days
      chartDataEntry.collectionPointMapping.push({
        key: `cp${index}`,
        collectionPointId: entry.collection_point_id,
      })
    })
    return chartDataEntry
  }

  const getData = () => {
    if (!data) {
      return []
    }

    const chartData = data.topOverfilledCollectionPointsPerMonth.map((entry) => ({
      date: moment()
        .year(entry.year)
        .month(entry.month - 1)
        .locale(UserService.getLanguage())
        .format("MMM"),
      ...getChartDataEntry(entry.days_overfilled),
    }))

    return chartData
  }

  // defined width for chart in order to have similar bar sizes
  const chartWidth = 140 + getData().length * 55
  const cardWidth = (ref as MutableRefObject<any>).current?.clientWidth - parseFloat(theme.spacing(4))
  const useCardWidth = chartWidth > cardWidth || loading

  return (
    <Grid item ref={ref} xs={12} md={6}>
      <Card sx={{ p: 1 }} ref={ref}>
        <Text variant="h6" bold>
          {t("top_overfilled_collection_points_chart.title")}
        </Text>
        {loading ? (
          <Skeleton width="100%" height={300} style={{ transform: "scale(1)" }} />
        ) : (
          <ResponsiveContainer width={lodash.max([useCardWidth ? cardWidth : chartWidth, 300])} height={300}>
            <BarChart
              data={getData()}
              margin={{
                left: 70,
                top: 70,
                right: 70,
                bottom: 70,
              }}
              barGap={1}
              barSize={8}
            >
              <CartesianGrid vertical={false} strokeDasharray="3 3" />"
              <XAxis dataKey="date" />
              <YAxis tickFormatter={(value) => Math.round(value).toString()} domain={[0, "dataMax"]}>
                <Label position="top" offset={16}>
                  {t("top_overfilled_collection_points_chart.days_label")}
                </Label>
              </YAxis>
              {lodash.range(rowNum).map((index) => (
                <Bar key={`cp${index}`} dataKey={`cp${index}`} fill={CHART_COLORS[index]} />
              ))}
              <Tooltip
                formatter={(value, name, entry) => {
                  const collectionPointId = (entry.payload.collectionPointMapping as ICollectionPointMapping[]).find(
                    (mapping) => mapping.key === name,
                  )?.collectionPointId
                  return [
                    t("top_overfilled_collection_points_chart.collection_point_info", {
                      id: collectionPointId,
                      days: value,
                    }),
                  ]
                }}
                cursor={false}
              />
            </BarChart>
          </ResponsiveContainer>
        )}
      </Card>
    </Grid>
  )
}
