import React, { FunctionComponent, useState, useEffect, useCallback, useContext } from "react"
import { Dialog, DialogContent, DialogTitle, DialogActions, Button, Grid, TextField, Card } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DeparturePoint } from "../../../../../api/graphql/queries/get-departure-points"
import { SearchMap } from "../../../../partials/maps/search-map"
import { defaultLocation } from "../../../../../utils/map"
import { useMutation } from "@apollo/client"
import {
  CreateDeparturePointResult,
  CreateDeparturePointVariables,
  CREATE_DEPARTURE_POINT_MUTATION,
} from "../../../../../api/graphql/mutations/create-departure-point"
import {
  UpdateDeparturePointResult,
  UpdateDeparturePointVariables,
  UPDATE_DEPARTURE_POINT_MUTATION,
} from "../../../../../api/graphql/mutations/update-departure-point"
import { DeparturePointContext } from "./context/departure-point-context"
import { toast } from "react-toastify"

interface IDeparturePointManagementDialogProps {
  open: boolean
  onClose: () => void
  departurePoint?: DeparturePoint
}

export const DeparturePointManagementDialog: FunctionComponent<IDeparturePointManagementDialogProps> = (props) => {
  const { open, onClose, departurePoint } = props
  const { t } = useTranslation()
  const [openToggle, setOpenToggle] = useState<boolean>(false)
  const { partnerId, refetchDeparturePoints } = useContext(DeparturePointContext)

  const [name, setName] = useState("")
  const [lat, setLat] = useState<string>(`${defaultLocation.latitude}`)
  const [long, setLong] = useState<string>(`${defaultLocation.longitude}`)

  const customOnClose = useCallback(() => {
    onClose()
    setName("")
    setLat(`${defaultLocation.latitude}`)
    setLong(`${defaultLocation.longitude}`)
    setOpenToggle(false)
  }, [setName, setLat, setLong, setOpenToggle, onClose])

  const [createDeparturePointMutation, { loading: createLoading }] = useMutation<
    CreateDeparturePointResult,
    CreateDeparturePointVariables
  >(CREATE_DEPARTURE_POINT_MUTATION, {
    onCompleted: () => {
      customOnClose()
      refetchDeparturePoints()
    },
    onError: () => toast.error(t("generic_error")),
  })

  const [updateDeparturePointMutation, { loading: updateLoading }] = useMutation<
    UpdateDeparturePointResult,
    UpdateDeparturePointVariables
  >(UPDATE_DEPARTURE_POINT_MUTATION, {
    onCompleted: () => {
      customOnClose()
      refetchDeparturePoints()
    },
    onError: () => toast.error(t("generic_error")),
  })

  useEffect(() => {
    if (open && departurePoint && !openToggle) {
      setName(departurePoint.name)
      setLat(`${departurePoint.latitude}`)
      setLong(`${departurePoint.longitude}`)
      setOpenToggle(true)
    }
  }, [name, setName, lat, setLat, long, setLong, open, departurePoint, openToggle])

  const onCreate = useCallback(() => {
    if (partnerId) {
      createDeparturePointMutation({
        variables: {
          collectionPartnerId: `${partnerId}`,
          name,
          latitude: Number(lat),
          longitude: Number(long),
        },
      })
    }
  }, [partnerId, createDeparturePointMutation, name, lat, long])

  const onSave = useCallback(() => {
    if (partnerId && departurePoint?.id) {
      updateDeparturePointMutation({
        variables: {
          id: `${departurePoint.id}`,
          departure_point: {
            collectionPartnerId: `${partnerId}`,
            name,
            latitude: Number(lat),
            longitude: Number(long),
          },
        },
      })
    }
  }, [partnerId, updateDeparturePointMutation, name, lat, long, departurePoint])

  return (
    <Dialog open={open} onClose={customOnClose}>
      <DialogTitle>
        {!!departurePoint?.id ? departurePoint.name : t("partner_overview.departure_point.create_departure_point")}
      </DialogTitle>
      <DialogContent>
        <Grid container spacing={1} sx={{ p: 1 }}>
          <Grid item xs={12}>
            <TextField
              size="small"
              label={t("departure_point.data.name")}
              fullWidth
              variant="outlined"
              value={name}
              onChange={(ev) => {
                setName(ev.target.value)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label={t("departure_point.data.lat")}
              fullWidth
              variant="outlined"
              value={lat}
              onChange={(ev) => {
                setLat(ev.target.value)
              }}
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              size="small"
              label={t("departure_point.data.long")}
              fullWidth
              variant="outlined"
              value={long}
              onChange={(ev) => {
                setLong(ev.target.value)
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Card sx={{ p: 2 }}>
              <SearchMap lat={lat} long={long} setLat={setLat} setLong={setLong} />
            </Card>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button variant="contained" onClick={customOnClose} disabled={createLoading || updateLoading} color="inherit">
          {t("cancel")}
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={!!departurePoint?.id ? onSave : onCreate}
          disabled={createLoading || updateLoading}
        >
          {!!departurePoint?.id ? t("save") : t("create")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
