import React, { FunctionComponent, useCallback } from "react"
import { useTranslation } from "react-i18next"
import { ConfirmDialog } from "../layout/dialog/confirm-dialog"
import { useUnsavedDataContext } from "../../../context/unsaved-data-context"
import { useBlocker } from "react-router-dom"

// https://medium.com/nerd-for-tech/custom-react-router-prompt-d325538b4d2b
export const UnsavedDataPrompt: FunctionComponent = () => {
  const { hasUnsavedData, setHasUnsavedData, unsavedDataTitle, unsavedDataContent } = useUnsavedDataContext()
  const { t } = useTranslation()

  const blocker = useBlocker(({ currentLocation, nextLocation }) => {
    return hasUnsavedData && currentLocation.pathname !== nextLocation.pathname
  })

  const handleOk = useCallback(async () => {
    setHasUnsavedData(false)
    blocker.proceed && blocker.proceed()
  }, [setHasUnsavedData, blocker])

  const handleCancel = useCallback(async () => {
    blocker.reset && blocker.reset()
  }, [blocker])

  return (
    <ConfirmDialog
      open={blocker.state === "blocked"}
      heading={unsavedDataTitle}
      text={unsavedDataContent}
      confirmText={t("yes")}
      negativeText={t("no")}
      onConfirm={handleOk}
      onClose={handleCancel}
    />
  )
}
