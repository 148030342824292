import { Card, Grid } from "@mui/material"
import React, { FunctionComponent } from "react"
import { useTranslation } from "react-i18next"
import { DisposalMerchantStatsOpeningHours } from "../../../../api/graphql/queries/get-disposal-merchant-stats-with-id"
import { OpeningHoursPicker } from "../../../partials/date-picker/opening-hours-picker"
import { Text } from "../../../partials/wrapper/text"

interface IDisposalMerchantOpeningHoursProps {
  openingHours: DisposalMerchantStatsOpeningHours[]
  updateOpeningHours: (openingHours: DisposalMerchantStatsOpeningHours[]) => void
}

export const DisposalMerchantOpeningHours: FunctionComponent<IDisposalMerchantOpeningHoursProps> = (props) => {
  const { t } = useTranslation()
  const { openingHours, updateOpeningHours } = props

  return (
    <Card sx={{ p: 2 }}>
      <Grid item container direction="column" spacing={2}>
        <Grid item>
          <Text bold>{t("disposal_merchant_overview.data.opening_hours")}</Text>
        </Grid>
        <Grid item container direction="column">
          <OpeningHoursPicker
            openingHours={openingHours.map((oh) => ({
              id: String(oh.opening_hours_id),
              active: oh.active,
              day: oh.openingHour.day,
              from: oh.openingHour.from,
              to: oh.openingHour.to,
            }))}
            updateOpeningHours={(openingHours) => {
              updateOpeningHours(
                openingHours.map((oh) => {
                  return {
                    active: oh.active ?? true,
                    openingHour: {
                      id: oh.id ?? "",
                      from: oh.from,
                      to: oh.to,
                      day: oh.day,
                      __typename: "OpeningHour",
                    },
                    opening_hours_id: oh.id ? Number(oh.id) : NaN,
                    __typename: "DisposalMerchantToOpeningHour",
                  }
                }),
              )
            }}
            showCheckboxes
          />
        </Grid>
      </Grid>
    </Card>
  )
}
