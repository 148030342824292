import React, { FunctionComponent, useState, useContext, useEffect, Fragment } from "react"
import {
  Button,
  Grid,
  Card,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Divider,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { Text } from "../../../partials/wrapper/text"
import { isIE } from "../../../../utils/browser"
import { UserManagementContext } from "../context/user-management-context"
import { UserManagementEmptyState } from "./user-management-empty-state"
import { UserManagementTownSelector } from "./user-management-town-selector"
import { UserRole } from "../../../../api/graphql/graphql-global-types"
import { useMutation, useQuery, useLazyQuery } from "@apollo/client"
import {
  CREATE_USER_MUTATION,
  CreateUserResult,
  CreateUserVariables,
} from "../../../../api/graphql/mutations/create-user"
import {
  DELETE_USER_MUTATION,
  DeleteUserResult,
  DeleteUserVariables,
} from "../../../../api/graphql/mutations/delete-user"
import {
  UPDATE_USER_MUTATION,
  UpdateUserResult,
  UpdateUserVariables,
} from "../../../../api/graphql/mutations/update-user"
import { PasswordChanger } from "./password-changer"
import { toast } from "react-toastify"
import { useRefetch } from "../../../../context/refetch-context"
import { User } from "../../../../api/graphql/queries/get-users"
import { ConfirmDialog } from "../../../partials/layout/dialog/confirm-dialog"
import emailValidator from "email-validator"
import { ILocationCollectionItem } from "./location-assignment/location-assignment-dialog"
import { UserService } from "../../../../services/user-service"
import {
  GETCOLLECTIONPARTNERS_QUERY,
  CollectionPartnersResult,
} from "../../../../api/graphql/queries/get-collection-partners"
import { CollectionPartner } from "../../../../api/graphql/queries/get-users"
import {
  DistrictsResult,
  GET_DISTRICTS_FOR_USER_MANAGEMENT_QUERY,
} from "../../../../api/graphql/queries/get-districts-for-user-management"

interface IUserManagementContentProps {}

export const UserManagementContent: FunctionComponent<IUserManagementContentProps> = (props) => {
  const { t } = useTranslation()
  const { selectedUser, isInCreateMode, updateSelectedUser } = useContext(UserManagementContext)
  const { setNeedToRefetch } = useRefetch()

  const onMutationComplete = (message: string, user: User | undefined) => {
    toast.info(message)
    updateSelectedUser(user)
    setNeedToRefetch(true)
  }

  const [createUserMutation, { loading: createLoading }] = useMutation<CreateUserResult, CreateUserVariables>(
    CREATE_USER_MUTATION,
    {
      onCompleted: (data) => onMutationComplete(t("user_management.data.user_created"), data.createUser),
      onError: () => toast.error(t("user_management.data.could_not_create_user")),
    },
  )

  const [updateUserMutation, { loading: updateLoading }] = useMutation<UpdateUserResult, UpdateUserVariables>(
    UPDATE_USER_MUTATION,
    {
      onCompleted: (data) => onMutationComplete(t("user_management.data.user_updated"), data.updateUser),
      onError: () => toast.error(t("user_management.data.could_not_update_user")),
    },
  )

  const [deleteUserMutation, { loading: deleteLoading }] = useMutation<DeleteUserResult, DeleteUserVariables>(
    DELETE_USER_MUTATION,
    {
      onCompleted: () => onMutationComplete(t("user_management.data.user_deleted"), undefined),
      onError: () => toast.error(t("user_management.data.could_not_delete_user")),
    },
  )

  const { data: collectionPartnerData } = useQuery<CollectionPartnersResult>(GETCOLLECTIONPARTNERS_QUERY)

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [firstName, setFirstName] = useState<string>("")
  const [lastName, setLastName] = useState<string>("")
  const [email, setEmail] = useState<string>("")
  const [role, setRole] = useState<UserRole>(UserRole.DRIVER)
  const [password, setPassword] = useState<string>("")
  const [selectedTowns, setSelectedTowns] = useState<ILocationCollectionItem[]>([])
  const [selectedGuidedDriverLocations, setSelectedGuidedDriverLocations] = useState<ILocationCollectionItem[]>([])
  const [selectedAssociations, setSelectedAssociations] = useState<ILocationCollectionItem[]>([])
  const [selectedDistricts, setSelectedDistricts] = useState<ILocationCollectionItem[]>([])
  const [partner, setPartner] = useState<CollectionPartner | null>(null)
  const [toggleOpenDialog, setToggleOpenDialog] = useState<boolean>(false)

  const [getDistricts, { data: districtsData }] = useLazyQuery<DistrictsResult>(
    GET_DISTRICTS_FOR_USER_MANAGEMENT_QUERY,
    {
      onCompleted: () => districtQueryCompleted(),
    },
  )

  useEffect(() => {
    setFirstName(selectedUser ? selectedUser.first_name : "")
    setLastName(selectedUser ? selectedUser.last_name : "")
    setEmail(selectedUser ? selectedUser.email : "")
    setRole(selectedUser ? selectedUser.role : UserRole.DRIVER)
    setPassword("")
    setSelectedAssociations([])
    setSelectedDistricts([])
    setSelectedTowns([])
    setSelectedGuidedDriverLocations([])
    let partner = null

    if (selectedUser && selectedUser.role === UserRole.COLLECTION_PARTNER && collectionPartnerData) {
      partner = selectedUser.collection_partner
    }

    setPartner(partner)
  }, [selectedUser, setFirstName, setLastName, setEmail, collectionPartnerData, getDistricts])

  useEffect(() => {
    setSelectedAssociations([])
    setSelectedDistricts([])
    setSelectedTowns([])
    setSelectedGuidedDriverLocations([])
    if (role !== UserRole.COLLECTION_PARTNER) {
      setPartner(null)
    }
  }, [role, setSelectedAssociations, setSelectedDistricts, setSelectedTowns, setSelectedGuidedDriverLocations])

  const updatePartner = (partner: CollectionPartner) => {
    setPartner(partner)
    if (partner) {
      getDistricts({
        variables: {
          collection_partner_id: partner.id,
        },
      })
    }
  }

  const districtQueryCompleted = () => {
    if (districtsData) {
      setSelectedDistricts(districtsData.getDistrictsForUserManagement)
      setToggleOpenDialog(true)
    }
  }

  const getLocationIds = () => {
    return {
      associationIds: selectedAssociations.map((association) => association.id),
      districtIds: selectedDistricts.map((district) => district.id),
      townIds: selectedTowns.map((town) => town.id),
      guidedDriverLocationIds: selectedGuidedDriverLocations.map((driverLocations) => driverLocations.id),
    }
  }

  const handleCreate = () => {
    createUserMutation({
      variables: {
        email,
        firstName,
        lastName,
        role,
        password,
        ...getLocationIds(),
        collection_partner_id: partner ? String(partner.id) : null,
      },
    })
  }

  const handleUpdate = () => {
    updateUserMutation({
      variables: {
        email,
        firstName,
        lastName,
        role,
        ...getLocationIds(),
        collection_partner_id: partner ? String(partner.id) : null,
      },
    })
  }

  const handleDelete = () => {
    deleteUserMutation({
      variables: {
        email,
      },
    })
    setDeleteDialogOpen(false)
  }

  const locationsValid = ![UserRole.SUPER_ADMIN, UserRole.GUIDED_DRIVER, UserRole.GUIDANCE_ADMIN].includes(role)
    ? selectedAssociations.length > 0 ||
      selectedDistricts.length > 0 ||
      selectedTowns.length > 0 ||
      selectedGuidedDriverLocations.length > 0
    : true

  const createValid =
    firstName &&
    lastName &&
    password &&
    password.length > 6 &&
    role &&
    email &&
    locationsValid &&
    emailValidator.validate(email)

  const updateValid = firstName && lastName && locationsValid

  let disableAction
  if (isInCreateMode) {
    disableAction = !createValid
  } else {
    disableAction = !updateValid
  }

  return (
    <Fragment>
      <ConfirmDialog
        heading={t("user_management.data.delete_dialog.heading")}
        text={t("user_management.data.delete_dialog.text")}
        confirmText={t("user_management.data.delete_dialog.confirm_text")}
        open={deleteDialogOpen}
        onConfirm={handleDelete}
        onClose={() => setDeleteDialogOpen(false)}
      />
      <Card sx={{ minHeight: 310, height: isIE ? "100%" : "calc(100vh - 110px)", overflowY: "auto" }}>
        {/* SHOWN FORM IF IS IN CREATE OR EDIT MODE */}
        {(isInCreateMode || selectedUser) && (
          <Grid container direction="column" sx={{ p: 1, height: "100%" }} justifyContent="space-between">
            <Grid container item direction={"row"} spacing={2} sx={{ mb: 1, flex: isIE ? "1 0 0" : undefined }}>
              <Grid item container direction="row" spacing={2}>
                <Grid item xs={12}>
                  <Text fontSize={18} bold>
                    {selectedUser &&
                      t("user_management.data.title", {
                        firstName: selectedUser.first_name,
                        lastName: selectedUser.last_name,
                      })}
                    {isInCreateMode && t("user_management.new_user.title")}
                  </Text>
                </Grid>
                {/* FORM */}
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    id="outlined-search"
                    fullWidth
                    label={t("user_management.data.first_name")}
                    variant="outlined"
                    value={firstName}
                    onChange={(ev) => {
                      setFirstName(ev.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    id="outlined-search"
                    fullWidth
                    label={t("user_management.data.last_name")}
                    variant="outlined"
                    value={lastName}
                    onChange={(ev) => {
                      setLastName(ev.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <TextField
                    size="small"
                    id="outlined-search"
                    fullWidth
                    label={t("user_management.data.email")}
                    variant="outlined"
                    disabled={!!selectedUser}
                    value={email}
                    onChange={(ev) => {
                      setEmail(ev.target.value)
                    }}
                  />
                </Grid>
                <Grid item xs={6}>
                  <PasswordChanger email={email} value={password} setValue={setPassword} />
                </Grid>
                <Grid item xs={6}>
                  <FormControl fullWidth variant="outlined" size="small">
                    <InputLabel id="role-label">{t("user_management.data.role")}</InputLabel>
                    <Select
                      variant="outlined"
                      labelId="role-label"
                      value={role}
                      fullWidth
                      required
                      onChange={(event) => setRole(event.target.value as UserRole)}
                      label={t("user_management.data.role")}
                    >
                      {Object.values(UserService.getAvailableRoles()).map((userRole) => (
                        <MenuItem key={userRole} value={userRole}>
                          {t(`user_role.${userRole}`)}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                {role === UserRole.COLLECTION_PARTNER && collectionPartnerData && (
                  <Grid item xs={6}>
                    <FormControl fullWidth variant="outlined" size="small">
                      <InputLabel id="role-label">{t("user_management.data.partner")}</InputLabel>
                      <Select
                        variant="outlined"
                        labelId="role-label"
                        value={partner ? partner.id : ""}
                        fullWidth
                        required
                        onChange={(event) =>
                          updatePartner(
                            collectionPartnerData.getCollectionPartners.find(
                              (partner) => event.target.value === partner.id,
                            ) as CollectionPartner,
                          )
                        }
                        label={t("user_management.data.partner")}
                      >
                        {collectionPartnerData.getCollectionPartners.map((partner) => (
                          <MenuItem key={partner.id} value={partner.id}>
                            {partner.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                )}
                {role !== UserRole.SUPER_ADMIN && (
                  <Fragment>
                    <Grid item xs={12}>
                      <Divider />
                    </Grid>
                    <Grid item xs={12}>
                      <UserManagementTownSelector
                        selectedRole={role}
                        selectedAssociations={selectedAssociations}
                        setSelectedAssociations={setSelectedAssociations}
                        selectedDistricts={selectedDistricts}
                        setSelectedDistricts={setSelectedDistricts}
                        selectedTowns={selectedTowns}
                        setSelectedTowns={setSelectedTowns}
                        selectedGuidedDriverLocations={selectedGuidedDriverLocations}
                        setSelectedGuidedDriverLocations={setSelectedGuidedDriverLocations}
                        selectedPartnerId={partner?.id || null}
                        toggleOpenDialog={toggleOpenDialog}
                        setToggleOpenDialog={setToggleOpenDialog}
                      />
                    </Grid>
                  </Fragment>
                )}
              </Grid>
            </Grid>

            {/* BUTTON BAR */}
            <Grid
              item
              xs
              container
              direction={"row"}
              alignItems="flex-end"
              alignContent="flex-end"
              justifyContent="space-between"
              sx={{ mt: isIE ? 5 : undefined }}
            >
              <Grid item>
                {selectedUser && (
                  <Button
                    variant="contained"
                    onClick={() => setDeleteDialogOpen(true)}
                    disabled={createLoading || updateLoading || deleteLoading}
                    color="inherit"
                  >
                    {!deleteLoading && t("user_management.data.delete_user")}
                    {deleteLoading && <CircularProgress size={24} color="primary" />}
                  </Button>
                )}
              </Grid>
              <Grid item>
                <Button
                  variant="contained"
                  type="button"
                  color="primary"
                  onClick={isInCreateMode ? handleCreate : handleUpdate}
                  disabled={createLoading || updateLoading || deleteLoading || disableAction}
                >
                  {!(createLoading || updateLoading) && isInCreateMode && t("user_management.data.create_user")}
                  {!(createLoading || updateLoading) && selectedUser && t("user_management.data.save_user")}
                  {(createLoading || updateLoading) && <CircularProgress size={24} color="primary" />}
                </Button>
              </Grid>
            </Grid>
          </Grid>
        )}

        {/* EMPTY STATE */}
        {!isInCreateMode && !selectedUser && <UserManagementEmptyState />}
      </Card>
    </Fragment>
  )
}
