import React, { PropsWithChildren, createContext, useCallback, useState } from "react"
import { GET_ME_QUERY, User, UserResult } from "../api/graphql/queries/get-me"
import { useLazyQuery } from "@apollo/client"

interface IUserContext {
  user: User | null | undefined
  loadUser: () => void
  resetUser: () => void
  isUserLoading: boolean
  problemSensorInterval: number
  showOpenLidWarning: boolean
  showDefectiveSensorWarning: boolean
}

export const defaultUserContext: IUserContext = {
  user: null,
  loadUser: () => {},
  resetUser: () => {},
  isUserLoading: false,
  problemSensorInterval: 10,
  showOpenLidWarning: true,
  showDefectiveSensorWarning: true,
}

export const UserContext = createContext<IUserContext>(defaultUserContext)

export const UserContextProvider: React.FC<PropsWithChildren<Record<never, never>>> = (props) => {
  const [user, setUser] = useState<User | null | undefined>(null)
  const [loadUser, { loading: isUserLoading }] = useLazyQuery<UserResult>(GET_ME_QUERY, {
    onCompleted: (d) => setUser(d.getMe),
  })

  const resetUser = useCallback(() => {
    setUser(null)
  }, [setUser])

  return (
    <UserContext.Provider
      value={{
        user,
        loadUser,
        resetUser,
        isUserLoading,
        problemSensorInterval: user?.slocUser.problem_sensor_interval ?? 10,
        showOpenLidWarning: user?.slocUser.show_open_lid_warning ?? true,
        showDefectiveSensorWarning: user?.slocUser.showDefectiveSensorWarning ?? false,
      }}
    >
      {props.children}
    </UserContext.Provider>
  )
}
