import store from "store"
import { jwtDecode } from "jwt-decode"
import lodash from "lodash"
import { UserGroups } from "../models/user-groups"
import { UserRole } from "../api/graphql/graphql-global-types"
import { PATH } from "../router/router"
import i18next from "i18next"
import moment from "moment"
import { ApolloClient } from "@apollo/client"

export enum STORE_KEYS {
  TOKEN = "jwt_token",
  DISTRICT = "district",
  ASSOCIATION = "association",
}

export enum USER_TYPE {
  INDUSTRY = "industry",
  COMMUNITY = "community",
}

export class UserService {
  public static login(token: string) {
    store.set(STORE_KEYS.TOKEN, token)
    UserService.switchLanguage()
  }

  public static switchLanguage() {
    const userType = UserService.getUserType()
    const lngPart = `${i18next.language}`.split("-").shift()
    let lng = `${lngPart}`.split("_").shift()

    if (lodash.isNil(lng) || !i18next.hasResourceBundle(lng as string, "translation")) {
      const fallBackLng = lodash.isArray(i18next.options.fallbackLng) ? i18next.options.fallbackLng[0] : null

      if (!lodash.isNil(fallBackLng)) {
        lng = fallBackLng
      }
    }

    switch (userType) {
      case USER_TYPE.COMMUNITY:
        i18next.changeLanguage(`${lng}`)
        break
      case USER_TYPE.INDUSTRY:
        i18next.changeLanguage(`${lng}_industry`)
        break
    }

    // https://momentjscom.readthedocs.io/en/latest/moment/07-customization/16-dow-doy/
    moment.locale(lng, { week: { dow: 1, doy: 4 } })
  }

  public static getUserType(): USER_TYPE {
    try {
      const payload = jwtDecode(store.get(STORE_KEYS.TOKEN))
      return lodash.get(payload, "usertype", "COMMUNITY").toLowerCase() as USER_TYPE
    } catch (e) {
      console.error(e)
      return USER_TYPE.COMMUNITY
    }
  }

  public static isUserLoggedIn(): boolean {
    return !lodash.isNil(store.get(STORE_KEYS.TOKEN))
  }

  public static isIndustry() {
    return UserService.getUserType() === USER_TYPE.INDUSTRY
  }

  public static isAdmin() {
    return UserService.getRole() === UserRole.SUPER_ADMIN
  }

  public static logout(client: ApolloClient<object>) {
    client.clearStore()
    store.remove(STORE_KEYS.TOKEN)
    store.remove(STORE_KEYS.ASSOCIATION)
    store.remove(STORE_KEYS.DISTRICT)
    UserService.switchLanguage()
  }

  public static getRole(): UserRole {
    try {
      const payload = jwtDecode(store.get(STORE_KEYS.TOKEN))
      return lodash.get(payload, "role", "") as UserRole
    } catch (e) {
      return UserRole.SUPER_ADMIN
    }
  }

  public static getAvailableRoles() {
    return Object.values(UserRole).filter((role) =>
      UserGroups.USER_MANAGEMENT_ACCESS_ROLE[role].includes(UserService.getRole()),
    )
  }

  public static getAvailablePaths() {
    return Object.keys(PATH).filter((route) =>
      PATH[route].roles ? PATH[route].roles?.includes(UserService.getRole()) : PATH[route].id !== PATH.LOGIN.id,
    )
  }

  public static hasAssociationFilter() {
    return UserGroups.ASSOCIATION_SELECT.includes(this.getRole())
  }

  public static isInUserGroup(userGroup: UserRole[]): boolean {
    return userGroup.includes(UserService.getRole())
  }

  public static getLanguage() {
    return i18next.language.split("_")[0]
  }
}
