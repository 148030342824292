import React, { FunctionComponent } from "react"
import { Tabs, Tab } from "@mui/material"
import { useTranslation } from "react-i18next"
import { ImageType } from "../../../../api/graphql/queries/get-initial-images-with-collection-point-id"

interface ICustomLightBoxTabsProps {
  imageTypes: ImageType[]
  onSelectionChange: (newValue: string) => void
  selectedImageType: string
}

export const CustomLightBoxTabs: FunctionComponent<ICustomLightBoxTabsProps> = (props) => {
  const { t } = useTranslation()
  const { imageTypes, onSelectionChange, selectedImageType } = props

  return (
    <Tabs
      sx={{ height: "100%" }}
      value={selectedImageType}
      indicatorColor="primary"
      textColor="primary"
      onChange={(event: React.ChangeEvent<{}>, newValue: string) => onSelectionChange(newValue)}
    >
      {imageTypes.map((type) => (
        <Tab
          key={type.id}
          value={type.id}
          label={t(`image_types.${type.name.toLowerCase()}`)}
          sx={{ color: "#fff", fontWeight: "bold", backgroundColor: "rgba(0,0,0,0.5)" }}
        />
      ))}
    </Tabs>
  )
}
