import React, { Fragment, FunctionComponent } from "react"
import { useQuery } from "@apollo/client"
import { CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select } from "@mui/material"
import { useTranslation } from "react-i18next"
import { DriverLocationResult, GET_DRIVER_LOCATIONS_QUERY } from "../../../api/graphql/queries/get-driver-locations"

interface ILocationSelectProps {
  defaultId?: number | null
  handleChange: (selectedLocationId: number) => void
}

export const LocationSelect: FunctionComponent<ILocationSelectProps> = (props) => {
  const { data, loading } = useQuery<DriverLocationResult>(GET_DRIVER_LOCATIONS_QUERY)
  const { defaultId, handleChange } = props
  const { t } = useTranslation()

  return (
    <Fragment>
      {loading && (
        <Grid item container justifyContent="center">
          <CircularProgress color="primary" />
        </Grid>
      )}
      {!loading && (
        <FormControl fullWidth required variant="outlined" size="small">
          <InputLabel id="location-label">{t("routes_management.choose_location")}</InputLabel>
          <Select
            labelId="location-label"
            defaultValue={defaultId || ""}
            fullWidth
            onChange={(e) => handleChange(Number(e.target.value))}
            label={t("routes_management.choose_location")}
          >
            {data?.driverLocations.map((driverLocation) => (
              <MenuItem key={driverLocation.id} value={driverLocation.id}>
                {driverLocation.name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Fragment>
  )
}
