import React, { FunctionComponent, ReactNode } from "react"
import { Dialog, DialogContent, DialogActions, Button, DialogContentText } from "@mui/material"
import { useTranslation } from "react-i18next"

interface IDeleteDialogProps {
  open: boolean
  onClose: () => void
  onDelete: () => void
  content: ReactNode
}

export const DeleteDialog: FunctionComponent<IDeleteDialogProps> = (props) => {
  const { t } = useTranslation()
  const { open, onClose, onDelete, content } = props
  return (
    <Dialog
      open={open}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} autoFocus color="inherit">
          {t("delete_dialog.no")}
        </Button>
        <Button onClick={onDelete} color="error">
          {t("delete_dialog.agree_on_delete")}
        </Button>
      </DialogActions>
    </Dialog>
  )
}
