import React, { Fragment, FunctionComponent } from "react"
import { CardContent, Grid, Paper, Tooltip, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useTourOverview } from "../context/tour-overview-context"
import { UserService } from "../../../../services/user-service"
import { CheckCircle, Warning } from "@mui/icons-material"

export const TourDetail: FunctionComponent = (props) => {
  const { t } = useTranslation()

  const { tourStatsData } = useTourOverview()

  if (!tourStatsData?.getTourStatsWithId?.emptying_stats) {
    return null
  }

  const getIconForDeviation = (amount: number, amountDisposed: number, materialName: string) =>
    Math.abs(amountDisposed - amount) > amountDisposed * 0.25 ? (
      <Tooltip title={t("tour_overview.details.warning")} data-testid={`material_name_${materialName}.on_site_tooltip`}>
        <Warning sx={{ fill: "#ffcc00" }} />
      </Tooltip>
    ) : (
      <CheckCircle sx={{ color: "#58AF47" }} data-testid={`material_name_${materialName}.on_site_success_icon`} />
    )

  return (
    <Paper variant="outlined" sx={{ borderRadius: 2, flex: 1, padding: "2px" }}>
      <CardContent>
        <Grid container>
          <Grid item xs={4}></Grid>
          <Grid item xs={4}>
            <Typography variant={"subtitle1"} sx={{ fontWeight: "bold", textAlign: "center" }}>
              {t("tour_overview.details.on_site")}
            </Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography variant={"subtitle1"} sx={{ fontWeight: "bold", textAlign: "center" }}>
              {t("tour_overview.details.disposal_merchant")}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography sx={{ fontWeight: "bold", textTransform: "uppercase" }}>{t("total")}</Typography>
          </Grid>
          {tourStatsData.getTourStatsWithId.emptying_stats.collected_amount_stats?.map((stats) => (
            <Fragment key={stats.material_id}>
              <Grid item xs={4}>
                <Typography data-testid={`material_name_${stats.material_name}`}>{stats.material_name}</Typography>
              </Grid>
              <Grid item xs={4}>
                <Grid container justifyContent={"center"} spacing={1}>
                  <Grid item>
                    <Typography
                      sx={{ textAlign: "center" }}
                      data-testid={`material_name_${stats.material_name}.on_site`}
                    >
                      {weightFormatted(stats.amount)}
                    </Typography>
                  </Grid>
                  <Grid item>
                    {stats.amountDisposed !== null &&
                      getIconForDeviation(stats.amount, stats.amountDisposed, stats.material_name)}
                  </Grid>
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography
                  sx={{ textAlign: "center" }}
                  data-testid={`material_name_${stats.material_name}.amount_disposed`}
                >
                  {weightFormatted(stats.amountDisposed)}
                </Typography>
              </Grid>
            </Fragment>
          ))}
        </Grid>
      </CardContent>
    </Paper>
  )
}

const weightFormatted = (weight: number | null) => {
  if (typeof weight !== "number") {
    return "-"
  }
  return (
    (weight / 1000).toLocaleString(UserService.getLanguage(), {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }) + " t"
  )
}
