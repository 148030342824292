import React, { FunctionComponent } from "react"
import { Grid, Chip, CircularProgress } from "@mui/material"
import { useQuery } from "@apollo/client"
import {
  GETTOWNS_QUERY,
  TownsResult,
} from "../../../../../api/graphql/queries/get-towns"
import { sortASC } from "../../../../../utils/sort"
import { ILocationCollectionItem } from "./location-assignment-dialog"

interface ITownSelectionProps {
  selectedItems: ILocationCollectionItem[]
  onClick: (item: ILocationCollectionItem) => void
  searchString: string
}

export const TownSelection: FunctionComponent<ITownSelectionProps> = (
  props
) => {
  const { onClick, selectedItems, searchString } = props

  const { data, loading } = useQuery<TownsResult>(GETTOWNS_QUERY)

  const towns =
    data?.getTowns
      .sort((a, b) => sortASC(a.name, b.name))
      .filter((town) =>
        town.name.toLowerCase().includes(searchString.toLowerCase())
      ) || []

  return (
    <Grid container spacing={1}>
      {loading && (
        <Grid item container justifyContent="center">
          <CircularProgress color="primary" />
        </Grid>
      )}
      {!loading &&
        towns.map((town) => (
          <Grid item key={town.id}>
            <Chip
              label={town.name}
              color={
                selectedItems.find(
                  (item) => item.id === town.id && item.name === town.name
                )
                  ? "primary"
                  : "default"
              }
              onClick={() => onClick(town)}
            />
          </Grid>
        ))}
    </Grid>
  )
}
