import React, { FunctionComponent, useState } from "react"
import { Grid, Card, Button, IconButton, TextField, useTheme } from "@mui/material"
import AddIcon from "@mui/icons-material/Add"
import HighlightOffIcon from "@mui/icons-material/HighlightOff"
import { useTranslation } from "react-i18next"
import { useQuery } from "@apollo/client"
import { Text } from "../../../partials/wrapper/text"
import { OutlinedSelect } from "../../../partials/layout/selection/outlined-select"
import { SelectPair } from "../../../partials/layout/selection/select-pair"
import { GETMATERIALS_QUERY, MaterialsResult } from "../../../../api/graphql/queries/get-materials"
import { VehicleToMaterial } from "../../../../api/graphql/queries/get-vehicle-with-id"

interface IVehicleMaterialsProps {
  updateSelectedMaterials: (materials: VehicleToMaterial[]) => void
  selectedMaterials: VehicleToMaterial[]
  title: string
  required?: boolean
}

export const VehicleMaterials: FunctionComponent<IVehicleMaterialsProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { selectedMaterials, updateSelectedMaterials, title, required } = props

  const [materialId, setMaterialId] = useState<string>("")

  let materials: SelectPair[] = []
  const { data: materialsData } = useQuery<MaterialsResult>(GETMATERIALS_QUERY)

  if (materialsData) {
    materials = materialsData!.getMaterials.map((material) => new SelectPair(material.id.toString(), material.name))
    if (materials.length && !materialId) {
      setMaterialId(materials[0].value)
    }
  }

  const getMaterialsForSelect = (selectedMaterial: VehicleToMaterial): SelectPair[] => {
    return materials.filter(
      (material) =>
        material.value === selectedMaterial.material.id.toString() ||
        !selectedMaterials.find((m) => m.material.id.toString() === material.value),
    )
  }

  const updateMaterials = (newValue: string, amount: number, index: number): void => {
    const copy = selectedMaterials.slice()
    copy[index] = {
      amount,
      material: materialsData!.getMaterials.find((material) => material.id.toString() === newValue),
    } as VehicleToMaterial
    updateSelectedMaterials(copy)
  }

  const getNextAvailableMaterial = (): VehicleToMaterial => {
    const material = materialsData!.getMaterials.find(
      (material) => !selectedMaterials.find((selectedMaterial) => material.id === selectedMaterial.material.id),
    )
    return {
      amount: 0,
      material,
    } as VehicleToMaterial
  }

  return (
    <Card sx={{ p: `0 ${theme.spacing(2)} ${theme.spacing(2)}`, height: `calc(100% - ${theme.spacing(2)})` }}>
      <Grid container direction={"column"} spacing={2}>
        <Grid item container alignItems="center" justifyContent="space-between">
          <Grid item>
            <Text bold>{`${title}${required ? "*" : ""}`}</Text>
          </Grid>
          <Grid item>
            <Button
              type="button"
              variant="outlined"
              color="inherit"
              sx={{
                borderWidth: 0.5,
                borderRadius: 1,
                borderColor: "primary.main",
                m: `${theme.spacing(1)} 0 ${theme.spacing(1)} 0`,
              }}
              onClick={() => updateSelectedMaterials(selectedMaterials.concat(getNextAvailableMaterial()))}
              disabled={selectedMaterials.length === materials.length}
              fullWidth
            >
              <AddIcon sx={{ color: selectedMaterials.length !== materials.length ? "primary.main" : undefined }} />
            </Button>
          </Grid>
        </Grid>
        <Grid item container direction="column" spacing={2}>
          {/* grid for managing chambers (material + weights in kg) */}
          {selectedMaterials.length ? (
            selectedMaterials.map((material, index) => (
              <Grid
                item
                container
                direction="row"
                key={`material${index}`}
                justifyContent="space-between"
                alignItems="center"
                spacing={2}
              >
                <Grid item xs>
                  <TextField
                    size="small"
                    label={t("vehicle_overview.data.chamber_amount")}
                    type="number"
                    inputProps={{ min: 0 }}
                    fullWidth
                    variant="outlined"
                    value={material.amount}
                    onChange={(ev) => {
                      updateMaterials(material.material.id, parseInt(ev.target.value, 10), index)
                    }}
                  />
                </Grid>
                <Grid item xs>
                  <OutlinedSelect
                    options={getMaterialsForSelect(material)}
                    name={t("vehicle_overview.data.material")}
                    onValueChange={(newValue) => updateMaterials(newValue, material.amount, index)}
                    value={material.material.id.toString()}
                  />
                </Grid>
                <Grid item>
                  <IconButton
                    color="error"
                    onClick={() =>
                      updateSelectedMaterials(selectedMaterials.filter((selectedMaterial, i) => index !== i))
                    }
                  >
                    <HighlightOffIcon />
                  </IconButton>
                </Grid>
              </Grid>
            ))
          ) : (
            <>
              <Text textAlign="center" color={required ? "error" : "inherit"}>
                {t("vehicle_overview.data.no_chambers") + " "}
                {required && t("vehicle_overview.data.chamber_required")}
              </Text>
            </>
          )}
        </Grid>
      </Grid>
    </Card>
  )
}
