import React, { FunctionComponent, Fragment } from "react"
import { Card, Grid } from "@mui/material"

import { Text } from "../../../partials/wrapper/text"
import { useSelectedDisposalMerchant } from "../../../../context/selected-disposal-merchant-context"
import { DisposalMerchant } from "../../../../api/graphql/queries/get-disposal-merchants"

interface IDisposalMerchantItemProps {
  item: DisposalMerchant
}

export const DisposalMerchantItem: FunctionComponent<IDisposalMerchantItemProps> = (props) => {
  const { setSelectedDisposalMerchantId, selectedDisposalMerchantId } = useSelectedDisposalMerchant()
  const { item } = props

  const onClick = () => {
    setSelectedDisposalMerchantId(props.item.id)
  }

  const isSelected = () => selectedDisposalMerchantId === props.item.id

  const postalTownString = item.postal && item.town ? `${item.postal} ${item.town}` : item.postal || item.town

  return (
    <Fragment>
      <Card
        sx={{
          height: 90,
          m: 1,
          width: 270,
          "&:hover": {
            cursor: "pointer",
          },
          boxShadow: isSelected() ? "0 0 4px 0 rgba(75, 151, 62, 0.5)" : undefined,
        }}
        onClick={onClick}
      >
        <Grid sx={{ height: "100%", p: 1 }} container direction="row">
          <Grid item xs={12}>
            <Text bold noWrap>
              {item.name}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text fontSize={15} noWrap>
              {item.street || "-"}
            </Text>
          </Grid>
          <Grid item xs={12}>
            <Text fontSize={15} noWrap>
              {postalTownString || "-"}
            </Text>
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
