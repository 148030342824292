import React, { FunctionComponent, useContext, useMemo, useCallback, Fragment, useState } from "react"
import {
  Dialog,
  DialogContent,
  DialogActions,
  Button,
  DialogTitle,
  DialogContentText,
  IconButton,
  SnackbarContent,
  Snackbar,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { TourGenerationContext } from "../../../../context/tour-generation-context"
import {
  AvailableDisposalMerchantsForTourGenerationResult,
  AvailableDisposalMerchantsForTourGenerationVariables,
  AVAILABLE_DISPOSAL_MERCHANTS_FOR_TOUR_GENERATION,
} from "../../../../api/graphql/queries/available-disposal-merchants-for-tour-generation"
import { useQuery } from "@apollo/client"
import moment from "moment"
import { PATH } from "../../../../router/router"
import { UserContext } from "../../../../context/user-context"

import CloseIcon from "@mui/icons-material/Close"
import { useNavigate } from "react-router-dom"

interface ITourGenerationDisposalMerchantConfigurationDialogProps {
  open: boolean
  onClose: () => void
}

export const TourGenerationDisposalMerchantAvailabiltyDialog: FunctionComponent<
  ITourGenerationDisposalMerchantConfigurationDialogProps
> = (props) => {
  const { open, onClose } = props
  const { t } = useTranslation()
  const { district, firstWeek, materials, disposalMerchants, setDisposalMerchants, setLoadingDisposalMerchants } =
    useContext(TourGenerationContext)
  const { user } = useContext(UserContext)

  const [showSnackbar, setShowSnackbar] = useState(false)

  const navigate = useNavigate()

  const { data, loading } = useQuery<
    AvailableDisposalMerchantsForTourGenerationResult,
    AvailableDisposalMerchantsForTourGenerationVariables
  >(AVAILABLE_DISPOSAL_MERCHANTS_FOR_TOUR_GENERATION, {
    variables: {
      districtId: district?.id || "",
      startDate: firstWeek.clone().utc().toDate(),
      materialIds: materials.map((material) => material.id),
    },
    skip: !district?.id,

    onCompleted: () => updateAvailableMerchants(),
  })

  const updateAvailableMerchants = useCallback(() => {
    const availableDisposalMerchantsPerDay = data?.availableDisposalMerchantsForTourGeneration.merchantsPerDay
    if (availableDisposalMerchantsPerDay) setDisposalMerchants(availableDisposalMerchantsPerDay)
  }, [data, setDisposalMerchants])

  const daysWithNoAvailableMerchant = useMemo(() => {
    return disposalMerchants.filter((day) => day.disposalMerchants.length === 0)
  }, [disposalMerchants])

  const goToDisposalMerchantsPage = useCallback(() => {
    if (PATH.DISPOSAL_MERCHANT_OVERVIEW.roles?.some((role) => role === user?.role)) {
      navigate(PATH.DISPOSAL_MERCHANT_OVERVIEW.route)
    } else {
      setShowSnackbar(true)
    }
  }, [navigate, user])

  useMemo(() => {
    setLoadingDisposalMerchants(loading)
  }, [loading, setLoadingDisposalMerchants])

  return (
    <Fragment>
      <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
        <DialogTitle>{t("tour_generation.data.attention")}</DialogTitle>
        <DialogContent>
          <DialogContentText>
            {daysWithNoAvailableMerchant.length > 1 &&
              t(
                "tour_generation.disposal_merchant_availability_dialog.no_available_disposal_merchants_for_several_days",
              )}

            {daysWithNoAvailableMerchant.length === 1 &&
              t(
                "tour_generation.disposal_merchant_availability_dialog.no_available_disposal_merchants_for_single_day",
                {
                  day: `${moment(daysWithNoAvailableMerchant[0].day).format(t("dddd"))} ${moment(
                    daysWithNoAvailableMerchant[0].day,
                  ).format(t("date_format"))}`,
                },
              )}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={onClose} variant="contained" color="inherit">
            {t("cancel")}
          </Button>
          <Button onClick={goToDisposalMerchantsPage} color="primary" variant="contained">
            {t("tour_generation.disposal_merchant_availability_dialog.customize_unloading_point")}
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={showSnackbar}
        autoHideDuration={5000}
        onClose={() => setShowSnackbar(false)}
        anchorOrigin={{ horizontal: "right", vertical: "top" }}
      >
        <SnackbarContent
          message={t("forbidden")}
          action={[
            <IconButton key="close" aria-label="close" color="inherit" onClick={() => setShowSnackbar(false)}>
              <CloseIcon />
            </IconButton>,
          ]}
        />
      </Snackbar>
    </Fragment>
  )
}
