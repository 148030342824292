import React, { createContext, useCallback, useContext, useEffect, useState } from "react"
import lodash from "lodash"
import { ExtrapolationWeightingsInput } from "../../../api/graphql/graphql-global-types"
import {
  AdminstrationCollectionPoint,
  GetAdminstrationCollectionPointsWithRegionIDResult,
  GetAdminstrationCollectionPointsWithRegionIDVariables,
  GET_ADMINISTRATION_COLLECTIONPOINT_WITH_REGION_ID_QUERY,
} from "../../../api/graphql/queries/get-administration-collection-points-with-region-id"
import { useQuery } from "@apollo/client"
import { useCollectionPointsFilter } from "../../../context/CollectionPointsFilterContext"
import { useRefetch } from "../../../context/refetch-context"
import { PATH, replaceUriParameter } from "../../../router/router"
import { ApolloError } from "@apollo/client"
import { useSelectedRegion } from "../../../hooks/use-selected-region"
import { useNavigate } from "react-router-dom"

interface ICollectionPointAdministrationProviderProps {
  children: React.ReactNode
  collectionPointId?: number
}

export interface ICollectionPointFormData {
  description: string
  locationNumber: string
  cadastralName: string
  cadastralNumber: string
  isCadastralNumberValid: boolean
  town_id: number | null
  fixedUnloadInterval: number | null
  extrapolationWeightings: ExtrapolationWeightingsInput
  areExtrapolationWeightingsValid: boolean
  fixedInterval: boolean
}

interface ICollectionPointAdministrationContext {
  selectedCollectionPoint: AdminstrationCollectionPoint | undefined
  setSelectedCollectionPoint: (collectionPoint: AdminstrationCollectionPoint | undefined) => void
  formData: ICollectionPointFormData
  updateFormData: (formData: Partial<ICollectionPointFormData>) => void
  isLoading: boolean
  setIsLoading: (value: boolean) => void
  collectionPointsLoading: boolean
  collectionPointsData: GetAdminstrationCollectionPointsWithRegionIDResult | undefined
  collectionPointsError: ApolloError | undefined
}

export const CollectionPointAdministrationContext = createContext<ICollectionPointAdministrationContext>(
  {} as ICollectionPointAdministrationContext,
)

export const useCollectionPointAdministrationContext = (): ICollectionPointAdministrationContext => {
  return useContext(CollectionPointAdministrationContext)
}

const useCollectionPointAdministrationProvider = (
  props: ICollectionPointAdministrationProviderProps,
): ICollectionPointAdministrationContext => {
  const [selectedCollectionPoint, setSelectedCollectionPoint] = useState<AdminstrationCollectionPoint | undefined>(
    undefined,
  )
  const { filter } = useCollectionPointsFilter()
  const { needToRefetch, setNeedToRefetch } = useRefetch()
  const [isLoading, setIsLoading] = useState<boolean>(true)
  const navigate = useNavigate()
  const { variables, skip } = useSelectedRegion()

  const {
    data: collectionPointsData,
    loading: collectionPointsLoading,
    error: collectionPointsError,
  } = useQuery<
    GetAdminstrationCollectionPointsWithRegionIDResult,
    GetAdminstrationCollectionPointsWithRegionIDVariables
  >(GET_ADMINISTRATION_COLLECTIONPOINT_WITH_REGION_ID_QUERY, {
    variables: {
      id: Number(variables.id),
      type: variables.type,
      page: 0,
      pagesize: 1000000,
      filter: {
        ...filter,
        materialIds: [],
      },
    },
    skip,
    fetchPolicy:
      needToRefetch || new URLSearchParams(window.location.search).get("refetch") === "true"
        ? "network-only"
        : "cache-first",
    onCompleted: () => onCollectionPointsQueryFinished(),
  })

  const onCollectionPointsQueryFinished = () => {
    setIsLoading(false)
    setNeedToRefetch(false)
    let selectedCollectionPointId: string | undefined = undefined
    if (selectedCollectionPoint) {
      selectedCollectionPointId = selectedCollectionPoint!.id
    } else if (props.collectionPointId) {
      selectedCollectionPointId = props.collectionPointId.toString()
    }

    if (selectedCollectionPointId && collectionPointsData) {
      const newSelectedCollectionPoint = collectionPointsData.getCollectionPointsWithRegionID.entries.find(
        (collectionPoint) => collectionPoint.id === selectedCollectionPointId,
      )
      setSelectedCollectionPoint(newSelectedCollectionPoint)
      const url = replaceUriParameter(
        `${PATH.COLLECTIONPOINTS_ADMINISTRATION_PRE_SELECT.route}`,
        "collectionPointId",
        selectedCollectionPointId,
      )
      navigate(url)
    } else if (new URLSearchParams(window.location.search).get("refetch") === "true") {
      navigate(PATH.COLLECTIONPOINTS_ADMINISTRATION.route)
    }
  }

  const [formData, setFormData] = useState<ICollectionPointFormData>({
    cadastralName: "",
    cadastralNumber: "",
    isCadastralNumberValid: true,
    description: "",
    locationNumber: "",
    town_id: null,
    fixedUnloadInterval: null,
    extrapolationWeightings: {},
    areExtrapolationWeightingsValid: true,
    fixedInterval: false,
  })

  const updateFormData = useCallback(
    (data: Partial<ICollectionPointFormData>) => {
      setFormData({
        ...formData,
        ...data,
      })
    },
    [formData],
  )

  useEffect(() => {
    let newIsCadastralNumberValid = true
    let newExtrapolationWeightings: ExtrapolationWeightingsInput = {
      monday: 100,
      tuesday: 100,
      wednesday: 100,
      thursday: 100,
      friday: 100,
      saturday: 100,
      sunday: 100,
    }

    if (selectedCollectionPoint?.extrapolation_weightings) {
      newExtrapolationWeightings = lodash.omit(selectedCollectionPoint.extrapolation_weightings, "__typename")
    }

    setFormData({
      description: selectedCollectionPoint?.description || "",
      locationNumber: selectedCollectionPoint?.location_number || "",
      cadastralName: selectedCollectionPoint?.cadastral_name || "",
      cadastralNumber: selectedCollectionPoint?.cadastral_nr || "",
      isCadastralNumberValid: newIsCadastralNumberValid,
      town_id: selectedCollectionPoint?.town.id ? Number(selectedCollectionPoint?.town.id) : null,
      fixedUnloadInterval: selectedCollectionPoint?.fixed_unload_interval || null,
      extrapolationWeightings: newExtrapolationWeightings,
      areExtrapolationWeightingsValid: true,
      fixedInterval: selectedCollectionPoint?.fixedInterval ?? false,
    })
  }, [selectedCollectionPoint])

  return {
    selectedCollectionPoint,
    setSelectedCollectionPoint,
    formData,
    updateFormData,
    isLoading,
    setIsLoading,
    collectionPointsLoading,
    collectionPointsData,
    collectionPointsError,
  }
}

export const CollectionPointAdministrationProvider = (props: ICollectionPointAdministrationProviderProps) => {
  const value = useCollectionPointAdministrationProvider(props)
  return (
    <CollectionPointAdministrationContext.Provider value={value}>
      {props.children}
    </CollectionPointAdministrationContext.Provider>
  )
}
