/*global google*/
import React, { FunctionComponent, useState } from "react"
import { TextField, Card, Grid } from "@mui/material"
import { useTranslation } from "react-i18next"
import PlacesAutocomplete, { geocodeByAddress, getLatLng } from "react-places-autocomplete"
import { useGoogleMap } from "@react-google-maps/api"
import { Text } from "../wrapper/text"

interface IPlaceSearcherProps {}

export const PlaceSearcher: FunctionComponent<IPlaceSearcherProps> = (props) => {
  const { t } = useTranslation()

  const instance = useGoogleMap()
  const [address, setAddress] = useState<string>("")

  const onSelect = async (address: any) => {
    setAddress(address)
    const res = await geocodeByAddress(address)
    const location = await getLatLng(res[0])

    if (instance) {
      ;(instance as any).zoom = 15

      instance.setCenter(new google.maps.LatLng(location.lat, location.lng))
    }
  }

  return (
    <PlacesAutocomplete value={address} onChange={(value) => setAddress(value)} onSelect={onSelect}>
      {({ getInputProps, suggestions, loading }) => (
        <Card sx={{ width: "100%", p: 1 }}>
          <Grid container direction="row" justifyContent="center" alignItems="center">
            <TextField
              size="small"
              {...getInputProps({
                placeholder: t("map.search"),
              })}
              fullWidth
            />
          </Grid>

          <Grid>
            {loading && <Text>{t("map.loading")}.</Text>}

            {suggestions.map((suggestion, idx) => {
              return (
                <Grid
                  key={idx}
                  sx={{
                    backgroundColor: suggestion.active ? "rgba(0, 0, 0, 0.1)" : "#ffffff",
                    cursor: "pointer",
                    m: 1,
                  }}
                  onMouseDown={() => onSelect(suggestion.description)}
                >
                  <Text>{suggestion.description}</Text>
                </Grid>
              )
            })}
          </Grid>
        </Card>
      )}
    </PlacesAutocomplete>
  )
}
