import { useLazyQuery } from "@apollo/client"
import { Grid } from "@mui/material"
import React, { FunctionComponent, useEffect } from "react"
import { useTranslation } from "react-i18next"
import {
  DefectContainersResult,
  DefectContainersResultVariables,
  ExcludedDefectContainersResult,
  GET_DEFECT_CONTAINERS_QUERY,
  IncludedDefectContainersResult,
} from "../../../../../api/graphql/queries/get-defect-containers"
import { ConfirmDialog } from "../../../../partials/layout/dialog/confirm-dialog"
import { Text } from "../../../../partials/wrapper/text"
import { DefectContainer, DefectContainersTable } from "./defect-containers-table"

interface IDefectContainersDialogProps {
  open: boolean
  onClose: () => void
  tourParametersId: string
}

export const DefectContainersDialog: FunctionComponent<IDefectContainersDialogProps> = (props) => {
  const { t } = useTranslation()
  const { open, onClose, tourParametersId } = props

  const [getDefectContainers, { data, loading, error }] = useLazyQuery<
    DefectContainersResult,
    DefectContainersResultVariables
  >(GET_DEFECT_CONTAINERS_QUERY, {
    variables: {
      tourParametersId,
    },
  })

  useEffect(() => {
    if (open) {
      getDefectContainers()
    }
  }, [getDefectContainers, open])

  const getIncluded = (result?: DefectContainersResult): DefectContainer[] => {
    if (!result) {
      return []
    }
    return result.getIncludedDefectContainers.map(mapIncluded)
  }

  const getExcluded = (result?: DefectContainersResult): DefectContainer[] => {
    if (!result) {
      return []
    }
    return result.getExcludedDefectContainers.map(mapExcluded)
  }
  return (
    <ConfirmDialog
      open={open}
      onClose={onClose}
      confirmText={t("ok")}
      heading={t("tour_overview.defect_containers.head")}
      onConfirm={onClose}
      showNegativeButton={false}
      size="lg"
    >
      <Grid container spacing={4} direction="column">
        <Grid item>
          <Text bold variant="h4" align="center">
            {t("tour_overview.defect_containers.included")}
          </Text>
          <DefectContainersTable defectContainers={getIncluded(data)} loading={loading} error={!!error} />
        </Grid>
        <Grid item>
          <Text bold variant="h4" align="center">
            {t("tour_overview.defect_containers.excluded")}
          </Text>
          <DefectContainersTable defectContainers={getExcluded(data)} loading={loading} error={!!error} />
        </Grid>
      </Grid>
    </ConfirmDialog>
  )
}

const mapIncluded = (c: IncludedDefectContainersResult): DefectContainer => ({
  tourParametersId: c.tourParametersId,
  containerId: c.containerId,
  collectionPointId: c.container.collection_point.id,
  filllevel: c.filllevel,
  sequenceNumber: c.sequenceNumber,
  date: c.date,
  materialName: c.material.name,
  sensorId: c.sensorId,
  place: `${c.postal ?? "-"} ${c.cadastral ?? "-"}`,
  street: c.street ?? "-",
  reason: c.reason,
})

const mapExcluded = (c: ExcludedDefectContainersResult): DefectContainer => ({
  tourParametersId: c.tourParametersId,
  containerId: c.containerId,
  collectionPointId: c.container.collection_point.id,
  filllevel: c.filllevel,
  materialName: c.material.name,
  sensorId: c.sensorId,
  place: `${c.postal ?? "-"} ${c.cadastral ?? "-"}`,
  street: c.street ?? "-",
})
