import React, { useContext } from "react"
import { HouseholdWithStatus } from "../../../../api/graphql/queries/household-statuses"
import { Box, Grid, Typography } from "@mui/material"
import { HouseholdOverviewFilterContext } from "../context/household-overview-filter-context"
import moment from "moment"
import { useTranslation } from "react-i18next"

interface IHouseholdMarkerInfoWindowContentProps {
  household: HouseholdWithStatus
}

export const HouseholdMarkerInfoWindowContent: React.FC<IHouseholdMarkerInfoWindowContentProps> = (props) => {
  const { household } = props
  const { t } = useTranslation()
  const { materialOptions } = useContext(HouseholdOverviewFilterContext)

  return (
    <Grid container direction="column" sx={{ p: 1 }}>
      <Grid item>
        <Typography
          sx={{ fontWeight: 700 }}
        >{`${household.street} ${household.number}, ${household.postal} ${household.place}`}</Typography>
      </Grid>
      {household.status.materialStatus.map((status) => (
        <Grid item key={`${household.id}-${status.materialId}`} container alignItems="center" spacing={1} wrap="nowrap">
          <Grid item>
            <Box
              sx={{
                width: 18,
                height: 18,
                borderRadius: 90,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                backgroundColor: status.color,
              }}
            >
              <Box
                sx={{
                  width: 14,
                  height: 14,
                  borderRadius: 90,
                  backgroundColor: "white",
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <Box sx={{ width: 10, height: 10, borderRadius: 90, backgroundColor: status.color }} />
              </Box>
            </Box>
          </Grid>
          <Grid item container>
            <Typography sx={{ fontWeight: 700 }}>
              {materialOptions.find((option) => option.id === status.materialId)?.name} ({status.amountContainers}/
              {status.amountIntendedContainers})
            </Typography>
            <Typography sx={{ ml: 1 }}>
              {t("status")} {status.lastUpdated ? `[${moment(status.lastUpdated).format("DD.MM.YY")}]` : "-"}
            </Typography>
          </Grid>
        </Grid>
      ))}
    </Grid>
  )
}
