import { gql } from "@apollo/client"
import {
  getFlattenedContainerFilllevels,
  getFlattenedContainerFilllevelsVariables,
} from "./types/getFlattenedContainerFilllevels"

export const GET_FLATTENED_CONTAINER_FILLLEVELS_QUERY = gql`
  query getFlattenedContainerFilllevels($id: Int!, $from: DateTime!, $to: DateTime!) {
    getFlattenedContainerFilllevels(id: $id, from: $from, to: $to) {
      timestamp
      value
      isExtrapolated
    }
  }
`

export type FlattenedContainerFilllevelsResult = getFlattenedContainerFilllevels
export type FlattenedContainerFilllevelsVariables = getFlattenedContainerFilllevelsVariables
