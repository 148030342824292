import React, { FunctionComponent } from "react"
import { Card, Grid } from "@mui/material"
import { RefetchProvider } from "../../../context/refetch-context"
import { ContainerWarningsContextProvider } from "../../../context/container-warnings-context"
import { ContainerWarningsTable } from "./partials/container-warnings-table"
import { ContentContainer } from "../../partials/layout/content/content-container"
import { RegionSelect } from "../../partials/region-select/region-select"

interface IContainerWarningsProps {}

export const ContainerWarningsPage: FunctionComponent<IContainerWarningsProps> = (props) => {
  return (
    <RefetchProvider>
      <ContainerWarningsContextProvider>
        <Grid container direction="column" spacing={2}>
          {/* Association Select */}
          <Grid item>
            <ContentContainer>
              <Card sx={{ p: 2 }}>
                <RegionSelect />
              </Card>
            </ContentContainer>
          </Grid>
          <Grid item>
            <ContainerWarningsTable />
          </Grid>
        </Grid>
      </ContainerWarningsContextProvider>
    </RefetchProvider>
  )
}
