import React, { FunctionComponent, Fragment, useState, useEffect } from "react"
import { Card, Grid, Checkbox, FormControlLabel, useTheme } from "@mui/material"
import { useTranslation } from "react-i18next"
import moment from "moment"
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ReferenceLine,
  Label,
} from "recharts"
import lodash from "lodash"
import { IFilllevel } from "./filllevel-chart"

const now = moment()

export interface IFilllevelOverview {
  material_id: number
  material_name: string
  fill_levels: IFilllevel[]
}

interface IFormattedData {
  date: Date
  // key is materialname, value is filllevel
  values: Map<string, IFilllevel>
}

interface IFilllevelOverviewChartProps {
  data?: IFilllevelOverview[]
  prepareFillLevel(fillLevel: number | null): number | null
}

export const FilllevelOverviewChart: FunctionComponent<IFilllevelOverviewChartProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()
  const INTERNAL_DATE_FORMAT = t("date_format")

  const getColor = (material_id: Number): string => {
    switch (material_id) {
      case 1:
        return "#808080"
      case 2:
        return theme.palette.primary.light
      case 3:
        return "#404040"
      default:
        return "#000000"
    }
  }

  const getInitialSelectedMaterials = (): number[] => {
    if (!props.data || !props.data[0]) {
      return []
    } else {
      return props.data.map((item) => item.material_id)
    }
  }

  const handleChangeCheckbox = (material_id: number) => () => {
    const index = selectedMaterials.indexOf(material_id)
    if (index === -1) {
      setSelectedMaterials(selectedMaterials.concat([material_id]))
    } else {
      const selectedMaterialsCopy = selectedMaterials.slice()
      selectedMaterialsCopy.splice(index, 1)
      setSelectedMaterials(selectedMaterialsCopy)
    }
  }

  const [fillLevelData, setFillLevelData] = useState<IFormattedData[]>([])
  const [referenceLineDate, setReferenceLineDate] = useState<Date>(now.clone().toDate())
  const [selectedMaterials, setSelectedMaterials] = useState<number[]>(getInitialSelectedMaterials())

  const findAndSetReferenceLineDate = (filllevelData: IFormattedData[]) => {
    const referenceDate = filllevelData.find((entry) => {
      return now.isSame(entry.date, "day")
    })
    if (referenceDate) {
      setReferenceLineDate(referenceDate.date)
    }
  }

  // update data if selection changed
  useEffect(() => {
    if (!props.data || !props.data[0]) {
      setFillLevelData([])
    } else {
      const formattedData: IFormattedData[] = []

      props.data
        .filter((item) => item.material_id !== null && selectedMaterials.includes(item.material_id))
        .forEach((item) => {
          item.fill_levels.forEach((filllevel) => {
            // filllevel.timestamp is a string
            const filllevelDate = new Date(filllevel.timestamp)
            const existing = formattedData.find((entry) => entry.date.getTime() === filllevelDate.getTime())
            const preparedFillLevel = props.prepareFillLevel(filllevel.value)

            if (existing) {
              existing.values.set(item.material_name, { ...filllevel, value: preparedFillLevel })
            } else {
              formattedData.push({
                date: filllevelDate,
                values: new Map<string, IFilllevel>([[item.material_name, { ...filllevel, value: preparedFillLevel }]]),
              })
            }
          })
        })
      const fillLeveldata = formattedData.sort((a, b) => a.date.getTime() - b.date.getTime())

      findAndSetReferenceLineDate(fillLeveldata)
      setFillLevelData(fillLeveldata)
    }
  }, [props, selectedMaterials])

  const getNonExtrapolatedValue = (key: string, dataPoint: IFormattedData) => {
    const filllevel = dataPoint.values.get(key)
    return filllevel?.isExtrapolated ? null : filllevel?.value ?? null
  }
  const getExtrapolatedValue = (key: string, dataPoint: IFormattedData) => {
    const filllevel = dataPoint.values.get(key)
    return filllevel?.isExtrapolated ? filllevel?.value : null
  }

  return (
    <Fragment>
      <Card>
        <Grid container alignItems="center">
          <Grid item xs={10}>
            <ResponsiveContainer width="100%" height={300}>
              <LineChart
                data={fillLevelData}
                margin={{
                  top: 30,
                  right: 30,
                  left: 30,
                  bottom: 5,
                }}
              >
                <CartesianGrid strokeDasharray="3 3" />"
                <XAxis
                  dataKey={(x) => moment(x.date).format(INTERNAL_DATE_FORMAT)}
                  tickFormatter={(value: string) => moment(value, INTERNAL_DATE_FORMAT).format("D.M.")}
                />
                <YAxis
                  padding={{ top: 0, bottom: 10 }}
                  unit="%"
                  domain={([_dataMin, dataMax]) => [0, dataMax < 100 ? 100 : (lodash.min([115, dataMax]) as number)]}
                  tickFormatter={(value) => Math.round(value).toString()}
                  allowDataOverflow={true}
                />
                <Tooltip
                  formatter={(value, label) => [`${Math.round(Number(value))}%`, `${t("fill_level")} ${label}`]}
                />
                {/* Non Extrapolated lines */}
                {props.data &&
                  props.data.map((d) => (
                    <Line
                      connectNulls
                      key={`N${d.material_id}`}
                      name={d.material_name}
                      type="monotone"
                      dataKey={(dataPoint) => getNonExtrapolatedValue(d.material_name, dataPoint)}
                      stroke={getColor(d.material_id)}
                      dot={false}
                    />
                  ))}
                {/* Extrapolated lines */}
                {props.data &&
                  props.data.map((d) => (
                    <Line
                      // animation offset in ms to look continuous with most non-extrapolated lines
                      animationBegin={1300}
                      connectNulls
                      key={`E${d.material_id}`}
                      type="monotone"
                      name={d.material_name}
                      dataKey={(dataPoint) => getExtrapolatedValue(d.material_name, dataPoint)}
                      stroke={getColor(d.material_id)}
                      dot={false}
                      strokeDasharray={"5 5"}
                    />
                  ))}
                <ReferenceLine
                  x={moment(referenceLineDate).format(INTERNAL_DATE_FORMAT)}
                  strokeWidth={2}
                  label={<Label position="top">{t("today")}</Label>}
                />
              </LineChart>
            </ResponsiveContainer>
          </Grid>
          <Grid container item xs={2} direction="column">
            {props.data &&
              props.data
                .filter((item) => item.material_id !== null)
                .map((d) => (
                  <Grid item key={`${d.material_id}`}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={selectedMaterials.includes(d.material_id)}
                          onChange={handleChangeCheckbox(d.material_id)}
                          style={{ color: getColor(d.material_id) }}
                          value={d.material_id}
                        />
                      }
                      label={d.material_name}
                    />
                  </Grid>
                ))}
          </Grid>
        </Grid>
      </Card>
    </Fragment>
  )
}
