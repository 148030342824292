import React, { FunctionComponent, Fragment, useContext } from "react"
import {
  Paper,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  TablePagination,
  Grid,
  TableSortLabel,
  Tooltip,
} from "@mui/material"
import { CollectionPoint } from "../../../../api/graphql/queries/get-collection-points-with-region-id"
import { CollectionPointSortingAttribute, OrderBy } from "../../../../api/graphql/graphql-global-types"
import { ReportProblem } from "@mui/icons-material"
import { hasContainerIssues } from "../../../../utils/collectionpoint"
import { Material } from "../../../../api/graphql/queries/get-materials"
import {
  useCollectionPointsFilter,
  ICollectionPointTableProps,
} from "../../../../context/CollectionPointsFilterContext"
import { UserContext } from "../../../../context/user-context"
import { useTranslation } from "react-i18next"

interface ICompactCollectionPointsTableProps {
  materials: Material[]
  data: CollectionPoint[]
  onRowClicked: (id: number) => void
  selectedRow: number | undefined
  loading: boolean
  rowNumber: number
  onPaginationChanged: (tableProps: ICollectionPointTableProps) => void
  onSortingChanged: (tableProps: ICollectionPointTableProps) => void
}

export const CompactCollectionPointsTable: FunctionComponent<ICompactCollectionPointsTableProps> = (props, ref) => {
  const { tableProps, setTableProps } = useCollectionPointsFilter()
  const { t } = useTranslation()
  const { problemSensorInterval } = useContext(UserContext)

  const handleChangePage = (event: any, page: any) => {
    const newTableProps = { ...tableProps, page }
    setTableProps(newTableProps)
    props.onPaginationChanged(newTableProps)
  }

  const handleChangeRowsPerPage = (event: any) => {
    const newTableProps = { ...tableProps, page: 0, pagesize: parseInt(event.target.value, 10) }
    setTableProps(newTableProps)
    props.onPaginationChanged(newTableProps)
  }

  const { data: collectionPoints, onRowClicked, selectedRow, onSortingChanged, materials } = props
  const containerTypes: { [key: string]: string } = {}
  collectionPoints.forEach((collectionPoint) => {
    collectionPoint.collectionPointFilllevels.forEach((filllevel) => {
      containerTypes[filllevel.material_id] =
        materials.find((material) => material.id === filllevel.material_id)?.name || ""
    })
  })
  const rowRefs: { [key: number]: any } = {}

  const getFillLevel = (collectionPoint: CollectionPoint, id: any) => {
    const num = collectionPoint.collectionPointFilllevels.find((filllevel) => filllevel.material_id === id)!.filllevel

    if (num >= 0) return `${num.toFixed()} %`

    return "-"
  }

  const handleClickSorting = (attribute: CollectionPointSortingAttribute, material: string | null) => {
    let newSortingType
    if (attribute === tableProps.order.orderBy && material === tableProps.order.materialIdToSortBy) {
      newSortingType = tableProps.order.sortingType === OrderBy.ASC ? OrderBy.DESC : OrderBy.ASC
    } else {
      newSortingType = OrderBy.ASC
    }
    const newTableProps = {
      ...tableProps,
      orderBy: attribute,
      sortingType: newSortingType,
      sortingMaterial: material,
      page: 0,
    }
    setTableProps(newTableProps)
    onSortingChanged(newTableProps)
  }

  return (
    <Paper sx={{ width: "100%", overflowX: "auto", overflow: "auto" }}>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell rowSpan={2} align="center" sx={{ minWidth: "60px!important" }}>
              <TableSortLabel
                sx={{
                  "& .MuiTableSortLabel-icon": {
                    color:
                      tableProps.order.orderBy !== CollectionPointSortingAttribute.ID
                        ? "rgba(0, 0, 0, 0.1)"
                        : undefined,
                  },
                }}
                direction={
                  tableProps.order.sortingType === OrderBy.ASC ||
                  tableProps.order.orderBy !== CollectionPointSortingAttribute.ID
                    ? "asc"
                    : "desc"
                }
                onClick={() => handleClickSorting(CollectionPointSortingAttribute.ID, null)}
              >
                {t("collection_points.table.id").toUpperCase()}
              </TableSortLabel>
            </TableCell>
            <TableCell rowSpan={2} align="center">
              {t("collection_points.table.description")}
            </TableCell>
            <TableCell rowSpan={2} align="center">
              {t("collection_points.table.address")}
            </TableCell>
            {Object.keys(containerTypes).map((id: string) => (
              <TableCell key={id} align="center" colSpan={2}>
                <TableSortLabel
                  sx={{
                    "& .MuiTableSortLabel-icon": {
                      color:
                        tableProps.order.orderBy !== CollectionPointSortingAttribute.FILLLEVEL ||
                        tableProps.order.materialIdToSortBy !== id
                          ? "rgba(0, 0, 0, 0.1)"
                          : undefined,
                    },
                  }}
                  direction={
                    tableProps.order.sortingType === OrderBy.ASC ||
                    tableProps.order.orderBy !== CollectionPointSortingAttribute.FILLLEVEL ||
                    tableProps.order.materialIdToSortBy !== id
                      ? "asc"
                      : "desc"
                  }
                  onClick={() => handleClickSorting(CollectionPointSortingAttribute.FILLLEVEL, id)}
                >
                  {t("collection_points.table.material")} {id} ({containerTypes[Number(id)]})
                </TableSortLabel>
              </TableCell>
            ))}
          </TableRow>
          <TableRow>
            {Object.keys(containerTypes).length > 0 &&
              Object.keys(containerTypes).map((id) => (
                <Fragment key={id}>
                  <TableCell key={`${id}_1`} align="center">
                    {t("collection_points.table.fill_level")}
                  </TableCell>
                  <TableCell key={`${id}_2`} align="center">
                    {t("collection_points.table.number_of_container")}
                  </TableCell>
                </Fragment>
              ))}
          </TableRow>
        </TableHead>
        <TableBody>
          {collectionPoints.map((collectionPoint) => (
            <TableRow
              ref={(ref: any) => {
                rowRefs[Number(collectionPoint.id)] = ref
              }}
              key={collectionPoint.id}
              hover
              selected={selectedRow && Number(collectionPoint.id) === selectedRow ? true : false}
              onClick={() => onRowClicked(Number(collectionPoint.id))}
            >
              <TableCell align="center" sx={{ minWidth: "60px!important" }}>
                <Grid container alignItems="center" justifyContent="center" direction="column">
                  <Grid item>{Number(collectionPoint.id)}</Grid>
                  {hasContainerIssues(collectionPoint, problemSensorInterval) && (
                    <Grid item sx={{ display: "flex" }}>
                      <Tooltip
                        placement="top"
                        title={
                          t("collection_points.last_send_warning", {
                            days: problemSensorInterval,
                          }) as string
                        }
                      >
                        <ReportProblem sx={{ fill: "#ffcc00" }} />
                      </Tooltip>
                    </Grid>
                  )}
                </Grid>
              </TableCell>
              <TableCell align="center">{collectionPoint.description}</TableCell>
              <TableCell align="center">
                <Grid container spacing={1}>
                  <Grid item xs={12}>
                    {collectionPoint.street}
                  </Grid>
                  <Grid item xs={12}>
                    {`${collectionPoint.postal || ""} ${collectionPoint.place || collectionPoint.town.name}`}
                  </Grid>
                  {collectionPoint.place &&
                    collectionPoint.town.name &&
                    collectionPoint.place !== collectionPoint.town.name && (
                      <Grid item xs={12}>
                        {collectionPoint.town.name}
                      </Grid>
                    )}
                </Grid>
              </TableCell>
              {Object.keys(containerTypes).map((id) =>
                collectionPoint.collectionPointFilllevels.find((filllevel) => filllevel.material_id === id) !==
                undefined ? (
                  <Fragment key={id}>
                    <TableCell key={`${id}_1`} align="center">
                      {getFillLevel(collectionPoint, id)}
                    </TableCell>
                    <TableCell key={`${id}_2`} align="center">
                      {collectionPoint.collectionPointFilllevels
                        .find((filllevel) => filllevel.material_id === id)!
                        .count_containers.toFixed()}
                    </TableCell>
                  </Fragment>
                ) : (
                  <Fragment key={id}>
                    <TableCell key={`${id}_1`} align="center">
                      -
                    </TableCell>
                    <TableCell key={`${id}_2`} align="center">
                      -
                    </TableCell>
                  </Fragment>
                ),
              )}
            </TableRow>
          ))}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[10, 25, 100]}
        component="div"
        count={props.rowNumber}
        rowsPerPage={tableProps.pagesize}
        page={tableProps.page}
        slotProps={{
          actions: {
            previousButton: {
              "aria-label": "previous page",
            },
            nextButton: {
              "aria-label": "next page",
            },
          },
        }}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </Paper>
  )
}
