import React, { FunctionComponent, useMemo } from "react"
import CheckCircleIcon from "@mui/icons-material/CheckCircle"
import ErrorIcon from "@mui/icons-material/Error"
import InfoIcon from "@mui/icons-material/Info"
import SnackbarContent from "@mui/material/SnackbarContent"
import WarningIcon from "@mui/icons-material/Warning"
import { SxProps, Theme } from "@mui/material/styles"
import { Box } from "@mui/material"

const variantIcon = {
  success: CheckCircleIcon,
  warning: WarningIcon,
  error: ErrorIcon,
  info: InfoIcon,
}

interface ICustomSnackBarContentProps {
  sx?: SxProps<Theme>
  message: string
  onClose?: () => void
  variant: "success" | "warning" | "error" | "info"
}

export const CustomSnackBarContent: FunctionComponent<ICustomSnackBarContentProps> = (props) => {
  const { sx, message, onClose, variant, ...other } = props
  const Icon = variantIcon[variant]

  const backgroundColor = useMemo(() => {
    switch (variant) {
      case "success":
        return "success.main"
      case "error":
        return "error.main"
      case "info":
        return "primary.main"
      case "warning":
        return "warning.main"
      default:
        return "#000"
    }
  }, [variant])

  return (
    <SnackbarContent
      sx={{ ...sx, backgroundColor }}
      aria-describedby="client-snackbar"
      message={
        <Box component="span" id="client-snackbar" sx={{ display: "flex", alignItems: "center" }}>
          <Icon sx={{ fontSize: 20, opacity: 0.9, mr: 1 }} />
          {message}
        </Box>
      }
      {...other}
    />
  )
}
