import React, { FunctionComponent, Fragment, useState } from "react"
import {
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
  Backdrop,
  CircularProgress,
  Skeleton,
  useTheme,
} from "@mui/material"
import { useTranslation } from "react-i18next"
import { RoutesForLocation } from "../../../../api/graphql/queries/get-routes-for-location"
import {
  DELETE_ROUTE_MUTATION,
  DeleteRouteResult,
  DeleteRouteVariables,
} from "../../../../api/graphql/mutations/delete-route"
import { useMutation } from "@apollo/client"
import moment from "moment"
import { Delete, Map } from "@mui/icons-material"
import { useRefetch } from "../../../../context/refetch-context"
import { toast } from "react-toastify"
import { DeleteDialog } from "../../../partials/layout/dialog/delete-dialog"
import { PATH } from "../../../../router/router"
import { useNavigate } from "react-router-dom"

interface IRoutesTableProps {
  routes: RoutesForLocation[]
  loading: boolean
}

export const RoutesTable: FunctionComponent<IRoutesTableProps> = (props) => {
  const theme = useTheme()
  const { t } = useTranslation()
  const { routes, loading } = props

  const { setNeedToRefetch } = useRefetch()

  const [deleteDialogOpen, setDeleteDialogOpen] = useState<boolean>(false)
  const [currentId, setCurrentId] = useState<string | null>(null)
  const navigate = useNavigate()

  const [deleteRoute, { loading: deleteLoading }] = useMutation<DeleteRouteResult, DeleteRouteVariables>(
    DELETE_ROUTE_MUTATION,
  )

  const deleteClicked = async (id: string) => {
    setCurrentId(id)
    setDeleteDialogOpen(true)
  }

  const editClicked = (id: string) => {
    const path = PATH.ROUTE_DETAILS.route.replace(":routeId", id)
    navigate(path)
  }

  const deleteConfirmed = async () => {
    const result = await deleteRoute({
      variables: {
        id: String(currentId),
      },
    })

    if (result.data?.deleteRoute) {
      toast.info(t("routes_management.route_deleted"))
    } else {
      toast.error(t("routes_management.could_not_delete_route"))
    }
    closeDialog()
    setNeedToRefetch(true)
  }

  const closeDialog = () => {
    setDeleteDialogOpen(false)
    setCurrentId(null)
  }

  return (
    <>
      <Backdrop open={deleteLoading} sx={{ zIndex: theme.zIndex.modal + 1 }}>
        <CircularProgress color="primary" />
      </Backdrop>
      <DeleteDialog
        open={deleteDialogOpen}
        onClose={() => closeDialog()}
        content={t("routes_management.delete_dialog_text_route")}
        onDelete={() => deleteConfirmed()}
      />
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell align="center" sx={{ width: 40 }}>
              {t("routes_management.table.id")}
            </TableCell>
            <TableCell align="center">{t("routes_management.table.filename")}</TableCell>
            <TableCell align="center">{t("routes_management.table.name")}</TableCell>
            <TableCell align="center">{t("routes_management.table.date")}</TableCell>
            <TableCell sx={{ width: 40 }}></TableCell>
            <TableCell sx={{ width: 40, borderRight: "none" }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {loading && (
            <TableRow>
              <TableCell align="center" sx={{ width: 40 }}>
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center">
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center">
                <Skeleton height={50} />
              </TableCell>
              <TableCell align="center">
                <Skeleton height={50} />
              </TableCell>
              <TableCell sx={{ width: 40 }}>
                <Skeleton height={50} />
              </TableCell>
              <TableCell sx={{ width: 40, borderRight: "none" }}>
                <Skeleton height={50} />
              </TableCell>
            </TableRow>
          )}
          {!loading &&
            routes.map((route, index) => (
              <TableRow key={route.id}>
                <TableCell
                  align="center"
                  sx={{ width: 40, borderBottom: index === routes.length - 1 ? "none" : undefined }}
                >
                  {route.id}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: index === routes.length - 1 ? "none" : undefined }}>
                  {route.filename}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: index === routes.length - 1 ? "none" : undefined }}>
                  {route.name}
                </TableCell>
                <TableCell align="center" sx={{ borderBottom: index === routes.length - 1 ? "none" : undefined }}>
                  {moment(route.inserted_at).format(t("date_time_format"))}
                </TableCell>
                <TableCell
                  sx={{ width: 40, borderBottom: index === routes.length - 1 ? "none" : undefined }}
                  align="center"
                >
                  <IconButton onClick={() => editClicked(route.id)}>
                    <Map />
                  </IconButton>
                </TableCell>
                <TableCell
                  sx={{
                    width: 40,
                    borderRight: "none",
                    borderBottom: index === routes.length - 1 ? "none" : undefined,
                  }}
                >
                  <IconButton onClick={() => deleteClicked(route.id)}>
                    <Delete />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </>
  )
}
