import React, { FunctionComponent } from "react"
import { Grid, useTheme } from "@mui/material"
import { PageHeading } from "../../../partials/layout/heading/page-heading"
import { useTranslation } from "react-i18next"
import { RefetchProvider } from "../../../../context/refetch-context"
import { DisposalMerchantSelector } from "./disposal-merchant-selector"
import { DisposalMerchantData } from "./disposal-merchant-data"

interface IDisposalMerchantOverviewProps {}

export const DisposalMerchantOverview: FunctionComponent<IDisposalMerchantOverviewProps> = (props) => {
  const { t } = useTranslation()
  const theme = useTheme()

  return (
    <Grid
      container
      sx={{
        height: "calc(100vh - 56px)", // 56px equals all paddings and margins
        display: "flex",
      }}
    >
      <Grid item xs={12} sx={{ p: `0 ${theme.spacing(1)}`, mb: 1, width: "100%" }}>
        <PageHeading>{t("disposal_merchant_overview.heading")}</PageHeading>
      </Grid>
      <Grid container item direction="row" sx={{ padding: `0 ${theme.spacing(1)}`, overflowY: "auto", height: "100%" }}>
        <RefetchProvider>
          <DisposalMerchantSelector />
          <Grid
            sx={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              height: "100%",
              pl: 2,
              pr: 2,
              ml: 2,
              overflowY: "auto",
            }}
          >
            <Grid item>
              <DisposalMerchantData />
            </Grid>
          </Grid>
        </RefetchProvider>
      </Grid>
    </Grid>
  )
}
